import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ERPSHIPService } from '../erpship.service';
import { MenuItem, MessageService } from 'primeng/api';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-manifest',
  templateUrl: './track-shipment.component.html',
  styleUrls: ['./track-shipment.component.scss'], providers: [MessageService],
})
export class TrackShipmentComponent implements OnInit {
actions = [
  { label: 'Edit', icon: 'pi pi-pencil', command: () => this.edit() },
  { label: 'Delete', icon: 'pi pi-trash', command: () => this.delete() }
];

edit() {
  console.log('Edit action');
}

delete() {
  console.log('Delete action');
}
  save(arg0: string) {
    throw new Error('Method not implemented.');
  }
  onHide() {
  throw new Error('Method not implemented.');
  }
  RecipientPopup() {
  throw new Error('Method not implemented.');
  }
  CostCenterPopup() {
  throw new Error('Method not implemented.');
  }
  ShipperNamePopup() {
  throw new Error('Method not implemented.');
  }
  UserPopup() {
  throw new Error('Method not implemented.');
  }

  @ViewChild('dt') dt: any;

  template: any;
  first = 0;
  rows = 10;

  filters:any={};
  
  isDataLoaded: boolean = false;
  passingid: any = '';
  shipRequests: any[] = [];
  Org_shipRequests:any[]=[];
  selectedShipRequest: any[] = [];
  totalRecords = 0;
  tableProperties_dialog: boolean = false;

  xfields: any[] = [];
  xcolumns: any[] = [];
  Carrier='';
  shipDateFrom: Date= new Date();
  shipDateTo: Date=new Date();
  documentType='';
  conditions:any ={}
  filterdialogFlag=false;
  filterFields:any=[];

  //Added fields
  filterForm: FormGroup | undefined;
  locationOptions: any[] = []; // Populate with real data
  supplierLocationOptions: any[] = []; // Populate with real data
  carrierOptions: any[] = []; // Populate with real data
  regionOptions: any[] = []; // Populate with real data
  statusOptions: any[] = []; // Populate with real data
  paymentTypeOptions: any[] = []; // Populate with real data
  shipperOptions: any[] = []; // Populate with real data
  costCenterOptions: any[] = []; // Populate with real data
  countryOptions: any[] = []; // Populate with real data
  feederSystemOptions: any[] = []; // Populate with real data
  
  selectedLocation: any;
  selectedSupplierLocation: any;
  selectedCarrier: any;
  selectedRegion: any;
  selectedStatus: any;
  selectedPaymentType: any;
  selectedShipper: any;
  selectedCostCenter: any;
  selectedCountry: any;
  selectedFeederSystem: any;

  items: MenuItem[];
  Track: any;

  searchTrackData(action: string) {
    // Implement your search logic here
  }

  changeStatus(status: number) {
      // Implement your change status logic here
  }
  constructor(private router: Router, public messageService: MessageService, private xfservice: ERPSHIPService, private fb: FormBuilder) {
    this.xfservice.processAuth().subscribe((data) => { }, error => {
      console.log("No token found")
      this.router.navigate(['']);
    });
    this.items = [
      {
          label: 'Copy',
          command: () => {
              this.Copy();
          }
      },
      {
          label: 'View',
          command: () => {
              this.View();
          }
      },
      {
          label: 'Update Status',
          command: () => {
              this.Status();
          }
      }
    ];
  }

    //Action from Table
    View() {
      throw new Error('Method not implemented.');
    }
    Status() {
      throw new Error('Method not implemented.');
    }
    Copy() {
      throw new Error('Method not implemented.');
    }

  ngOnInit(): void {
    //Added
    this.filterForm = this.fb.group({
      location: [null, Validators.required],
      supplierLocation: [null, Validators.required],
      fromDate: [null, Validators.required],
      toDate: [null, Validators.required],
      deliveryNumber: [null],
      status: [null],
      carrier: [null],
      region: [null],
      trackingNumber: [null],
      userName: [null, Validators.required],
      paymentType: [null],
      shipperName: [null],
      costCenter: [null],
      supplier: [null],
      poNumber: [null],
      shipToCompany: [null],
      feederSystem: [null],
      country: [null]
    });
    
    this.xfservice.getLocationMasterData().subscribe((data) => {
      this.countryOptions = data.countrylist;
      this.statusOptions = data.shipmentstatuses;
      this.carrierOptions = data?.carriers;
      this.locationOptions = data?.location;
    });
    
    // Load options for dropdowns
    this.loadOptions();
    this.shipDateFrom = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 7);
    //Old Code
    this.xfields=[];
    this.xfservice.getScreenTemplateByName('ManifestTable')
    .subscribe((mdata:any)=>{
      if (mdata.length >0)
      {
        // this.xfields=mdata[0].details;
        // Update each object's 'title' property by adding spaces before capital letters
        this.xfields = mdata[0].details.map(detail => ({
          ...detail,
          IncludeHeader: this.addSpacesToTitles(detail.IncludeHeader),
          fields: detail.fields.map(field => ({
            ...field,
            title: this.addSpacesToTitles(field.title)
          }))
        }));
      }
     //console.log(JSON.stringify(this.xfields))
      
      this.xfields.forEach((item)=>{
        // console.log(JSON.stringify(item))
        item.fields.forEach((field:any)=>{
          this.xcolumns.push(item.fieldHeader +'.' +field.fieldName)
          if(field['filterField']==true && field['fieldName']!= "ShipDate") 
          {
            this.filters[item.fieldHeader +'.' +field.fieldName]='';
          }
          else if(field['fieldName']=="ShipDate"){
            this.filters['shipDateFrom']='';
            this.filters['shipDateTo']='';
          }
        })
      })
      console.log(this.filters)
   
      //this.xcolumns=["HeaderInfo.ERP","HeaderInfo.DocumentType"]
      //'----------------------------------------'
      this.xfservice.getScreenTemplateByName('ShipScreen')
      .subscribe((tdata: any) => {
        this.template = tdata[0].details;
        if( this.xfields.length < 1)
        {
          Object.keys(this.template).forEach((key: string) => { 
            if(key != 'FOOTER_LEFT' && key != 'FOOTER_RIGHT' && key!='MasterData'  && key!='Items'  && key!='Packages'  && key!='InternationalDetails' ){
              this.xfields.push({ visible: true, fieldHeader: key, fields: this.template[key].fields }) 
            }
          })
        }
        
        console.log(this.xfields)
        //ShipRequest //shipments
        console.log(' Adding from shiprequest fields')
        this.xfservice
        .getxFormRecordsByFromName('ShipRequest')
        .subscribe((data: any) => {
          data.forEach((item: any) => {
            this.shipRequests.push(item.details)
          });

          this.Org_shipRequests = Array.from(this.shipRequests)
          this.totalRecords = data.length;
          this.isDataLoaded = true;
        });
      })
      //--------------------------------------------
    })
  }

  addSpacesToTitles(text: string): string {
    return text.replace(/([a-z])([A-Z])/g, '$1 $2');
  }
  
  loadOptions() {
    // Fetch or initialize dropdown options here
    this.locationOptions = [{ label: 'Elemica', value: 'elemica' }]; // Example data
    this.supplierLocationOptions = [{ label: 'Supplier Location 1', value: 'supplier1' }];
    this.regionOptions = [{ label: 'Region 1', value: 'region1' }];
    this.statusOptions = [{ label: 'Status 1', value: 'status1' }];
    this.paymentTypeOptions = [    { label: 'Sender', value: 'Sender' },
      { label: 'Receipient', value: 'Receipient' },
      { label: 'Third Party', value: 'Third Party' },
      { label: 'Collect', value: 'Collect' }];
    this.shipperOptions = [{ label: 'Shipper 1', value: 'shipper1' }];
    this.costCenterOptions = [{ label: 'Cost Center 1', value: 'costcenter1' }];
    this.countryOptions = [{ label: 'Country 1', value: 'country1' }];
    this.feederSystemOptions = [{ label: 'Feeder System 1', value: 'feedersystem1' }];
  }

  onSubmit() {
    if (this.filterForm?.valid) {
      // Process form data
      console.log(this.filterForm.value);
    }
  }

  // Example of a method to handle dropdown changes
  onDropdownChange(event: any) {
    // Handle dropdown changes
  }
  //Open Table properties
  openTableProperties() {
    this.tableProperties_dialog = true;
  }

  //Save Manifest Table properties
  // Save/Update TemplateChanges
  SaveTemplate(templatename: any): void {
    this.xfields.forEach((item)=>{
      if (item.visible==true)
      {
        if(item.IncludeHeader ==undefined)
           item["IncludeHeader"]=""
      }
    })

    this.xfservice
      .saveScreenTemplateByName(templatename, this.xfields)
      .subscribe((data) => {

        this.tableProperties_dialog = false
        this.messageService.add({
          key: 'tc',
          severity: 'success',
          summary: 'Template',
          detail: templatename + ' Properties are successfully saved.',
        });


      });

  }

  selectedDateRange: string = '';

  formatDate(date: Date): string {
    const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: '2-digit', day: '2-digit' };
    return new Intl.DateTimeFormat('en-US', options).format(date);
  }

  applyFilterGlobal($event: any, stringVal: any): void {
    if (($event.target as HTMLInputElement).value) {
      this.shipRequests = this.filterNestedArray(this.Org_shipRequests, ($event.target as HTMLInputElement).value)
    } else {
      this.shipRequests = Array.from(this.Org_shipRequests);
    }
  }

   filterNestedArray(jsonArray: any[], searchTerm: string): any[] {
    return jsonArray.filter(item => {
      if (typeof item === 'object' && item !== null) {
        return Object.values(item).some(value => this.filterNestedArray([value], searchTerm).length > 0);
      }
      return String(item).includes(searchTerm);
    });
  }

  getShipRequest(){
    if(this.Carrier != '')
      this.conditions["Carrier"]="details->'CarrierDetails'->>'Carrier'='" + this.Carrier +"'"

    if(this.documentType !='')
      this.conditions["DocumentType"]="details->'HeaderInfo'->>'DocumentType'='" + this.documentType +"'";
    
    console.log(JSON.stringify(this.conditions))
  }
  
  applyFilters(){
    var fromdate=''
    var toDate=''
    console.log(this.filters['shipDateFrom'])
    if(this.filters['shipDateFrom']!='' && this.filters['shipDateto']!=''){
    
      this.shipDateFrom=this.filters['shipDateFrom'];
      this.shipDateTo=this.filters['shipDateTo']
      console.log(this.shipDateFrom)
    
      fromdate += this.shipDateFrom.getFullYear();
      if((this.shipDateFrom.getMonth() + 1) < 10){
       fromdate += '-0' +  (this.shipDateFrom.getMonth()+1) 
      }
      else
       fromdate += '-' + (this.shipDateFrom.getMonth()+1)
      if(this.shipDateFrom.getDate()<10)
       fromdate += '-0' +this.shipDateFrom.getDate();
      else
       fromdate += '-' + this.shipDateFrom.getDate();
      toDate += this.shipDateTo.getFullYear() ;
      if((this.shipDateTo.getMonth() + 1) < 10){
       toDate += '-0' +  (this.shipDateTo.getMonth()+1) 
      }
      else
       toDate += '-' + (this.shipDateTo.getMonth()+1)
      if(this.shipDateTo.getDate()<10)
       toDate += '-0' +this.shipDateTo.getDate();
      else
       toDate += '-' + this.shipDateTo.getDate();  
   
      this.conditions['shipdate']="details->'HeaderInfo'->>'ShipDate' between '" + fromdate +"' and '" + toDate +"'"
    }

    var keys=Object.keys(this.filters);
    console.log(keys)
    keys.forEach((item,index)=>{
      var condition='';
      var fields: any =[];
      if(item !="shipDateFrom" && item !="shipDateTo"){
        if(this.filters[item]!=''){
          fields=item.split('.')
          condition += "details->'" + fields[0] + "'->>'" + fields[1] +"'='" + this.filters[item] +"'";
          console.log(condition)
          this.conditions["condition" + index]=condition
        }
      }
    })
    console.log(JSON.stringify(this.conditions))
    this.xfservice.getShipRequestByFilters(JSON.stringify(this.conditions)).subscribe((data:any)=>{ 
      this.shipRequests=data
      this.totalRecords = data.length;
      this.isDataLoaded = true;
    });

  }   
    

  
}
