<div style="height: 93vh !important;" class="row p-2">
  <div class="col-md-6">
    <div class="form-section">
      <p-card class="titleTypoh3 ">
        <div class="header-container">
          <div class="p-card-title align-btm title-border">Ship From</div>
        </div>
        <div class="form-grid">
          <div class="form-group">
            <label for="locationFrom">Location Id</label>
            <div class="p-inputgroup p-input-icon-right">   
              <i class="pi pi-search" (click)="showPopup()"></i>
              <input pInputText placeholder="Location Name" id="locationFrom" [(ngModel)]="shipment.ShipFrom.LOCATIONID" [disabled]="isDisabled" />
            </div>
          </div>
          <div class="form-group">
            <label for="">Location Name</label>
            <input pInputText placeholder="Location Name" id="locationFrom" [(ngModel)]="shipment.ShipFrom.LOCATIONNAME" [disabled]="isDisabled" />
        </div>         
          <div class="form-group">
            <label for="company">Company Name</label>
            <input pInputText id="company" [(ngModel)]="shipment.ShipFrom.COMPANY" [disabled]="isDisabled"/>
          </div>         
          <div class="form-group">
            <label for="contact">Contact Name</label>
            <input pInputText id="contact" [(ngModel)]="shipment.ShipFrom.CONTACT" [disabled]="isDisabled"/>
          </div>
          <div class="form-group">
            <label for="company">Address Line 1</label>
            <input pInputText id="addressLine1" [(ngModel)]="shipment.ShipFrom.ADDRESS_LINE1" [disabled]="isDisabled"/>
          </div>
          <div class="form-group">
            <label for="contact">Address Line 2</label>
            <input pInputText id="addressLine2" [(ngModel)]="shipment.ShipFrom.address_line2" [disabled]="isDisabled"/>
          </div>
          <div class="grid m-0">
            <div class="col-6 pt-0 pl-0">
              <div class="form-group">
                <label for="city">City</label>
                <input pInputText id="city" [(ngModel)]="shipment.ShipFrom.CITY" [disabled]="isDisabled"/>
              </div>
            </div>
            <div class="col-6 p-0">
              <div class="form-group">             
                <label for="stateFrom">State</label>             
                   <!-- <p-dropdown [(ngModel)]="shipment.ShipFrom.STATE"[options]="states" ></p-dropdown> -->
                   <input pInputText id="State" [(ngModel)]="shipment.ShipFrom.STATE" />

              </div>
            </div>
          </div>
          <div class="grid m-0">
               <div class="col-6 pt-0 pl-0">
                <div class="form-group">
                  <label for="Zipcode">Zipcode</label>
                  <input pInputText id="Zipcode" [(ngModel)]="shipment.ShipFrom.ZIPCODE" [disabled]="isDisabled"/>
                </div>
               </div>
               <div class="col-6 p-0">
                <div class="form-group">
                  <label for="countryFrom">Country</label>
                  <p-dropdown optionValue="code" [(ngModel)]="shipment.ShipFrom.COUNTRY" [options]="countries"
                       id="ShipFromCountry" optionLabel="name" (onChange)="changeCountry($event)" [filter]="true" [disabled]="isDisabled"></p-dropdown>
                </div>
               </div>
          </div>
          <div class="form-group">
            <label for="email">Email</label>
            <input pInputText id="email" [(ngModel)]="shipment.ShipFrom.EMAIL" [disabled]="isDisabled"/>
          </div>
          <div class="grid m-0">
              <div class="col-6 pt-0 pl-0">
                <div class="form-group">
                  <label for="phone">Phone</label>
                  <input pInputText id="phone" [(ngModel)]="shipment.ShipFrom.PHONE" [disabled]="isDisabled"/>
                </div>
              </div>
              <div class="col-6 p-0">
                <div class="form-group">
                  <label for="contact">Address Type</label>
                  <p-dropdown [options]="addressTypes" [(ngModel)]="shipment.ShipFrom.ADDRESS_TYPE" [disabled]="isDisabled"></p-dropdown>
                </div>
              </div>
          </div>         
          <!-- Uncomment if needed -->
          <!--
          <div class="form-group">
            <label for="addressTypeFrom">Address Type</label>
            <p-dropdown [options]="addressTypes"></p-dropdown>
          </div>
          -->
        </div>
      </p-card>

    </div>
    
   </div>  
 
   <div class="col-md-6">
     <div class="form-section">
       <p-card class="titleTypoh3 ">
         <div class="header-container">
           <div class="p-card-title">Ship To</div>
           <button pButton label="Validate Address" class="p-button-primary validate-button"></button>
         </div>
         <div class="form-grid">
           <div class="form-group">
             <label for="locationFrom">Company Name</label>
             <div class="p-inputgroup p-input-icon-right">
              <i class="pi pi-search" (click)="openAddressBook('ShipTo')"></i>
               <input pInputText id="companyNameTo" [(ngModel)]="shipment.ShipTo.COMPANY" [disabled]="isDisabled"/>
             </div>
           </div>
           <div class="form-group">
             <label for="locationName">Customer display name*</label>
             <input pInputText id="displayName" [(ngModel)]="shipment.ShipTo.CONTACT" [disabled]="isDisabled"/>
           </div>            
           <div class="form-group">
             <label for="company">Address Line 1</label>
             <input pInputText id="addressLine1" [(ngModel)]="shipment.ShipTo.ADDRESS_LINE1" [disabled]="isDisabled"/>
           </div>
           <div class="form-group">
             <label for="contact">Address Line 2</label>
             <input pInputText id="addressLine2" [(ngModel)]="shipment.ShipTo.address_line2" [disabled]="isDisabled"/>
           </div>
           <div class="form-group">
              <label for="AddressLine 3">Address Line 3</label>
              <input pInputText id="addressLine3" [(ngModel)]="shipment.ShipTo.address_line3" [disabled]="isDisabled"/>
           </div>
           <div class="grid m-0">
               <div class="col-6 pt-0 pl-0">
                <div class="form-group">
                  <label for="city">City</label>
                  <input pInputText id="city" [(ngModel)]="shipment.ShipTo.CITY" [disabled]="isDisabled"/>
                </div>
               </div>
               <div class="col-6 p-0">
                <div class="form-group">
                  <label for="stateFrom">State</label>
                 <!-- <p-dropdown [(ngModel)]="shipment.ShipTo.STATE" [options]="Master_states" [disabled]="isDisabled"></p-dropdown> -->
                 <input pInputText id="State" [(ngModel)]="shipment.ShipTo.STATE" [disabled]="isDisabled"/>

                </div>
               </div>
           </div>        
           <div class="grid m-0">
                <div class="col-6 pt-0 pl-0">
                  <div class="form-group">
                    <label for="Zipcode">Zipcode</label>
                    <input pInputText id="Zipcode" [(ngModel)]="shipment.ShipTo.ZIPCODE" [disabled]="isDisabled"/>
                  </div>
                </div>
                <div class="col-6 p-0">
                  <div class="form-group">
                    <label for="countryFrom">Country</label>
                    <p-dropdown optionLabel="name" optionValue="code" id="countryTo" [(ngModel)]="shipment.ShipTo.COUNTRY" [options]="countries" (onChange)="changeCountry($event)" [filter]="true" [disabled]="isDisabled"></p-dropdown>
                  </div>
                </div>
           </div>
           <div class="form-group">
             <label for="city">Email</label>
             <input pInputText id="email" [(ngModel)]="shipment.ShipTo.EMAIL" [disabled]="isDisabled"/>
           </div>
           <div class="grid m-0">
                <div class="col-6 pt-0 pl-0 pb-0">
                  <div class="form-group">
                    <label for="phone">Phone</label>
                    <input pInputText id="phone" [(ngModel)]="shipment.ShipTo.PHONE" [disabled]="isDisabled"/>
                  </div>
                </div>
                <div class="col-6 p-0">
                  <div class="form-group">
                    <label for="addressTypeFrom">Address Type</label>
                    <p-dropdown  [options]="addressTypes" [(ngModel)]="shipment.ShipTo.ADDRESS_TYPE" [disabled]="isDisabled"></p-dropdown>
                  </div>
                </div>
           </div>
         </div>
       </p-card>
    
   </div>  
 </div>
 <app-address-book-popup #popup (onSelect)="handleAddressSelect($event)"></app-address-book-popup>

           <div class="row">
     <app-dashboard-table  [shipment]="shipment"></app-dashboard-table>
    </div>
   </div>
