import { Component } from '@angular/core';

@Component({
  selector: 'app-additional-info',
  templateUrl: './additional-info.component.html',
  styleUrls: ['./additional-info.component.scss'],
})
export class AdditionalInfoComponent {
  referenceOptions: any[] = [];
  selectedReference: string = '';
  transportationModeOptions: any[] = [];
  selectedTransportationMode: string = '';
  moveCodeOptions: any[] = [];
  selectedMoveCode: string = '';
  shipmentMethodOptions: any[] = [];
  selectedShipmentMethod: string = '';
  reeferOptions: any[] = [];
  selectedReefer: string = '';
}
