<p-toast></p-toast>
<p-toast position="top-left" key="tl"></p-toast>
<p-toast position="top-center" key="tc"></p-toast>
<p-toast position="bottom-center" key="bc"></p-toast>

<p-toast position="bottom-center" key="c" [baseZIndex]="5000">
    <ng-template let-message pTemplate="message">
        <div class="p-flex p-flex-column" style="flex: 1">
            <div class="p-text-center">
                <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
                <h4>{{ message.summary }}</h4>
                <p>{{ message.detail }}</p>
            </div>
            <div class="p-grid p-fluid">
                <div class="p-col-6">
                    <button type="button" pButton label="Yes" class="p-button-success"></button>
                </div>
                <div class="p-col-6">
                    <button type="button" pButton label="No" class="p-button-secondary"></button>
                </div>
            </div>
        </div>
    </ng-template>
</p-toast>
<div class="card"  *ngIf="isDataLoaded">
        <p-table [value]="xfields" styleClass="p-datatable-sm p-datatable-gridlines p-datatable-striped "
            dataKey="fieldHeader" responsiveLayout="stack" >
            <ng-template pTemplate="caption">
                <div class="flex align-items-center justify-content-between">
                   Routing  Guide Fields
                </div>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th style="width: 5rem"></th>
                    <th>Visible</th>
                    <th>Coulmn Header</th>
                   
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-fielddata let-expanded="expanded">
                <tr>
                    <td>
                        <button type="button" pButton pRipple [pRowToggler]="fielddata" *ngIf="fielddata.visible"
                            class="p-button-text p-button-rounded p-button-plain"
                            [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
                    </td>
                    <td>
                        <p-checkbox [(ngModel)]="fielddata.visible" [binary]="true"
                            [disabled]="false"></p-checkbox>{{
                        fielddata.visible }}
                    </td>
                    <td> {{ fielddata.fieldHeader }}</td>


                    

                </tr>
            </ng-template>

            <ng-template pTemplate="rowexpansion" let-fielddata  >
                <tr>

                    <td colspan="3">
                        <div class="p-3">

                            <p-table [value]="fielddata.fields" dataKey="title">
                                <ng-template pTemplate="header">
                <tr>
                    <th>Visible</th>
                    <th>Field</th>
                    

                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-field>

                <tr>
                    <td>
                        <p-checkbox [(ngModel)]="field['TableVisible']" [binary]="true"
                            [disabled]="false"></p-checkbox>{{
                        fielddata['TableVisible'] }}
                    </td>

                    <td>{{field.title}}</td>
                   

                </tr>
            </ng-template>

        </p-table>
    </div>
    </td>
    </tr>
    </ng-template>

    </p-table>
    </div>
    <div>

    <button type="button" pButton pRipple label="Save Changes" icon="pi pi-check" class="p-button-sm"
    (click)="SaveTemplate('RoutingGuideDefaults')"></button>
</div>



 

