import { Component } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'app-progress-status',
  templateUrl: './progress-status.component.html',
  styleUrls: ['./progress-status.component.scss'],
})
export class ProgressStatusComponent {
  constructor(private router: Router) {}
  pageTitle: string = 'Ship Request';
  buttonTitle: string = 'Add Ship Request';
  total: number = 100; // Example total records
  active: number = 80; // Example active records
  inActive: number = 20;
  statusProgressBarClick() {}
  onTitleBtnClick() {
    this.router.navigate(['/home/shiprequestmodal']);
  }
}
