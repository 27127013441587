<div  class="table-container">
    <div class="titleCard">
        <span class="name">
            Business Partners
        </span>
    </div>
    <p-table [value]="products" [tableStyle]="{ 'min-width': '50rem','height':'25vh','overflow':'scrollable' }">
        <ng-template  pTemplate="header">
            <tr>
                <th>PARTNER TYPE</th>
                <th></th>
                <th>COMPANY</th>
                <th>CONTACT</th>
                <th>ADDRESS LINE 1</th>
                <th>ADDRESS LINE 2</th>
                <th>CITY</th>
                <th>STATE</th>
                <th>ZIPCODE</th>
                <th>COUNTRY</th>
                <th>PHONE</th>
                <th>EMAIL</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-product let-i="rowIndex">
            <tr>
                <td>{{ product.partnerType }}</td>
                <td> <i class="pi pi-search iconPosition" (click)="showPopup(i)"></i></td>
                <td>{{ product.company }}</td>
                <td>{{ product.contact }}</td>
                <td>{{ product.addressLine1 }}</td>
                <td>{{ product.addressLine2 }}</td>
                <td>{{ product.city }}</td>
                <td>{{ product.state }}</td>
                <td>{{ product.zipcode }}</td>
                <td>{{ product.country }}</td>
                <td>{{ product.phone }}</td>
                <td>{{ product.email }}</td>
            </tr>
        </ng-template>
    </p-table>
    <app-address-book-popup #popup (onSelect)="handleAddressSelect($event)"></app-address-book-popup>
</div>