import { Component } from '@angular/core';
import { ERPSHIPService } from '../erpship.service';
@Component({
  selector: 'app-chatbot',
  templateUrl: './chatbot.component.html',
  styleUrls: ['./chatbot.component.scss']
})
export class ChatbotComponent {
  messages:any [] = [];
  userMessage: string = '';
  msgindex =0;
  constructor( private xfservice: ERPSHIPService){}
  processmike(){
    
  }
  sendMessage() {
    if (this.userMessage.trim() !== '') {
      let requestData:any ={};
      requestData['question'] = this.userMessage;
      const req:any ={}

      const res:any ={}
      this.msgindex = this.msgindex +1;
      req['source']= 'User';
      req['data']= requestData;
      req['msgindex'] = this.msgindex;
      this.messages.push(req);

      res['source']= 'Chatbot';
      res['data']= '....';
      res['msgindex'] = this.msgindex;

      this.messages.push(res);
      //this.messages.push(`User: ${this.userMessage}`);

    const chatrequest =[];
    chatrequest.push(requestData);
    chatrequest.push({"index":this.msgindex})

     


      this.xfservice.processChatbot(chatrequest).subscribe((data:any)=>{ 
        const res:any ={};
        res['source']='Chatbot';
        res['data'] = data;

        //this.messages.push(res);

        const filteredArray = this.messages.filter(obj => obj.msgindex === data.index && obj.source === 'Chatbot');
        if ( filteredArray.length > 0)
        {
          (filteredArray[0])['data'] = data
        }

      })

    
      // Add logic to send userMessage to chatbot service and get bot response
      // For simplicity, the bot response is hardcoded here
      //this.messages.push('Chatbot: Hello! How can I assist you?');
      this.userMessage = '';
    }
  }

}
