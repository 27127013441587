import { Component,ViewChild,EventEmitter, Output,Input, OnInit } from '@angular/core';
import { ERPSHIPService } from 'src/app/erpship.service';

@Component({
  selector: 'app-address-book-popup',
  templateUrl: './address-book-popup.component.html',
  styleUrl: './address-book-popup.component.scss'
})
export class AddressBookPopupComponent implements OnInit {
  
  @ViewChild('dt2') addrdt: any;
  // @Output() onClose: EventEmitter<any> = new EventEmitter<any>();
  @Input() display: boolean = false;
  @Output() onSelect: EventEmitter<any> = new EventEmitter<any>();
  value: any; // This is the value to be returned

  AddressBook_dialog: boolean = false;
  addressbookcols: any[] = [];
  Master_addressBook: any[] = [];
  addressbookfilters: any[] = [];
  shipment!: any;
  currentAddressBlock: any;
  addressbookgrid: any[] = [];

  constructor(
    private xfservice: ERPSHIPService
  ){}
  ngOnInit(){
    this.openAddressBook('ShipFrom')
  };
  
  applyFilterGlobal_Address($event: any, stringVal: any): void {
    this.addrdt.filterGlobal(($event.target as HTMLInputElement).value, stringVal);
  };
  

  selectAddress(rowData: any) {
    this.onSelect.emit(rowData); // Emit the selected row data
    this.close(); 
  };

  close() {
    this.display = false;
  };

  openAddressBook(fromblock: any): void {
    this.currentAddressBlock = fromblock;
    this.addressbookcols = [];
    this.addressbookfilters = [];
    this.xfservice.getAddressBookTemplate().subscribe((data) => {
      this.addressbookgrid = data[0].fields;
      this.addressbookgrid.forEach((colfield) => {
        // console.log(colfield);
        let col = {
          field: '',
          header: '',
          visible: true,
          editable: false,
          fieldType: '',
          datatype: '',
        };
        col.field = colfield.fieldName;
        col.header = colfield.fieldName;
        col.editable = colfield.editable;
        col.fieldType = colfield.fieldType;
        col.datatype = colfield.datatype;
        this.addressbookfilters.push(colfield.fieldName)
        if (col.field == 'id' || col.field == 'IsActive') {
          col.visible = false;
        }
        if (col.visible) {
          this.addressbookcols.push(col);
        }
      });
      //console.log('addressbook fields are: ' + JSON.stringify(this.addressbookcols));

      this.xfservice.getMasterDataByName('addressBook').subscribe((data) => {
        this.Master_addressBook = data;
        // console.log('Address:' + data)
        this.AddressBook_dialog = true;
      });
    });
  };
}
