import { Component, OnInit, ViewChild,Input, OnChanges, SimpleChanges } from '@angular/core';
import { ShipNowService } from '../../../services/ship-now/ship-now.service';
import { Table } from 'primeng/table';
import { MessageService } from 'primeng/api';
import { StorageService } from '../../../services/storage.service';
import { GetAllDataService } from 'src/app/services/ship-now/get-all-data.service';

@Component({
  selector: 'app-ship-now-pack',
  templateUrl: './ship-now-pack.component.html',
  styleUrls: ['./ship-now-pack.component.scss']
})

export class ShipNowPackComponent implements OnInit, OnChanges {
  @Input() shipment!: any;
  @Input() refreshFlag: boolean = false;


  products: any[] = [];
  displayDialog: boolean = false;
  productDetails: any = null;
  packDetails: any[] = [];
  secondTableData: any[] = [];
  packedItems: any[] = [];
  newPackedItems :any[] = [];
  displayProductDetails: boolean = false;
  selectedProduct: any = {};
  @ViewChild('dt') dt: Table | undefined;

  constructor(private ShipNowService: ShipNowService, private messageService: MessageService,private storageService:StorageService,private dataService:GetAllDataService) { }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['shipment'] && this.shipment) {
    // Clear existing data
    this.products = [];
    this.packedItems = [];

    // Process shipment items
    const productItem = this.shipment.Items || [];
    for (let i = 0; i < productItem.length; i++) {
      const newProduct = {
        product: productItem[i]['ProductNo'] ?? '',
        description: productItem[i]['Description'] ?? '',
        totalQuantity: productItem[i]['Quantity'] ?? 0,
        balanceQuantity: productItem[i]['Balance'] ?? 0,
        partialQuantity: productItem[i]['Partial'] ?? 0,
        s: false,
        isDG: productItem[i]['IsDG'] ?? false,
      };
      this.products.push(newProduct);
    }
    this.addProductRow();

    // Process shipment packages
    const packItem = this.shipment.Packages || [];
    for (let i = 0; i < packItem.length; i++) {
      const packProduct = packItem[i].ItemsInfo || [];
      const packProductList = [];

      for (let j = 0; j < packProduct.length; j++) {
        const newPack = {
          product: packProduct[j]['ProductNo'] ?? '',
          description: packProduct[j]['Description'] ?? '',
          totalQuantity: packProduct[j]['Quantity'] ?? 0,
          balanceQuantity: packProduct[j]['Balance'] ?? 0,
          partialQuantity: packProduct[j]['Partial'] ?? 0,
          isSerial: packProduct[j]['IsSerial'] ?? false,
          isDG: packProduct[j]['IsDG'] ?? false,
        };
        packProductList.push(newPack);
      }

      const newPack = {
        hu: packItem[i]['HU'] ?? '',
        weight: packItem[i]['Weight'] ?? 0,
        weightUnits: packItem[i]['Weightunits'] ?? 0,
        dimension: packItem[i]['Dimension'] ?? 0,
        dimensionUnits: packItem[i]['DimensionUnits'] ?? 0,
        class: '',
        tracking: '',
        ItemsInfo: packProductList,
      };
      this.packedItems.push(newPack);
    }
  }
  
    if (changes['refreshFlag'] && changes['refreshFlag'].currentValue) {
      this.products=[]
      this.packedItems=[]
      this.productDetails=[]
      this.ngOnInit()
    }
  
  }

  ngOnInit(): void {
    this.retrieveStoredItem();
    for(let i=0; i<4;i++)
    {
      this.addProductRow(); // Automatically add one row on component initialization
    }
  }
  retrieveStoredItem(): void {
    const storedItem = this.storageService.getItem<any>('data');
    if (storedItem) {
      console.log(storedItem.dimensions);
      this.Dimensions = storedItem.dimensions;

    }
  }
  addProductRow(): void {
    const newProduct = {
      product: '',
      description: '',
      totalQuantity: 0,
      balanceQuantity: 0,
      partialQuantity: 0,
      s: false,
      isDG: false,
    };
    this.products.push(newProduct);
  }
  packingOptions = [
    { label: 'Packing', value: 'Packing' },
    { label: 'Box', value: 'box' },
    { label: 'Bag', value: 'bag' }
  ];
  Dimensions = [];
  dimensionsOptions = [
    { label: 'Small', value: 'small' },
    { label: 'Medium', value: 'medium' },
    { label: 'Large', value: 'large' }
  ];
  selectedDimensions: string = "";
  selectedPacking: string = "";

  deleteProduct(index: number) {
    if (this.products.length === 1) {
      // Show a warning message and prevent deletion
      this.messageService.add({
        severity: 'warn',
        summary: 'Cannot Delete',
        detail: 'At least one row is required.',
        life: 3000
      });
    } else {
      this.products.splice(index, 1);
    }
  }
  

  deleteAll() {
    this.products = [];
  }

  selectedDimension: any;


  dimensionOptions = [
    { label: 'Dimension Option 1', value: 1 },
    { label: 'Dimension Option 2', value: 2 },
    // Add more dimension options as needed
  ];


  searchProducts(): void {
    this.ShipNowService.getProducts().subscribe((data) => {
      this.productDetails = data;
      this.displayDialog = true;
      console.log("products", this.products);
    });
  }
  closeDialog(): void {
    this.displayDialog = false;
    this.productDetails = null;
  }

  applyFilterGlobal(event: any) {
    if (this.dt) {
      this.dt.filterGlobal(event.target.value, 'contains');
    }
  }

  updateBalanceQuantity(product: any): void {
    const totalQuantity = product.totalQuantity || 0;
    const balanceQuantity = product.balanceQuantity || 0;
    const partialQuantity = product.partialQuantity || 0;

    // Validate if partial quantity exceeds balance quantity
    if (partialQuantity > balanceQuantity) {
      // Show error toast notification
      this.messageService.add({
        severity: 'error',
        summary: 'Validation Error',
        detail: 'Partial quantity cannot be greater than balance quantity.',
        life: 3000
      });

      // Reset partial quantity or correct it if needed
      product.partialQuantity = balanceQuantity;
    } else {
      // Update balance quantity if partial quantity is valid
      product.balanceQuantity = totalQuantity - partialQuantity;
    }
  }

  packItem(): void {
    const validProducts = this.products.filter(product =>
      product.product &&
      product.totalQuantity > 0 &&
      product.balanceQuantity >= 0 &&
      product.partialQuantity > 0
    );
  
    const hasPartialQuantity = validProducts.some(product => product.partialQuantity > 0);
  
    if (!hasPartialQuantity) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Validation Error',
        detail: 'Please add partial quantity for at least one valid product.',
        life: 3000
      });
      return;
    }
    const payload = {
      ItemsInfo: validProducts,
      SpecialServices: {},
      Weightunits: '',
      DimensionUnits: '',
      Sno: 1,
      IsInternal: true,
    };

    this.ShipNowService.packItem(payload).subscribe(
      (response) => {
        // Process each item in the response
        response.ItemsInfo.forEach(item => {
          const product = this.products.find(p => p.product === item.product);
  
          if (product) {
            if (!product.deducted) {
              product.deducted = true; // Mark as deducted for future saves
            } else if (product.balanceQuantity >= item.partialQuantity) {
              product.balanceQuantity -= item.partialQuantity;
            } else {
              this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: 'Balance quantity cannot be negative.',
                life: 3000
              });
              return; // Stop further processing if any product is invalid
            }
          }
        });
  
        // Check if there's already a packed item with the same ID
        let existingPackedItem = this.packedItems.find(packedItem => packedItem.hu === response.HU);
  
        if (existingPackedItem) {
          // Merge ItemsInfo for existing packed item
          response.ItemsInfo.forEach(item => existingPackedItem.ItemsInfo.push(item));
        } else {
          // Add a new packed item if it doesn't exist
          this.packedItems.push({
            hu: response.HU,
            weight: response.Weight || '',
            weightUnits: response.Weightunits,
            dimension: response.Dimension || '',
            dimensionUnits: response.DimensionUnits,
            class: '', // You may need to adjust this field if needed
            tracking: response.tracking || '',
            ItemsInfo: response.ItemsInfo,
            id: response.id  // Store the ID for future reference
          });
          this.dataService.setPackingInfoData(this.packedItems);
        }
      },
      (error) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Failed to pack items. Please try again.', life: 3000 });
      }
    );
  }
  
  onInputChange() {
    this.dataService.setPackingInfoData(this.packedItems);
  }
    
  
  packAllItems(): void {
    // Prepare valid products by updating partialQuantity to balanceQuantity
    const validProducts = this.products
      .filter(product => 
        product.product &&
        product.totalQuantity > 0 &&
        product.balanceQuantity >= 0 &&
        product.partialQuantity >= 0
      )
      .map(product => {
        // Update partialQuantity to balanceQuantity for payload
        return {
          ...product,
          partialQuantity: product.balanceQuantity,
          balanceQuantity : 0
        };
      });
  
    // Prepare the payload
    const payload = {
      ItemsInfo: validProducts,
      SpecialServices: {},
      Weightunits: 'LBS',
      DimensionUnits: 'IN',
      Sno: 1,
      IsInternal: true,
    };
  
    this.ShipNowService.packAllItems(payload).subscribe((response) => {
      console.log('Pack All API response:', response);
  
      // Process new packed items
       this.newPackedItems.push({
        hu: response.HU,
        weight: response.Weight || '', // Ensure weight is defined
        weightUnits: response.Weightunits,
        dimension: response.Dimension || '', // Ensure dimension is defined
        dimensionUnits: response.DimensionUnits,
        class: response.product || '',  // Default to '' if product is not defined
        tracking: response.tracking || '', // Use response ID or default to ''
        ItemsInfo : response.ItemsInfo || {}
    });
      // Update packed items and reset fields
      this.packedItems = [...this.packedItems, ...this.newPackedItems];
      this.products.forEach(product => {
        product.balanceQuantity = 0; // Reset balance quantity after packing
        product.partialQuantity = 0; // Reset partial quantity
      });
      this.dataService.setPackingInfoData(this.packedItems);
      // Show success toast
      this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Items packed successfully!', life: 3000 });
    }, (error) => {
      // Show error toast
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Failed to pack items. Please try again.', life: 3000 });
    });
  }
  

  removeItem(index: number): void {
    this.secondTableData.splice(index, 1);
  }

  deletePackDetail(index: number): void {
    this.packDetails.splice(index, 1);
  }

  deleteItem(item: any): void {
    // Prepare the payload for the DELETE request
    const deletePayload = {
      hideAddressBlock: false,
      hideCarrierBlock: true,
      defaultWorkCentre: "Y921",
      defaultStorageBin: "GI-YDO1",
      defaultwarehouse: "1710",
      user: "venkat",
      id: 10,  // Ensure this ID is dynamically set if needed
      DocumntNo: "80000702",  // Ensure this document number is dynamically set if needed
      HU: item.hu,  // Adjust as per your data structure
      StockItemUUID: item.stockItemUUID,  // Adjust as per your data structure
      Quantity: item.quantity || 1,  // Default quantity if not provided
      AlternativeUnit: "PC",
      HUCreateCount: 1,
      DeleteQuantity: item.deleteQuantity || 6  // Default delete quantity if not provided
    };

    this.ShipNowService.deleteItems(deletePayload).subscribe((data) => {
      const product = this.products.find(p => p.product === item.class);

      if (product) {
        product.partialQuantity = 0;
        // Update the balance quantity
        product.balanceQuantity = (product.totalQuantity - (product.partialQuantity || 0));

        // Reset the partial quantity
      }

      // Remove the item from packedItems
      this.packedItems = this.packedItems.filter(p => p !== item);

      // Optionally show a notification
      this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Item deleted successfully!' });
      this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Items packed successfully!', life: 3000 });
    }, (error) => {
      // Show error toast
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Failed to pack items. Please try again.', life: 3000 });
    });

  }

  selectProduct(product: any): void {
    console.log(product);

    // Extract relevant details from the product
    const mappedProduct = {
      product: product.ProductNo || '', // Map ProductNo
      description: product.Description || '', // Map Description
      totalQuantity: parseInt(product.Quantity, 10) || 0, // Assuming 'Quantity' is part of the data structure
      balanceQuantity: 0, // Balance quantity is not available in the JSON, set to 0
      partialQuantity: 0, // Initialize partial quantity as 0
      isSerial: product.IsSerial || false, // Map IsSerial
      isDG: product.IsDG || false, // Map IsDG
    };

    console.log("Mapped Product:", mappedProduct);

    // Add product to the top of the list if it's not empty
    if (mappedProduct.product) {
      this.products.unshift(mappedProduct);
    }

    // Close the dialog
    this.displayDialog = false;
  }

  getProducts(item)
  {
    this.selectedProduct = item;
    this.displayProductDetails = true; 
  }

  manageRows(index: number): void {
    const product = this.products[index];

    // Check if the row is not empty
    if (product.product || product.description || product.totalQuantity > 0) {
      // If the last row is being edited, add a new empty row
      if (index === this.products.length - 1) {
        this.addProductRow();
      }
    } else if (index < this.products.length - 1) {
      // Remove the row if it's empty and not the last one
      this.products.splice(index, 1);
    }
  }

    // Method to calculate the total balance quantity
    getTotalBalanceQuantity(): number {
      return this.products.reduce((total, product) => {
        return total + (product.balanceQuantity || 0);
      }, 0);
    }


}