import { Component, ViewChild } from '@angular/core';
import { Table } from 'primeng/table';
import { HttpClient } from '@angular/common/http';
import { ERPSHIPService } from '../erpship.service';
import { MessageService, ConfirmationService } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { ChangeDetectorRef } from '@angular/core';
import { GalleriaThumbnails } from 'primeng/galleria';

export interface DropdownOption {
  label: string;
  value: any;
}

@Component({
  selector: 'ship-request-modal',
  templateUrl: './ship-request-modal.component.html',
  styleUrls: ['./ship-request-modal.component.scss'],
  providers: [MessageService, ConfirmationService],
})
export class ShipRequestModalComponent {
  @ViewChild('dt1') dt: any;
  @ViewChild('dt2') addrdt: any;
  @ViewChild('fsdt') freightdt: any;
  @ViewChild('loc') loc: any;
  @ViewChild('requiredData') requiredData: any;
  @ViewChild('manifestdt') manifestdata: any;

  shipDate: Date;
  expDate: Date;
  expTime: Date;
  selectedConnection: any;
  searchText: string = '';
  connections: Array<any> = [
    { id: 1, name: 'd365' },
    { id: 2, name: 'Salesforce' },
    { id: 3, name: 'snowflake' },
  ];
  selectedFilter: any;
  filters: Array<any> = [
    { id: 1, name: 'Delivery Number' },
    { id: 2, name: 'Ship Request' },
    { id: 3, name: 'Sales Order' },
  ];
  inputSearch: string = '';
  states: Array<any> = [
    { label: 'Select', value: null },
    { label: 'State 1', value: 'state1' },
    { label: 'State 2', value: 'state2' },
  ];
  addressTypes: Array<any> = [
    { label: 'Residential', value: 'residential' },
    { label: 'Commercial', value: 'commercial' },
  ];

  shipMethods = [
    { label: 'Ship Method 1', value: 'Ship Method 1' },
    { label: 'Ship Method 2', value: 'Ship Method 2' },
  ];

  serviceTypes = [
    { label: 'Service 1', value: 'Service 1' },
    { label: 'Service 2', value: 'Service 2' },
  ];

  paymentTypes = [
    { label: 'Payment 1', value: 'Payment 1' },
    { label: 'Payment 2', value: 'Payment 2' },
  ];

  costCenters = [
    { label: 'Cost Centre 1', value: 'Cost Centre 1' },
    { label: 'Cost Centre 2', value: 'Cost Centre 2' },
  ];

  ItemsList = [{}];
  countries: any[] = [{}];
  selectedShipMethod: string = '';
  selectedServiceType: string = '';
  carrierId: string = '';
  accountNumber: string = '';
  selectedPaymentType: string = '';
  selectedCostCenter: string = '';
  poNo: string = '';
  invoiceNo: string = '';
  notes: string = '';

  selectedValues: string[] = [];
  AddressBook_dialog: boolean = false;

  currentAddressBlock: any;
  addressbookfilters: any[] = [];
  addressbookcols: any[] = [];
  addressbookgrid: any[] = [];

  ShipScreen: any;
  //empty model

  clearShip: any = {};
  shipment: any = {}; //super master data
  Original_LocationMasterData: any;
  LocationMasterData: any;
  currentSelectedLocationid: any = {};
  dataSourceList: any[] = [];
  currentSelectedTableData = [];
  currentSelectedTable: any = '';

  currentSelectedBlock: any = [];
  currentSelected_Block_Field_Name: any;
  currentSelected_Block_Name: any;
  propertyFieldSelected: boolean = false;
  currentSelctedField: any = {};
  //filters
  history_filters: any = {};
  history_filter_xfields: any[] = [];

  //loading flags
  shiploading: boolean = false;
  rateloading: boolean = false;
  addressvalidationload: boolean = false;

  addressResult: any = {
    Address: {},
    CarrierDetails: {},
    VerifiedAddress: {},
  };

  //history orders
  historyOrders: any[] = [];
  Org_historyOrders: any[] = [];
  selectedHistoryOrder: any;

  //for History
  history_xfields: any[] = [];
  history_xcolumns: any[] = [];
  global_history_xcolumns: any[] = [];

  //  for Location
  xformMetaData: any;
  xfields: any[] = [];
  xcolumns: any[] = [];

  allForms: any[] = [];
  FreightShopResult: any[] = [];
  selectedHu: any;
  selectedHUItems: any[] = [];
  isDataLoaded: boolean = false;
  package: any = {};

  parameterId = -1;
  saveButtontitle = 'Save';
  //MasterData
  Master_products: any[] = [];
  Master_feederSystem: any[] = [];
  Master_orderTypes: any[] = [];
  Master_countries: any[] = [];
  Master_states: any[] = [];
  Master_cities: any[] = [];
  Master_dimensions: any[] = [];
  Master_addressBook: any[] = [];
  OrderTypes: any[] = [];
  ErpList: any[] = [];
  ShipmentTypes: any[] = [];
  isdis: boolean = false;

  masterData: any;

  billingFieldsDisable: boolean = true;
  carriers: any[] = [];
  ServiceList: any[] = [];
  AccountList: any[] = [];
  carrierservices: any[] = [];

  closeShipScreen(): void {
    this.location.back();
  }
  activeTabIndex: number;
  constructor(
    private confirmationService: ConfirmationService,
    private xfservice: ERPSHIPService,
    private router: Router,
    private location: Location,
    public messageService: MessageService,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef
  ) {
    // Initialize with current date
  }

  ngOnInit() {
    this.activeTabIndex = 1;
    this.selectedFilter = this.filters[0];
    this.shipment = {
      HeaderInfo: {
        FeederSystem: '',
        DocumentType: '',
        ShipDate: '',
        DocumentNumber: 'DN000056',
        Location: '1',
        ShipmentType: '',
        Status: 'open',
      },
      CarrierDetails: {
        Carrier: '',
        ShippingAccount: '',
        PaymentType: '',
        BillingAccount: '',
        BillingCountry: '',
        BillingZipCode: '',
        Note: '',
        Reference1: '',
        Reference2: '',
        Reference3: '',
        Reference4: '',
        ServiceName: '',
        CostCenter: '',
        PoNo: '',
        InvoiceNo: '',
      },
      ShipFrom: {
        COMPANY: '',
        CONTACT: '',
        ADDRESS_LINE1: '',
        ADDRESS_LINE2: '',
        ADDRESS_LINE3: '',
        CITY: '',
        STATE: '',
        ZIPCODE: '',
        COUNTRY: '',
        PHONE: '',
        EMAIL: '',
      },
      ShipTo: {
        COMPANY: '',
        CONTACT: '',
        ADDRESS_LINE1: '',
        ADDRESS_LINE2: '',
        ADDRESS_LINE3: '',
        CITY: '',
        STATE: '',
        ZIPCODE: '',
        COUNTRY: '',
        PHONE: '',
        EMAIL: '',
      },
      InternationalDetails: {},
      Items: this.products,
      Packages: [],
      Shipper: {
        COMPANY: '',
        CONTACT: '',
        ADDRESS_LINE1: '',
        ADDRESS_LINE2: '',
        ADDRESS_LINE3: '',
        CITY: '',
        STATE: '',
        ZIPCODE: '',
        COUNTRY: '',
        PHONE: '',
        EMAIL: '',
      },
      SoldTo: {
        COMPANY: '',
        CONTACT: '',
        ADDRESS_LINE1: '',
        ADDRESS_LINE2: '',
        ADDRESS_LINE3: '',
        CITY: '',
        STATE: '',
        ZIPCODE: '',
        COUNTRY: '',
        PHONE: '',
        EMAIL: '',
      },
      ShipmentLevelServices: {},
    };

    this.paymentTypes = [
      { label: 'Sender', value: 'Sender' },
      { label: 'Receipient', value: 'Receipient' },
      { label: 'Third Party', value: 'ThirdParty' },
      { label: 'Collect', value: 'Collect' },
    ];
    this.paymentTypes.sort((a, b) => a.label.localeCompare(b.label));

    this.openMasterProducts();
    this.ShipmentTypes = [
      { label: 'Business', value: 'Business' },
      { label: 'Personal', value: 'Personal' },
      { label: 'Inter Company', value: 'Inter Company' },
    ];

    this.xfservice
      .getScreenTemplateByName('ShipMasterProductDialog')
      .subscribe((data) => {
        this.allColumns = data[0].details.filter(
          (col: any) => col.fieldName.toLowerCase() !== 'sno'
        );

        // Ensure mandatory columns are always selected
        this.selectedColumns = [...this.mandatoryColumns];
      });
    this.xfservice.getLocationMasterData().subscribe((data) => {
      data['paymentTypes'] = this.paymentTypes;

      this.LocationMasterData = data;
      this.initializeLocationMasterData(data);
      this.cdr.detectChanges();

      // alert(      this.shipment.HeaderInfo.ShipDate )
      const dtoken: any = JSON.stringify(
        JSON.parse(
          window.atob(window.sessionStorage.getItem('X-Token')!.split('.')[1])
        )
      );

      this.shipment.HeaderInfo['Location'] = JSON.parse(dtoken).LocationId;

      this.updateShipFrom();

      var cchip = JSON.stringify(this.shipment);

      this.global_history_xcolumns = this.getKeys(this.shipment);
      console.log(this.global_history_xcolumns);

      this.clearShip = JSON.parse(cchip);
      this.currentSelctedField =
        this.LocationMasterData['FieldModel'][0].details;

      this.route.params.subscribe((params) => {
        this.parameterId = -2;
        this.parameterId = params['id'];

        if (this.parameterId == -2 || this.parameterId == undefined) {
          this.isDataLoaded = true;
        } else if (this.parameterId.toString().startsWith('COPY')) {
          this.saveButtontitle = 'Save';
          var prm = this.parameterId.toString().split('-');
          this.xfservice
            .getxFormRecordsByFromName('ShipRequest/' + prm[1])
            .subscribe((data) => {
              data[0].details.id = -1;
              this.shipment = data[0].details;
              this.shipment.HeaderInfo.ShipDate = new Date();
              this.products = [...this.shipment.Items];
              this.cdr.detectChanges();
              var cchip = JSON.stringify(this.shipment);
              this.clearShip = JSON.parse(cchip);
              this.isDataLoaded = true;
            });

          this.isDataLoaded = true;
        } else if (this.parameterId > -1) {
          this.saveButtontitle = 'Update';
          this.xfservice
            .getxFormRecordsByFromName('ShipRequest/' + this.parameterId)
            .subscribe((data) => {
              this.shipment = data[0].details;
              // this.shipment.HeaderInfo.ShipDate = new Date();
              this.products = [...this.shipment.Items, ...this.products];
              console.log('products', this.products);
              var cchip = JSON.stringify(this.shipment);
              this.clearShip = JSON.parse(cchip);

              this.processChange(
                'CarrierDetails',
                'Carrier',
                this.shipment.CarrierDetails['Carrier'],
                '',
                ''
              );
              this.isDataLoaded = true;
            });
        }

        //preparing history filter field data - start
        //--------------------------------------------
        this.history_filter_xfields =
          this.LocationMasterData.HistoryTable[0].details;

        this.history_filter_xfields.forEach((item) => {
          // console.log(JSON.stringify(item))
          item.fields.forEach((field: any) => {
            // this.xcolumns.push(item.fieldHeader +'.' +field.fieldName)
            if (
              field['filterField'] == true &&
              field['fieldName'] != 'ShipDate'
            ) {
              this.history_filters[item.fieldHeader + '.' + field.fieldName] =
                '';
            } else if (field['fieldName'] == 'ShipDate') {
              this.history_filters['shipDateFrom'] = '';
              this.history_filters['shipDateTo'] = '';
            }
          });
        });
        //-------------------------------------------------
        //End history filed preparation

        this.xfservice.getxAllForms().subscribe((data) => {
          this.allForms = data;
        });
      });
    });

    // this.shipment.HeaderInfo.FeederSystem = this.ErpList[0].value;
    // this.shipment.HeaderInfo.DocumentType = this.OrderTypes[0].value;
    // this.shipment.HeaderInfo.ShipmentType = this.ShipmentTypes[0].value;
  }
  initializeLocationMasterData(data) {
    this.countries = this.LocationMasterData.countrylist.map((item: any) => ({
      label: item.name,
      value: item.code,
      currency: item.currency,
    }));
    const defaultCountry = {
      label: 'Select',
      value: 'Select',
      currency: 'Select',
    };
    // Add the default defaultCountry to the beginning of the list
    this.countries.unshift(defaultCountry);
    this.countries.sort((a, b) => a.label.localeCompare(b.label));
    this.carriers = this.LocationMasterData.carriers.map((item: any) => ({
      label: item.CarrierName,
      value: item.CarrierName,
      LocationId: item.LocationId,
    }));
    const defaultCarrier = {
      label: 'Select',
      value: '',
      LocationId: '',
    };
    // Add the default carrier to the beginning of the list
    this.carriers.sort((a, b) => a.label.localeCompare(b.label));
    this.carriers.unshift(defaultCarrier);

    this.carrierservices = this.LocationMasterData.carrierservices.map(
      (item: any) => ({
        label: item.ServiceName,
        value: item.ServiceCode,
        CarrierName: item.CarrierName,
        LocationId: item.LocationId,
      })
    );
    const defaultcarrierservices = {
      label: 'Select',
      value: '',
      currency: '',
      CarrierName: '',
      LocationId: '',
    };
    // Add the default defaultCountry to the beginning of the list
    this.carrierservices.unshift(defaultcarrierservices);
    this.OrderTypes = this.LocationMasterData.ordertypes.map((item: any) => ({
      label: item.Name,
      value: item.Name,
    }));
    this.OrderTypes.sort((a, b) => a.label.localeCompare(b.label));

    this.ErpList = this.LocationMasterData.erp.map((item: any) => ({
      label: item.Name,
      value: item.Name,
    }));
    const defaultErp = {
      label: 'Manual',
      value: 'Manual',
    };
    // Add the default defaultErp to the beginning of the list
    this.ErpList.unshift(defaultErp);
    this.ErpList.sort((a, b) => a.label.localeCompare(b.label));
    this.dimensionOptions = this.LocationMasterData.dimensions.map(
      (item: any) => ({
        label: item.Name,
        value: item.Name,
      })
    );
    this.cdr.detectChanges();
    // console.log("Received Local Master Data & Location id is : " + this.currentSelectedLocationid)
    this.Original_LocationMasterData = JSON.parse(JSON.stringify(data));

    let rowdata = this.Original_LocationMasterData.location.filter(
      (item: any) => item.LocationId == data['xLocationId']
    );
    this.currentSelectedLocationid = rowdata[0];
    Object.keys(this.Original_LocationMasterData).forEach((keyName) => {
      let keydata: any = {};
      keydata['name'] = keyName;
      this.dataSourceList.push(keydata);
    });

    this.xfields =
      this.Original_LocationMasterData.LocationTemplate[0].details.fields;
    this.xcolumns = [];
    this.xfields.forEach((item) => {
      this.xcolumns.push(item.fieldName);
    });

    //for historytemplates
    this.history_xfields = this.LocationMasterData['HistoryTable'][0].details;
    this.history_xfields.forEach((xitem) => {
      xitem.fields.forEach((field: any) => {
        this.history_xcolumns.push(xitem.fieldHeader + '.' + field.fieldName);
      });
    });
    //screen templates
    this.ShipScreen = this.LocationMasterData['ShipScreenTemplate'][0].details;
    this.package['ItemsInfo'] = [];
    this.package['SpecialServices'] = {};

    this.package['Weightunits'] = this.currentSelectedLocationid['weightunit'];
    this.package['DimensionUnits'] =
      this.currentSelectedLocationid['dimensionunit'];

    this.shipment = this.LocationMasterData['ShipModel'][0].details;

    // this.shipment.HeaderInfo.ShipDate = new Date();
    this.shipment.HeaderInfo.ShipmentType = 'Business';
    this.shipment.HeaderInfo.FeederSystem = 'Manual';
    this.shipment.HeaderInfo.DocumentType = 'Delivery';
    this.shipment.CarrierDetails.PaymentType = 'Sender';
    this.shipment.ShipFrom.COUNTRY = 'US';
    this.shipment.ShipTo.COUNTRY = 'US';
    this.shipment.HeaderInfo.ShipDate = new Date();

    this.cdr.detectChanges();
  }
  clear() {
    this.shipment = this.clearShip;
    this.shipment.HeaderInfo.ShipDate = new Date();
    this.isdis = false;
  }
  updateShipFrom() {
    let rowdata = this.Original_LocationMasterData.location.filter(
      (item: any) => item.LocationId == this.shipment.HeaderInfo.Location
    );
    //console.log('ROW DATA IS: ' + JSON.stringify(rowdata));
    this.currentSelectedLocationid = rowdata[0];
    //console.log('Selected Location Is : ' + JSON.stringify(this.currentSelectedLocationid) + ' ---------> ' + this.currentSelectedLocationid.weightunit)
    this.package['Weightunits'] = this.currentSelectedLocationid['weightunit'];
    this.package['DimensionUnits'] =
      this.currentSelectedLocationid['dimensionunit'];
    this.shipment.ShipFrom.COMPANY = rowdata[0].LocationName;
    this.shipment.ShipFrom.CONTACT = rowdata[0].Contact;
    this.shipment.ShipFrom.ADDRESS_LINE1 = rowdata[0].AddressLine1;
    this.shipment.ShipFrom.ADDRESS_LINE2 = rowdata[0].AddressLine2;
    this.shipment.ShipFrom.CITY = rowdata[0].City;
    this.shipment.ShipFrom.STATE = rowdata[0].State;
    this.shipment.ShipFrom.ZIPCODE = rowdata[0].ZipCode;
    this.shipment.ShipFrom.COUNTRY = rowdata[0].Country;
    this.shipment.ShipFrom.PHONE = rowdata[0].Phone;
    this.shipment.ShipFrom.EMAIL = rowdata[0].Email;

    this.shipment.Shipper.COMPANY = rowdata[0].LocationName;
    this.shipment.Shipper.CONTACT = rowdata[0].Contact;
    this.shipment.Shipper.ADDRESS_LINE1 = rowdata[0].AddressLine1;
    this.shipment.Shipper.ADDRESS_LINE2 = rowdata[0].AddressLine2;
    this.shipment.Shipper.CITY = rowdata[0].City;
    this.shipment.Shipper.STATE = rowdata[0].State;
    this.shipment.Shipper.ZIPCODE = rowdata[0].ZipCode;
    this.shipment.Shipper.COUNTRY = rowdata[0].Country;
    this.shipment.Shipper.PHONE = rowdata[0].Phone;
    this.shipment.Shipper.EMAIL = rowdata[0].Email;

    this.LocationMasterData.carriers =
      this.Original_LocationMasterData.carriers.filter(
        (item: any) => item.LocationId == this.shipment.HeaderInfo.Location
      );
    this.LocationMasterData.carrieraccounts =
      this.Original_LocationMasterData.carrieraccounts.filter(
        (item: any) => item.LocationId == this.shipment.HeaderInfo.Location
      );
    this.LocationMasterData.carrierservices =
      this.Original_LocationMasterData.carrierservices.filter(
        (item: any) => item.LocationId == this.shipment.HeaderInfo.Location
      );
    this.LocationMasterData.dimensions =
      this.Original_LocationMasterData.dimensions.filter(
        (item: any) => item.LocationId == this.shipment.HeaderInfo.Location
      );
    this.LocationMasterData.erp = this.Original_LocationMasterData.erp.filter(
      (item: any) => item.LocationId == this.shipment.HeaderInfo.Location
    );
    this.LocationMasterData.ordertypes =
      this.Original_LocationMasterData.ordertypes.filter(
        (item: any) => item.LocationId == this.shipment.HeaderInfo.Location
      );
  }
  //COUNTRY CHNAGE START
  changeCountry(event: any): void {
    console.log(event.value);
    //to get all countries
    this.xfservice
      .getMasterCountries("country_code='" + event.value + "'")
      .subscribe((data) => {
        this.Master_states = data;
        console.log(this.Master_countries);
      });
  }
  //COUNTRY CHANGE END

  specialServiceData: any = {};

  processChange(
    blockname: any,
    sourcefield: any,
    value: any,
    effectedfields: string,
    mappingfield: string
  ) {
    // code to get event releated to source field

    //alert(sourcefield);
    var Before_userExist_code = 'return true';
    var After_userExist_code = 'return true';
    var cfield = this.ShipScreen[blockname].fields.filter(
      (item: any) => item.fieldName == sourcefield
    )[0];

    if (cfield) {
      if (cfield['Before_event']) {
        Before_userExist_code = atob(cfield['Before_event']);
      }

      // Field based logic -> carrier field
      //-------------------------------------------------
      if (sourcefield == 'Carrier') {
        //console.log( 'Current selectttd Location is : ' +  JSON.stringify(this.currentSelectedLocationid ))
        this.shipment.CarrierDetails.PaymentType = 'Sender';
        this.LocationMasterData.carrieraccounts = [];
        if (this.shipment.CarrierDetails.Carrier) {
          this.LocationMasterData.carrieraccounts =
            this.Original_LocationMasterData.carrieraccounts.filter(
              (ca: any) =>
                ca.CarrierName == value &&
                ca.LocationId == this.currentSelectedLocationid['LocationId']
            );
          if (this.LocationMasterData.carrieraccounts.length > 0) {
            this.shipment.CarrierDetails.ShippingAccount =
              this.LocationMasterData.carrieraccounts[0].Account;
            this.shipment.CarrierDetails.UserId =
              this.LocationMasterData.carrieraccounts[0].UserId;
            this.shipment.CarrierDetails.Password =
              this.LocationMasterData.carrieraccounts[0].Password;
            this.shipment.CarrierDetails['AccessKey'] =
              this.LocationMasterData.carrieraccounts[0].AccessKey;
          }
        } else {
          this.LocationMasterData.carrieraccounts =
            this.Original_LocationMasterData.carrieraccounts.filter(
              (ca: any) =>
                ca.LocationId == this.currentSelectedLocationid['LocationId'] &&
                ca.IsActive == true
            );
        }

        console.log(this.LocationMasterData.carrieraccounts);

        this.ServiceList = this.carrierservices.filter(
          (s: any) =>
            s.CarrierName == value &&
            s.LocationId == this.currentSelectedLocationid['LocationId']
        );

        const defaultcarrierservices = {
          label: 'Select',
          value: '',
          currency: '',
          CarrierName: '',
          LocationId: '',
        };
        // Add the default defaultCountry to the beginning of the list
        this.ServiceList.unshift(defaultcarrierservices);
        this.ServiceList.sort((a, b) => a.label.localeCompare(b.label));
      }

      //-------------------------------------------------------------

      if (cfield['After_event']) {
        After_userExist_code = atob(cfield['After_event']);
      }

      // need to move on inital call @ location master data
      //-------------------------------------------------------------------
      //if (sourcefield == 'Carrier') {
      //  this.xfservice.getSpecialServicesByCarrier(value).subscribe((data: any) => {
      //    this.specialServiceData = data;
      //    this.isSSLoded = true;
      //    // console.log(this.specialServiceData)
      //  })
      //}
      //-------------------------------------------------------------------
    }

    //Get Special Servises for selected carrier

    //Default code condition to run standard functionality
    //var func = new Function("return " + "function ('template','org_masterdata','masterdata') { " + userExist_code + " }")();
    var func = new Function(
      'return ' +
        'function (messageProcess,template,value,model) { ' +
        Before_userExist_code +
        ' }'
    )();
    if (func(this.messageService, this.ShipScreen, value, this.shipment)) {
      // alert('After call: ' + JSON.stringify(this.ShipScreen['CarrierDetails'].fields))

      if (effectedfields) {
        var ef_fields = [];
        if (effectedfields.indexOf(',') > 0) {
          ef_fields = effectedfields.split(',');
        } else {
          ef_fields.push(effectedfields);
        }

        ef_fields.forEach((item) => {
          this.LocationMasterData[item] = this.Original_LocationMasterData[
            item
          ].filter((mf: any) => mf[mappingfield] === value);
        });
      }
    }

    //after User Exist
    if (After_userExist_code) {
      var after_func = new Function(
        'return ' +
          'function (messageProcess,template,value,model,orgMasterData,currentMasterData) { ' +
          After_userExist_code +
          ' }'
      )();
      after_func(
        this.messageService,
        this.ShipScreen,
        value,
        this.shipment,
        this.Original_LocationMasterData,
        this.LocationMasterData
      );
    }
  }

  //clear() {
  //  this.shipment = this.clearShip;
  //  this.shipment.HeaderInfo.ShipDate = new Date();
  //  this.isdis = false;
  //}
  //Save Ship Request Start
  validateForm() {
    if (!this.shipment.HeaderInfo.DocumentType) {
      this.showError('Order Type is required.');
      return false;
    }
    if (!this.shipment.ShipFrom.COMPANY) {
      this.showError('ShipFrom Company Name is required.');
      return false;
    }
    if (!this.shipment.ShipFrom.CONTACT) {
      this.showError('ShipFrom Contact Name is required.');
      return false;
    }
    if (!this.shipment.ShipFrom.ADDRESS_LINE1) {
      this.showError('ShipFrom Address Line 1 is required.');
      return false;
    }
    if (!this.shipment.ShipFrom.CITY) {
      this.showError('ShipFrom City is required.');
      return false;
    }
    if (!this.shipment.ShipFrom.STATE) {
      this.showError('ShipFrom State is required.');
      return false;
    }
    if (!this.shipment.ShipFrom.ZIPCODE) {
      this.showError('ShipFrom Zip Code is required.');
      return false;
    }
    if (!this.shipment.ShipFrom.COUNTRY) {
      this.showError('ShipFrom Country is required.');
      return false;
    }
    if (!this.shipment.ShipFrom.PHONE) {
      this.showError('ShipFrom Phone is required.');
      return false;
    }
    if (!this.shipment.ShipFrom.EMAIL) {
      this.showError('ShipFrom Email is required.');
      return false;
    }
    if (!this.shipment.ShipTo.COMPANY) {
      this.showError('ShipTo Company Name is required.');
      return false;
    }
    if (!this.shipment.ShipTo.CONTACT) {
      this.showError('ShipTo Contact Name is required.');
      return false;
    }
    if (!this.shipment.ShipTo.ADDRESS_LINE1) {
      this.showError('ShipTo Address Line 1 is required.');
      return false;
    }
    if (!this.shipment.ShipTo.CITY) {
      this.showError('ShipTo City is required.');
      return false;
    }
    if (!this.shipment.ShipTo.STATE) {
      this.showError('ShipTo State is required.');
      return false;
    }
    if (!this.shipment.ShipTo.ZIPCODE) {
      this.showError('ShipTo Zip Code is required.');
      return false;
    }
    if (!this.shipment.ShipTo.COUNTRY) {
      this.showError('ShipTo Country is required.');
      return false;
    }
    if (!this.shipment.ShipTo.PHONE) {
      this.showError('ShipTo Phone is required.');
      return false;
    }
    if (!this.shipment.ShipTo.EMAIL) {
      this.showError('ShipTo Email is required.');
      return false;
    }
    if (!this.shipment.CarrierDetails.Carrier) {
      this.showError('Ship Method is required.');
      return false;
    }
    if (!this.shipment.CarrierDetails.ServiceName) {
      this.showError('Service Type is required.');
      return false;
    }
    // If all fields are valid
    let isValid = this.validateProducts();
    if (!isValid) {
      return false;
    }
    // this.showSuccess('All fields are valid.');
    return true;
  }
  validateProducts(): boolean {
    let hasAtLeastOneCompleteRow = false;

    for (const [index, product] of this.products.entries()) {
      // Check if all fields in the row are filled
      const isRowComplete =
        product.ProductNo && product.Quantity && product.UnitWeight;

      // If all fields are filled in this row, mark as having at least one complete row
      if (isRowComplete) {
        hasAtLeastOneCompleteRow = true;
      }

      // Check if the row has partial data (some fields are filled, but not all)
      const hasPartialData =
        product.ProductNo || product.Quantity || product.UnitWeight;

      // If there is partial data but the row isn't fully complete, return an error
      if (hasPartialData && !isRowComplete) {
        if (!product.ProductNo) {
          this.showError(`ProductNo is required at row ${index + 1}.`);
          return false;
        }
        if (!product.Quantity) {
          this.showError(`Quantity is required at row ${index + 1}.`);
          return false;
        }
        if (!product.UnitWeight) {
          this.showError(`Unit Weight is required at row ${index + 1}.`);
          return false;
        }
      }
    }

    // If no complete row was found, show an error
    if (!hasAtLeastOneCompleteRow) {
      this.showError(
        'At least one row must have all required fields (ProductNo, Quantity, Unit Weight).'
      );
      return false;
    }

    // If validation passes, return true
    return true;
  }
  showError(detail: string) {
    this.messageService.add({
      key: 'tc',
      severity: 'error',
      summary: 'Validation Error',
      detail,
    });
  }

  showSuccess(detail: string) {
    this.messageService.add({
      key: 'tc',
      severity: 'success',
      summary: 'Success',
      detail,
    });
  }

  Save(): void {
    // const isValid = this.validateProducts();
    if (this.validateForm()) {
      this.shipment.Items = this.products.filter(
        (product) => product.ProductNo
      );
      this.shipment.HeaderInfo.Status = 'Open';
      this.xfservice.saveShipRequest(this.shipment).subscribe((data) => {
        this.shipment = Object.assign({}, this.clearShip);
        this.messageService.add({
          key: 'tc',
          severity: 'success',
          summary: 'Ship Request',
          detail: 'ShipRequest Successfully saved',
        });
      });
    }
  }
  confirmDelete(rowIndex: number) {
    this.confirmationService.confirm({
      message: 'Do you want to delete this record?',
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',
      acceptButtonStyleClass: 'p-button-danger p-button-text',
      rejectButtonStyleClass: 'p-button-text p-button-text',
      acceptIcon: 'none',
      rejectIcon: 'none',

      accept: () => {
        this.deleteProduct(rowIndex);
        // this.messageService.add({
        //   severity: 'info',
        //   summary: 'Confirmed',
        //   detail: 'Record deleted',
        // });
      },
    });
  }
  deleteProduct(rowIndex: number) {
    this.products.splice(rowIndex, 1);
    // this.messageService.add({
    //   severity: 'info',
    //   summary: 'Confirmed',
    //   detail: 'Product deleted',
    // });
  }
  // Save Ship Request end
  getKeys(obj: any): string[] {
    const keys: string[] = [];
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        keys.push(key);
        if (typeof obj[key] === 'object' && obj[key] !== null) {
          const nestedKeys = this.getKeys(obj[key]);
          keys.push(...nestedKeys.map((nestedKey) => `${key}.${nestedKey}`));
        }
      }
    }
    return keys;
  }
  openAddressBook(fromblock: any): void {
    this.currentAddressBlock = fromblock;
    this.addressbookcols = [];
    this.addressbookfilters = [];
    this.xfservice.getAddressBookTemplate().subscribe((data) => {
      this.addressbookgrid = data[0].fields;
      this.addressbookgrid.forEach((colfield) => {
        // console.log(colfield);
        let col = {
          field: '',
          header: '',
          visible: true,
          editable: false,
          fieldType: '',
          datatype: '',
        };
        col.field = colfield.fieldName;
        col.header = colfield.fieldName;
        col.editable = colfield.editable;
        col.fieldType = colfield.fieldType;
        col.datatype = colfield.datatype;
        this.addressbookfilters.push(colfield.fieldName);
        if (col.field == 'id' || col.field == 'IsActive') {
          col.visible = false;
        }
        if (col.visible) {
          this.addressbookcols.push(col);
        }
      });
      //console.log('addressbook fields are: ' + JSON.stringify(this.addressbookcols));

      this.xfservice.getMasterDataByName('addressBook').subscribe((data) => {
        this.Master_addressBook = data;
        // console.log('Address:' + data)
        this.AddressBook_dialog = true;
      });
    });
  }
  applyFilterGlobal_Address($event: any, stringVal: any): void {
    this.addrdt.filterGlobal(
      ($event.target as HTMLInputElement).value,
      stringVal
    );
  }
  assignAddress(data: any): void {
    this.shipment[this.currentAddressBlock].COMPANY = data.COMPANY;
    this.shipment[this.currentAddressBlock].CONTACT = data.CONTACT;
    this.shipment[this.currentAddressBlock].ADDRESS_LINE1 = data.ADDRESS_LINE1;
    this.shipment[this.currentAddressBlock].ADDRESS_LINE2 = data.ADDRESS_LINE2;
    this.shipment[this.currentAddressBlock].CITY = data.CITY;
    this.shipment[this.currentAddressBlock].STATE = data.STATE;
    this.shipment[this.currentAddressBlock].ZIPCODE = data.ZIPCODE;
    this.shipment[this.currentAddressBlock].COUNTRY = data.COUNTRY;
    this.shipment[this.currentAddressBlock].PHONE = data.PHONE;
    this.shipment[this.currentAddressBlock].EMAIL = data.EMAIL;
    this.AddressBook_dialog = false;
  }
  handleLocationChange(event: any) {
    console.log('handleLocationChange', event);
  }

  handleDeliveryChange(event: any) {
    console.log('handleDeliveryChange', event);
  }
  displayColumnConfig: boolean = false;
  displayProductDialog: boolean = false;
  selectedProduct: any = null;
  selectedRowIndex: number = -1;
  // Data for the table
  products: any[] = [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}];
  allProducts: any[] = [];
  // All available columns
  allColumns: any[] = [];
  dimensionOptions: DropdownOption[] = [];
  mandatoryColumns = [
    'productno',
    'description',
    'quantity',
    'unitweight',
    'dimension',
    'insurance',
  ];
  // Columns selected by the user
  selectedColumns: string[] = [];
  numberOfFixedColumns = 4;
  defaultSelectedColumns: any[] = [];
  isColumnVisible(field: string): boolean {
    let fieldLowerCase = field.toLowerCase();
    return this.selectedColumns.includes(fieldLowerCase);
  }
  isColAvailable(val: any): boolean {
    const isAvailable = !this.selectedColumns.includes(
      val.fieldName.toLowerCase()
    );
    return isAvailable;
  }
  // Toggle column visibility when a checkbox is clicked
  toggleColumnVisibility(columnName: string, isVisible: boolean): void {
    if (this.mandatoryColumns.includes(columnName.toLowerCase())) {
      return; // Prevent toggling mandatory columns
    }

    if (isVisible) {
      this.selectedColumns.push(columnName.toLowerCase());
    } else {
      this.selectedColumns = this.selectedColumns.filter(
        (col) => col !== columnName.toLowerCase()
      );
    }
    // let fieldLowerCase = field.toLowerCase();
    // const isDefaultColumn = this.defaultSelectedColumns.includes(field);
    // if (checked) {
    //   if (!this.selectedColumns.includes(field)) {
    //     this.selectedColumns.push(fieldLowerCase);
    //   }
    // } else {
    //   if (!isDefaultColumn) {
    //     this.selectedColumns = this.selectedColumns.filter(
    //       (col) => col !== fieldLowerCase
    //     );
    //   }
    // }
  }
  applyColumnConfiguration(): void {
    this.selectedColumns = [
      ...new Set([...this.mandatoryColumns, ...this.selectedColumns]),
    ];
    this.displayColumnConfig = false;
  }
  openProductDialog(rowIndex: number): void {
    this.selectedRowIndex = rowIndex;
    this.displayProductDialog = true;
  }
  selectProduct(product: any): void {
    // Update the corresponding row in the main table with the selected product's details
    if (this.selectedRowIndex !== undefined && this.selectedRowIndex !== null) {
      this.products[this.selectedRowIndex] = {
        ...this.products[this.selectedRowIndex], // Keep existing fields if necessary
        ProductNo: product.ProductNo,
        Description: product.Description,
        TotalQty:
          product.TotalQty || this.products[this.selectedRowIndex].TotalQty, // Preserve if needed
        Weight: product.Weight || this.products[this.selectedRowIndex].Weight, // Preserve if needed
        Dimension:
          product.Dimension || this.products[this.selectedRowIndex].Dimension, // Preserve if needed
        Insurance:
          product.Insurance || this.products[this.selectedRowIndex].Insurance, // Preserve if needed
      };
    }

    // Close the dialog after selection
    this.displayProductDialog = false;
  }
  openMasterProducts(): void {
    this.xfservice.getMasterDataByName('products').subscribe((data) => {
      // console.log("Products are 1:" + JSON.stringify(data))
      this.allProducts = data;
      var i = 1;
      this.allProducts.forEach((p: any) => {
        p['Sno'] = i++;
      });
    });
  }
  onRowClick(rowIndex: number): void {
    if (rowIndex === this.products.length - 1) {
      // If it's the last row, add a new row
      this.addNewRow();
    }
  }
  addNewRow(): void {
    const newRow = {
      sno: this.products.length + 1, // Automatically increment S.No
      itemNo: '',
      productNo: '',
      description: '',
    };
    this.products.push(newRow);
  }
}
