
import { Component, OnInit } from '@angular/core';
import { Validators, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ERPSHIPService } from '../erpship.service';
import { RegisterCompanyService } from '../register-company.service';

@Component({
  selector: 'app-slogin',
  templateUrl: './slogin.component.html',
  styleUrls: ['./slogin.component.scss']
})
export class SloginComponent  implements OnInit {


  constructor(private router: Router, private xfservice: ERPSHIPService,private regcomservise:RegisterCompanyService) { }
  loginForm: any;
  submitted = false;
  openRegisterFormDialog=false;
  newcompany:any={};

  ngOnInit() {
    this.loginForm = new FormGroup({
      'login': new FormControl('', Validators.required),
      'password': new FormControl('', Validators.required)
    });
  }


  onSubmit() {
    this.submitted = true;
    this.xfservice
      .processLogin(this.loginForm.value)
      .subscribe((response) => {
        response.headers.keys().map((key: any) => console.log(`${key}: ${response.headers.get(key)}`));
        console.log('JWT : ' + response.headers.get('x-token'))
        window.sessionStorage.setItem('X-Token', response.headers.get('x-token'))

        const dtoken: any = JSON.stringify(JSON.parse(window.atob(response.headers.get('x-token').split('.')[1])))
        let date = new Date();
        date.setTime(date.getTime() + (1 * 24 * 60 * 60 * 1000));
        const expires = "expires=" + date.toUTCString();
        document.cookie = 'role' + "=" + dtoken + "; " + expires + "; path=/";
        this.router.navigate(['home/DashBoard'])
      }, error => {
        console.log("Error is : " + JSON.stringify(error));

      });
  }

  openRegister():void{
    this.openRegisterFormDialog=true
  }
  saveCompany(){
   
    this.regcomservise.saveCompany(this.newcompany).subscribe(data=>alert("Company Created"), error=>{alert(error)})

  }

}
