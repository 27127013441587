<!-- ADDRESSBOOK  DIALOG START-->
<p-dialog [(visible)]="display" [style]="{ width: '70%' }" header="Address Book" [modal]="true"
          styleClass="p-fluid">
  <p-table #dt2 [columns]="addressbookcols" [value]="Master_addressBook" 
           [style]="{ width: '100%', height: '90%' }"
           styleClass="p-datatable-sm p-datatable-gridlines p-datatable-striped mt-3" 
           responsiveLayout="stack"
           [globalFilterFields]="addressbookfilters" [paginator]="true" [rows]="10">
    
    <ng-template pTemplate="caption">
      <div style="text-align: right">
        <i class="pi pi-search" style="margin:4px 4px 0 0"></i>
        <input type="text" pInputText size="50" placeholder="Global Filter"
               (input)="applyFilterGlobal_Address($event, 'contains')" style="width:auto">
      </div>
    </ng-template>
    
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th alignFrozen="left" pFrozenColumn [frozen]="true">Select</th>
        <ng-template ngFor let-col [ngForOf]="columns">
          <th pSortableColumn="{{ col.field }}">
            {{ col.header }}<p-sortIcon field="{{ col.field }}"></p-sortIcon>
          </th>
        </ng-template>
      </tr>
    </ng-template>
    
    <ng-template pTemplate="body" let-rowData let-columns="columns">
      <tr>
        <td alignFrozen="left" pFrozenColumn [frozen]="true">
          <button pButton type="button" icon="pi pi-check" iconPos="left" class="p-button-rounded p-button-sm"
                  (click)="selectAddress(rowData)"></button>
        </td>
        <ng-template ngFor let-col [ngForOf]="columns" let-i="index">
          <td>{{ rowData[col.field] }}</td>
        </ng-template>
      </tr>
    </ng-template>
  </p-table>
</p-dialog>

<!-- ADDRESSBOOK DIALOG  END-->