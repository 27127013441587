import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {  HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RegisterCompanyService {
  sourceurl: string = 'http://localhost:5000';

  constructor(private http: HttpClient) {

   }
   saveCompany(record:any):Observable<any>{

    alert('request received : ' + record)
    const headers = {
      'content-type': 'application/json',
      //'X-CSRF-Token': 'Fetch',
    };
    
    return this.http.post(
      this.sourceurl + '/registercompany',
      record,
      {
        headers,
      })
    }
      
}
