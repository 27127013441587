import { Component } from '@angular/core';

@Component({
  selector: 'app-international-details',
  templateUrl: './international-details.component.html',
  styleUrl: './international-details.component.scss'
})
export class InternationalDetailsComponent {
  shipMethods = [
    { label: 'Ship Method 1', value: 'Ship Method 1' },
    { label: 'Ship Method 2', value: 'Ship Method 2' }
  ];

  serviceTypes = [
    { label: 'Service 1', value: 'Service 1' },
    { label: 'Service 2', value: 'Service 2' }
  ];

  paymentTypes = [
    { label: 'Payment 1', value: 'Payment 1' },
    { label: 'Payment 2', value: 'Payment 2' }
  ];

  costCenters = [
    { label: 'Cost Centre 1', value: 'Cost Centre 1' },
    { label: 'Cost Centre 2', value: 'Cost Centre 2' }
  ];

  selectedShipMethod: string = "";
  selectedServiceType: string = "";
  carrierId: string = "";
  accountNumber: string = "";
  selectedPaymentType: string = "";
  selectedCostCenter: string = "";
  poNo: string = "";
  invoiceNo: string = "";
  notes: string = "";

}