<p-toast></p-toast>
<p-toast position="top-left" key="tl"></p-toast>
<p-toast position="top-center" key="tc"></p-toast>
<p-toast position="bottom-center" key="bc"></p-toast>

<!-- <p-toast position="bottom-center" key="c" [baseZIndex]="5000"> -->
<!-- Header Section Starts Here -->
<div class="add-ship-request-title">
    <span>Create Ship Request </span>
    <button pButton icon="pi pi-times" class="p-button-secondary close-icon-css" (click)="closeShipScreen()"></button>
</div>
<div class="add-ship-request-header ml-3 mr-3">
    <div class="grid m-0 mb-2">
        <div class="col-3 pb-0 pt-0">
            <div class="grid m-0">
                <div class="col-4 pb-0">
                    <label class="lbl-css" for="feedersystem">Feeder System <span
                            class="mandatory_fileds">*</span></label>
                </div>
                <div class="col-8 pb-0">
                    <p-dropdown class="vertical-align-middle" [(ngModel)]="shipment.HeaderInfo.FeederSystem"
                        [options]="ErpList" [filter]="true">
                    </p-dropdown>
                </div>
            </div>
        </div>
        <div class="col-3 pb-0 pt-0">

            <div class="grid m-0">
                <div class="col-4 pb-0">
                    <label class="lbl-css" for="ordertype"> Order Type <span class="mandatory_fileds">*</span></label>
                </div>
                <div class="col-8 pb-0">
                    <p-dropdown id="ordertype" [filter]="true" [options]="OrderTypes"
                        [(ngModel)]="shipment.HeaderInfo.DocumentType">
                    </p-dropdown>
                </div>
            </div>
        </div>

        <div class="col-3 pb-0 pt-0">
            <div class="grid m-0">
                <div class="col-4 pb-0">
                    <label class="lbl-css" for="shipmenttype"> Shipment Type <span
                            class="mandatory_fileds">*</span></label>
                </div>
                <div class="col-8 pb-0">
                    <p-dropdown id="shipmenttype" [options]="ShipmentTypes" [filter]="true"
                        [(ngModel)]="shipment.HeaderInfo.ShipmentType">
                    </p-dropdown>
                </div>
            </div>

        </div>
        <div class="col-3 pb-0 pt-0">
            <div class="grid m-0">
                <div class="col-4 pb-0">
                    <label class="lbl-css" for="shipmenttype"> Ship Date </label>
                </div>
                <div class="col-8 pb-0">
                    <p-calendar [(ngModel)]="shipment.HeaderInfo.ShipDate" showIcon="true" />
                </div>
            </div>
        </div>

        <!-- <button pButton label="New" class="p-button-primary"
    style="background-color: rgba(3, 14, 76, 1); color: white; border-radius: 5px;"></button>
  <button pButton icon="pi pi-refresh" class="p-button-secondary"></button> -->

    </div>
</div>
<!-- Tabs starts  -->
<div class="grid m-0 m-1">
    <div class="col-6 pt-0 pb-0 pl-4px">
        <!-- Ship from  -->
        <p-tabView [(activeIndex)]="activeTabIndex">
            <p-tabPanel header="Ship From">
                <div class="grid m-0">
                    <div class="col-6 pl-0 pr-3 pt-0 pb-0">
                        <div class="form-group">
                            <label for="locationFrom">Company Name <span class="mandatory_fileds">*</span></label>
                            <div class="p-inputgroup p-input-icon-right">
                                <i class="pi pi-search" (click)="openAddressBook('ShipFrom')"></i>
                                <input pInputText id="locationFrom" [(ngModel)]="shipment.ShipFrom.COMPANY" />
                            </div>
                        </div>
                        <div class="form-group pt-2">
                            <label for="addressLine1">Address Line 1 <span class="mandatory_fileds">*</span></label>
                            <input pInputText id="addressLine1" [(ngModel)]="shipment.ShipFrom.ADDRESS_LINE1" />
                        </div>
                        <div class="grid m-0">
                            <div class="col-6 pl-0 pt-0">
                                <div class="form-group pt-2">
                                    <label for="city">City <span class="mandatory_fileds">*</span></label>
                                    <input pInputText id="city" [(ngModel)]="shipment.ShipFrom.CITY" />
                                </div>
                            </div>
                            <div class="col-6 pr-0 pt-0">
                                <div class="form-group pt-2">
                                    <label for="stateFrom">State <span class="mandatory_fileds">*</span></label>
                                    <input pInputText id="stateFrom" [(ngModel)]="shipment.ShipFrom.STATE" />
                                </div>
                            </div>
                        </div>

                        <div class="form-group pt-0">
                            <label for="email">Email <span class="mandatory_fileds">*</span></label>
                            <input pInputText id="email" />
                        </div>
                    </div>
                    <div class="col-6 pt-0 pr-0 pb-0">
                        <div class="form-group">
                            <label for="contact">Contact Name <span class="mandatory_fileds">*</span></label>
                            <input pInputText id="contact" [(ngModel)]="shipment.ShipFrom.CONTACT" />
                        </div>
                        <div class="form-group pt-2">
                            <label for="addressLine2">Address Line 2 <span class="mandatory_fileds">*</span></label>
                            <input pInputText id="addressLine2" [(ngModel)]="shipment.ShipFrom.ADDRESS_LINE2" />
                        </div>

                        <div class="grid m-0">
                            <div class="col-6 pl-0 pt-0">
                                <div class="form-group pt-2">
                                    <label for="countryFrom">Country <span class="mandatory_fileds">*</span></label>
                                    <p-dropdown [options]="countries" [filter]="true"
                                        [(ngModel)]="shipment.ShipFrom.COUNTRY"></p-dropdown>
                                </div>
                            </div>
                            <div class="col-6 pr-0 pt-0">
                                <div class="form-group pt-2">
                                    <label for="Zipcode">Zipcode <span class="mandatory_fileds">*</span></label>
                                    <input pInputText id="Zipcode" [(ngModel)]="shipment.ShipFrom.ZIPCODE" />
                                </div>
                            </div>
                        </div>
                        <div class="grid m-0">
                            <div class="col-6 pl-0 pt-0">
                                <div class="form-group pt-0">
                                    <label for="phone">Phone <span class="mandatory_fileds">*</span></label>
                                    <input pInputText id="phone" [(ngModel)]="shipment.ShipFrom.PHONE" />
                                </div>
                            </div>
                            <div class="col-6 pr-0 pt-0">
                                <div class="form-group pt-0">
                                    <label for="addressTypeFrom">Address Type</label>
                                    <p-dropdown [options]="addressTypes" [options]="addressTypes"></p-dropdown>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </p-tabPanel>
            <p-tabPanel header="Ship To">
                <div class="form-container">
                    <div class="form-section">
                        <div class="grid m-0">
                            <div class="col-6 pl-0 pr-3 pt-0 pb-0">
                                <div class="form-group">
                                    <label class="shipto_labels" for="companyNameTo">Company Name <span
                                            class="mandatory_fileds">*</span></label>
                                    <div class="p-inputgroup p-input-icon-right">
                                        <i class="pi pi-search" (click)="openAddressBook('ShipTo')"></i>
                                        <input pInputText id="companyNameTo" [(ngModel)]="shipment.ShipTo.COMPANY" />
                                    </div>
                                </div>
                                <div class="form-group pt-2">
                                    <label class="shipto_labels" for="addressLine1">Address Line 1 <span
                                            class="mandatory_fileds">*</span></label>
                                    <input pInputText id="addressLine1" [(ngModel)]="shipment.ShipTo.ADDRESS_LINE1" />
                                </div>

                                <div class="grid m-0">
                                    <div class="col-6 pl-0 pt-0">
                                        <div class="form-group pt-2">
                                            <label class="shipto_labels" for="city">City <span
                                                    class="mandatory_fileds">*</span></label>
                                            <input pInputText id="city" [(ngModel)]="shipment.ShipTo.CITY" />
                                        </div>
                                    </div>
                                    <div class="col-6 pr-0 pt-0">
                                        <div class="form-group pt-2">
                                            <label class="shipto_labels" for="stateTo">State <span
                                                    class="mandatory_fileds">*</span></label>
                                            <input pInputText id="stateTo" [(ngModel)]="shipment.ShipTo.STATE" />
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group pt-0">
                                    <label class="shipto_labels" for="email">Email <span
                                            class="mandatory_fileds">*</span></label>
                                    <input pInputText id="email" [(ngModel)]="shipment.ShipTo.EMAIL" />
                                </div>
                            </div>
                            <div class="col-6 pt-0 pr-0 pb-0">
                                <div class="form-group pt-0">
                                    <label class="shipto_labels" for="displayName">Contact Name <span
                                            class="mandatory_fileds">*</span></label>
                                    <input pInputText id="displayName" [(ngModel)]="shipment.ShipTo.CONTACT" />
                                </div>
                                <div class="form-group pt-2">
                                    <label class="shipto_labels" for="addressLine2">Address Line 2 <span
                                            class="mandatory_fileds">*</span></label>
                                    <input pInputText id="addressLine2" [(ngModel)]="shipment.ShipTo.ADDRESS_LINE2" />
                                </div>

                                <div class="grid m-0">
                                    <div class="col-6 pt-0 pl-0">
                                        <div class="form-group pt-2 pl-0">
                                            <label class="shipto_labels" for="countryTo">Country <span
                                                    class="mandatory_fileds">*</span></label>
                                            <p-dropdown [options]="countries"
                                                [(ngModel)]="shipment.ShipTo.COUNTRY"></p-dropdown>
                                        </div>
                                    </div>
                                    <div class="col-6 pt-0 pr-0">
                                        <div class="form-group pt-2 pr-0">
                                            <label class="shipto_labels" for="Zipcode">Zipcode <span
                                                    class="mandatory_fileds">*</span></label>
                                            <input pInputText id="Zipcode" [(ngModel)]="shipment.ShipTo.ZIPCODE" />
                                        </div>
                                    </div>
                                </div>
                                <div class="grid m-0">
                                    <div class="col-6 pt-0 pl-0">
                                        <div class="form-group pt-0 pl-0">
                                            <label class="shipto_labels" for="phone">Phone <span
                                                    class="mandatory_fileds">*</span></label>
                                            <input pInputText id="phone" [(ngModel)]="shipment.ShipTo.PHONE" />
                                        </div>
                                    </div>
                                    <div class="col-6 pt-0 pr-0">
                                        <div class="form-group pt-0 pr-0">
                                            <label class="shipto_labels" for="addressTypeFrom">Address Type</label>
                                            <p-dropdown [options]="addressTypes"></p-dropdown>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </p-tabPanel>
        </p-tabView>
    </div>

    <!-- Tabs End  -->
    <!-- ADDRESSBOOK  DIALOG START-->
    <p-dialog [(visible)]="AddressBook_dialog" [style]="{ width: '70%' }" header="Address Book" [modal]="true"
        styleClass="p-fluid">
        <p-table #dt2 [columns]="addressbookcols" [value]="Master_addressBook"
            [style]="{ width: '100%', height: '90%' }"
            styleClass="p-datatable-sm p-datatable-gridlines p-datatable-striped mt-3" responsiveLayout="stack"
            [globalFilterFields]="addressbookfilters" [paginator]="true" [rows]="10">


            <ng-template pTemplate="caption">
                <div style="text-align: right">
                    <i class="pi pi-search" style="margin:4px 4px 0 0"></i>

                    <input type="text" pInputText size="50" placeholder="Global Filter"
                        (input)="applyFilterGlobal_Address($event, 'contains')" style="width:auto">
                </div>
            </ng-template>

            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th alignFrozen="left" pFrozenColumn [frozen]="true">Select</th>
                    <ng-template ngFor let-col [ngForOf]="columns">
                        <th pSortableColumn="{{ col.field }}">
                            {{ col.header }}<p-sortIcon field="{{ col.field }}"></p-sortIcon>
                        </th>
                    </ng-template>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-columns="columns">
                <tr>
                    <td alignFrozen="left" pFrozenColumn [frozen]="true">
                        <button pButton type="button" icon="pi pi-check" iconPos="left"
                            class="p-button-rounded p-button-sm" (click)="assignAddress(rowData)"></button>
                    </td>
                    <ng-template ngFor let-col [ngForOf]="columns" let-i="index">
                        <td>{{ rowData[col.field] }}</td>
                    </ng-template>
                </tr>
            </ng-template>
        </p-table>
        <!-- </div> -->
    </p-dialog>
    <!-- ADDRESSBOOK DIALOG  END-->
    <div class="col-6 pt-0 pb-0">
        <div class="routing-billing-heading">
            <h5>Routing & Billing</h5>
        </div>
        <div class="grid m-0 p-3 pt-1 routing_billing_sec_css">
            <div class="col-6 pl-0 pr-3 pt-0 pb-2">
                <div class="form-group">
                    <div>
                        <label for="shipMethod">Ship Method <span class="mandatory_fileds">*</span></label>
                        <p-dropdown id="shipMethod" [options]="carriers" [(ngModel)]="shipment.CarrierDetails.Carrier"
                            [filter]="true"
                            (onChange)="processChange('CarrierDetails', 'Carrier', this.shipment.CarrierDetails['Carrier'], '', '')"></p-dropdown>
                    </div>
                </div>
                <div class="grid m-0">
                    <div class="col-6 pl-0">
                        <div class="form-group pt-0">
                            <label for="carrierId">Carrier</label>
                            <input id="carrierId" type="text" pInputText [(ngModel)]="carrierId" />
                        </div>
                    </div>
                    <div class="col-6 pr-0">
                        <div class="form-group pt-0">
                            <label for="accountNumber">Account #</label>
                            <input id="accountNumber" type="text" pInputText
                                [(ngModel)]="shipment.CarrierDetails.ShippingAccount" />
                        </div>
                    </div>
                </div>
                <div class="form-group pt-0">
                    <label for="poNo">PO #</label>
                    <input id="poNo" type="text" pInputText [(ngModel)]="shipment.CarrierDetails.PoNo" />
                </div>

                <div class="form-group pt-2">
                    <label for="invoiceNo">Invoice #</label>
                    <input id="invoiceNo" type="text" pInputText [(ngModel)]="shipment.CarrierDetails.InvoiceNo" />
                </div>

            </div>
            <div class="col-6 col-6 pt-0 pr-0 pb-2">
                <div class="form-group">
                    <div>
                        <label for="serviceType">Service Type <span class="mandatory_fileds">*</span></label>
                        <p-dropdown id="serviceType" [options]="ServiceList" [filter]="true"
                            [(ngModel)]="shipment.CarrierDetails.ServiceName"></p-dropdown>
                    </div>
                </div>
                <div class="grid m-0">
                    <div class="col-6 pl-0">
                        <div class="form-group pt-0">
                            <label for="paymentType">Payment Type</label>
                            <p-dropdown id="paymentType" [options]="paymentTypes" [filter]="true"
                                [(ngModel)]="shipment.CarrierDetails.PaymentType"></p-dropdown>
                        </div>
                    </div>
                    <div class="col-6 pr-0">
                        <div class="form-group pt-0">
                            <label for="costCenter">Cost Center</label>
                            <p-dropdown id="costCenter" [options]="costCenters"
                                [(ngModel)]="selectedCostCenter"></p-dropdown>
                        </div>
                    </div>
                </div>
                <div class="col-12 p-0">
                    <div class="form-group">
                        <label for="notes">Notes</label>
                        <textarea id="notes" rows="4" pInputTextarea
                            [(ngModel)]="shipment.CarrierDetails.Note"></textarea>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
<div class="card shiprequest_grid_modal_css" style="height: 340px;">
    <div class="item-info">
        <div class="grid m-0">
            <div class="col-11 pl-0">
                <h5 class="card-item-css">Item(s) Information</h5>
            </div>
            <div class="col-1 pr-0">
                <div>
                    <button pButton icon="pi pi-cog" class="setting_btn_css"
                        (click)="displayColumnConfig=true"></button>
                    <p-dialog class="column-configure-popup" header="Configure Fields" [(visible)]="displayColumnConfig"
                        [modal]="true">
                        <div class="p-fluid">
                            <div *ngFor="let col of allColumns">
                                <p-checkbox [label]="col.header" class="chk-configure-fileds" [binary]="true"
                                    [ngModel]="isColumnVisible(col.fieldName)"
                                    (onChange)="toggleColumnVisibility(col.fieldName, $event.checked)">
                                </p-checkbox>
                            </div>
                        </div>
                        <div class="p-dialog-footer">
                            <button type="button" class="btn-common-css modal-btn-apply"
                                (click)="applyColumnConfiguration()"><span class="pi pi-check"></span> Apply </button>
                            <button type="button" class="btn-common-css btn-ship-cancel"
                                (click)="displayColumnConfig=false"><span class="pi pi-times"></span> Cancel </button>
                            <!-- 

                            <button pButton label="Apply" icon="pi pi-check" class="modal-btn-apply"
                                (click)="applyColumnConfiguration()"></button>
                            <button pButton label="Cancel" icon="pi pi-times" class="p-button-secondary modal-btn-cancel"
                                (click)="displayColumnConfig=false"></button> -->
                        </div>
                    </p-dialog>
                </div>
            </div>
        </div>

    </div>
    <p-confirmDialog></p-confirmDialog>
    <p-table [value]="products" [scrollable]="true" editable="true" class="productstable"
        *ngIf="allColumns && selectedColumns.length > 0">
        <ng-template pTemplate="header">
            <tr>
                <th>S.No</th>
                <th *ngFor="let col of allColumns" [ngClass]="{'hidden': isColAvailable(col)}">
                    {{ col.header }}
                </th>
                <th>Actions</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-product let-rowIndex="rowIndex">
            <tr (click)="onRowClick(rowIndex)">
                <td>
                    {{ rowIndex + 1 }}
                </td>
                <td *ngFor="let col of allColumns" [ngClass]="{'hidden': isColAvailable(col)}" pEditableColumn>
                    <p-cellEditor>
                        <ng-template pTemplate="input">
                            <ng-container *ngIf="col.fieldName === 'Dimension'; else defaultInput">
                                <p-dropdown [(ngModel)]="product[col.fieldName]" [options]="dimensionOptions"
                                    [editable]="true" placeholder="Select or Add Dimension">
                                </p-dropdown>
                            </ng-container>
                            <ng-template #defaultInput>
                                <input pInputText [(ngModel)]="product[col.fieldName]" />
                            </ng-template>
                        </ng-template>
                        <ng-template pTemplate="output">
                            {{ product[col.fieldName] }}
                        </ng-template>
                    </p-cellEditor>
                </td>



                <!-- Actions Column -->
                <td>
                    <i class="pi pi-search" style="cursor: pointer; margin-right: 10px;"
                        (click)="openProductDialog(rowIndex)"></i>

                    <i class="pi pi-trash" (click)="confirmDelete(rowIndex)" style="cursor: pointer; color: red;"></i>
                </td>
            </tr>
        </ng-template>
    </p-table>
    <!-- Product Information Dialog -->
    <p-dialog header="Products Information" [(visible)]="displayProductDialog" [modal]="true" [style]="{width: '60vw'}">
        <!-- Product Search Table inside the Dialog -->
        <p-table [value]="allProducts" selectionMode="single" [(selection)]="selectedProduct">
            <ng-template pTemplate="header">
                <tr>
                    <th *ngFor="let col of allColumns">
                        {{ col.header }}
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-product let-rowIndex="rowIndex">
                <tr (click)="selectProduct(product)">
                    <!-- Dynamically load the values for each product based on the columns -->
                    <td *ngFor="let col of allColumns">
                        {{ product[col.fieldName] }}
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </p-dialog>
</div>
<!-- Footer Seaction Starts here -->
<div class="footer-dialog-css">
    <div class="grid m-0 pl-2 pr-2">
        <div class="col">
            <button type="button" class="btn-common-css btn-ship-cancel" label="Cancel">Cancel</button>
        </div>
        <div class="col text-center">
            <button type="button" class="btn-common-css btn-freight-shop">Freight Shop</button>
            <button type="button" class="btn-common-css btn-freight-console">Freight Quote</button>
        </div>
        <div class="col text-right">
            <button type="button" class="btn-common-css btn-save-new" (click)="Save()">Save & New</button>
        </div>
    </div>
</div>