<div class="grid">
    <!-- Row 1 -->

    <div class="col-3">
        <label for="customer">Customer #</label>
        <input pInputText id="customer" />
    </div>
    <div class="col-3">
        <label for="po">PO</label>
        <input pInputText id="po" />
    </div>
    <div class="col-3">
        <label for="invoice">Invoice #</label>
        <input id="invoice" type="text" pInputText />
    </div>

    <!-- Row 2 -->
    <div class="col-3">
        <label for="salesorder">Sales Order</label>
        <input pInputText id="salesorder" />
    </div>
    <div class="col-3">
        <label for="references">References</label>
        <p-dropdown class="ngprime-dropdown" [options]="referenceOptions" [(ngModel)]="selectedReference"
            id="references" [style]="{ minWidth: '50px', width: '80%' }"></p-dropdown>
    </div>

    <div class="col-3">
        <label for="prefix"> Prefix </label>
        <input pInputText id="prefix" />
    </div>
    <div class="col-3">
        <label for="incoterm">Incoterm Location</label>
        <input pInputText id="incoterm" />
    </div>
    <div class="col-3">
        <label for="transportationmode">Transportation Mode</label>
        <p-dropdown [options]="transportationModeOptions" [(ngModel)]="selectedTransportationMode"
            id="transportationmode" [style]="{ minWidth: '50px', width: '80%' }"></p-dropdown>
    </div>
    <div class="col-3">
        <label for="movecode">Move Code</label>
        <p-dropdown [options]="moveCodeOptions" [(ngModel)]="selectedMoveCode" id="movecode"
            [style]="{ minWidth: '50px', width: '80%' }"></p-dropdown>
    </div>
    <div class="col-3">
        <label for="shipmentmethod">Shipment Method (Shipment Type)</label>
        <p-dropdown [options]="shipmentMethodOptions" [(ngModel)]="selectedShipmentMethod" id="shipmentmethod"
            [style]="{ minWidth: '50px', width: '80%' }"></p-dropdown>
    </div>
    <div class="col-3">
        <label for="Reefer">Reefer</label>
        <p-dropdown [options]="reeferOptions" [(ngModel)]="selectedReefer" id="Reefer"
            [style]="{ minWidth: '50px', width: '80%' }"></p-dropdown>
    </div>
    <div class="field col-12">
        <label for="notes">Notes</label>
        <textarea id="notes" type="text" rows="4"
            class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"></textarea>
    </div>
</div>