import { Component, Input, Output, EventEmitter,ViewEncapsulation  } from '@angular/core';
import { MessageService } from 'primeng/api';
@Component({
  selector: 'app-warning-popup',
  templateUrl: './warning-popup.component.html',
  styleUrls: ['./warning-popup.component.scss'],
  encapsulation: ViewEncapsulation.None  // Disable view encapsulation
  
})
export class WarningPopupComponent {
  constructor(private messageService: MessageService) {}
  isPopupVisible = false;
  message:any;
  showWarning(message: string) {
    this.isPopupVisible = true;

    this.messageService.add({
      key: 'confirm',
      sticky: true,
      severity: 'warn',
      summary: 'Validation Error',
      detail: message
    });
    this.message=message
  }

  clearWarnings() {
    this.messageService.clear();
    this.isPopupVisible = false;
  }
}
