<p-toast></p-toast>
<p-toast position="top-left" key="tl"></p-toast>
<p-toast position="top-center" key="tc"></p-toast>
<p-toast position="bottom-center" key="bc"></p-toast>

<p-toast position="bottom-center" key="c" [baseZIndex]="5000">
    <ng-template let-message pTemplate="message">
        <div class="p-flex p-flex-column" style="flex: 1">
            <div class="p-text-center">
                <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
                <h4>{{ message.summary }}</h4>
                <p>{{ message.detail }}</p>
            </div>
            <div class="p-grid p-fluid">
                <div class="p-col-6">
                    <button type="button" pButton label="Yes" class="p-button-success"></button>
                </div>
                <div class="p-col-6">
                    <button type="button" pButton label="No" class="p-button-secondary"></button>
                </div>
            </div>
        </div>
    </ng-template>
</p-toast>

 
<div class="flex justify-content-between bg-blue-300 w-full pt-7 pt-5">
    <div class="flex text-dark justify-content-left pt-3 pb-2 p-3">
        <h4 title="TrackShipment"><b>Shipping Manifest</b></h4>
    </div>
    <div class="p-d-flex p-jc-end pt-3 pb-2 p-3">
        <p-button label="Manifest & Print" icon="pi pi-caret-down" styleClass="shadow-none p-button-sm bg-green-800 text-white" (click)="gm.toggle($event)"></p-button>
    </div>
</div>
<p-overlayPanel #gm [dismissable]="true" styleClass="mt-4" [style]="{width: '50%'}">
    <div class="grid mt-3">
        <div class="col-12 md:col-6">
            <div class="p-field">
                <label for="location">Location Name <span class="p-error">*</span></label>
                <p-dropdown [options]="locationOptions" optionLabel="LocationName" optionValue="LocationId"
                filter="true" showClear="true"
                [(ngModel)]="selectedLocation" placeholder="Select Location">
                </p-dropdown>
            </div>
        </div>
        <div class="col-12 md:col-3">
            <div class="p-field">
                <label for="carrier">Carrier</label>
                <p-dropdown [options]="carrierOptions" [(ngModel)]="selectedCarrier"
                 placeholder="Select Carrier" filter="true" showClear="true"
                 optionLabel="CarrierName" optionValue="id">
                </p-dropdown>
            </div>
        </div>
        <div class="col-12 md:col-3">
            <div class="p-field">
                <label for="account">Account #</label>
                <p-dropdown [options]="accounts" [(ngModel)]="selectedAccount" placeholder="Select Account"></p-dropdown>
            </div>
        </div>
    </div>
    <div class="grid">
        <div class="col-12 md:col-3">
            <div class="p-field">
                <label for="fromDate">Ship From Date</label>
                <p-calendar styleClass="w-16rem" inputId="from" [showIcon]="true" [iconDisplay]="'input'" [(ngModel)]="filters['shipDateFrom']" dateFormat="yy-mm-dd" placeholder="Select Date"></p-calendar>
            </div>
        </div>
        <div class="col-12 md:col-3">
            <div class="p-field">
                <label for="fromDate">Ship From Date</label>
                <p-calendar styleClass="w-16rem" inputId="to" [showIcon]="true" [iconDisplay]="'input'" [(ngModel)]="filters['shipDateTo']" dateFormat="yy-mm-dd" placeholder="Select Date"></p-calendar>
            </div>
        </div>
        <div class="col-12 md:col-3">
            <div class="p-field">
                <div class="p-field">
                    <label for="shipFromTime">Ship Time(HH:MM)</label>
                    <p-calendar inputId="shipTimeFrom" [showIcon]="true" [iconDisplay]="'input'" [(ngModel)]="filters['shipTimeFrom']" [timeOnly]="true" placeholder="From Time">
                        <ng-template pTemplate="inputicon" let-clickCallBack="clickCallBack">
                            <i class="pi pi-clock pointer-events-auto"
                                (click)="clickCallBack($event)">
                            </i>
                        </ng-template>
                    </p-calendar>
                </div>
            </div>
        </div>
        <div class="col-12 md:col-3">
            <div class="p-field">
                <label hidden for="shipTimeTo">Ship Time(HH:MM)</label>
                <p-calendar styleClass="p-calendar-timeonly	mt-4" inputId="shipTimeTo" [showIcon]="true" [iconDisplay]="'input'" [(ngModel)]="filters['shipTimeTo']" [timeOnly]="true" placeholder="To Time">
                    <ng-template pTemplate="inputicon" let-clickCallBack="clickCallBack">
                        <i class="pi pi-clock pointer-events-auto"
                            (click)="clickCallBack($event)">
                        </i>
                    </ng-template>
                </p-calendar>
            </div>
        </div>
    </div>
    <p-divider />
    <div class="grid p-fluid justify-content-between">
        <div class="flex justify-content-left">
            <div class="p-1">
                <div class="p-d-flex p-ai-center">
                    <p-button styleClass="bg-gray-50 shadow-1 text-primary" label="Cancel" (click)="generateManiCancel()"></p-button>
                </div>
            </div>
        </div>
        <div class="p-2">
            <div class="p-d-flex p-ai-center">
                <p-button styleClass="bg-green-600 text-white" label="Generate Manifest" (click)="generateManifest()"></p-button>
            </div>
        </div>
    </div>
</p-overlayPanel>

<div class="mb-3 mt-3 w-full">
    <div class="p-grid p-align-center p-justify-between xcarr-filters__wrapper">
        <!-- Dashboard Filters Section -->
        <div class="xcarr-dashbrd-filters p-d-flex p-jc-start p-ai-center ms-3" id="thumbdiv" style="overflow-x: auto;">
            <div *ngFor="let dummy of ' '.repeat(3).split(''), let x = index">
                <div *ngIf="x==0" class="flex justify-content-between grid col-12 ps-2 mt-1">
                    <ng-container *ngFor="let status of thumbnailStatusOptions">
                        <div class="col ps-0 border-round-sm">
                            <div class="w-6rem">
                                <span class="text-left font-bold text-base">{{status.id}}</span>
                            </div>
                        </div>
                    </ng-container>
                </div>
                <div *ngIf="x==1" class="flex justify-content-between grid col-12 ps-2 pt-0">
                    <ng-container *ngFor="let status of thumbnailStatusOptions">
                        <div class="col pt-0 ps-0 border-round-sm">
                            <div class="w-10rem">
                                <span class="text-left font-bold">{{status.description}}</span>
                            </div>
                        </div>
                    </ng-container>
                </div>
                <div *ngIf="x==2" class="flex justify-content-between grid col-12 ps-2 mt-1">
                    <ng-container *ngFor="let status of thumbnailStatusOptions">
                            <!-- <span>{{status.description}}</span> -->
                        <div class="col pr-2 mr-1 border-round-sm" (click)="changeStatus(status.id)" [ngStyle]="{'background-color': status.colorpicker, 'cursor': 'pointer'}">
                            <div class="w-4rem p-1">
                                <!-- <span (click)="changeStatus(status.id)" [ngStyle]="{'background-color': status.colorpicker}">c</span> -->
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>

<div  *ngIf="isDataLoaded">
    
    <!-- MANIEFST TABLE-->
    <p-table #dt [value]="shipRequests" [(selection)]="selectedShipRequest" [paginator]="true" [rows]="10"
        [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,15,20,25,50]"  [resizableColumns]="true"  [rowHover]="true"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" dataKey="id" [scrollable]="true"
        responsiveLayout="stack" scrollHeight="15rem"
        styleClass="p-datatable-sm p-datatable-gridlines p-datatable-striped  mr-3 ml-3"
        [globalFilterFields]="xcolumns" [tableStyle]="{ 'min-width': '50rem'}">
        <ng-template pTemplate="caption">
            <div class="flex align-items-center justify-content-between">
                <p-button icon="pi pi-filter-fill" (click)="op.toggle($event)"></p-button>
                <p-button icon="fa fa-lg fa-cog" (click)="openTableProperties()"></p-button>
            </div>
        </ng-template>
        <ng-template pTemplate="header">
            <tr>
                <th style="width: 3rem">
                    <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                </th>
                <th hidden>Id</th>
                <ng-container *ngFor="let xf of xfields; let i = index">
                    <ng-template [ngIf]="xf.visible==true">
                        <ng-container *ngFor="let field of xf.fields; let j = index">
                            <ng-template [ngIf]="field['TableVisible'] === true">
                                <th pResizableColumn>
                                    {{ xf['IncludeHeader'] + ' ' + field.title }}
                                </th>
                            </ng-template>
                        </ng-container>
                    </ng-template>
                </ng-container>
                <th></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-shipRequest let-expanded="expanded" let-columns="xfields">
            <tr>
                <td>
                    <p-tableCheckbox [value]="shipRequest"></p-tableCheckbox>
                </td>
           
                <td hidden>{{ shipRequest.id }}</td>
                <ng-template [ngIf]="xfields[0].visible==true">
                    <ng-container *ngFor="let field of xfields[0].fields; let j = index">
                        <ng-template [ngIf]="field['TableVisible'] === true">
                            <td> {{ shipRequest.HeaderInfo[field.fieldName] }}</td>
                        </ng-template>
                    </ng-container>
                </ng-template>
                <ng-template [ngIf]="xfields[1].visible==true">
                    <ng-container *ngFor="let field of xfields[1].fields; let j = index">
                        <ng-template [ngIf]="field['TableVisible'] === true">
                            <td> {{ shipRequest.Shipper[field.fieldName] }}</td>
                        </ng-template>
                    </ng-container>

                </ng-template>

                <ng-template [ngIf]="xfields[2].visible==true">

                    <ng-container *ngFor="let field of xfields[2].fields; let j = index">
                        <ng-template [ngIf]="field['TableVisible'] === true">
                            <td> {{ shipRequest.ShipTo[field.fieldName] }}</td>
                        </ng-template>
                    </ng-container>
                </ng-template>
                <ng-template [ngIf]="xfields[3].visible==true">

                    <ng-container *ngFor="let field of xfields[3].fields; let j = index">
                        <ng-template [ngIf]="field['TableVisible'] === true">
                            <td> {{ shipRequest.ShipFrom[field.fieldName] }}</td>
                        </ng-template>
                    </ng-container>
                </ng-template>
                <ng-template [ngIf]="xfields[4].visible==true">
                    <ng-container *ngFor="let field of xfields[4].fields; let j = index">
                        <ng-template [ngIf]="field['TableVisible'] === true">
                            <td> {{ shipRequest.SoldTo[field.fieldName] }}</td>
                        </ng-template>
                    </ng-container>
                </ng-template>
                <ng-template [ngIf]="xfields[5].visible==true">
                    <ng-container *ngFor="let field of xfields[5].fields; let j = index">
                        <ng-template [ngIf]="field['TableVisible'] === true">
                            <td> {{ shipRequest.CarrierDetails[field.fieldName] }}</td>
                        </ng-template>
                    </ng-container>
                </ng-template>
                <td>
                    <p-splitButton class="bg-none"
                        label="Edit"
                        (onClick)="save('info')"
                        styleClass="p-button-text" appendTo="body"
                        [model]="items" text></p-splitButton>
                </td>
            </tr>
        </ng-template>
    </p-table>
</div>
 <!-- TABLE PROPERTIES TABLE-->
    <!-- TABLE PROPERTIES  DIALOG START-->
    <p-dialog [(visible)]="tableProperties_dialog" [style]="{ width: '70%' }" [modal]="true" styleClass="p-fluid">
        <p-header>{{ " Properties"}}</p-header>
        <div class="card" [style]="{
            padding: '0px',
            height: '70vh',
            width: '99%',
            float: 'left',
            overflow: 'scroll'}">

            <p-table [value]="xfields" styleClass="p-datatable-sm p-datatable-gridlines p-datatable-striped "
                dataKey="fieldHeader" responsiveLayout="stack">
                <ng-template pTemplate="header">
                    <tr>
                        <th style="width: 5rem"></th>
                        <th>Visible</th>
                        <th>Coulm Header</th>
                        <th>Include Header Name</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-fielddata let-expanded="expanded">
                    <tr>
                        <td>
                            <button type="button" pButton pRipple [pRowToggler]="fielddata"
                                class="p-button-text p-button-rounded p-button-plain"
                                [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
                        </td>
                        <td>
                            <p-checkbox styleClass="mr-2" [(ngModel)]="fielddata.visible" [binary]="true"
                                [disabled]="false"></p-checkbox>{{
                            fielddata.visible }}
                        </td>
                        <td> {{ fielddata.fieldHeader }}</td>

                        <td> <span class="p-float-label">

                                <input pInputText [(ngModel)]="fielddata['IncludeHeader']" />
                                <!-- <input [type]="field.datatype" pInputText /> -->

                            </span></td>

                    </tr>
                </ng-template>

                <ng-template pTemplate="rowexpansion" let-fielddata>
                    <tr>

                        <td colspan="3">
                            <div class="p-3">

                                <p-table [value]="fielddata.fields" dataKey="title">
                                    <ng-template pTemplate="header">
                    <tr>
                        <th>Visible</th>
                        <th>Field</th>
                        <th>Filter</th>

                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-field>
                    <tr>
                        <td>
                            <p-checkbox [(ngModel)]="field['TableVisible']" [binary]="true"
                                [disabled]="false"></p-checkbox>{{
                            fielddata['TableVisible'] }}
                        </td>

                        <td>{{field.title}}</td>
                        <td>
                            <p-checkbox [(ngModel)]="field['filterField']" [binary]="true"
                                [disabled]="false">{{field['filterField']}}</p-checkbox>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
                </td>
            </tr>
            </ng-template>
        </p-table>
    </div>
    <button type="button" pButton pRipple label="Save Changes" icon="pi pi-check" class="p-button-sm"
        (click)="SaveTemplate('ManifestTable')">
    </button>
</p-dialog>

<p-overlayPanel #op [showCloseIcon]="true" [dismissable]="true" styleClass="mt-4" [style]="{width: '80%'}">
    <div class="panel-heading">
        <h3 class="flex justify-content-center panel-title">Filters</h3>
    </div>
    <div class="grid p-fluid">
        <div class="col-12 md:col-3">
            <div class="p-field">
                <label for="location">Location Name <span class="p-error">*</span></label>
                <p-dropdown id="ddlLocation" [options]="locationOptions" 
                [(ngModel)]="selectedLocation" [filter]="true" [showClear]="true" 
                placeholder="Select Location" optionLabel="LocationName" optionValue="LocationId">
            </p-dropdown>
            </div>
        </div>
        <div class="col-12 md:col-3">
            <div class="p-field">
                <label for="carrier">Carrier</label>
                <p-dropdown id="ddlCarrier" [options]="carrierOptions" 
                    [(ngModel)]="selectedCarrier" [filter]="true" [showClear]="true" 
                    placeholder="Select Carrier" optionLabel="CarrierName" optionValue="id">
                </p-dropdown>
            </div>
        </div>
        <div class="col-12 md:col-3">
            <div class="p-field">
                <label for="country">Country</label>
                <p-dropdown id="ddlCountry" [options]="countryOptions" [(ngModel)]="selectedCountry"
                    optionLabel="name" placeholder="Select Country" optionValue="id"
                    [filter]="true" [showClear]="true"
                >
                </p-dropdown>
            </div>
        </div>
        <div class="col-12 md:col-3">
            <label for="shipToCompany">Ship To Company</label>
            <span class="p-input-icon-right">
                <i class="pi pi-search" (click)="ShipToCompanyPopup()"></i>
                <input type="text" id="shipToCompany" (click)="ShipToCompanyPopup()" pInputText [(ngModel)]="Track" placeholder="Search Ship To Company">       
            </span>
        </div>
    </div>
    <div class="grid p-fluid">
        <div class="col-12 md:col-3">
            <div class="flex justify-content-between">
                <div class="p-field w-10rem mr-1">
                    <label for="fromDate">Ship From Date</label>
                    <p-calendar inputId="from" [showIcon]="true" [iconDisplay]="'input'" [(ngModel)]="filters['shipDateFrom']" dateFormat="yy-mm-dd" placeholder="Select Date"></p-calendar>
                </div>
                <div class="p-field w-10rem">
                    <label for="fromDate">Ship From Date</label>
                    <p-calendar inputId="to" [showIcon]="true" [iconDisplay]="'input'" [(ngModel)]="filters['shipDateTo']" dateFormat="yy-mm-dd" placeholder="Select Date"></p-calendar>
                </div>
            </div>
        </div>
        <div class="col-12 md:col-3">
            <div class="flex justify-content-between">
                <div class="p-field w-10rem mr-1">
                    <label for="shipFromTime">Ship Time(HH:MM)</label>
                    <p-calendar inputId="shipTimeFrom" [showIcon]="true" [iconDisplay]="'input'" [(ngModel)]="filters['shipTimeFrom']" [timeOnly]="true" placeholder="From Time">
                        <ng-template pTemplate="inputicon" let-clickCallBack="clickCallBack">
                            <i class="pi pi-clock pointer-events-auto"
                                (click)="clickCallBack($event)">
                            </i>
                        </ng-template>
                    </p-calendar>
                </div>
                <div class="p-field w-10rem">
                    <label hidden for="shipTimeTo">Ship Time(HH:MM)</label>
                    <p-calendar styleClass="p-calendar-timeonly	mt-4" inputId="shipTimeTo" [showIcon]="true" [iconDisplay]="'input'" [(ngModel)]="filters['shipTimeTo']" [timeOnly]="true" placeholder="To Time">
                        <ng-template pTemplate="inputicon" let-clickCallBack="clickCallBack">
                            <i class="pi pi-clock pointer-events-auto"
                                (click)="clickCallBack($event)">
                            </i>
                        </ng-template>
                    </p-calendar>
                </div>
            </div>
        </div>
        <div class="col-12 md:col-3">
            <label for="shipperName">Shipper Name</label>
            <span class="p-input-icon-right">
                <i class="pi pi-search" (click)="ShipperNamePopup()"></i>
                <input type="text" id="shipperName" (click)="ShipperNamePopup()" pInputText [(ngModel)]="Track" placeholder="Search Shipper Name">       
            </span>
        </div>
        <div class="col-12 md:col-3">
            <div class="p-field">
                <label for="paymentType">Payment Type</label>
                <p-dropdown id="ddlPaymentType" [options]="paymentTypeOptions" [(ngModel)]="Track" 
                    optionLabel="label" optionValue="value" placeholder="Select Payment Type"
                    [filter]="true" [showClear]="true">
                </p-dropdown>
            </div>
        </div>
    </div>
    <div class="grid p-fluid">
        <div class="col-12 md:col-3">
            <div class="p-field">
                <label for="shipRequest">Ship Request #</label>
                <input id="shipRequest" type="text" pInputText [(ngModel)]="Track" maxlength="30" />
            </div>
        </div>
        <div class="col-12 md:col-3">
            <div class="p-field">
                <label for="tracking">Tracking #</label>
                <input id="tracking" type="text" pInputText [(ngModel)]="Track" maxlength="30" />
            </div>
        </div>
        <div class="col-12 md:col-3">
            <div class="p-field">
                <label for="poNumber">PO Number</label>
                <input id="poNumber" type="text" pInputText [(ngModel)]="Track" maxlength="30" />
            </div>
        </div>
        <div class="col-12 md:col-3">
            <label for="manifestId">Manifest ID</label>
            <span class="p-input-icon-right">
                <i class="pi pi-search" (click)="manifestIdPopup()"></i>
                <input type="text" id="manifestId" (click)="manifestIdPopup()" pInputText [(ngModel)]="Track" placeholder="Search Manifest ID">       
            </span>
        </div>
    </div>
    <div class="grid p-fluid">
        <div class="col-12 md:col-3">
            <label for="costCenter">Cost Center</label>
            <span class="p-input-icon-right">
                <i class="pi pi-search" (click)="CostCenterPopup()"></i>
                <input type="text" id="costCenter" (click)="CostCenterPopup()" pInputText [(ngModel)]="Track" placeholder="Cost Center">         
            </span>
        </div>
        <div class="col-12 md:col-3">
            <div class="p-field">
                <label for="status">Status</label>
                <p-dropdown id="ddlStatus" [options]="statusOptions" 
                    [(ngModel)]="selectedStatus" optionLabel="description"
                    optionValue="id" [filter]="true" [showClear]="true"
                    placeholder="Select Status">
                </p-dropdown>
            </div>
        </div>
        <div class="col-12 md:col-3">
            <label for="user">User</label>
            <span class="p-input-icon-right">
                <i class="pi pi-search" (click)="UserPopup()"></i>
                <input type="text" id="user" (click)="UserPopup()" pInputText [(ngModel)]="Track" placeholder="Search User">         
            </span>
        </div>
        <div class="col-12 md:col-3">
            <div class="p-field">
                <label for="manifestFlag">Manifest Flag</label>
                <p-dropdown id="manifestFlag" [options]="manifestFlagOptions" 
                    [(ngModel)]="selectedStatus" optionLabel="description"
                    optionValue="id" [filter]="true" [showClear]="true"
                    placeholder="Select">
                </p-dropdown>
            </div>
        </div>
    </div>
    <p-divider />
    <div class="grid p-fluid justify-content-between">
        <div class="flex justify-content-left">
            <div class="p-1">
                <div class="p-d-flex p-ai-center">
                    <p-button styleClass="bg-gray-50 shadow-1 text-primary" label="Cancel" (click)="filterCancel()"></p-button>
                </div>
            </div>
            <div class="p-1">
                <div class="p-d-flex p-ai-center">
                    <p-button styleClass="bg-gray-50 shadow-1 text-primary" label="Clear" (click)="filterClear()"></p-button>
                </div>
            </div>
        </div>
        <div class="p-2">
            <div class="p-d-flex p-ai-center">
                <p-button styleClass="bg-green-600 text-white" label="Apply Filter" (click)="applyFilters()"></p-button>
            </div>
        </div>
    </div>
</p-overlayPanel>