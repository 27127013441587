import { Component, OnInit, AfterViewInit, ViewChild, ɵsetAllowDuplicateNgModuleIdsForTest, ElementRef } from '@angular/core';
import { Table } from 'primeng/table'
import { HttpClient, withJsonpSupport } from '@angular/common/http';
import { ERPSHIPService } from '../erpship.service';
import { MessageService } from 'primeng/api';
import { Observable, forkJoin } from 'rxjs';
import { PrimeNGConfig } from 'primeng/api';
import { CodeModel } from '../code-model';
import { ActivatedRoute, Router } from '@angular/router';
import { JsonPipe, Location } from '@angular/common'
import {
  FormGroup,
  FormControl,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { ThisReceiver } from '@angular/compiler';


import { filter, take } from 'rxjs/operators';
import {
  MonacoEditorComponent,
  MonacoEditorConstructionOptions,
  MonacoEditorLoaderService,
  MonacoStandaloneCodeEditor
} from '@materia-ui/ngx-monaco-editor';



@Component({
  selector: 'app-packship',
  templateUrl: './packship.component.html',
  styleUrls: ['./packship.component.scss'], providers: [MessageService]
})
export class PackshipComponent implements OnInit, AfterViewInit {
  @ViewChild('dt1') dt: any;
  @ViewChild('dt2') addrdt: any;
  @ViewChild('fsdt') freightdt: any;
  @ViewChild('loc') loc: any;
  @ViewChild('requiredData') requiredData: any;
  @ViewChild('manifestdt') manifestdata: any;
  @ViewChild('deliveryno') deliveryno: any;
  @ViewChild('inputBatch') batchelm: any;
  @ViewChild('inputProduct') productelm: any;
  @ViewChild('inputBox') boxelm: any;


  @ViewChild(MonacoEditorComponent, { static: false })
  autoPack = false;
  autoShip = false
  currentSelectedLocationid: any = {};

  packagingMaterial = "EWMS4_CB";

  packagingMaterialList = [
    {
      "PackagingMaterial": "EWM_1710_PL",
      "PackagingMaterialDescription": "EWM_1710_CB - PALLET PACKAGING"
    }
  ];

  testmsg = 'testmessage here'
  isdis: boolean = false;

  showItemTable: boolean = true;

  chatFlag_dialog: boolean = false;

  //Repeate Pack Re;eated

  totalPossibleBoxes = 0;
  repeatePack_dialog: boolean = false;
  requestedNumberOfHUs = 0;


  //loadings
  repeatButton_loading: boolean = false;
  deleteHuButton_Loading: boolean = false;

  //filters
  history_filters: any = {};
  history_filter_xfields: any[] = [];


  //loading flags
  shiploading: boolean = false;
  rateloading: boolean = false;
  addressvalidationload: boolean = false;

  shiphistoryloading: boolean = false;
  openorderloading: boolean = false;
  validateAddressloading: boolean = false


  //shipmentLevelSpecialServices
  shipmentLevelSpecialServices: any = {};
  packageLevelSpecialServices: any = {};


  addressResult: any = {
    'Address': {},
    'CarrierDetails': {},
    'VerifiedAddress': {}
  }

  //TESTING PURPOSE & DELETE LATAR
  isSSLoded: boolean = true;
  ssdetails: any = {
    "holdAtLocationDetail": {
      "locationId": "",
      "locationContactAndAddress": {
        "address": {
          "streetLines": [
            "",
            ""
          ],
          "city": "",
          "stateOrProvinceCode": "",
          "postalCode": "",
          "countryCode": "",
          "residential": false
        },
        "contact": {
          "personName": "",
          "emailAddress": "",
          "phoneNumber": "",
          "phoneExtension": "",
          "companyName": "",
          "faxNumber": ""
        }
      },
      "locationType": ""
    }
  };


  //------------------------- TESTING PURPORSE END

  // for Event Editor
  theme = 'vs-dark';


  //packing releated
  currentOperationalHU: any = '';
  currentHU: any = ' ';
  currentProduct: any = ' ';
  currentProductBach: any = ' '
  currentselectedssItemUID: any = '';
  currentProductQuantity = 1;
  selectedHU: any = {};

  selectedHUList: any = [];
  repeatePack_flag: boolean = false;
  autoHu: boolean = false;
  noOfBoxes = 1;



  eventsDetails: any = {};

  //dialog flags
  HistorytableProperties_dialog: boolean = false;
  packageSpecialService_Dialog: boolean = false;
  newField_Dialog: boolean = false;
  newGridColumns_Dialog: boolean = false;
  event_Dialog: boolean = false;
  history_Dialog: boolean = false;
  LabelPreview_Dialog: boolean = false;
  AddressValidation_Dialog: boolean = false;
  currentEventType: any; // Describes Before_event or After_Event

  currentlabeldata: any = '';


  // new Grid Column property
  newColumn: any = {};

  //newField releated
  newField: any = null;

  //history orders

  historyOrders: any[] = [];
  Org_historyOrders: any[] = [];
  selectedHistoryOrder: any;

  //for History
  history_xfields: any[] = [];
  history_xcolumns: any[] = [];
  global_history_xcolumns: any[] = [];

  //  for Location 
  xformMetaData: any;
  xfields: any[] = [];
  xcolumns: any[] = [];

  allForms: any[] = [];

  //
  parameterId = -1;
  saveButtontitle = 'Save';

  //Default values - ENUM
  //------------------------------------
  fieldUITypes = [
    { name: 'Textbox', code: 'Textbox' },
    { name: 'Checkbox', code: 'Checkbox' },
    { name: 'Dropdown', code: 'Dropdown' },
    { name: 'label', code: 'label' },
    { name: 'Button', code: 'Button' }

  ];

  // weightUnits=[{name : 'LBS', code :'LBS'},{name : 'KGS', code :'KGS'}]
  // dimensionUnits=[{name : 'IN', code :'IN'},{name : 'CM', code :'CM'}]

  fieldDataTypes = [
    { name: 'text', code: 'text' },
    { name: 'number', code: 'number' },
  ];

  iconPositions = [
    { name: 'left', code: 'left' },
    { name: 'right', code: 'right' },
  ];

  iconList = [];
  //-----------------------------------
  dataSourceList: any[] = [];
  currentSelectedTableData = [];
  currentSelectedTable: any = '';

  currentSelectedBlock: any = [];
  currentSelected_Block_Field_Name: any;
  currentSelected_Block_Name: any;
  propertyFieldSelected: boolean = false;
  currentSelctedField: any = {}
  selectedHULineItems: any = [];


  ShipScreen: any;
  //empty model

  clearShip: any = {};
  shipment: any = {};

  //super master data
  Original_LocationMasterData: any;
  LocationMasterData: any;


  //MasterData
  Master_products: any[] = [];
  Master_feederSystem: any[] = [];
  Master_orderTypes: any[] = [];
  Master_countries: any[] = [];
  Master_states: any[] = [];
  Master_cities: any[] = [];
  Master_dimensions: any[] = [];
  Master_addressBook: any[] = [];

  //grids & popups

  //Itemcols: any[] = [];

  productsgrid: any[] = [];
  productscol: any[] = [];

  //packcols: any[] = [];

  addressbookfilters: any[] = [];
  addressbookcols: any[] = [];
  addressbookgrid: any[] = [];

  // dialog flags
  AddressBook_dialog: boolean = false;
  FreightShop_dialog: boolean = false;
  MasterProduct_dialog: boolean = false;
  hudetails_dialog: boolean = false;
  property_dialog: boolean = false;
  tableProperties_dialog: boolean = false;
  test_dialog: boolean = false;
  fieldsReorder_dialog: boolean = false;

  loading_dialog: boolean = false;

  consolidationData: any = [];
  consolidationDocNumber: any = '';


  FreightShopResult: any[] = [];
  currentAddressBlock: any;
  selectedHu: any;
  selectedHUItems: any[] = [];
  isDataLoaded: boolean = false;
  package: any = {};
  packShipDef: any = {};
  tablealignment: any = 'Side By Side'
  hideitemgrid: boolean = false;
  packingOperationOnly: boolean = false;
  internalHUOnly: boolean = false;
  itemgridwidth: any = '12';
  itemtableheight: any = '33vh';  // 40vh for top-bottom
  sidePanel_visible: boolean = false;
  // packShipDef={"hideAddressBlock":true,"hideCarrierBlock":true,"defaultWorkCentre":"","defaultStorageBin":"","defaultwarehouse":""}


  //MONACO EDITOR RELEATED
  editorOptions: MonacoEditorConstructionOptions = {
    theme: 'myCustomTheme',
    language: 'typescript',
    roundedSelection: true,
    autoIndent: 'full'
  };

  code: any = this.getCode();
  shippingDocuments = [{ "DocumentProvider": "", "DocumentType": "", "Description": "", "Action": "" }]
  shippingCharges = [{ "SNo": "", "ChargeDescription": "", "Charge": "", "Currency": "" }]


  //scannedItemRequestList

  scannedItemRequestList: any = [];

  backGroundProcessScannedItems() {


    const currentRequests = [];

    console.log('posting: ' + (new Date()).toLocaleString())
    var xscannedRequest = Object.assign({}, this.scannedItemRequestList[0]);


    console.log('xscannedRequest', xscannedRequest)

    this.scannedItemRequestList.shift();
    try {
      this.isReady = false;
      this.xfservice.processPack(xscannedRequest).subscribe(data => {
        this.isReady = true;
        this.updateHUItems(xscannedRequest['HU']);
        //this.assignItemToHU(xscannedRequest['HU'], xscannedRequest['StockItemUUID'], xscannedRequest['Quantity'])
        if (this.scannedItemRequestList.length < 1) {
          this.sidePanel_visible = false;
        }
      });

    } catch (ex) { this.isReady = true; }




  }

  procesChat() {
    this.chatFlag_dialog = true;
  }


  updateHUItems(HU: any) {
    this.packShipDef['HU'] = HU;
    this.xfservice.getHUItems(this.packShipDef).subscribe((data: any) => {
      const cpackage = this.shipment.Packages.filter((pack: any) => pack.HU.trim() == HU.trim());
      cpackage[0].ItemsInfo = data;

      console.log(cpackage[0])
    })
  }
  assignItemToHU(HU: any, stockid: any, quantity: number) {
    const cpackage = this.shipment.Packages.filter((pack: any) => pack.HU.trim() == HU.trim());
    const citem = this.shipment.Items.filter((item: any) => item.StockItemUUID == stockid);
    if (citem.length > 0) {

      console.log('package items are: ', cpackage[0].ItemsInfo)
      const packageItems = cpackage[0].ItemsInfo.filter((item: any) => item.StockItemUUID == citem[0].StockItemUUID);
      if (packageItems.length > 0) {
        packageItems[0].Partial = packageItems[0].Partial + quantity;
      }
      else {
        citem[0].Partial = quantity;
        cpackage[0].ItemsInfo.push(citem)
      }
    }

    console.log('Package Details :', cpackage[0])

  }
  getConsolidateData(event: any, element: any) {
    this.loading_dialog = true;
    this.packShipDef['DocumntNo'] = this.consolidationData[0];
    this.packShipDef['ConsolidationData'] = this.consolidationData;


    var docno = this.shipment.HeaderInfo.DocumentNumber
    this.xfservice.getERPConsolidatedDocumentsRequest(this.packShipDef).subscribe(data => {



      this.shipment = data;
      this.shipment.HeaderInfo.ShipDate = new Date(this.shipment.HeaderInfo.ShipDate);
      this.shipment.HeaderInfo['ERP'] = "SAP"
      this.shipment.HeaderInfo['DocumentType'] = "Delivery"
      this.loading_dialog = false;
    });


    element.hide(event);
  }

  processConsolidationDocEnter() {
    this.consolidationData.push(this.consolidationDocNumber);
    this.consolidationDocNumber = ''
  }


  deleteDocFromConsolidation(condelno: any) {
    this.consolidationData = this.consolidationData.filter((element: any) => element !== condelno);
  }

  ngAfterViewInit() {

    this.tablealignment = localStorage.getItem('tablealign')?.toString();





    const hadr = localStorage.getItem('hideAddressBlock');
    if (hadr !== null) {
      this.packingOperationOnly = JSON.parse(hadr);
      //this.packShipDef['hideAddressBlock'] = JSON.parse(hadr);
    }
    else {
      this.packingOperationOnly = false;
      //this.packShipDef['hideAddressBlock'] = false;
    }



    const hitemgrid = localStorage.getItem('hideitemgrid');
    if (hitemgrid !== null)
      this.hideitemgrid = JSON.parse(hitemgrid);
    else
      this.hideitemgrid = false;

    // alert(this.packingOperationOnly + '       ' + this.hideitemgrid)

    if (this.tablealignment.startsWith('Side')) {




      if (this.packingOperationOnly) {
        this.itemgridwidth = 6;
        this.itemtableheight = '70vh'
      } else {
        this.itemgridwidth = 6;
        this.itemtableheight = '30vh'
      }
    } else {
      this.itemgridwidth = 12;
      this.itemtableheight = '33vh';
    }

    if (this.hideitemgrid) {
      this.itemgridwidth = 12;
      //this.itemtableheight = '70vh';
    }

    // alert(this.packingOperationOnly + '       ' + this.hideitemgrid)

    if (this.packingOperationOnly && this.hideitemgrid) {
      this.itemtableheight = '70vh'
    }

  }

  savesettings(event: any, element: any) {

    element.hide(event);
    localStorage.setItem('tablealign', this.tablealignment);
    localStorage.setItem('hideitemgrid', this.hideitemgrid.toString());
    localStorage.setItem('hideAddressBlock', this.packingOperationOnly.toString());
    if (this.tablealignment.startsWith('Side')) {
      if (this.packingOperationOnly) {
        this.itemgridwidth = 6;
        this.itemtableheight = '70vh'
      } else {
        this.itemgridwidth = 6;
        this.itemtableheight = '30vh'
      }

    } else {
      this.itemgridwidth = 12;
      this.itemtableheight = '33vh'
    }
    if (this.hideitemgrid) {
      this.itemgridwidth = 12;
      //this.itemtableheight = '70vh';
    }

    if (this.packingOperationOnly && this.hideitemgrid) {
      this.itemtableheight = '70vh'
    }
  }


  processPackingOnlyOnchange() {

    //this.packShipDef['hideAddressBlock'] = this.packingOperationOnly;
    localStorage.setItem('tablealign', this.tablealignment);
    if (this.tablealignment.startsWith('Side')) {
      if (this.packingOperationOnly) {
        this.itemgridwidth = 6;
        this.itemtableheight = '70vh'
      } else {
        this.itemgridwidth = 6;
        this.itemtableheight = '30vh'
      }

    } else {
      this.itemgridwidth = 12;
      this.itemtableheight = '33vh'
    }

    if (this.hideitemgrid) {
      this.itemgridwidth = 12;
      // this.itemtableheight = '70vh';
    }


    if (this.packingOperationOnly && this.hideitemgrid) {
      this.itemtableheight = '70vh'
    }

  }

  constructor(
    private router: Router,
    private http: HttpClient,
    private xfservice: ERPSHIPService, public messageService: MessageService,
    private primengConfig: PrimeNGConfig, private route: ActivatedRoute, private location: Location,
    private monacoLoaderService: MonacoEditorLoaderService,

  ) {

    //this.isdis= true;

    this.xfservice.processAuth().subscribe((data) => { }, error => {
      this.router.navigate(['']);
    });
    //MONACO EDITOR SETTINGS
    this.monacoLoaderService.isMonacoLoaded$
      .pipe(
        filter(isLoaded => !!isLoaded),
        take(1)
      )
      .subscribe(() => {

        this.registerMonacoCustomTheme();
      });

    //console.log("Rcalling Local Master Data")

    this.xfservice.getLocationMasterData().subscribe((data) => {



      this.xfservice.getPackShipDefaults().subscribe((defaultdata: any) => {

        this.packShipDef = defaultdata;

        this.xfservice.getPackagingMaterial(this.packShipDef).subscribe((packagingmaterials: any) => {

          this.packagingMaterialList = packagingmaterials
        })
      })


      data['paymentTypes'] = this.paymentTypes;
      this.LocationMasterData = data;
      // console.log("Received Local Master Data & Location id is : " + this.currentSelectedLocationid)
      this.Original_LocationMasterData = JSON.parse(JSON.stringify(data));
      let rowdata = this.Original_LocationMasterData.location.filter((item: any) => item.LocationId == data['xLocationId'])
      this.currentSelectedLocationid = rowdata[0]
      // alert( JSON.stringify(this.currentSelectedLocationid ));
      // alert(data['xLocationId'])
      //console.log("Initial Location is : " + JSON.stringify(this.currentSelectedLocationid) + '   ' + this.currentSelectedLocationid.weightunit)
      Object.keys(this.Original_LocationMasterData).forEach(keyName => {
        let keydata: any = {};
        keydata['name'] = keyName;
        this.dataSourceList.push(keydata)
      });


      this.xfields = this.Original_LocationMasterData.LocationTemplate[0].details.fields;
      this.xcolumns = [];
      this.xfields.forEach(item => {
        this.xcolumns.push(item.fieldName);
      });

      //for historytemplates

      this.history_xfields = this.LocationMasterData['HistoryTable'][0].details


      this.history_xfields.forEach((xitem) => {
        xitem.fields.forEach((field: any) => {
          this.history_xcolumns.push(xitem.fieldHeader + '.' + field.fieldName)
        });

      });
      //screen templates
      this.ShipScreen = this.LocationMasterData['ShipScreenTemplate'][0].details;
      this.package['ItemsInfo'] = [];
      this.package['SpecialServices'] = {};


      this.package['Weightunits'] = this.currentSelectedLocationid['weightunit']
      this.package['DimensionUnits'] = this.currentSelectedLocationid['dimensionunit']

      this.shipment = this.LocationMasterData['ShipModel'][0].details;

      this.shipment.HeaderInfo.ShipDate = new Date();
      // alert(      this.shipment.HeaderInfo.ShipDate )
      const dtoken: any = JSON.stringify(JSON.parse(window.atob(window.sessionStorage.getItem('X-Token')!.split('.')[1])))

      this.shipment.HeaderInfo['Location'] = JSON.parse(dtoken).LocationId

      //SAP DEFAULT

      this.shipment.HeaderInfo['ERP'] = "SAP"
      this.shipment.HeaderInfo['DocumentType'] = "Delivery"

      this.updateShipFrom();


      var cchip = JSON.stringify(this.shipment);


      this.global_history_xcolumns = this.getKeys(this.shipment)
      // console.log(this.global_history_xcolumns)


      this.clearShip = JSON.parse(cchip);
      this.currentSelctedField = this.LocationMasterData['FieldModel'][0].details;


      this.route.params.subscribe((params) => {
        this.parameterId = -2
        this.parameterId = params['id'];


        if (this.parameterId == -2 || this.parameterId == undefined) {
          this.isDataLoaded = true;

        }
        else if (this.parameterId.toString().startsWith('COPY')) {

          this.saveButtontitle = 'Save'
          var prm = this.parameterId.toString().split("-");
          this.xfservice.getxFormRecordsByFromName('ShipRequest/' + prm[1]).subscribe((data) => {
            data[0].details.id = -1;
            this.shipment = data[0].details;
            this.shipment.HeaderInfo.ShipDate = new Date();

            var cchip = JSON.stringify(this.shipment);
            this.clearShip = JSON.parse(cchip);
            this.isDataLoaded = true;

          });

          this.isDataLoaded = true;

        } else if (this.parameterId > -1) {
          this.saveButtontitle = 'Update'
          this.xfservice.getxFormRecordsByFromName('ShipRequest/' + this.parameterId).subscribe((data) => {
            this.shipment = data[0].details;
            // this.shipment.HeaderInfo.ShipDate = new Date();

            var cchip = JSON.stringify(this.shipment);
            this.clearShip = JSON.parse(cchip);

            this.processChange('CarrierDetails', 'Carrier', this.shipment.CarrierDetails['Carrier'], '', '')
            this.isDataLoaded = true;

          });



        }



        //preparing history filter field data - start
        //--------------------------------------------
        this.history_filter_xfields = this.LocationMasterData.HistoryTable[0].details;

        this.history_filter_xfields.forEach((item) => {
          // console.log(JSON.stringify(item))
          item.fields.forEach((field: any) => {
            // this.xcolumns.push(item.fieldHeader +'.' +field.fieldName)
            if (field['filterField'] == true && field['fieldName'] != "ShipDate") {
              this.history_filters[item.fieldHeader + '.' + field.fieldName] = '';
            }
            else if (field['fieldName'] == "ShipDate") {
              this.history_filters['shipDateFrom'] = '';
              this.history_filters['shipDateTo'] = '';
            }
          })
        })


        //-------------------------------------------------
        //End history filed preparation

        this.xfservice.getxAllForms().subscribe((data) => {
          this.allForms = data;
        });

      });
    });





  }



  //Delivery Enter key press

  onDeliveryEnterKey() {
    this.getExternalDelivery();


    this.boxelm.nativeElement.focus();
  }


  //get External Delivery

  getExternalDelivery() {

    this.loading_dialog = true;
    this.packShipDef['DocumntNo'] = this.shipment.HeaderInfo.DocumentNumber;


    var docno = this.shipment.HeaderInfo.DocumentNumber
    this.xfservice.getERPDocumentsRequest(this.packShipDef).subscribe(data => {



      this.shipment = data;
      // this.shipment.HeaderInfo.ShipDate = new Date(this.shipment.HeaderInfo.ShipDate);
      this.shipment.HeaderInfo.ShipDate = new Date();
      this.shipment.HeaderInfo['ERP'] = "SAP"
      this.shipment.HeaderInfo['DocumentType'] = "Delivery"

      this.shipment['ShipmentLevelServices'] = {};


      this.shipment.Packages.forEach((pack: any) => {

        pack['packageLevelSpecialServices'] = Object.assign({}, this.packageLevelSpecialServices);
      })

      this.updateShipFrom();
      this.loading_dialog = false;

    },
      error => {

        this.loading_dialog = false;
        // alert(JSON.stringify(error));

      })
  }


  openItemProcessSidePanel() {

    this.sidePanel_visible = true;
  }



  assignSepecialServices() {
    // alert(JSON.stringify(this.shipment))
    // console.log( this.LocationMasterData['globalcarrierspecialservice'])
  }
  changeLabel(title: any) {
    alert(JSON.stringify(this.ShipScreen.HeaderInfo.fields[0]))
  }

  saveDefaults(event: any, element: any) {
    this.xfservice.savePackShipDefaults(this.packShipDef).subscribe((data) => {

      element.hide(event);
      this.messageService.add({
        key: 'tc',
        severity: 'success',
        summary: 'Template',
        detail: 'Pack&Ship Defaultsare successfully saved.',
      });
    });

  }


  clear() {
    this.shipment = this.clearShip;
    this.shipment.HeaderInfo.ShipDate = new Date();
    this.isdis = false;
  }
  // //   getDataSourceList() {
  // //     var sourceList: any[] = [];
  // //     Object.keys(this.Original_LocationMasterData).forEach(keyName => {
  // //       let keydata = {};
  // //       keydata['name'] = keyName;
  // //       sourceList.push(keydata)
  // //     });
  // // console.log('Source List is : ' + JSON.stringify(sourceList))
  // //     return sourceList;
  // //   }


  // getSourceFieldsList() {
  //   if (this.currentSelctedField.source !== undefined) {
  //     var opNames: any[] = [];
  //     Object.keys(this.Original_LocationMasterData).forEach(keyName => {
  //       let keydata = {};
  //       keydata['name'] = keyName;
  //       opNames.push(keydata)
  //     });
  //     return opNames;
  //   } else {
  //     return [];
  //   }
  // }


  getKeys(obj: any): string[] {
    const keys: string[] = [];
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        keys.push(key);
        if (typeof obj[key] === 'object' && obj[key] !== null) {
          const nestedKeys = this.getKeys(obj[key]);
          keys.push(...nestedKeys.map(nestedKey => `${key}.${nestedKey}`));
        }
      }
    }
    return keys;
  }


  getOptioneNames() {
    if (this.currentSelctedField.source) {
      var opNames: any[] = [];
      //console.log(JSON.stringify(this.currentSelctedField.source))
      Object.keys(this.Original_LocationMasterData[this.currentSelctedField.source][0]).forEach(keyName => {
        //console.log(keyName)
        let keydata: any = {};
        keydata['name'] = keyName;
        opNames.push(keydata)
      });
      return opNames;
    } else {
      return [];
    }
  }


  getUserDetails(): any {
    let cookies = document.cookie;
    let cparts = cookies.split('=');
    var udetails = JSON.parse(cparts[1])
    return udetails.Role;
  }
  //deletePackage

  // deletePackage(prd: any): void {
  //   console.log('Package Info is : ' + JSON.stringify(prd));
  // }

  onEditComplete(event: any) {
    event.data.Partial = parseInt(event.data.Partial);
    if (event.data.Balance < event.data.Partial) {
      console.log('calling error');
      //this.showError('Invalid Partial Quantity.');
    }
  }


  // SHOW PACK HU ITEMS
  showHUDetails(hudetails: any): void {

    if (hudetails['IsInternal']) {
      this.selectedHu = hudetails;
      this.selectedHUItems = this.selectedHu.ItemsInfo;
      // console.log('Columns: ' + this.productscol)
      this.hudetails_dialog = true;
    } else {
      // console.log(hudetails)
      this.selectedHu = hudetails;
      this.packShipDef['HU'] = hudetails.HU;
      this.currentOperationalHU = hudetails.HU;
      this.xfservice.getHUItems(this.packShipDef).subscribe((data: any) => {
        this.selectedHUItems = data;
        this.hudetails_dialog = true;
      })
    }

  }


  //Delete Item by Quantity

  deleteItemByQuantity(itemrow: any): void {
    this.packShipDef['HU'] = this.currentOperationalHU;
    this.packShipDef['StockItemUUID'] = itemrow.StockItemUUID;
    this.packShipDef['PackagingMaterial'] = this.selectedHU['PackagingMaterial'];
    this.packShipDef['DocumntNo'] = this.shipment.HeaderInfo['DocumentNumber'];
    this.packShipDef['Quantity'] = itemrow['deleteQuantity'];
    this.packShipDef['AlternativeUnit'] = itemrow.UOM;
    this.packShipDef['DeleteQuantity'] = itemrow['deleteQuantity'];

//this.xfservice.deletePartialItemQtyFromHU(this.packShipDef).subscribe((data: any) => {
    this.xfservice.deleteTotalItemFromHU(this.packShipDef).subscribe((data: any) => {
      this.xfservice.getHUItems(this.packShipDef).subscribe((data: any) => {
        this.selectedHUItems = data;
        this.getExternalDelivery();
      })
    })
  }



  //delete Tota lItem From HU
  deleteTotalItemFromHU(itemrow: any): void {
    this.packShipDef['HU'] = this.currentOperationalHU;
    this.packShipDef['StockItemUUID'] = itemrow.StockItemUUID;
    this.packShipDef['PackagingMaterial'] = this.selectedHU['PackagingMaterial'];
    this.packShipDef['DocumntNo'] = this.shipment.HeaderInfo['DocumentNumber'];
    this.packShipDef['Quantity'] = this.currentProductQuantity;
    this.packShipDef['AlternativeUnit'] = itemrow.UOM;

    this.xfservice.deleteTotalItemFromHU(this.packShipDef).subscribe((data: any) => {
      this.xfservice.getHUItems(this.packShipDef).subscribe((data: any) => {
        this.selectedHUItems = data;
        this.getExternalDelivery();
      })
    })

  }








  masterData: any;
  paymentTypes: any[] = [];
  billingFieldsDisable: boolean = true;
  carriers: any[] = [];
  ServiceList: any[] = [];
  AccountList: any[] = [];
  closeShipScreen(): void {

    this.location.back()
  }
  getData(): void {
    console.log(this.shipment);
  }

  showngmodelchange() { }


  isReady: boolean = true;


  ngOnInit() {


    setInterval(() => {
      // console.log('calling sending request ')
      if ((this.scannedItemRequestList.length > 0) && this.isReady) {


        this.backGroundProcessScannedItems();
      }

    }, 100);

    // this.isdis= false;
    this.paymentTypes = [
      { name: 'Sender', code: 'Sender' },
      { name: 'Receipient', code: 'Receipient' },
      { name: 'Third Party', code: 'ThirdParty' },
      { name: 'Collect', code: 'Collect' },
    ];


    //to enable carrier special services--------------------
    this.assignSepecialServices();
    //-----------------------------------
  }

  applyHistoryFilters(status: any, event: any, element: any): void {

    var fromdate = ''
    var toDate = ''
    var conditions: any = {};
    // console.log(this.history_filters['shipDateFrom'])
    if (this.history_filters['shipDateFrom'] != '' && this.history_filters['shipDateto'] != '') {

      var shipDateFrom = this.history_filters['shipDateFrom'];
      var shipDateTo = this.history_filters['shipDateTo']
      // console.log(this.shipDateFrom)

      fromdate += shipDateFrom.getFullYear();
      if ((shipDateFrom.getMonth() + 1) < 10) {
        fromdate += '-0' + (shipDateFrom.getMonth() + 1)
      }
      else
        fromdate += '-' + (shipDateFrom.getMonth() + 1)
      if (shipDateFrom.getDate() < 10)
        fromdate += '-0' + shipDateFrom.getDate();
      else
        fromdate += '-' + shipDateFrom.getDate();
      toDate += shipDateTo.getFullYear();
      if ((shipDateTo.getMonth() + 1) < 10) {
        toDate += '-0' + (shipDateTo.getMonth() + 1)
      }
      else
        toDate += '-' + (shipDateTo.getMonth() + 1)
      if (shipDateTo.getDate() < 10)
        toDate += '-0' + shipDateTo.getDate();
      else
        toDate += '-' + shipDateTo.getDate();


      conditions['shipdate'] = "details->'HeaderInfo'->>'ShipDate' between '" + fromdate + "' and '" + toDate + "'"
    }
    var keys = Object.keys(this.history_filters);
    // alert(keys.length)
    keys.forEach((item, index) => {

      var condition = '';
      var fields: any = [];
      var dataConditions = '';
      if (item != "shipDateFrom" && item != "shipDateTo") {
        if (this.history_filters[item] != '') {
          fields = item.split('.')
          condition += "upper(details->'" + fields[0] + "'->>'" + fields[1] + "')=upper('" + this.history_filters[item] + "')";

          dataConditions = dataConditions + ' ' + "upper(details->'" + fields[0] + "'->>'" + fields[1] + "')=upper('" + this.history_filters[item] + "')"
          // console.log(condition)
          conditions["condition" + index] = condition
        }
      }
    })


    // alert(JSON.stringify(conditions))
    this.xfservice.getShipmentstByFilters(JSON.stringify(conditions)).subscribe((data: any) => {

      this.Org_historyOrders = data;
      this.historyOrders = data;
      element.hide(event);

      this.isDataLoaded = true;

    })

  }

  onPaymentTypeChange(): void {
    if (
      this.shipment.CarrierDetails.PaymentType == 'Receipient' ||
      this.shipment.CarrierDetails.PaymentType == 'ThirdParty' ||
      this.shipment.CarrierDetails.PaymentType == 'Collect'
    ) {
      this.billingFieldsDisable = false;
    } else {
      this.billingFieldsDisable = true;
    }
  }

  // DELETE HU START 
  deleteHu(hudetails: any): void {


    //this.messageService.add({ key: 'c', sticky: true, severity: 'warn', summary: 'Are you sure?', detail: 'Confirm to proceed' });

    this.packShipDef['HU'] = hudetails['HU'];
    try {
      this.xfservice.deleteHUSAP(this.packShipDef).subscribe((data: any) => {
        this.messageService.add({
          key: 'tc',
          severity: 'success',
          summary: 'Success',
          detail: "HU deleted successfully",
        });
        this.getExternalDelivery();
        this.currentHU = ''
      })
    } catch (e) { }

    hudetails.ItemsInfo.forEach((p: any) => {
      const pidata = this.shipment.Items.filter((pi: any) => {
        if (p.Sno == pi.Sno) {
          pi.Balance = pi.Balance + parseInt(p.Partial);

          return pi;
        }
      })
      if (pidata.length == 0) {
        p.Balance = parseInt(p.Partial);
        p.Partial = 0;
        this.shipment.Items.push(p);
      }



      this.shipment.Packages = this.shipment.Packages.filter((item: any) => item !== hudetails)
      this.deleteIntlItems(p);
    })
  }

  // DELETE HU END



  //CREATE HU START
  createHu(): void {


    var Before_userExist_code = 'return true';
    var After_userExist_code = 'return true';
    var cfield = this.ShipScreen['FOOTER_LEFT'].fields.filter((item: any) => item.fieldName == 'Pack')[0];
    if (cfield) {
      if (cfield['Before_event']) {
        Before_userExist_code = atob(cfield['Before_event'])
      }
      if (cfield['After_event']) {
        After_userExist_code = atob(cfield['After_event'])
      }
    }



    var func = new Function("return " + "function (messageProcess,template,value,model) { " + Before_userExist_code + " }")();
    if (func(this.messageService, this.ShipScreen, null, this.shipment)) {

      //console.log('Selected Products are');
      let current_package = JSON.parse(JSON.stringify(this.package));
      this.shipment.Items.forEach((p: any) => {
        if (p.Partial > 0) {
          current_package['ItemsInfo'].push(Object.assign({}, p));
          this.processIntlItems(p);
        }
      });



      current_package['Sno'] = this.shipment.Packages.length + 1;
      current_package['IsInternal'] = true;
      console.log('-----> Current package is : ' + JSON.stringify(current_package))
      this.xfservice.createHU(current_package).subscribe((data) => {
        current_package.HU = data.HU;
        this.shipment.Packages.push(current_package);
        this.shipment.Items.forEach((p: any) => {
          if (p.Partial > 0) {
            p.Balance = p.Balance - p.Partial;
            p.Partial = 0;
          }
        });
        //this.shipment.Items = this.shipment.Items.filter((p: any) => { return p.Balance > 0 });
      });
    }


    //after User Exist
    if (After_userExist_code) {
      var after_func = new Function("return " + "function (messageProcess,template,value,model) { " + After_userExist_code + " }")();
      after_func(this.messageService, this.ShipScreen, null, this.shipment)

    }

  }
  // CREATE HU END


  // PACK ALL HU START
  createHu_packall(): void {


    var Before_userExist_code = 'return true';
    var After_userExist_code = 'return true';
    var cfield = this.ShipScreen['FOOTER_LEFT'].fields.filter((item: any) => item.fieldName == 'PackAll')[0];
    if (cfield) {
      if (cfield['Before_event']) {
        Before_userExist_code = atob(cfield['Before_event'])
      }
      if (cfield['After_event']) {
        After_userExist_code = atob(cfield['After_event'])
      }
    }
    var func = new Function("return " + "function (messageProcess,template,value,model) { " + Before_userExist_code + " }")();
    if (func(this.messageService, this.ShipScreen, null, this.shipment)) {
      let current_package = JSON.parse(JSON.stringify(this.package));
      this.shipment.Items.forEach((p: any) => {
        p.Partial = p.Balance;
        current_package['ItemsInfo'].push(Object.assign({}, p));
        this.processIntlItems(p);


      });
      current_package['Sno'] = this.shipment.Packages.length + 1;
      current_package['IsInternal'] = true;
      this.xfservice.createHU(current_package).subscribe((data) => {
        current_package.HU = data.HU;
        this.shipment.Packages.push(current_package);
        this.shipment.Items = []
      });

    }

    if (After_userExist_code) {
      var after_func = new Function("return " + "function (messageProcess,template,value,model) { " + After_userExist_code + " }")();
      after_func(this.messageService, this.ShipScreen, null, this.shipment)

    }

  }
  // PACK ALL END


  //Imyternational Item Processing 
  processIntlItems(item: any) {
    if (this.shipment.InternationalDetails.ItemInfo) { } else {
      this.shipment.InternationalDetails['ItemInfo'] = [];
    }


    const existingitem = this.shipment.InternationalDetails.ItemInfo.filter((it: any) => it.ItemNo == item.ItemNo)
    if (existingitem.length > 0) {
      //existingitem[0].Balance = parseInt(existingitem[0].Balance )-parseInt(item.Partial);

      existingitem[0].Partial = parseInt(existingitem[0].Partial) + parseInt(item.Partial);


    } else {
      this.shipment.InternationalDetails['ItemInfo'].push(Object.assign({}, item))
    }


    // 
    //alert ( JSON.stringify(item))
  }
  deleteIntlItems(item: any) {

    const deletableItem = this.shipment.InternationalDetails.ItemInfo.filter((it: any) => it.ItemNo == item.ItemNo)

    if (deletableItem.length > 0) {
      deletableItem[0].Partial = parseInt(deletableItem[0].Partial) - parseInt(item.Partial);
      this.shipment.InternationalDetails.ItemInfo = this.shipment.InternationalDetails.ItemInfo.filter((it: any) => it.Partial > 0)
    }


  }


  // delete ITEM START
  deleteItem(selecteditem: any): void {
    this.shipment.Items = this.shipment.Items.filter((item: any) => item !== selecteditem)
  }
  // delete ITEM END


  //On BOIX SELECT

  onPackageRowSelect(event: any) {
    this.currentHU = event.data['HU'];
  }


  //BOX enter Event
  processBoxEnter() {
    const selectedbox = this.shipment.Packages.filter((box: any) => box.HU == this.currentHU.trimStart().trimEnd());
    if (selectedbox.length < 1) {
      this.messageService.add({
        key: 'tc',
        severity: 'error',
        summary: 'Error',
        detail: "Box Doesn't exist! Enter valid Box Number.",
      });
    } else {
      this.selectedHU = selectedbox[0];
      this.productelm.nativeElement.focus();
      //  
    }
  }



  //BATCH  ENTER EVENT
  processBatchEnter() {
    const selectdProductWithBatch = this.shipment.Items.filter((item: any) => (item.BatchNo == this.currentProductBach.trimStart().trimEnd()) && (item.Balance > 0));
    if (selectdProductWithBatch.length > 0) {


      // this.packShipDef['HU'] = this.currentHU;
      // this.packShipDef['StockItemUUID'] = (selectdProductWithBatch[0]).StockItemUUID;
      // this.packShipDef['PackagingMaterial'] = this.selectedHU['PackagingMaterial'];
      // this.packShipDef['DocumntNo'] = this.shipment.HeaderInfo['DocumentNumber'];
      // this.packShipDef['Quantity'] = this.currentProductQuantity;
      // this.packShipDef['Product'] = (selectdProductWithBatch[0]).ProductNo;
      // this.packShipDef['AlternativeUnit'] = (selectdProductWithBatch[0]).UOM;

      this.packShipDef['HU'] = this.currentHU;
      // this.packShipDef['StockItemUUID'] = (selectdProduct[0]).['StockItemUUID'];
      this.packShipDef['ItemNo'] = (selectdProductWithBatch[0]).ItemNo;
      this.packShipDef['PackagingMaterial'] = this.selectedHU['PackagingMaterial'];
      this.packShipDef['DocumntNo'] = this.shipment.HeaderInfo['DocumentNumber'];
      this.packShipDef['Quantity'] = this.currentProductQuantity;
      this.packShipDef['AlternativeUnit'] = (selectdProductWithBatch[0]).UOM;
      this.packShipDef['Product'] = (selectdProductWithBatch[0]).ProductNo;
      this.packShipDef['SourceId'] = this.shipment.HeaderInfo['DocumentNumber'];
      // this.packShipDef['UOM'] = (selectdProduct[0]).UOM;
      this.packShipDef['UOM'] = 'PC';

      // const batchindex = this.shipment.Items.findIndex((itemobj: any) => itemobj.StockItemUUID === (selectdProductWithBatch[0]).StockItemUUID);
      const batchindex = this.shipment.Items.findIndex((itemobj: any) => itemobj.ProductNo === (selectdProductWithBatch[0]).ProductNo);

      if (batchindex > 0) {
        const tempItem = Object.assign({}, this.shipment.Items[0]);
        this.shipment.Items[0] = this.shipment.Items[batchindex];
        this.shipment.Items[batchindex] = tempItem;
      }

      this.scannedItemRequestList.push(Object.assign({}, this.packShipDef));
      (selectdProductWithBatch[0]).Balance = (selectdProductWithBatch[0]).Balance - this.currentProductQuantity;

      this.currentProductBach = ' '
      this.currentProduct = ' '
      this.productelm.nativeElement.focus();


    } else {
      this.messageService.add({
        key: 'tc',
        severity: 'error',
        summary: 'Error',
        detail: "Batch Number " + this.currentProductBach + "is not available or Not valid.",
      });
    }

  }


  //Repeate Pack focus leave 

  requestedHU_onFocusOutEvent(event: any) {
    //alert(event.target.value)
  }


  async processRepeatPack() {

    this.repeatButton_loading = true;
    var repatpackHUs = [];
    //creating Boxes
    this.packShipDef['PackagingMaterial'] = this.packagingMaterial;
    this.packShipDef['HUCreateCount'] = this.requestedNumberOfHUs;

    this.xfservice.createHUSAP(this.packShipDef).subscribe((data: any) => {
      repatpackHUs = data;
      if (data.length > 0) {
        data.forEach((hu: any) => {
          hu['Sno'] = this.shipment['Packages'].length + 1
          this.shipment['Packages'].push(hu);
        })
      }
      this.shipment.Packages.sort((a: any, b: any) => b.Sno - a.Sno)


      repatpackHUs.forEach((rhu: any) => {
        // var resultItems=  this.prepareItemsforRepeatPack();
        // console.log(resultItems);
        // console.log("-----------------------")



        const targetItemNo = this.currentProduct.trimStart().trimEnd();
        var resultArray = [];

        var filteredArray = this.shipment.Items.filter((item: any) => item.ProductNo === targetItemNo && item.Balance > 0);
        var n = this.currentProductQuantity;

        for (const element of this.shipment.Items) {
          const xItem = Object.assign({}, element);
          if (element.Balance > n) {

            xItem.Partial = n;
            element.Balance = element.Balance - n;
            resultArray.push(xItem);
            n = 0;

            break;
          } else if (element.Balance < n) {

            xItem.Partial = element.Balance;
            resultArray.push(xItem);
            n = n - element.Balance;
            element.Balance = 0;

            //console.log('---------------------->' + n)
          } else if (element.Balance == n) {
            xItem.Partial = n;
            resultArray.push(xItem);

            n = n - element.Balance;
            element.Balance = 0;
            //console.log('---------------------->' + n)
            break;
          }
        }

        // console.log(resultArray);

        this.prepareItemsforRepeatPack(resultArray, rhu['HU'], rhu['PackagingMaterial']);
        resultArray = [];
        filteredArray = [];



      })
      // if (this.scannedItemRequestList.length < 1)
      //   {
          this.repeatButton_loading = false;
          this.repeatePack_dialog = false;
      //   }
      

    })




  }


  repeatpackPayload: any = {};

  //Toget Items for Repeate Pack

  prepareItemsforRepeatPack(seledtedItems: any, HUNO: any, packMaterial: any) {
    console.log('#####################################################')

    var individualHUItems: any = [];
    seledtedItems.forEach((item: any) => {
      if (item.Partial > 0) {
        this.packShipDef['HU'] = HUNO;
        // this.packShipDef['StockItemUUID'] = item.StockItemUUID;
        this.packShipDef['PackagingMaterial'] = packMaterial;
        this.packShipDef['DocumntNo'] = this.shipment.HeaderInfo['DocumentNumber'];
        this.packShipDef['Quantity'] = item.Partial;
        this.packShipDef['AlternativeUnit'] = item.UOM;
        this.packShipDef['HandlingUnitQuantityUnit']= item.UOM;
        this.packShipDef['UOM']= item.UOM;
        this.packShipDef['ItemNo'] =item.ItemNo;
        this.packShipDef['Product'] = item.ProductNo;
        this.packShipDef['SourceId'] = this.shipment.HeaderInfo['DocumentNumber'];
        console.log(this.packShipDef['ItemNo'] + ' -----  ' + this.packShipDef['ItemNo'] + ' -----  ' + this.packShipDef['Product'] + '      ' + this.packShipDef['HU']);
        individualHUItems.push(Object.assign({}, this.packShipDef));



        this.scannedItemRequestList.push(Object.assign({}, this.packShipDef))
        // await this.processSingleItemForRepeatPAck(individualHUItems)

        //   this.xfservice.processRepeatPack(individualHUItems).subscribe(data => {

        //   console.log('-----------------------------------> Repeact ack Completed')

        // });


        //(this.repeatpackPayload[HUNO]).push(Object.assign({}, this.packShipDef)) ;

        // this.scannedItemRequestList.push(Object.assign({}, this.packShipDef));
      }


    })



  }


  async processSingleItemForRepeatPAck(huitem: any) {
    this.xfservice.processRepeatPack(huitem).subscribe(data => {

      console.log('-----------------------------------> Repeact ack Completed')

    });
  }


  //PRODUCT ENTER EVENT
  itemindex = 0;
  processProductEnter() {
    // console.log(this.shipment.Packages)
    if (this.repeatePack_flag) {
      //alert('Repeate Pack:')


      const selectdProduct = this.shipment.Items.filter((item: any) => (item.ProductNo == this.currentProduct.trimStart().trimEnd()) && (item.Balance > 0));
      if (selectdProduct.length < 1) {
        this.messageService.add({
          key: 'tc',
          severity: 'error',
          summary: 'Error',
          detail: "Poduct Doesn't exist! Enter valid Product Number.",
        });
      } else {

        const total_available_quantity = selectdProduct.reduce((sum: any, item: any) => sum + item.Balance, 0);
        this.totalPossibleBoxes = Math.floor(total_available_quantity / this.currentProductQuantity);

        this.requestedNumberOfHUs = this.totalPossibleBoxes;
        this.repeatePack_dialog = true;


      }
    }

    else {
      // const selectdProduct = this.shipment.Items.filter((item: any) => (item.ItemNo == this.currentProduct.trimStart().trimEnd()) && (item.Balance > 0));
      const selectdProduct = this.shipment.Items.filter((item: any) => (item.ProductNo == this.currentProduct.trimStart().trimEnd()) && (item.Balance > 0));
      const selectedHU = this.shipment.Packages.filter((pack: any) => pack.HU == this.currentHU.trimStart().trimEnd());
      if (selectedHU.length < 1) {
        this.messageService.add({
          key: 'tc',
          severity: 'error',
          summary: 'Error',
          detail: "Invalid HU / BOX ! Enter Valid HU/BOX Number.",
        });
      }
      else if (selectdProduct.length < 1) {
        this.messageService.add({
          key: 'tc',
          severity: 'error',
          summary: 'Error',
          detail: "Poduct Doesn't exist! Enter valid Product Number.",
        });
      } else if ((selectdProduct[0].Balance < this.currentProductQuantity)) {

        this.messageService.add({
          key: 'tc',
          severity: 'error',
          summary: 'Error',
          detail: "Poduct Quantity is not available! Enter valid Product Quantity.",
        });

      } else if (this.currentProductQuantity < 1) {

        this.messageService.add({
          key: 'tc',
          severity: 'error',
          summary: 'Error',
          detail: "Poduct Quantity is not available! Enter valid Product Quantity.",
        });

      } else if (selectdProduct[0].BatchNo.length > 0) {

        this.batchelm.nativeElement.focus();
        this.messageService.add({
          key: 'tc',
          severity: 'error',
          summary: 'Error',
          detail: "Scan Batch Number",
        });

      } else {


        this.packShipDef['HU'] = this.currentHU;
        // this.packShipDef['StockItemUUID'] = (selectdProduct[0]).['StockItemUUID'];
        this.packShipDef['ItemNo'] = (selectdProduct[0]).ItemNo;
        this.packShipDef['PackagingMaterial'] = this.selectedHU['PackagingMaterial'];
        this.packShipDef['DocumntNo'] = this.shipment.HeaderInfo['DocumentNumber'];
        this.packShipDef['Quantity'] = this.currentProductQuantity;
        this.packShipDef['AlternativeUnit'] = (selectdProduct[0]).UOM;
        this.packShipDef['Product'] = (selectdProduct[0]).ProductNo;
        this.packShipDef['SourceId'] = this.shipment.HeaderInfo['DocumentNumber'];
        // this.packShipDef['UOM'] = (selectdProduct[0]).UOM;
        this.packShipDef['UOM'] = 'PC';


        // this.itemindex = this.shipment.Items.findIndex((itemobj: any) => itemobj.StockItemUUID === (selectdProduct[0]).StockItemUUID);
        this.itemindex = this.shipment.Items.findIndex((itemobj: any) => itemobj.ProductNo === (selectdProduct[0]).ProductNo);

        if (this.itemindex > 0) {
          const tempItem = Object.assign({}, this.shipment.Items[0]);
          this.shipment.Items[0] = this.shipment.Items[this.itemindex];
          this.shipment.Items[this.itemindex] = tempItem;
        }


        const existingItem = this.scannedItemRequestList.find((item: any) =>
          // item.StockItemUUID === this.packShipDef['StockItemUUID'] && item.SourceId === this.packShipDef['SourceId']
        item.ItemNo === this.packShipDef['ItemNo'] && item.SourceId === this.packShipDef['SourceId']
        );

        if (existingItem) {

          existingItem.Quantity += this.packShipDef['Quantity'];
        } else {
          // If the item doesn't exist, add it to the accumulator
          this.scannedItemRequestList.push(Object.assign({}, this.packShipDef));

          // alert(JSON.stringify(this.packShipDef))

        }
        (selectdProduct[0]).Balance = (selectdProduct[0]).Balance - this.currentProductQuantity;
        this.currentProduct = ''
      }






    }
  }


  //delete HU list

  deleteHUList(): void {
    this.deleteHuButton_Loading = true;
    this.packShipDef['DocumentNo'] = this.shipment.HeaderInfo.DocumentNumber;
    const hulist: any = [];
    this.selectedHUList.forEach((IndHU: any) => {
      hulist.push(IndHU['HU']);
    });

    this.packShipDef['HU'] = hulist;

    this.xfservice.deleteHUList(this.packShipDef).subscribe((data: any) => {
      this.deleteHuButton_Loading = false;
      this.getExternalDelivery();

    }, (err) => {
      this.deleteHuButton_Loading = false;
    })
  }



  openPackageSpecialService(rowdata: any) {
    this.selectedHu = rowdata;


    this.packageSpecialService_Dialog = true;
  }



  // open Master Products
  createHUList(): void {

    if (this.shipment.HeaderInfo.DocumentNumber == "") {
      this.messageService.add({
        key: 'tc',
        severity: 'error',
        summary: 'Error',
        detail: "Please enter valid Document Number",
      });

    }
    else if (this.packagingMaterial == "") {
      this.messageService.add({
        key: 'tc',
        severity: 'error',
        summary: 'Error',
        detail: "Please Select Packaging Material",
      });

    }
    else {
      this.packShipDef['PackagingMaterial'] = this.packagingMaterial;
      this.packShipDef['HUCreateCount'] = this.noOfBoxes;
      //
      this.xfservice.createHUSAP(this.packShipDef).subscribe((data: any) => {


        if (data.length > 0) {
          data.forEach((hu: any) => {
            hu['Sno'] = this.shipment['Packages'].length + 1

            hu['packageLevelSpecialServices'] = Object.assign({}, this.packageLevelSpecialServices);
            this.shipment['Packages'].push(hu)
          })
        }




        this.shipment.Packages.sort((a: any, b: any) => b.Sno - a.Sno)
      })

    }

    // this.xfservice
    //   .getScreenTemplateByName('ShipMasterProductDialog')
    //   .subscribe((data) => {

    //     this.productscol = [];
    //     this.productsgrid = data[0].details;
    //     this.productsgrid.forEach((colfield) => {
    //       //console.log(colfield.fieldName);
    //       let col = {
    //         field: '',
    //         header: '',
    //         visible: true,
    //         editable: true,
    //         fieldType: '',
    //         datatype: '',
    //       };
    //       col.field = colfield.fieldName;
    //       col.header = colfield.header;
    //       col.visible = colfield.visible;
    //       col.editable = colfield.editable;
    //       col.fieldType = colfield.fieldType;
    //       col.datatype = colfield.datatype;
    //       if (col.field == 'Quantity' || col.field == 'Balance' || col.field == 'Partial' || col.field == 'IsSerial' || col.field == 'IsBatch' || col.field == 'IsStackable') {

    //       } else {
    //         this.productscol.push(col);
    //       }
    //     });

    //   });


    // this.xfservice
    //   .getMasterDataByName('products')
    //   .subscribe((data) => {

    //     // console.log("Products are 1:" + JSON.stringify(data))
    //     this.Master_products = data;
    //     var i = 1;
    //     this.Master_products.forEach((p: any) => {
    //       p["Sno"] = i++;

    //     })
    //     //  console.log("Products are 22:" + JSON.stringify(this.Master_products));
    //     this.MasterProduct_dialog = true;
    //   });



  }

  addProduct(data: any): void {
    var sitem = Object.assign({}, data);
    var max = 0;
    this.shipment.Items.forEach((p: any) => { if (p.Sno > max) max = p.Sno; })
    sitem.Sno = max + 1;
    this.shipment.Items.push(sitem);
    this.MasterProduct_dialog = false;
  }


  assignAddress(data: any): void {

    this.shipment[this.currentAddressBlock].COMPANY = data.COMPANY;
    this.shipment[this.currentAddressBlock].CONTACT = data.CONTACT;
    this.shipment[this.currentAddressBlock].ADDRESS_LINE1 = data.ADDRESS_LINE1;
    this.shipment[this.currentAddressBlock].ADDRESS_LINE2 = data.ADDRESS_LINE2;
    this.shipment[this.currentAddressBlock].CITY = data.CITY;
    this.shipment[this.currentAddressBlock].STATE = data.STATE;
    this.shipment[this.currentAddressBlock].ZIPCODE = data.ZIPCODE;
    this.shipment[this.currentAddressBlock].COUNTRY = data.COUNTRY;
    this.shipment[this.currentAddressBlock].PHONE = data.PHONE;
    this.shipment[this.currentAddressBlock].EMAIL = data.EMAIL;
    this.AddressBook_dialog = false;

  }


  // PRODUCT GLOBAL FILTER START
  applyFilterGlobal($event: any, stringVal: any): void {
    this.dt.filterGlobal(($event.target as HTMLInputElement).value, stringVal);
  }

  applyFilterGlobal_Location($event: any, stringVal: any): void {
    this.loc.filterGlobal(($event.target as HTMLInputElement).value, stringVal);
  }

  applyFilterGlobal_Address($event: any, stringVal: any): void {
    this.addrdt.filterGlobal(($event.target as HTMLInputElement).value, stringVal);
  }


  applyManifestFilterGlobal($event: any, stringVal: any): void {
    if (($event.target as HTMLInputElement).value) {
      this.historyOrders = this.filterNestedArray(this.Org_historyOrders, ($event.target as HTMLInputElement).value)
    } else {
      this.historyOrders = Array.from(this.Org_historyOrders)
    }

  }





  applyFilterGlobal_FreightShop($event: any, stringVal: any): void {
    this.freightdt.filterGlobal(($event.target as HTMLInputElement).value, stringVal);
  }
  //PRODUCT GLOBAL FILETER END 





  filterNestedArray(jsonArray: any[], searchTerm: string): any[] {
    return jsonArray.filter(item => {
      if (typeof item === 'object' && item !== null) {
        return Object.values(item).some(value => this.filterNestedArray([value], searchTerm).length > 0);
      }
      return String(item).includes(searchTerm);
    });
  }





  //COUNTRY CHNAGE START

  changeCountry(event: any): void {
    console.log(event.value);
    //to get all countries
    this.xfservice
      .getMasterCountries("country_code='" + event.value + "'")
      .subscribe((data) => {
        this.Master_states = data;
        console.log(this.Master_countries)
      });
  }
  //COUNTRY CHANGE END


  assigneServiceFromFreightshop(data: any) {
    alert(JSON.stringify(data))
  }



  //ProcessFreightShop

  processFreightShop(): void {

    this.rateloading = true;

    if (this.shipment.CarrierDetails.Carrier) {

      var currentCarrier = this.LocationMasterData.carrieraccounts.filter((accDetails: any) => accDetails.Account == this.shipment.CarrierDetails.ShippingAccount)
      if (currentCarrier.length > 0) {
        this.shipment.CarrierDetails['UserId'] = currentCarrier[0].UserId
        this.shipment.CarrierDetails['Password'] = currentCarrier[0].Password
      }

      var freightshopurl = this.shipment.CarrierDetails['Carrier'] + 'Rate'
      if (this.shipment.CarrierDetails.Carrier !== 'FedEx' && this.shipment.CarrierDetails.Carrier !== 'UPS' && this.shipment.CarrierDetails.Carrier !== 'USPS' && this.shipment.CarrierDetails.Carrier !== 'YRC' && this.shipment.CarrierDetails.Carrier !== 'SAIA') {
       
        freightshopurl = 'GenericRate'
      }
      this.xfservice
        .processRateShop(this.shipment, freightshopurl)
        .subscribe((data) => {
          this.rateloading = false;
          if (data.status == "Error") {
            data.Errors.forEach((err: any) => {
              this.messageService.add({
                key: 'tc',
                severity: 'error',
                summary: 'Error',
                detail: err,
              });
            })

          } else {
            this.FreightShopResult = data;
            this.FreightShop_dialog = true;
          }
        });

    } else {
      //Freight Shop All Carriers
      //-------------------------------------------------------------
      //this.rateloading = true;

      var activeCarriers = this.LocationMasterData.carriers.filter((carrierDetails: any) => carrierDetails.IsActive == true)
      //console.log(activeCarriers)
      if (activeCarriers.length > 0) {
        //console.log('Carrier Accounts are : ' ) ; console.log(this.LocationMasterData.carrieraccounts)
        this.LocationMasterData.carrieraccounts = this.Original_LocationMasterData.carrieraccounts.filter((ca: any) => ca.LocationId == this.currentSelectedLocationid['LocationId'] && ca.IsActive == true)

        const activeCarrierAccounts = this.LocationMasterData.carrieraccounts.filter(
          (accDetails: any) => activeCarriers.some((carrierDetails: any) => carrierDetails.CarrierName == accDetails.CarrierName && accDetails.IsActive == true));
        // console.log(activeCarrierAccounts)

        if (activeCarrierAccounts.length > 0) {

          var rateRequest: any[] = [];//JSON.parse(JSON.stringify(this.shipment))//Object.assign({},this.shipment);
          var rateResponse: Observable<any>[] = []
          activeCarrierAccounts.forEach((acc: any, index: number) => {
            rateRequest.push(JSON.parse(JSON.stringify(this.shipment)))

            rateRequest[index].CarrierDetails.Carrier = acc.CarrierName;
            rateRequest[index].CarrierDetails.UserId = acc.UserId;
            rateRequest[index].CarrierDetails.Password = acc.Password;
            rateRequest[index].CarrierDetails.ShippingAccount = acc.Account;
            rateRequest[index].CarrierDetails.PaymentType = 'Sender';



            rateResponse.push(this.xfservice.processRateShop(rateRequest[index], rateRequest[index].CarrierDetails['Carrier'] + 'Rate'))

          })

          //  rateResponse.forEach((res:Observable<any>)=> res.subscribe((data:any)=>{
          //   this.FreightShopResult = this.FreightShopResult.concat(data);
          //   this.FreightShop_dialog = true;
          //   console.log(data)
          // }))
          this.rateloading = false;
          forkJoin(rateResponse).subscribe((res) => {
            this.rateloading = false;
            // console.log(new Date())
            //     console.log('Response 1:', JSON.stringify(res));
            this.FreightShopResult = [];
            res.forEach(rateres => {
              this.FreightShopResult = this.FreightShopResult.concat(rateres);
            })
            this.FreightShop_dialog = true;

          }, error => {
            // alert('An error occurred:' + error);
          });


        }



      }


      // if (currentCarrier.length > 0) {
      //   this.shipment.CarrierDetails['UserId'] = currentCarrier[0].UserId
      //   this.shipment.CarrierDetails['Password'] = currentCarrier[0].Password
      // }
      // this.xfservice
      //   .processRateShop(this.shipment, this.shipment.CarrierDetails['Carrier'] + 'Rate')
      //   .subscribe((data) => {
      //     this.rateloading = false;
      //     if (data.status == "Error") {
      //       data.Errors.forEach((err: any) => {
      //         this.messageService.add({
      //           key: 'tc',
      //           severity: 'error',
      //           summary: 'Error',
      //           detail: err,
      //         });
      //       })

      //     } else {
      //       this.FreightShopResult = data;
      //       this.FreightShop_dialog = true;
      //     }
      //   });










      //----------------------------------------------------------------

    }


  }


  //Save Ship Request Start
  Save(): void {

    alert('SS Details are : ' + this.ssdetails.holdAtLocationDetail.locationId)
    //saveShipRequest
    //console.log(JSON.stringify(this.shipment))
    this.xfservice
      .saveShipRequest(this.shipment)
      .subscribe((data) => {
        console.log("ClearShip is :" + JSON.stringify(this.clearShip))
        this.shipment = Object.assign({}, this.clearShip);
        this.messageService.add({
          key: 'tc',
          severity: 'success',
          summary: 'Ship Request',
          detail: 'ShipRequest Successfully saved',
        });


      });

  }

  // Save Ship Request end

  //Show Label

  showLabel(rdata: any) {

    if (rdata['docType'] == 'PDF') {

      const byteArray = new Uint8Array(atob(rdata.encodedLabel).split('').map(char => char.charCodeAt(0)));

      var pblob = new Blob([byteArray], { type: 'application/pdf' });

      const url = window.URL.createObjectURL(pblob);
      window.open(url, "_blank");

    }
    else {
      if (rdata.encodedLabel) {
        this.currentlabeldata = 'data:image/png;base64,' + rdata.encodedLabel;
        this.LabelPreview_Dialog = true;
      }

    }


  }



  printLabel(rdata: any) {
    alert(rdata)
  }

  //get Carrier By Routing Rules

  getCarrierByRoutingRules(): void {

    const totalWeight: number = this.shipment.Packages.reduce((acc: any, obj: any) => acc + obj.Weight, 0);
    console.log('Total Weight is : ' + totalWeight);
    this.shipment.HeaderInfo['TotalWeight'] = Number(totalWeight);
    console.log(this.shipment.HeaderInfo);



    const shipmentlelveltruekeys = Object.entries(this.shipmentLevelSpecialServices).filter(([key, value]) => value === true).map(([key]) => key);
    this.shipment['ShipmentSpecialServiceTyeps'] = shipmentlelveltruekeys;

    this.shipment.Packages.forEach((pack: any) => {
      pack['PackageSpecialServiceTyeps'] = [];

      const trueKeys: string[] = Object.entries(pack['packageLevelSpecialServices']).filter(([key, value]) => value === true).map(([key]) => key);
      pack['PackageSpecialServiceTyeps'] = trueKeys;
      console.log(trueKeys)
      //pack['packageLevelSpecialServices']=Object.assign({},this.packageLevelSpecialServices);
    })

    //-----------------------------------------------------------------------------------

    this.xfservice.processRoutingGuide(this.shipment).subscribe((data) => {
      if (data.length > 0) {

        this.shipment.CarrierDetails.Carrier = data[0].details.shippingProcess.carrierDetails[0].CarrierName;
        this.shipment.CarrierDetails.ServiceName = data[0].details.shippingProcess.carrierDetails[0].ServiceCode;

        try {
          this.shipment.CarrierDetails.PaymentType = data[0].details.shippingProcess.carrierDetails[0].PaymentType;
          this.shipment.CarrierDetails.ShippingAccount = data[0].details.shippingProcess.carrierDetails[0].ShippingAccount;
        } catch (ex) { }

        this.processChange('CarrierDetails', "PaymentType", this.shipment.CarrierDetails.PaymentType, '', '');
        this.processChange('CarrierDetails', "Carrier", this.shipment.CarrierDetails.Carrier, '', '');


        data[0].details.defaults.forEach((def: any) => {
          this.setNestedValue(def['fieldName'], def['value']);
        })

      }
      else {
        this.messageService.add({
          key: 'tc',
          severity: 'error',
          summary: 'Error',
          detail: 'No Rules found for given Parameters',
        });

      }

    });

    //-----------------------------------------------------------------------------------



  }



  //Save Ship Request Start
  Ship() {


    //Prepre HU ITEMS and International Items
    //-------------------------------------------------------------------------
    // this.shipment.Packages.forEach( (hudetails:any) =>{
    //   if (hudetails['IsInternal']) {
    //   }
    //   else
    //   {
    //     this.packShipDef['HU'] = hudetails.HU;
    //     this.currentOperationalHU = hudetails.HU;
    //     this.xfservice.getHUItems(this.packShipDef).subscribe((data: any) => {
    //     hudetails['ItemsInfo']= data;
    //     })
    //   }
    // })



    //--------------------------------------------------------------------





    const shipmentlelveltruekeys = Object.entries(this.shipmentLevelSpecialServices).filter(([key, value]) => value === true).map(([key]) => key);
    this.shipment['ShipmentSpecialServiceTyeps'] = shipmentlelveltruekeys;
    try {
      this.shipment.Packages.forEach((pack: any) => {
        pack['PackageSpecialServiceTyeps'] = [];
        const trueKeys: string[] = Object.entries(pack['packageLevelSpecialServices']).filter(([key, value]) => value === true).map(([key]) => key);
        pack['PackageSpecialServiceTyeps'] = trueKeys;
        console.log(trueKeys)
      })
    } catch (e) { }



    this.shiploading = true;
    var currentCarrier = this.LocationMasterData.carrieraccounts.filter((accDetails: any) => accDetails.Account == this.shipment.CarrierDetails.ShippingAccount)
    if (currentCarrier.length > 0) {
      this.shipment.CarrierDetails['UserId'] = currentCarrier[0].UserId
      this.shipment.CarrierDetails['Password'] = currentCarrier[0].Password
    }


    const totalWeight: number = this.shipment.Packages.reduce((acc: any, obj: any) => acc + obj.Weight, 0);
    console.log('Total Weight is : ' + totalWeight);

    this.shipment.HeaderInfo['TotalWeight'] = totalWeight;

    console.log(this.shipment.HeaderInfo)

    console.log(this.shipment)

    var shipendurl=this.shipment.CarrierDetails.Carrier
    if (this.shipment.CarrierDetails.Carrier !== 'FedEx' && this.shipment.CarrierDetails.Carrier !== 'UPS' && this.shipment.CarrierDetails.Carrier !== 'USPS' && this.shipment.CarrierDetails.Carrier !== 'YRC' && this.shipment.CarrierDetails.Carrier !== 'SAIA') {
       
      shipendurl = 'Generic'
    }


    this.xfservice
      .BookShipment(this.shipment, shipendurl)
      .subscribe((data) => {
        this.shiploading = false;
        if (data.status == "Error") {
          data.Errors.forEach((err: any) => {
            this.messageService.add({
              // key: 'tc',
              severity: 'error',
              summary: 'Error',
              detail: err,
            });
          })
        }
        else if (data.status == "Success") {
          this.shipment = data;

          Object.keys(this.shipment.HeaderInfo).forEach((key) => {

            console.log(key + '   ' + this.shipment.HeaderInfo[key])
            this.shipment[key] = this.shipment.HeaderInfo[key]

            console.log(this.shipment[key])
          })



          Object.keys(this.shipment.CarrierDetails).forEach((key) => {

            console.log(key + '   ' + this.shipment.CarrierDetails[key])
            this.shipment[key] = this.shipment.CarrierDetails[key]

            console.log(this.shipment[key])
          })

          var manifestship = JSON.parse(JSON.stringify(this.shipment));
          this.shipment = this.clearShip;
          this.xfservice
            .SaveShipment(manifestship)
            .subscribe((sdata) => {
              this.messageService.add({
                key: 'tc',
                severity: 'success',
                summary: 'Shipment',
                detail: 'Shipment Processed Successfully',
              });

            });
        }
      },

        (error) => {
          this.shiploading = false;
          this.messageService.add({
            key: 'tc',
            severity: 'error',
            summary: 'Error',
            detail: error.message,
          });

        }

      );

  }

  // Save Ship Request end



  //Address Book Popup START

  openAddressBook(fromblock: any): void {
    this.currentAddressBlock = fromblock;
    this.addressbookcols = [];
    this.addressbookfilters = [];
    this.xfservice.getAddressBookTemplate().subscribe((data) => {


      this.addressbookgrid = data[0].fields;

      this.addressbookgrid.forEach((colfield) => {
        // console.log(colfield);
        let col = {
          field: '',
          header: '',
          visible: true,
          editable: false,
          fieldType: '',
          datatype: '',
        };
        col.field = colfield.fieldName;
        col.header = colfield.fieldName;
        col.editable = colfield.editable;
        col.fieldType = colfield.fieldType;
        col.datatype = colfield.datatype;
        this.addressbookfilters.push(colfield.fieldName)
        if (col.field == 'id' || col.field == 'IsActive') {
          col.visible = false;
        }
        if (col.visible) {
          this.addressbookcols.push(col);
        }
      });
      //console.log('addressbook fields are: ' + JSON.stringify(this.addressbookcols));

      this.xfservice.getMasterDataByName('addressBook').subscribe((data) => {
        this.Master_addressBook = data;
        // console.log('Address:' + data)
        this.AddressBook_dialog = true;
      });
    });






  }

  //Address Book popup end



  specialServiceData: any = {}

  processChange(blockname: any, sourcefield: any, value: any, effectedfields: string, mappingfield: string) {
    // code to get event releated to source field

    //alert(sourcefield);
    var Before_userExist_code = 'return true';
    var After_userExist_code = 'return true';
    var cfield = this.ShipScreen[blockname].fields.filter((item: any) => item.fieldName == sourcefield)[0];

    if (cfield) {
      if (cfield['Before_event']) {
        Before_userExist_code = atob(cfield['Before_event'])
      }

      // Field based logic -> carrier field
      //-------------------------------------------------
      if (sourcefield == 'Carrier') {

        //console.log( 'Current selectttd Location is : ' +  JSON.stringify(this.currentSelectedLocationid ))
        this.shipment.CarrierDetails.PaymentType = 'Sender';
        this.LocationMasterData.carrieraccounts = [];
        if (this.shipment.CarrierDetails.Carrier) {
          this.LocationMasterData.carrieraccounts = this.Original_LocationMasterData.carrieraccounts.filter((ca: any) => ca.CarrierName == value && ca.LocationId == this.currentSelectedLocationid['LocationId'])
          if (this.LocationMasterData.carrieraccounts.length > 0) {
            this.shipment.CarrierDetails.ShippingAccount = this.LocationMasterData.carrieraccounts[0].Account;
            this.shipment.CarrierDetails.UserId = this.LocationMasterData.carrieraccounts[0].UserId;
            this.shipment.CarrierDetails.Password = this.LocationMasterData.carrieraccounts[0].Password;
            this.shipment.CarrierDetails['AccessKey'] = this.LocationMasterData.carrieraccounts[0].AccessKey;
          }

        } else {
          this.LocationMasterData.carrieraccounts = this.Original_LocationMasterData.carrieraccounts.filter((ca: any) => ca.LocationId == this.currentSelectedLocationid['LocationId'] && ca.IsActive == true)

        }

        console.log(this.LocationMasterData.carrieraccounts)
        //  console.log( this.LocationMasterData.carrieraccounts)

        //  console.log(this.LocationMasterData.carrieraccounts[0].Account)
        //  console.log(this.LocationMasterData.carrieraccounts[0].UserId)
        //  console.log(this.LocationMasterData.carrieraccounts[0].Password)


        // alert(value)
      }



      //-------------------------------------------------------------





      if (cfield['After_event']) {
        After_userExist_code = atob(cfield['After_event'])
      }

      // need to move on inital call @ location master data
      //-------------------------------------------------------------------
      if (sourcefield == 'Carrier') {
        this.xfservice.getSpecialServicesByCarrier(value).subscribe((data: any) => {

          this.specialServiceData = data;

          //           console.log('SSSSSSSSSSSSSSSSSSSSSSSSSSSSS')
          // console.log( JSON.stringify(this.specialServiceData))
          // console.log('SSSSSSSSSSSSSSSSSSSSSSSSSSSSS')
          this.isSSLoded = true;
          // console.log(this.specialServiceData)

        })
      }
      //-------------------------------------------------------------------
    }

    //Get Special Servises for selected carrier



    //Default code condition to run standard functionality
    //var func = new Function("return " + "function ('template','org_masterdata','masterdata') { " + userExist_code + " }")();
    var func = new Function("return " + "function (messageProcess,template,value,model) { " + Before_userExist_code + " }")();
    if (func(this.messageService, this.ShipScreen, value, this.shipment)) {



      // alert('After call: ' + JSON.stringify(this.ShipScreen['CarrierDetails'].fields))

      if (effectedfields) {
        var ef_fields = [];
        if (effectedfields.indexOf(",") > 0) {
          ef_fields = effectedfields.split(",");
        } else {
          ef_fields.push(effectedfields);
        }

        ef_fields.forEach(item => {
          this.LocationMasterData[item] = this.Original_LocationMasterData[item].filter((mf: any) => mf[mappingfield] === value)
        });

      }

    }

    //after User Exist
    if (After_userExist_code) {
      var after_func = new Function("return " + "function (messageProcess,template,value,model,orgMasterData,currentMasterData) { " + After_userExist_code + " }")();
      after_func(this.messageService, this.ShipScreen, value, this.shipment, this.Original_LocationMasterData,
        this.LocationMasterData)

    }


  }



  //Process Button Click
  processButtonClick(blockname: any, sourcefield: any, value: any, effectedfields: string, mappingfield: string) {


    // code to get event releated to source field
    var Before_userExist_code = 'return true';
    var After_userExist_code = 'return true';
    var cfield = this.ShipScreen[blockname].fields.filter((item: any) => item.fieldName == sourcefield)[0];
    if (cfield) {
      if (cfield['Before_event']) {
        Before_userExist_code = atob(cfield['Before_event'])
      }
      if (cfield['After_event']) {
        After_userExist_code = atob(cfield['After_event'])
      }
    }





    //var func = new Function("return " + "function ('template','org_masterdata','masterdata') { " + userExist_code + " }")();
    var func = new Function("return " + "function (messageProcess,template,value,model) { " + Before_userExist_code + " }")();
    if (func(this.messageService, this.ShipScreen, value, this.shipment)) {
      if (effectedfields) {
        var ef_fields = [];
        if (effectedfields.indexOf(",") > 0) {
          ef_fields = effectedfields.split(",");
        } else {
          ef_fields.push(effectedfields);
        }
        ef_fields.forEach(item => {
          this.LocationMasterData[item] = this.Original_LocationMasterData[item].filter((mf: any) => mf[mappingfield] === value)
        });
      }
    }

    //Default code condition to run standard functionality
    //------------------------------------------------------------------------------

    if (blockname == 'ShipTo' && sourcefield == 'Validate Address') {
      this.addressvalidationload = true;
      this.addressResult.Address = this.shipment.ShipTo;
      this.addressResult.CarrierDetails = this.shipment.CarrierDetails;
      this.xfservice
        .processAddressValidation(this.addressResult)
        .subscribe((data) => {
          this.addressvalidationload = false;
          console.log('Address Result is : ' + JSON.stringify(data));
          this.addressResult = data;
          this.AddressValidation_Dialog = true;
        })
      // alert(blockname + '   ' + sourcefield)
    } else if (blockname == 'CarrierDetails' && sourcefield == 'GetCarrier') {
      this.getCarrierByRoutingRules();
    }




    //--------------------------------------------------------------------------------
    //after User Exist
    if (After_userExist_code) {
      var after_func = new Function("return " + "function (messageProcess,template,value,model) { " + After_userExist_code + " }")();
      after_func(this.messageService, this.ShipScreen, value, this.shipment)

    }


  }



  //Update Verified address
  updateWithVerifiedAddress(): void {
    this.shipment.ShipTo.ADDRESS_LINE1 = this.addressResult.VerifiedAddress['ADDRESS_LINE1']
    this.shipment.ShipTo.ADDRESS_LINE2 = this.addressResult.VerifiedAddress['ADDRESS_LINE2']
    this.shipment.ShipTo.CITY = this.addressResult.VerifiedAddress['CITY']
    this.shipment.ShipTo.STATE = this.addressResult.VerifiedAddress['STATE']
    this.shipment.ShipTo.ZIPCODE = this.addressResult.VerifiedAddress['ZIPCODE']
    this.shipment.ShipTo.COUNRTY = this.addressResult.VerifiedAddress['COUNRTY']
    this.addressResult.VerifiedAddress = {}
    this.AddressValidation_Dialog = false;
  }

  // TABLE PROPERTIES 
  openTableProperties(tableproperties: any, tname: any): void {
    this.currentSelectedTableData = tableproperties
    this.currentSelectedTable = tname;
    this.tableProperties_dialog = true
  }


  //BlockProperties
  openBlockProperties(blockname: any): void {
    this.currentSelectedBlock = this.ShipScreen[blockname].fields;
    this.currentSelected_Block_Name = blockname;
    this.property_dialog = true;
    // console.log('BLOCk DATA : ' + JSON.stringify(this.currentSelectedBlock))
  }


  onKeyUp(event: any, fn: any, bn: any): void {
  }

  loadFieldProperties() {

    this.currentSelctedField = this.ShipScreen[this.currentSelected_Block_Name].fields.filter((item: any) => { return item.fieldName == this.currentSelected_Block_Field_Name })[0];
    this.propertyFieldSelected = true

  }


  // Save/Update TemplateChanges
  SaveTemplate(templatename: any): void {

    this.xfservice
      .saveScreenTemplateByName(templatename, this.ShipScreen)
      .subscribe((data) => {

        this.tableProperties_dialog = false
        this.messageService.add({
          key: 'tc',
          severity: 'success',
          summary: 'Template',
          detail: templatename + ' Properties are successfully saved.',
        });
        this.property_dialog = false;

      });

  }


  SaveHistoryTemplate(): void {

    this.xfservice
      .saveScreenTemplateByName('HistoryTable', this.history_xfields)
      .subscribe((data) => {

        this.tableProperties_dialog = false
        this.messageService.add({
          key: 'tc',
          severity: 'success',
          summary: 'Template',
          detail: 'HistoryTable' + ' Properties are successfully saved.',
        });
        this.property_dialog = false;

      });

  }



  SaveRequiredMasterData(templatename: any, event: any, element: any): void {

    this.xfservice
      .saveScreenTemplateByName(templatename, this.ShipScreen)
      .subscribe((data) => {

        this.tableProperties_dialog = false
        this.messageService.add({
          key: 'tc',
          severity: 'success',
          summary: 'Template',
          detail: templatename + ' Properties are successfully saved.',
        });

        element.hide(event);
      });

  }

  updateShipFrom() {


    let rowdata = this.Original_LocationMasterData.location.filter((item: any) => item.LocationId == this.shipment.HeaderInfo.Location)
    //console.log('ROW DATA IS: ' + JSON.stringify(rowdata));

    this.currentSelectedLocationid = rowdata[0];
    //console.log('Selected Location Is : ' + JSON.stringify(this.currentSelectedLocationid) + ' ---------> ' + this.currentSelectedLocationid.weightunit)
    this.package['Weightunits'] = this.currentSelectedLocationid['weightunit']
    this.package['DimensionUnits'] = this.currentSelectedLocationid['dimensionunit']
    this.shipment.ShipFrom.COMPANY = rowdata[0].LocationName;
    this.shipment.ShipFrom.CONTACT = rowdata[0].Contact;
    this.shipment.ShipFrom.ADDRESS_LINE1 = rowdata[0].AddressLine1;
    this.shipment.ShipFrom.ADDRESS_LINE2 = rowdata[0].AddressLine2;
    this.shipment.ShipFrom.CITY = rowdata[0].City;
    this.shipment.ShipFrom.STATE = rowdata[0].State;
    this.shipment.ShipFrom.ZIPCODE = rowdata[0].ZipCode;
    this.shipment.ShipFrom.COUNTRY = rowdata[0].Country;
    this.shipment.ShipFrom.PHONE = rowdata[0].Phone;
    this.shipment.ShipFrom.EMAIL = rowdata[0].Email;



    this.shipment.Shipper.COMPANY = rowdata[0].LocationName;
    this.shipment.Shipper.CONTACT = rowdata[0].Contact;
    this.shipment.Shipper.ADDRESS_LINE1 = rowdata[0].AddressLine1;
    this.shipment.Shipper.ADDRESS_LINE2 = rowdata[0].AddressLine2;
    this.shipment.Shipper.CITY = rowdata[0].City;
    this.shipment.Shipper.STATE = rowdata[0].State;
    this.shipment.Shipper.ZIPCODE = rowdata[0].ZipCode;
    this.shipment.Shipper.COUNTRY = rowdata[0].Country;
    this.shipment.Shipper.PHONE = rowdata[0].Phone;
    this.shipment.Shipper.EMAIL = rowdata[0].Email;



    this.LocationMasterData.carriers = this.Original_LocationMasterData.carriers.filter((item: any) => item.LocationId == this.shipment.HeaderInfo.Location);
    this.LocationMasterData.carrieraccounts = this.Original_LocationMasterData.carrieraccounts.filter((item: any) => item.LocationId == this.shipment.HeaderInfo.Location);
    this.LocationMasterData.carrierservices = this.Original_LocationMasterData.carrierservices.filter((item: any) => item.LocationId == this.shipment.HeaderInfo.Location);
    this.LocationMasterData.dimensions = this.Original_LocationMasterData.dimensions.filter((item: any) => item.LocationId == this.shipment.HeaderInfo.Location);
    this.LocationMasterData.erp = this.Original_LocationMasterData.erp.filter((item: any) => item.LocationId == this.shipment.HeaderInfo.Location);
    this.LocationMasterData.ordertypes = this.Original_LocationMasterData.ordertypes.filter((item: any) => item.LocationId == this.shipment.HeaderInfo.Location);
  }


  //Assigne Location 
  assignLocation(rowdata: any, event: any, element: any) {

    // console.log('Selected Location is: ' + JSON.stringify(rowdata))

    this.currentSelectedLocationid = rowdata;
    this.shipment.HeaderInfo.Location = rowdata.LocationId;

    this.shipment.ShipFrom.COMPANY = rowdata.LocationName;
    this.shipment.ShipFrom.CONTACT = rowdata.Contact;
    this.shipment.ShipFrom.ADDRESS_LINE1 = rowdata.AddressLine1;
    this.shipment.ShipFrom.ADDRESS_LINE2 = rowdata.AddressLine2;
    this.shipment.ShipFrom.CITY = rowdata.City;
    this.shipment.ShipFrom.STATE = rowdata.State;
    this.shipment.ShipFrom.ZIPCODE = rowdata.ZipCode;
    this.shipment.ShipFrom.COUNTRY = rowdata.Country;
    this.shipment.ShipFrom.PHONE = rowdata.Phone;
    this.shipment.ShipFrom.EMAIL = rowdata.Email;


    this.shipment.Shipper.COMPANY = rowdata.LocationName;
    this.shipment.Shipper.CONTACT = rowdata.Contact;
    this.shipment.Shipper.ADDRESS_LINE1 = rowdata.AddressLine1;
    this.shipment.Shipper.ADDRESS_LINE2 = rowdata.AddressLine2;
    this.shipment.Shipper.CITY = rowdata.City;
    this.shipment.Shipper.STATE = rowdata.State;
    this.shipment.Shipper.ZIPCODE = rowdata.ZipCode;
    this.shipment.Shipper.COUNTRY = rowdata.Country;
    this.shipment.Shipper.PHONE = rowdata.Phone;
    this.shipment.Shipper.EMAIL = rowdata.Email;

    this.LocationMasterData.carriers = this.Original_LocationMasterData.carriers.filter((item: any) => item.LocationId == this.shipment.HeaderInfo.Location);
    this.LocationMasterData.carrieraccounts = this.Original_LocationMasterData.carrieraccounts.filter((item: any) => item.LocationId == this.shipment.HeaderInfo.Location);
    this.LocationMasterData.carrierservices = this.Original_LocationMasterData.carrierservices.filter((item: any) => item.LocationId == this.shipment.HeaderInfo.Location);
    this.LocationMasterData.dimensions = this.Original_LocationMasterData.dimensions.filter((item: any) => item.LocationId == this.shipment.HeaderInfo.Location);
    this.LocationMasterData.erp = this.Original_LocationMasterData.erp.filter((item: any) => item.LocationId == this.shipment.HeaderInfo.Location);
    this.LocationMasterData.ordertypes = this.Original_LocationMasterData.ordertypes.filter((item: any) => item.LocationId == this.shipment.HeaderInfo.Location);
    element.hide(event)
  }




  fieldValueChange(blockName: any, fieldname: any, fieldValue: any) {

    var cfield = this.ShipScreen[blockName].fields.filter((it: any) => it.fieldName == fieldname)
    // console.log('Field Template is : ' + JSON.stringify(cfield))
    // console.log('Changing @ '+ blockName + ' --> ' + fieldname + ' @ ' + fieldValue  )
  }


  //new field diaploopen 
  processNewField() {

    this.newField_Dialog = true;
  }

  //Adding new filed 
  addNewField() {
    var isfieldexist = []
    isfieldexist = this.ShipScreen[this.currentSelected_Block_Name].fields.filter((item: any) => item.fieldName == this.newField)
    if (isfieldexist.length > 0) {
      this.messageService.add({
        key: 'tc',
        severity: 'error',
        summary: 'Template',
        detail: 'Field Name with: ' + this.newField + ' Is Already Existed ',
      });

    } else {
      var newfieldmodel = Object.assign({}, this.LocationMasterData['FieldModel'][0].details)
      newfieldmodel.fieldName = this.newField;
      newfieldmodel.title = this.newField;
      newfieldmodel.standard = false;
      newfieldmodel.UDF = true;
      this.ShipScreen[this.currentSelected_Block_Name].fields.push(newfieldmodel);
      this.SaveTemplateWithDescription('ShipScreen', 'New Field : ' + this.newField + ' added to ' + this.currentSelected_Block_Name)
      this.newField_Dialog = false;
    }
  }


  //adding New Button

  addNewButton() {
    var isfieldexist = []
    isfieldexist = this.ShipScreen[this.currentSelected_Block_Name].fields.filter((item: any) => item.fieldName == this.newField)
    if (isfieldexist.length > 0) {
      this.messageService.add({
        key: 'tc',
        severity: 'error',
        summary: 'Template',
        detail: 'Field Name with: ' + this.newField + ' Is Already Existed ',
      });

    } else {
      var newfieldmodel = Object.assign({}, this.LocationMasterData['FieldModel'][0].details)
      newfieldmodel.fieldName = this.newField;
      newfieldmodel.title = this.newField;
      newfieldmodel.fieldType = "Button"
      newfieldmodel.standard = false;
      newfieldmodel.UDF = true;
      this.ShipScreen[this.currentSelected_Block_Name].fields.push(newfieldmodel);
      this.SaveTemplateWithDescription('ShipScreen', 'New Button : ' + this.newField + ' added to ' + this.currentSelected_Block_Name)
      this.newField_Dialog = false;
    }
  }


  SaveTemplateWithDescription(templatename: any, description: any): void {
    this.xfservice
      .saveScreenTemplateByNameAndDescription(templatename, this.ShipScreen, description)
      .subscribe((data) => {
        this.messageService.add({
          key: 'tc',
          severity: 'success',
          summary: 'Template',
          detail: description,
        });
      });
  }

  //Delete Field from currentselected block
  deleteFieldFromBlock() {
    this.xfservice
      .deleteFieldFromBlock(this.currentSelctedField.fieldName, this.currentSelected_Block_Name, 'Deleted ' + this.currentSelctedField.fieldName + ' from ' + this.currentSelected_Block_Name, 'ShipScreen')
      .subscribe((data) => {
        this.ShipScreen = data[0].details;
        this.messageService.add({
          key: 'tc',
          severity: 'success',
          summary: 'Template',
          detail: 'Deleted ' + this.currentSelctedField.fieldName + ' from ' + this.currentSelected_Block_Name
        });

        this.property_dialog = false;
      });
  }

  //Open Event Dialog

  openEventDialog(eventType: any) {
    this.event_Dialog = true;
    this.currentEventType = eventType;

    this.code = '';
    if (this.currentSelctedField[eventType]) {
      this.code = atob(this.currentSelctedField[eventType]);
    }

  }


  onCodeChanged(value: any) {
    this.code = value;

  }


  //MONACO RELEATED FUNCTIONS ---------------------

  mergeOptions(partialOptions: any) {
    return {
      ...this.editorOptions,
      ...partialOptions
    };
  }

  editorInit(editor: MonacoStandaloneCodeEditor) {
    // Programatic content selection example
    editor.setSelection({
      startLineNumber: 1,
      startColumn: 1,
      endColumn: 50,
      endLineNumber: 3
    });
  }



  setNestedValue(fieldPath: any, value: any) {
    const fields = fieldPath.split('->');
    let currentObj = this.shipment;

    for (const field of fields.slice(0, -1)) {
      currentObj[field] = currentObj[field] || {};
      currentObj = currentObj[field];
    }

    currentObj[fields[fields.length - 1]] = value;
  }


  getCode() {
    return (

      "console.log('This is Working')"
    );
  }


  registerMonacoCustomTheme() {
    monaco.editor.defineTheme('myCustomTheme', {
      base: 'vs-dark', // can also be vs or hc-black, vs-dark
      inherit: true, // can also be false to completely replace the builtin rules
      rules: [
        {
          token: 'comment',
          foreground: 'ffa500',
          fontStyle: 'italic underline'
        },
        { token: 'comment.js', foreground: '008800', fontStyle: 'bold' },
        { token: 'comment.css', foreground: '0000ff' } // will inherit fontStyle from `comment` above
      ],
      colors: {}
    });
  }
  //MONACO RELEATED FUNCTIONS END 

  saveEvent(blockname: any, fieldname: any) {
    // alert('Event Created for : @' + blockname + '  -->  ' + fieldname)

    this.currentSelctedField[this.currentEventType] = btoa(this.code);
    this.event_Dialog = false;
  }



  //Open new colum property

  openNewColumProperty() {
    this.newGridColumns_Dialog = true;
  }


  //adding new column to grid

  addnewColumnToGrid() {
    //     alert(this.currentSelectedTable);
    // alert(this.ShipScreen[this.currentSelectedTable])
    //alert('Data is : ' + JSON.stringify(this.ShipScreen));
    this.ShipScreen[this.currentSelectedTable].columns.push(Object.assign({}, this.newColumn));
    this.SaveTemplateWithDescription('ShipScreen', 'New Field : ' + this.newColumn['fieldName'] + ' added to ' + this.currentSelectedTable);

    // this.messageService.add({
    //   key: 'tc',
    //   severity: 'success',
    //   summary: 'Template',
    //   detail: this.newColumn['fieldName']  + ' successfully added to ' +  this.currentSelectedTable + ' Table.',
    // });

    this.newColumn = {};
    this.currentSelectedTableData = this.ShipScreen[this.currentSelectedTable].columns;



    // this.SaveTemplateWithDescription('ShipScreen', 'New Field : ' + this.newField + ' added to ' + this.currentSelected_Block_Name)
    this.newGridColumns_Dialog = false;
    //console.log( this.ShipScreen[this.currentSelectedTable])


  }


  //open History Dialog 

  openHistoryDialog() {
    this.historyOrders = [];
    this.history_Dialog = true;
  }

  openHistoryTableProperties() {
    this.HistorytableProperties_dialog = true;
  }

  getShipments(fromobject: any) {
    this.historyOrders = []
    if (fromobject == 'shiprequest')
      this.openorderloading = true
    else
      this.shiphistoryloading = true
    this.xfservice
      .getxFormRecordsByFromName(fromobject)
      .subscribe((data) => {
        this.historyOrders = data.reverse();
        this.Org_historyOrders = data;
        if (fromobject == 'shiprequest')
          this.openorderloading = false
        else
          this.shiphistoryloading = false
      });



  }





  getShipReqByDocNo() {
    var docno = this.shipment.HeaderInfo.DocumentNumber
    this.xfservice.getShipRequest(docno).subscribe(data => {
      //alert(JSON.stringify(data[0]))

      this.shipment = data[0].details;
      this.shipment.HeaderInfo.ShipDate = new Date(this.shipment.HeaderInfo.ShipDate)
      if (this.autoShip == true) {
        this.Ship();

      }
      else if (this.autoPack == true) {
        this.createHu_packall()
      }



    })

  }


  //select shipment or order 
  selectOrderorShipment(ssd: any) {
    console.log('parameter is :')
    console.log(ssd.details)
    this.shipment = this.clearShip;
    // this.shipment = this.selectedHistoryOrder.details;
    this.shipment = ssd.details
    this.shipment.HeaderInfo.ShipDate = new Date(this.shipment.HeaderInfo.ShipDate)

    //Getting Special Service data for  carrier
    //---------------------------------------------------------

    if (this.shipment.CarrierDetails.Carrier) {
      this.xfservice.getSpecialServicesByCarrier(this.shipment.CarrierDetails.Carrier).subscribe((data: any) => {
        this.specialServiceData = data;
        this.isSSLoded = true;
      })
    }

    //----------------------------------------------
    console.log('Selectd Shipment is : ')
    console.log(this.shipment)

    this.history_Dialog = false;
    //alert(this.shipment.status)
    if (this.shipment['status'] == 'Success') {
      this.isdis = true
    } else {
      this.isdis = false

      this.shipment.Shipper = this.shipment.ShipFrom;

    }
  }

}
