



<div class="grid" [style]="{ padding: '10px',height: '50vh'}">
    <div  class="card col-12 md:col-12" [style]="{ height: '50px'}">
        <label for="shipdate">From Date  </label>
            <p-calendar id="shipdate" [showIcon]="true" inputId="icon" [(ngModel)]="requestDateRange.fromdate"
              [readonlyInput]="true" class="mr-2 "></p-calendar>


              <label for="shipdate">To Date  </label>
            <p-calendar id="shipdate" [showIcon]="true" inputId="icon"  [(ngModel)]="requestDateRange.todate"
              [readonlyInput]="true" class="mr-2"></p-calendar>

              <p-button  label="Apply"  class="mr-2" (onClick)="processDashBoardrequest()"></p-button>
       
       
    </div>
        <div class="card col-12 md:col-6">
            <p-chart type="bar" [data]="data" [options]="options">Dates</p-chart>
        </div>
        <div class="card col-12 md:col-6  ">
            <p-chart type="pie"  height="380"  [data]="trackdata" [options]="trackoptions" ></p-chart>
            <!-- <p-chart type="doughnut"  height="380"  [data]="trackdata" [options]="trackoptions" ></p-chart> -->
        </div>


        <div class="card col-12 md:col-12">
            <p-chart type="line" [data]="data3" height="380" [options]="options3"></p-chart>
        </div>
</div>


