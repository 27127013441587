import { Component } from '@angular/core';

@Component({
  selector: 'app-inline-profile',
  templateUrl: './app-profile.component.html',
  styleUrls: ['./app-profile.component.scss']
})
export class AppProfileComponent {

}
