import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ERPSHIPService } from '../erpship.service';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-rgfield-priority',
  templateUrl: './rgfield-priority.component.html',
  styleUrls: ['./rgfield-priority.component.scss'], providers: [MessageService],
})
export class RGFieldPriorityComponent implements OnInit {
  isDataLoaded = false;
  openNewDialogFlag = false;
  orgxfields: any[] = [];
  xfields: any[] = [];
  newPriority = { fields: [], IsActive: true }
  buttonTitle = '';
  currentRecord: any = {};


  rgPriority = { PriorityFieldList: [] }



  constructor(private router: Router, public messageService: MessageService, private xfservice: ERPSHIPService) {
    this.xfservice.processAuth().subscribe((data) => { }, error => {
      console.log("No token found")
      this.router.navigate(['']);
    });
  }

  ngOnInit(): void {

    this.xfservice.getRGPriority().subscribe((data: any) => {
      console.log('-----------------')
      console.log(data)
      if (data) {
        this.rgPriority = data
      }
      console.log('RG Priority is ')
      console.log(this.rgPriority)
      // console.log(JSON.stringify(this.rgPriority))
      // console.log(JSON.stringify(data));
      this.isDataLoaded = true;
      this.xfservice.getScreenTemplateByName('RoutingGuideFields').subscribe((data: any) => {
        this.xfields = data[0].details;
        this.orgxfields = data[0].details;
      })
      // console.log(JSON.stringify(data));

    })


  }
  openNew() {
    this.openNewDialogFlag = true;
    this.xfields = JSON.parse(JSON.stringify(this.orgxfields))
    this.buttonTitle = "Add";
  }
  savePriorityList() {

    this.xfservice.saveRGPriority(this.rgPriority).subscribe((data: any) => {
      this.messageService.add({
        key: 'tc',
        severity: 'success',
        summary: 'Template',
        detail: 'Routing Guide Priority fields are successfully saved.',
      });
      this.xfservice.getRGPriority().subscribe((data: any) => {
        this.rgPriority = data

      })
    })
  }
  AddToList() {
    if (this.buttonTitle == "Add") {
      var currentfields = JSON.parse(JSON.stringify(this.newPriority))
      this.xfields.forEach((element: any) => {
        var fieldName = '';
        element['fields'].forEach((item: any) => {
          if (item['priorityField'] == true) {
           // fieldName += element['fieldHeader'] + '->' + item['fieldName'];
            //currentfields.fields.push(fieldName as never);
            currentfields.fields.push(element['fieldHeader'] + '->' + item['fieldName'])
            currentfields.IsActive = true;

          }

        })


      })

      this.rgPriority.PriorityFieldList.push(Object.assign(currentfields) as never)
    }
    else if (this.buttonTitle == "Update") {
      // console.log(this.currentRecord)
       this.currentRecord.fields = [];
      // console.log(JSON.stringify(this.currentRecord))
      this.xfields.forEach((element: any) => {
        element['fields'].forEach((item: any) => {
          if (item['priorityField'] == true) {
            this.currentRecord.fields.push(element['fieldHeader'] + '->' + item['fieldName']);
            this.currentRecord.IsActive = true;
          }
        })
      })

     // console.log( this.currentRecord)

    }
    this.openNewDialogFlag=false;



  }
  edit(record: any) {
    console.log(JSON.stringify(record))
    this.currentRecord = record
    this.xfields = JSON.parse(JSON.stringify(this.orgxfields))
    this.buttonTitle = "Update";
    record.fields.forEach((item: any) => {
      var data = item;
      var fieldsArray = data.split('->');
      //alert( fieldsArray[0] + '   ' + fieldsArray[1])

      this.xfields.filter((celement: any) => {
        if (celement.fieldHeader == fieldsArray[0]) {
          celement.fields.filter((cfield: any) => {
            if (cfield.fieldName == fieldsArray[1]) {
              cfield['priorityField'] = true;
            }
          })
        }
      })



    })


    this.openNewDialogFlag = true;

    this.buttonTitle = "Update";

  }
}
