import { Component,ViewChild,Input, OnChanges, SimpleChanges } from '@angular/core';
import { ProductService } from './productservice';
import { AddressBookPopupComponent } from 'src/app/popup/address-book-popup/address-book-popup.component';

@Component({
  selector: 'app-dashboard-table',

  templateUrl: './dashboard-table.component.html',
  styleUrl: './dashboard-table.component.scss'
})
export class DashboardTableComponent implements OnChanges {
  products!: any[];
  @Input() shipment!: any;
  @ViewChild(AddressBookPopupComponent) popup: AddressBookPopupComponent;
  selectedProduct: any;
  selectedProductIndex: number | null = null;
  constructor(private productService: ProductService) {}

  ngOnInit() {
      this.productService.getProductsMini().then((data) => {
          this.products = data;
      });
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['shipment'] && this.shipment) {
      this.updateProductDate(this.shipment.ShipFrom,0)
      this.updateProductDate(this.shipment.ShipFrom,1)
    }
  };
  
  showPopup( index: number) {
    this.selectedProductIndex = index; // Store the index of the product
    this.popup.display = true;
  };

  handleAddressSelect(address: any) {
    this.updateProductDate(address,this.selectedProductIndex)
  };

  updateProductDate(data,index){
    for (let key in data) {
      // Check if the lowercase version of the key from data exists in the products object
      let lowerKey = key.toLowerCase();
      if (lowerKey in this.products[index]) {
        this.products[index][lowerKey] = data[key];
      }
      }
  };
  
}