<div class="card">
  <p-toolbar styleClass="p-mb-4">
    <ng-template pTemplate="right">
      <button pButton pRipple label="New" icon="pi pi-plus" class="p-button-primary p-mr-2"
        (click)="openNew()"></button>
    </ng-template>


  </p-toolbar>

  <p-table #dt [value]="xformslist" [(selection)]="selectedxforms" [rows]="10" [paginator]="true"
    [globalFilterFields]="['FormName']" [rowHover]="true" dataKey="id"
    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [showCurrentPageReport]="true">
    <ng-template pTemplate="header">
      <tr>
        <th style="width: 3rem">
          <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
        </th>
        <th pSortableColumn="id">Id <p-sortIcon field="id"></p-sortIcon></th>
        <th pSortableColumn="formHeader">
          Title <p-sortIcon field="formTitle"></p-sortIcon>
        </th>
        <th pSortableColumn="FormName">
          FormName <p-sortIcon field="FormName"></p-sortIcon>
        </th>
        <th pSortableColumn="IsActive">
          IsActive <p-sortIcon field="IsActive"></p-sortIcon>
        </th>



        <th></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-xformsobj>
      <tr>
        <td>
          <p-tableCheckbox [value]="xformsobj"></p-tableCheckbox>
        </td>
        <td>{{ xformsobj.details.id }}</td>
        <td>{{ xformsobj.details.formTitle }}</td>
        <td>{{ xformsobj.details.formName }}</td>
        <td>{{ xformsobj.details.isActive }}</td>


        <td>
          <button pButton pRipple icon="pi pi-pencil" class="p-button-rounded p-button-primary p-mr-2"
            (click)="editXform(xformsobj.details)"></button>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="summary">
      <div class="p-d-flex p-ai-center p-jc-between">
        In total there are {{ xformslist ? xformslist.length : 0 }} dynamic
        forms.
      </div>
    </ng-template>
  </p-table>

  <p-dialog [(visible)]="formDialog" [style]="{ width: '70%' }" header="Form Details" [modal]="true"
    styleClass="p-fluid">
    <ng-template pTemplate="content">
      <div class="card">
        <div class="col-15">
          <div class="p-fluid p-formgrid grid">
            <div class="field col-15 md:col-2">
              <span class="p-float-label">
                <input id="xformName" type="text" pInputText [(ngModel)]="newXformobject.formName" />
                <label for="xformName">Form Name</label>
              </span>
            </div>

            <div class="field col-12 md:col-2">
              <span class="p-float-label">
                <input id="FormTitle" type="text" pInputText [(ngModel)]="newXformobject.formTitle" />
                <label for="FormTitle">Form Title</label>
              </span>
            </div>

            <!-- <div class="field col-12 md:col-2" hidden="true">
                <span class="p-float-label">
                  <input
                    id="numberOfFieldsPerRow"
                    type="text"
                    pInputText
                    [(ngModel)]="newXformobject.numberOfFieldsPerRow"
                  />
                  <label for="xformName">No.of Fields per Row:</label>
                </span>
              </div> -->

            <div class="field col-12 md:col-2">
              <span class="p-float-label">
                <input id="Icon" type="text" pInputText [(ngModel)]="newXformobject.icon" />
                <label for="Icon">Icon</label>
              </span>
            </div>

          



            <div class="field col-15 md:col-2">
              <span class="p-float-label">
                <p-multiSelect [options]="allForms" [(ngModel)]="newXformobject.masterdata" optionLabel="name"
                  optionValue="code" display="chip" appendTo="body"></p-multiSelect>
                <label for="masterdata">Required Data</label>
              </span>
            </div>

            <div class="field col-15 md:col-2">
              <span class="p-float-label">
                <p-multiSelect [options]="allForms" [(ngModel)]="newXformobject['ParentForm']" optionLabel="name"
                  optionValue="code" display="chip" appendTo="body"></p-multiSelect>
                <label for="masterdata">Parent Form</label>
              </span>
            </div>

            <div class="field col-15 md:col-2">
              <span class="p-float-label">
                <p-dropdown [options]="[
                { name: 'MasterData', value: 'MasterData' },
                { name: 'Enumarations', value: 'Enumarations' },
                { name: 'SupplierData', value: 'SupplierData' }
               
              ]" [autoDisplayFirst]="false" [(ngModel)]="newXformobject.formgroupname" optionLabel="name"
                  optionValue="value" appendTo="body"></p-dropdown>

                  <label for="masterdata">Form group</label>
              </span>
            </div>

            <div class="field-checkbox">
              <p-checkbox [(ngModel)]="newXformobject.isActive" [binary]="true" inputId="binary"></p-checkbox>
              <label for="binary">Is Active</label>
            </div>

            <div class="field-checkbox">
              <p-checkbox [(ngModel)]="newXformobject['Independent']" [binary]="true" inputId="binary"></p-checkbox>
              <label for="binary">Is Independent Form</label>
            </div>



          </div>
        </div>

        <!-- <div class="col-15">
            <div class="card" >
             
              <form [formGroup]="fieldForm" (ngSubmit)="onFieldSubmit()">
                <div class="p-fluid p-formgrid grid"> -->
        <!-- <div class="field col-15 md:col-2">
                    <span class="p-float-label">
                      <input
                        pInputText
                        id="fieldName"
                        type="text"
                        formControlName="fieldName"
                      />
                      <label htmlFor="fieldName">Field Name</label>
                    </span>
                  </div> -->

        <!-- <div class="field col-15 md:col-2">
                    <span class="p-float-label">
                      <input
                        pInputText
                        id="fieldHeader"
                        type="text"
                        formControlName="fieldHeader"
                      />
                      <label htmlFor="fieldHeader">Field Header</label>
                    </span>
                  </div> -->

        <!-- <div class="field col-15 md:col-2">
                    <span class="p-float-label">
                      <p-dropdown
                        [options]="[
                          { name: 'Textbox', value: 'Textbox' },
                          { name: 'Dropdown', value: 'Dropdown' },
                          { name: 'Checkbox', value: 'Checkbox' },
                          { name: 'DateField', value: 'DateField' },
                          { name: 'Lookup', value: 'Lookup' }
                        ]"
                        [autoDisplayFirst]="false"
                        formControlName="fieldType"
                        optionLabel="name"
                        optionValue="value"
                      ></p-dropdown>
                      <label htmlFor="fieldType">UI Type</label>
                    </span>
                  
                  </div> -->
        <!-- <div class="field col-15 md:col-2">
                    <span class="p-float-label">
                      <p-dropdown
                        [options]="[
                          { name: 'Int', value: 'Int' },
                          { name: 'String', value: 'String' },
                          { name: 'Boolean', value: 'Boolean' },
                          { name: 'Lookup', value: 'Lookup' },
                          { name: 'date', value: 'date' }
                        ]"
                        [autoDisplayFirst]="false"
                        formControlName="dataType"
                        optionLabel="name"
                        optionValue="value"
                      ></p-dropdown>
                      <label htmlFor="dataType">Data Type</label>
                    </span>
                  </div> -->
        <!-- <div class="field col-15 md:col-1">
                    <span class="p-float-label">
                      <label htmlFor="isrequired" class="mr-3">Is Required</label>
                      <p-checkbox formControlName="isrequired" [binary]="true" inputId="binary" class="mr-3" >
                      </p-checkbox>
                     
                      
                    </span>
                  </div> -->
        <!--<div class="field col-15 md:col-2">
                    <span class="p-float-label">
                      <input
                        pInputText
                        id="errorMessage"
                        type="text"
                        formControlName="errorMessage"
                      />
                      <label htmlFor="errorMessage">Error Message</label>
                    </span>
                  </div>-->
        <!-- <div class="field col-15 md:col-1">
                    <button
                      pButton
                      pRipple
                      type="submit"
                      [label]="fieldOperationType"
                      class="p-button-primary p-mr-2"
                    ></button>
                  </div> -->
        <!-- </div>
              </form>
            </div> -->
        <!-- Filed Property  [rows]="5"
              [paginator]="true"  currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
              [showCurrentPageReport]="true"Table stat-->

        <!-- <p-table
              #ft
              [value]="newXformobject.fields"
              [rows]="20"
              responsiveLayout="stack"
              [scrollable]="true"
              scrollHeight="400px"
              [rowHover]="true"
              [reorderableColumns]="true"
              [paginator]="true"
              [globalFilterFields]="['fieldName']"
            >
              <ng-template pTemplate="header">
                <tr>
                  <th style="width: 3rem"></th>
                  <th pSortableColumn="fieldHeader">
                    Field Header <p-sortIcon field="fieldHeader"></p-sortIcon>
                  </th>
                  <th pSortableColumn="fieldName">
                    Field Name <p-sortIcon field="fieldName"></p-sortIcon>
                  </th>
                  <th pSortableColumn="fieldType">
                    Field Type <p-sortIcon field="fieldType"></p-sortIcon>
                  </th>
                  <th pSortableColumn="dataType">
                    Data Type <p-sortIcon field="dataType"></p-sortIcon>
                  </th>
                  <th pSortableColumn="isRequired">
                    Is Required <p-sortIcon field="isRequired"></p-sortIcon>
                  </th>
                  <th pSortableColumn="errorMessage">
                    Error Message <p-sortIcon field="errorMessage"></p-sortIcon>
                  </th>
  
                  <th></th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-xfield let-index="rowIndex">
                <tr [pReorderableRow]="index">
                  <td>
                    <span class="pi pi-bars"></span>
                  </td>
                  <td>{{ xfield.fieldHeader }}</td>
                  <td>{{ xfield.fieldName }}</td>
                  <td>{{ xfield.fieldType }}</td>
                  <td>{{ xfield.dataType }}</td>
                  <td>{{ xfield.isrequired }}</td>
                  <td>{{ xfield.errorMessage }}</td>
                  <td>
                    <button
                      pButton
                      pRipple
                      icon="pi pi-pencil"
                      class="p-button-rounded p-button-primary p-mr-2"
                      (click)="editxField(xfield)"
                    ></button>
                  </td>
                </tr>
              </ng-template>
            </p-table> -->

        <!-- Field Property Table end-->
        <!-- </div> -->
      </div>


    </ng-template>

    <ng-template pTemplate="footer">
      <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="hideDialog()"></button>
      <button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text" (click)="saveForm()"></button>
    </ng-template>
  </p-dialog>

  <p-confirmDialog [style]="{ width: '450px' }"></p-confirmDialog>
</div>