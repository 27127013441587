import { Component,Input, OnChanges, SimpleChanges } from '@angular/core';
import fedExFields from "./additional-info-details-data/fedExFields";
import upsFields from "./additional-info-details-data/upsFields";
import uspsFields from "./additional-info-details-data/uspsFields";
import ltlGeneric from "./additional-info-details-data/ltlGeneric";
import { ERPSHIPService } from '../../../../app/erpship.service';
import { GetAllDataService } from 'src/app/services/ship-now/get-all-data.service';

@Component({
  selector: 'app-routing-billing-detail',
  templateUrl: './routing-billing-detail.component.html',
  styleUrl: './routing-billing-detail.component.scss'
})
export class RoutingBillingDetailComponent implements OnChanges {
  @Input() shipment!: any;
  @Input() refreshFlag: boolean = false;
  todayDate: Date = new Date();
  Original_LocationMasterData: any;
  carrierData: any [] = [];
  allCarrierServicesData:any [] = [];
  carrierServiceData : any []=[];
  detailsData : any = {};
  selectedCarrierData : any []=[];
  
  
  selectedShipMethod: string = "";
  selectedServiceType: string = "";
  carrierId: string = "";
  selectedPaymentType: string = "";
  selectedCostCenter: string = "";
  poNo: string = "";
  invoiceNo: string = "";
  popupVisible: boolean = false;
  popupFields : any = [];
  popupHeader : string = "";
  popupCls : string="";
  popupSubcontainer : any = {};
  additionalOptionsFormData : any = {};

  constructor( private xfservice: ERPSHIPService,private dataService:GetAllDataService) {
    this.todayDate = new Date();
    this.detailsData = {
      'UPS': upsFields,
      'FedEx':fedExFields,
      'USPS':uspsFields,
      'YRC':ltlGeneric
    };
    this.xfservice.getLocationMasterData().subscribe((data) => {
      this.Original_LocationMasterData = JSON.parse(JSON.stringify(data));
      this.carrierData = this.Original_LocationMasterData?.carriers;
      this.allCarrierServicesData = this.Original_LocationMasterData?.carrierservices;
      this.selectedCarrierData = this.carrierData.find((rec)=>rec.CarrierName == "FedEx");
      this.selectedCarrierData = fedExFields;
    });
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.dataService.setRoutingAndBillingData(this.shipment);
    if (changes['shipment'] && this.shipment) {
      const carrierName = this.shipment['CarrierDetails']?.['Carrier'];
      const selectedCarrier = this.carrierData.find(item => item.CarrierName === carrierName);
      const paymentTypeValue = this.shipment['CarrierDetails']?.['PaymentType'];
      const selectedPayment = this.paymentTypes.find(item => item.value === paymentTypeValue);

      this.shipment.CarrierDetails.Carrier = selectedCarrier;
      this.shipment.CarrierDetails.PaymentType = selectedPayment;
      this.onCarrierChange(selectedCarrier)
    this.shipment.CarrierDetails.ShippingAccount=this.shipment['CarrierDetails']['ShippingAccount']
    this.shipment.CarrierDetails.Note=this.shipment['CarrierDetails']['Note']
  }
  if (changes['refreshFlag'] && changes['refreshFlag'].currentValue) {
    this.shipment.CarrierDetails.Note=''
    this.shipment.CarrierDetails.Reference2=''
    this.shipment.CarrierDetails.Reference1=''
    this.shipment.CarrierDetails.PaymentType=''
    this.shipment.CarrierDetails.ShippingAccount=''
    this.shipment.CarrierDetails.ServiceName=''
    this.shipment.CarrierDetails.Carrier=''
    this.selectedCarrierData=[]
    this.carrierServiceData=[]
  }
}

 onCarrierChange(event){
    const value = event?.value?.CarrierName || event.CarrierName;
    this.selectedCarrierData = this.detailsData[value] || [];
    this.carrierServiceData = this.allCarrierServicesData.filter((rec)=>{
      return rec.CarrierName == value;
    });
  };
  onEditClick(event: any,fieldData: any){
    console.log(event,fieldData);
    this.popupHeader = fieldData.popupTitle
    this.popupVisible = true;
    this.popupFields = fieldData.popupFields;
    this.popupCls = fieldData.popupCls;
    this.popupSubcontainer = fieldData.formContainerRequirements || {
      cls:"",
      containerNames:[]
    };
  };
  onPopUpHide(event:any){
    this.popupHeader = "";
    this.popupVisible = false;
    this.popupFields = [];
    this.popupCls = "";
  }
  onAddDetailsDropdownChange(event,data:any){
   console.log(data);
  };
  onAdditioanlOptInputChange(evt,data:any){
    console.log(evt,data);
  }
  shipMethods = [
    { label: 'Ship Method 1', value: 'Ship Method 1' },
    { label: 'Ship Method 2', value: 'Ship Method 2' }
  ];

  serviceTypes = [
    { label: 'Service 1', value: 'Service 1' },
    { label: 'Service 2', value: 'Service 2' }
  ];

  paymentTypes = [
    { label: 'Sender', value: 'Sender' },
    { label: 'Receipient', value: 'Receipient' },
    { label: 'Third Party', value: 'Third Party' },
    { label: 'Collect', value: 'Collect' }
  ];

  costCenters = [
    { label: 'Cost Centre 1', value: 'Cost Centre 1' },
    { label: 'Cost Centre 2', value: 'Cost Centre 2' }
  ];

  ngOnInit() {
    this.shipment = {
      CarrierDetails: {
        Carrier: "",
        ServiceName: "",
        PaymentType: "",
        ShippingAccount: "",
        BillingAccount: "",
        BillingCountry: "",
        BillingZipCode: "",
        Reference1: "",
        Reference2: "",
        Reference3: "",
        Reference4: "",
        Note: "",
        UserId: "",
        Password: "",
        AccessKey: ""
      }
    }
  }
}
