import { Component, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { AppMainComponent } from './app-main/app-main.component';
import { DashBoardComponent } from './dash-board/dash-board.component';
import { DashBoardsComponent } from './dash-boards/dash-boards.component';
import { DynamicformsComponent } from './dynamicforms/dynamicforms.component';
import { FormOperationsComponent } from './form-operations/form-operations.component';
import { XShipComponent } from './xship/xship.component';
import { ShipRequestComponent } from './ship-request/Ship-Request.component';
import { CarrierSpecialServiceConfigComponent } from './carrier-special-service-config/carrier-special-service-config.component';
import { shipComponent } from './ship/ship.component';
import { ManifestComponent } from './manifest/manifest.component';
import { ConsolidationComponent } from './consolidation/consolidation.component';
import { ConsolidationTemplateComponent } from './consolidation-template/consolidation-template.component';
import { SloginComponent } from './slogin/slogin.component';
import { RoutingguideComponent } from './routingguide/routingguide.component';
import { RoutingGuideParametersComponent } from './routing-guide-parameters/routing-guide-parameters.component';
import { RGFieldPriorityComponent } from './rgfield-priority/rgfield-priority.component';
import { NewroutingruleComponent } from './newroutingrule/newroutingrule.component';
import { PackshipComponent } from './packship/packship.component';
import { PackShipV2Component } from './pack-ship-v2/pack-ship-v2.component';
import { RoutingGuideDefaultsComponent } from './routing-guide-defaults/routing-guide-defaults.component';
import { ShipNowComponent } from './ship-now/ship-now.component';
// import { ShipRequestComponent } from './Ship-Request/Ship-Request.component';
import { ProductService } from './pages/dashboard/dashboard-table/productservice';
import { ShipRequestModalComponent } from './ship-request-modal/ship-request-modal.component';
import { TrackShipmentComponent } from './track-shipment/track-shipment.component';
import { CopilotComponent } from './copilot/copilot.component';

const routes: Routes = [
  { path: '', component: LoginComponent },
  { path: 'slogin', component: SloginComponent },
  {
    path: 'home',
    component: AppMainComponent,
    children: [
      {
        path: 'DashBoard',
        component: DashBoardComponent,
        pathMatch: 'full',
      },
      {
        path: 'DashBoards',
        component: DashBoardsComponent,
        pathMatch: 'full',
      },
      {
        path: 'DynamicForms',
        component: DynamicformsComponent,
        pathMatch: 'full',
      },
      {
        path: 'formoperations/:id',
        component: FormOperationsComponent,
        pathMatch: 'full',
      },
      {
        path: 'ShipNow',
        component: ShipNowComponent,
        pathMatch: 'full',
      },
      { path: 'Manifest', component: ManifestComponent, pathMatch: 'full' },
      { path: 'Ship', component: shipComponent, pathMatch: 'full' },
      { path: 'PackShip', component: PackshipComponent, pathMatch: 'full' },
      { path: 'PackShipV2', component: PackShipV2Component, pathMatch: 'full' },
      // { path: 'ShipNow',    component: ShipNowComponent,   pathMatch: 'full',       },
      { path: 'xShip', component: XShipComponent, pathMatch: 'full' },
      { path: 'xShip/:id', component: XShipComponent, pathMatch: 'full' },
      {
        path: 'shiprequest',
        component: ShipRequestComponent,
        pathMatch: 'full',
      },
      {
        path: 'SpecialService',
        component: CarrierSpecialServiceConfigComponent,
        pathMatch: 'full',
      },
      {
        path: 'consolidation',
        component: ConsolidationComponent,
        pathMatch: 'full',
      },
      {
        path: 'consolidationTemplate',
        component: ConsolidationTemplateComponent,
        pathMatch: 'full',
      },
      {
        path: 'routingguide',
        component: RoutingguideComponent,
        pathMatch: 'full',
      },
      {
        path: 'RoutingGuideParameters',
        component: RoutingGuideParametersComponent,
        pathMatch: 'full',
      },
      {
        path: 'RoutingGuideDefaults',
        component: RoutingGuideDefaultsComponent,
        pathMatch: 'full',
      },
      {
        path: 'RGFieldPriority',
        component: RGFieldPriorityComponent,
        pathMatch: 'full',
      },
      {
        path: 'newroutingrule',
        component: NewroutingruleComponent,
        pathMatch: 'full',
      },
      {
        path: 'newroutingrule/:id',
        component: NewroutingruleComponent,
        pathMatch: 'full',
      },
      { path: 'shipnow', component: ShipNowComponent, pathMatch: 'full' },
      {
        path: 'shiprequestmodal',
        component: ShipRequestModalComponent,
        pathMatch: 'full',
      },
      {
        path: 'TrackShipment',
        component: TrackShipmentComponent,
        pathMatch: 'full',
      },
      {
        path: 'shiprequestmodal/:id',
        component: ShipRequestModalComponent,
        pathMatch: 'full',
      },
      {
        path: 'copilot',
        component: CopilotComponent,
        pathMatch: 'full',
      },
      //  {
      //    path: 'Editor',SpecialService
      //    component: EditorComponent,
      //    pathMatch: 'full',
      //  },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false })],
  exports: [RouterModule],
  providers: [ProductService],
})
export class AppRoutingModule {}
