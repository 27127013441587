import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { MessageService } from 'primeng/api';

import { saveAs } from 'file-saver-es';
import { Router, NavigationEnd } from '@angular/router';
import {
  FormGroup,
  FormControl,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { ERPSHIPService } from '../erpship.service';

@Component({
  selector: 'app-form-operations',
  templateUrl: './form-operations.component.html',
  styleUrls: ['./form-operations.component.scss'],providers: [MessageService]
})
export class FormOperationsComponent implements OnInit {
  @ViewChild('dt') dt: any;

  selectedRecords: any[] = [];
  xformtitle:any;
  items = [
    {
      label: 'Selected Records', icon: 'pi pi-check', command: () => {

        this.downloadSelectedRecordsFile();
      }
    },
    {
      label: 'All Records', icon: 'pi pi-list', command: () => {
        this.downloadFile();
      }
    },
    // {label: 'Angular.io', icon: 'pi pi-info', url: 'http://angular.io'},
    // {separator:true},
    // {label: 'Setup', icon: 'pi pi-cog', routerLink: ['/setup']}
  ];


  isDataLoaded: boolean = false;

  formMasterData: any = {};
  orginalFormMasterData:any = {};
  sourceKeys: any[] = [];

  bindindDialog: boolean = false;
  bindingFieldData: any[] = [];

  formOperationType = 'Save';

  formDialogNew: boolean = false;
  id: number = -1;

  xformName: string = '';
  xformMetaData: any;
  xfields: any[] = [];
  xcolumns: any[] = [];
  formDialog: boolean = false;
  formDialog_Test: boolean = false;
  currentFormModel: any;

  formRecords: any[] = [];
  xFormRecords: any[] = [];
  masterForm:any;
  filterForm:any;
  showReorderTable=false;
  showNewField=false;
  newField='';
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private http: HttpClient,
    private xfservice: ERPSHIPService,
    private messageService: MessageService
  ) {
    console.log("calling constructor");
    // checking login JWT
    this.xfservice.processAuth().subscribe((data) => {
      //------------------checking parameter --------------------//
      this.route.params.subscribe((params) => {
        this.id = +params['id'];
        this.xfservice.getFormMasterData(this.id).subscribe((data) => {
          this.formMasterData = data;
          console.log('Form MAster Data is : ')
          console.log(JSON.stringify(data))
          this.orginalFormMasterData = Object.assign({}, data);
//alert(JSON.stringify( data))

          Object.keys(data).forEach(keyName => {

            let keydata:any = {};
            keydata['name'] = keyName;

            this.sourceKeys.push(keydata)
          });

          console.log(JSON.stringify(this.sourceKeys))
          // ------------------------- GETTING form MASTER DATA -------------------------//
          this.masterForm = new FormGroup({});
          this.xfservice.getxFormById(this.id).subscribe((data) => {
            this.xformMetaData = data;
            this.xformName = this.xformMetaData[0].details.formName;
            this.xfields = this.xformMetaData[0].details.fields;
            this.xcolumns = [];
            this.xfields.forEach(item => {
              this.xcolumns.push(item.fieldName);
            });
            console.log(this.xcolumns)
            this.formRecords = [];
            this.masterForm = new FormGroup({});
            for (const formField of this.xfields) {
              this.masterForm.addControl(
                formField.fieldName,
                new FormControl('', this.getValidator(formField)),

              );
            }


            this.filterForm = new FormGroup({});
            for (const formField of this.xfields) {
              if(formField['FilterVisible']){
                
                this.filterForm.addControl(
                  formField.fieldName,
                  new FormControl('', null),
  
                );
              }
              
             }
// console.log(this.masterForm)
// console.log(this.filterForm)
             // console.log(JSON.stringify(this.xfields))
            //this.masterForm.controls[0].setValidators()
            // new FormControl('', this.getValidator(formField))
            //this.getFormRecords();  new FormControl('', [Validators.required]),

            this.xfservice
              .getxFormRecordsByFromName(this.xformName)
              .subscribe((data) => {
                this.formRecords = data;
                this.xFormRecords = [];
                this.formRecords.forEach(item => {
                  this.xFormRecords.push(item.details);
                })
                this.isDataLoaded = true;
              });

            // this.isDataLoaded = true;
          });


        }, error => {
          console.log("Form MAster Data Error: " + error)
        });



        //this.getFormDetails();
      });

      //--------------------------------------------------------//
    }, error => {
      this.router.navigate(['']);
    });

  }

  ngOnInit(): void {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    console.log("INIT : " + this.id)
  }

  ngOnChanges(): void {
    alert("ON CHANGE")
  }


  private getValidator(formField: any): ValidatorFn[] {


    let validations = [];
    console.log(formField.fieldName + '  ---> ' + formField.isrequired)
    if (formField.isrequired == true) {
      // console.log(formField.fieldName + '  ---> Required Set')
      validations.push(Validators.required);
    }



    if (formField.minLength > 0)
      validations.push(Validators.minLength(formField.minLength))


    if (formField.maxLength > 0)
      validations.push(Validators.maxLength(formField.maxLength))


    if (formField.emailValidate)
      validations.push(Validators.email)

    //console.log( formField.fieldName + " : -> "  +    validations)

    return validations;








  }

  getFormRecords(): void {
    this.xfservice
      .getxFormRecordsByFromName(this.xformName)
      .subscribe((data) => {
        this.formRecords = data;
        this.xFormRecords = [];
        this.formRecords.forEach(item => {
          this.xFormRecords.push(item.details);
        })
        //this.isDataLoaded = true;
      });


  }

  getFormDetails(): void {

    this.masterForm = new FormGroup({});
    this.xfservice.getxFormById(this.id).subscribe((data) => {
      this.xformMetaData = data;
      this.xformName = this.xformMetaData[0].details.formName;
      this.xfields = this.xformMetaData[0].details.fields;
      this.xcolumns = [];
      this.xfields.forEach(item => {
        this.xcolumns.push(item.fieldName);
      });
      this.formRecords = [];
      this.masterForm = new FormGroup({});
      for (const formField of this.xfields) {
        this.masterForm.addControl(
          formField.fieldName,
          new FormControl('', this.getValidator(formField))
        );
      }

      this.getFormRecords();

      this.isDataLoaded = true;
    });
  }



  openNew(): void {
    // this.getFormDetails();
    // Object.keys(this.masterForm.controls).forEach(key => {
    //   console.log("key: " + key)
    // });

    this.formOperationType = 'Save';
    this.masterForm.reset();
    this.formDialogNew = true;


  }



  async editRecord(event: any) {
     
    
//var code = "this.xfields.forEach(item => {if (item.fieldType == 'Dropdown') {  this.processChange(item.fieldName, item.RelatedFields, item.ConditionFieldName, item.BindingFields)} })"

    this.masterForm.reset();
    this.formOperationType = 'Update';
    this.currentFormModel = event;
    this.masterForm.patchValue(event);

//eval(code);

   
      this.xfields.forEach(item => {
     
         if (item.fieldType == 'Dropdown') {
           this.processChange(item.fieldName, item.RelatedFields, item.ConditionFieldName, item.BindingFields)
         }
         this.formDialogNew = true;
      
     })



  }

  onFormSubmit(): void {
    this.xfservice
      .saveRecordData(this.xformName, this.masterForm.value)
      .subscribe((data) => {
        this.messageService.add({
          key: 'tc',
          severity: 'success',
          summary: 'Success',
          detail: ' Data Successfully Saved.',
        });
        this.formDialogNew = false;
        this.getFormRecords();

      });
  }

  onFilterSubmit(event: any, element: any):void{
    var filters= this.filterForm.value;
    var condition='';
    var conditions:any=[];
    var keys=Object.keys(filters);
    console.log(keys)
    keys.forEach((item,index)=>{
     
      var fields: any =[];
     
        if(filters[item]!=''){
         
          conditions.push("details->>'" + item +"'='" + filters[item] +"'");
        
        }
      
    })

    var query = 'select * from ' +  this.xformName  + ' where ' ;

    query='';
    conditions.forEach((conditionItem:any, index:any)=>{
      if (index == conditions.length-1)
      {
        query= query + conditions[index]
      }else
      {
        query= query + conditions[index] + ' and '
      }
    })
    console.log('Consition is :'+ query)

    this.xfservice.getFilterDataByFromName(this.xformName, query).subscribe((data)=>{
      this.formRecords = data;
        this.xFormRecords = [];
        this.formRecords.forEach(item => {
          this.xFormRecords.push(item.details);
        })
        element.hide(event);
    })

  }

  // hideDialog_Test(): void {
  //   this.formDialog_Test = false;
  // }

  // hideDialog(): void {
  //   this.formDialog = false;
  // }

  saveRecord(): void {
    this.xfservice
      .saveRecordData(this.xformName, this.masterForm.value)
      .subscribe((data) => {
       


        console.log(data);
      });
  }



  downloadSelectedRecordsFile() {

    let tdata: any[] = [];
    this.selectedRecords.forEach(item => {
      tdata.push(item);
    })

    let columns: any[] = [];
    this.xfields.forEach(item => {
      columns.push(item.fieldName);
    })

    //alert (JSON.stringify(this.formRecords[0].details))
    const header = Object.keys(this.formRecords[0].details);

    console.log("headers: " + header)
    let csv = tdata.map(row => header.map(fieldName => JSON.stringify(row[fieldName], null)).join(','));
    csv.unshift(header.join(','));
    let csvArray = csv.join('\r\n');

    var blob = new Blob([csvArray], { type: 'text/csv' })
    saveAs(blob, this.xformName + ".csv");
  }



  downloadFile() {

    let tdata: any[] = [];
    this.formRecords.forEach(item => {
      tdata.push(item.details);
    })

    let columns: any[] = [];
    this.xfields.forEach(item => {
      columns.push(item.fieldName);
    })




    const header = Object.keys(tdata[0]);

    console.log("headers: " + header)
    let csv = tdata.map(row => header.map(fieldName => JSON.stringify(row[fieldName], null)).join(','));
    csv.unshift(header.join(','));
    let csvArray = csv.join('\r\n');

    var blob = new Blob([csvArray], { type: 'text/csv' })
    saveAs(blob, this.xformName + ".csv");


  }


  // PRODUCT GLOBAL FILTER START
  applyFilterGlobal($event: any, stringVal: any): void {

    this.dt.filterGlobal(($event.target as HTMLInputElement).value, stringVal);
  }


  //Table properties
  // TABLE PROPERTIES 

  tableProperties_dialog: boolean = false;
  openTableProperties(tableproperties: any, tname: any): void {

    /* this.currentSelectedTableData = tableproperties
     this.currentSelectedTable = tname;*/
    this.tableProperties_dialog = true
  }

  getUserDetails(): any {
    let cookies = document.cookie;
    let cparts = cookies.split('=');
    var udetails = JSON.parse(cparts[1])
    return udetails.Role;
  }


  SaveTemplate() {

    this.xformMetaData[0].details.fields= this.xfields;
    console.log('@Save : ' + JSON.stringify(this.xformMetaData[0].details) )
    this.xfservice
      .addForm(this.xformName, this.xformMetaData[0].details)
      .subscribe((data) => {

        this.tableProperties_dialog = false;
        this.property_dialog = false;
        this.messageService.add({
          key: 'tc',
          severity: 'success',
          summary: 'Success',
          detail: ' Template Successfully Saved.',
        });

      }, err => { alert('Error: ' + err) });
  }

  // form field properties

  currentSelctedField: any = [];
  selectedField: any = '';
  propertyFieldSelected: boolean = false;
  property_dialog: boolean = false;
  fieldUITypes = [
    { name: 'Textbox', code: 'Textbox' },
    { name: 'Checkbox', code: 'Checkbox' },
    { name: 'Dropdown', code: 'Dropdown' },
    { name: 'ColorPicker', code: 'ColorPicker' },
  ];

  fieldDataTypes = [
    { name: 'text', code: 'text' },
    { name: 'number', code: 'number' },
  ];
  iconPositions = [
    { name: 'left', code: 'left' },
    { name: 'right', code: 'right' },
  ];
  openBlockProperties(): void {
    this.property_dialog = true;
  }


  loadFieldProperties(): void {
    var propertyField = this.xfields.filter(item => item.fieldName == this.selectedField);
    console.log('propertyField---->' + JSON.stringify(propertyField))
    this.currentSelctedField = propertyField[0];
  }


  // modelchange(event) {
  //   console.log('Model Change : ' + event)
  // }

  //droipdown change logic
  processChange(sourcefield: any, effectedfields: string, mappingfield: string, bfields: any) {

    //alert ( "Process Change is calling")

    if (typeof effectedfields !== 'undefined') {
      var ef_fields = [];
      if (effectedfields.indexOf(",") > 0) {
        ef_fields = effectedfields.split(",");
      } else {
        ef_fields.push(effectedfields);
      }
      try{
        ef_fields.forEach(item => {
          this.formMasterData[item] = this.orginalFormMasterData[item].filter((mf: any) => mf[mappingfield] === this.masterForm.value[sourcefield])
        });
      }catch(e){}
      
    }



    if (bfields) {


      var sourcefielddata = this.xfields.filter(item => item.fieldName == sourcefield);
      var currentselectedDPRecord = this.formMasterData[sourcefielddata[0].source].filter((item:any) => item[sourcefielddata[0].optionValue] == this.masterForm.value[sourcefield])
      var currentDPRecord = currentselectedDPRecord[0];
      bfields.forEach((item: any) => {
        if (item.sourceField) {
          this.masterForm.value[item.targetField] = currentDPRecord[item.sourceField];
          this.masterForm.patchValue(this.masterForm.value);
        }
      })
    }
  }






  getDataSourceList() {
    var sourceList: any[] = [];

  // alert(JSON.stringify(this.orginalFormMasterData))
    // this.orginalFormMasterData.masterdata.forEach((keyName:any) => {
    //   let keydata:any = {};
    //    keydata['name'] = keyName;
    //    sourceList.push(keydata)
    // });
    

    
     Object.keys(this.orginalFormMasterData).forEach(keyName => {
       let keydata:any = {};
       keydata['name'] = keyName;
       sourceList.push(keydata)
     });

    return sourceList;
  }
  getOptioneNames() {
//console.log("Master Data : " + JSON.stringify(this.orginalFormMasterData))
    try{
      if (this.currentSelctedField.source !== undefined) {
        var opNames: any[] = [];
        Object.keys(this.orginalFormMasterData[this.currentSelctedField.source][0]).forEach(keyName => {
          let keydata:any = {};
          keydata['name'] = keyName;
          opNames.push(keydata)
        });
        return opNames;
      } else {
        return [];
      }
    }catch(ex){
      return [];
    }
    
  }

  getOptioneValues() {
    try{
      if (this.currentSelctedField.source !== undefined) {
        var opNames: any[] = [];
        Object.keys(this.orginalFormMasterData[this.currentSelctedField.source][0]).forEach(keyName => {
          let keydata:any = {};
          keydata['name'] = keyName;
          opNames.push(keydata)
        });
        return opNames;
      } else {
        return [];
      }
    }catch(ex){
      return [];
    }
    
  }


  getSourceFieldsList() {
    if (this.currentSelctedField.source !== undefined) {
      var opNames: any[] = [];
      Object.keys(this.orginalFormMasterData[this.currentSelctedField.source][0]).forEach(keyName => {
        let keydata:any = {};
        keydata['name'] = keyName;
        opNames.push(keydata)
      });
      return opNames;
    } else {
      return [];
    }
  }




  openBindingFieldForm() {
    if (this.currentSelctedField.BindingFields !== undefined) {
      this.bindingFieldData = this.currentSelctedField.BindingFields;
      this.bindindDialog = true;
    }
    else {
      this.bindingFieldData = [];
      this.xfields.forEach(item => {
        let rec = { sourceField: '', targetField: '' };
        rec.targetField = item.fieldName,
          rec.sourceField = '';

        this.bindingFieldData.push(rec);
      })
      this.bindindDialog = true;
    }





  }


  SaveBindingFieldData() {
    this.currentSelctedField.BindingFields = this.bindingFieldData;
    this.bindindDialog = false;

  }

  // Adding New Control
  SaveControl(){
    this.xfields.push({"fieldName":this.newField ,"UDF":true})
    this.showNewField=false
    this.newField=''
   // this.xfields[fieldName]=this.newField
  }
  deleteField(){
    alert(this.selectedField)
    this.xfields=this.xfields.filter((item)=>item.fieldName!=this.selectedField)

    console.log(this.xfields)
  }

}



