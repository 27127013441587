export const currency = [
    { label: "AFN", value: "AFN" },
    { label: "ALL", value: "ALL" },
    { label: "DZD", value: "DZD" },
    { label: "USD", value: "USD" },
    { label: "EUR", value: "EUR" },
    { label: "AOA", value: "AOA" },
    { label: "XCD", value: "XCD" },
    { label: "NOK", value: "NOK" },
    { label: "XCD", value: "XCD" },
    { label: "ARS", value: "ARS" },
    { label: "AMD", value: "AMD" },
    { label: "AWG", value: "AWG" },
    { label: "AUD", value: "AUD" },
    { label: "EUR", value: "EUR" },
    { label: "AZN", value: "AZN" },
    { label: "EUR", value: "EUR" },
    { label: "BSD", value: "BSD" },
    { label: "BHD", value: "BHD" },
    { label: "BDT", value: "BDT" },
    { label: "BBD", value: "BBD" },
    { label: "BYR", value: "BYR" },
    { label: "EUR", value: "EUR" },
    { label: "BZD", value: "BZD" },
    { label: "XOF", value: "XOF" },
    { label: "BMD", value: "BMD" },
    { label: "BTN", value: "BTN" },
    { label: "BOB", value: "BOB" },
    { label: "ANG", value: "ANG" },
    { label: "ANG", value: "ANG" },
    { label: "BAM", value: "BAM" },
    { label: "BWP", value: "BWP" },
    { label: "NOK", value: "NOK" },
    { label: "BRL", value: "BRL" },
    { label: "USD", value: "USD" },
    { label: "BND", value: "BND" },
    { label: "BGN", value: "BGN" },
    { label: "XOF", value: "XOF" },
    { label: "BIF", value: "BIF" },
    { label: "XOF", value: "XOF" },
    { label: "KHR", value: "KHR" },
    { label: "XAF", value: "XAF" },
    { label: "CAD", value: "CAD" },
    { label: "CVE", value: "CVE" },
    { label: "KYD", value: "KYD" },
    { label: "XAF", value: "XAF" },
    { label: "XAF", value: "XAF" },
    { label: "CLP", value: "CLP" },
    { label: "RMB", value: "RMB" },
    { label: "AUD", value: "AUD" },
    { label: "AUD", value: "AUD" },
    { label: "COP", value: "COP" },
    { label: "KMF", value: "KMF" },
    { label: "XAF", value: "XAF" },
    { label: "CDF", value: "CDF" },
    { label: "NZD", value: "NZD" },
    { label: "CRC", value: "CRC" },
    { label: "HRK", value: "HRK" },
    { label: "CUP", value: "CUP" },
    { label: "ANG", value: "ANG" },
    { label: "EUR", value: "EUR" },
    { label: "CZK", value: "CZK" },
    { label: "DKK", value: "DKK" },
    { label: "DJF", value: "DJF" },
    { label: "XCD", value: "XCD" },
    { label: "DOP", value: "DOP" },
    { label: "USD", value: "USD" },
    { label: "USD", value: "USD" },
    { label: "EGP", value: "EGP" },
    { label: "SVC", value: "SVC" },
    { label: "XAF", value: "XAF" },
    { label: "ERN", value: "ERN" },
    { label: "EEK", value: "EEK" },
    { label: "ETB", value: "ETB" },
    { label: "EUR", value: "EUR" },
    { label: "FKP", value: "FKP" },
    { label: "DKK", value: "DKK" },
    { label: "FJD", value: "FJD" },
    { label: "EUR", value: "EUR" },
    { label: "EUR", value: "EUR" },
    { label: "EUR", value: "EUR" },
    { label: "EUR", value: "EUR" },
    { label: "XAF", value: "XAF" },
    { label: "GMD", value: "GMD" },
    { label: "GEL", value: "GEL" },
    { label: "EUR", value: "EUR" },
    { label: "GHC", value: "GHC" },
    { label: "GIP", value: "GIP" },
    { label: "EUR", value: "EUR" },
    { label: "DKK", value: "DKK" },
    { label: "XCD", value: "XCD" },
    { label: "EUR", value: "EUR" },
    { label: "USD", value: "USD" },
    { label: "GTQ", value: "GTQ" },
    { label: "GBP", value: "GBP" },
    { label: "GNF", value: "GNF" },
    { label: "XOF", value: "XOF" },
    { label: "GYD", value: "GYD" },
    { label: "HTG", value: "HTG" },
    { label: "AUD", value: "AUD" },
    { label: "EUR", value: "EUR" },
    { label: "EUR", value: "EUR" },
    { label: "HNL", value: "HNL" },
    { label: "HKD", value: "HKD" },
    { label: "HUF", value: "HUF" },
    { label: "ISK", value: "ISK" },
    { label: "INR", value: "INR" },
    { label: "IDR", value: "IDR" },
    { label: "IRR", value: "IRR" },
    { label: "IQD", value: "IQD" },
    { label: "EUR", value: "EUR" },
    { label: "GBP", value: "GBP" },
    { label: "ILS", value: "ILS" },
    { label: "EUR", value: "EUR" },
    { label: "XOF", value: "XOF" },
    { label: "JMD", value: "JMD" },
    { label: "JPY", value: "JPY" },
    { label: "GBP", value: "GBP" },
    { label: "JOD", value: "JOD" },
    { label: "KZT", value: "KZT" },
    { label: "KES", value: "KES" },
    { label: "AUD", value: "AUD" },
    { label: "KPW", value: "KPW" },
    { label: "KRW", value: "KRW" },
    { label: "KWD", value: "KWD" },
    { label: "KGS", value: "KGS" },
    { label: "LAK", value: "LAK" },
    { label: "LVL", value: "LVL" },
    { label: "LBP", value: "LBP" },
    { label: "LSL", value: "LSL" },
    { label: "LRD", value: "LRD" },
    { label: "LYD", value: "LYD" },
    { label: "LYD", value: "LYD" },
    { label: "CHF", value: "CHF" },
    { label: "LTL", value: "LTL" },
    { label: "EUR", value: "EUR" },
    { label: "MOP", value: "MOP" },
    { label: "MKD", value: "MKD" },
    { label: "MGA", value: "MGA" },
    { label: "MWK", value: "MWK" },
    { label: "MYR", value: "MYR" },
    { label: "MVR", value: "MVR" },
    { label: "XOF", value: "XOF" },
    { label: "EUR", value: "EUR" },
    { label: "USD", value: "USD" },
    { label: "EUR", value: "EUR" },
    { label: "MRO", value: "MRO" },
    { label: "MUR", value: "MUR" },
    { label: "EUR", value: "EUR" },
    { label: "MXN", value: "MXN" },
    { label: "USD", value: "USD" },
    { label: "MDL", value: "MDL" },
    { label: "EUR", value: "EUR" },
    { label: "MNT", value: "MNT" },
    { label: "EUR", value: "EUR" },
    { label: "XCD", value: "XCD" },
    { label: "MAD", value: "MAD" },
    { label: "MZN", value: "MZN" },
    { label: "MMK", value: "MMK" },
    { label: "NAD", value: "NAD" },
    { label: "AUD", value: "AUD" },
    { label: "NPR", value: "NPR" },
    { label: "EUR", value: "EUR" },
    { label: "ANG", value: "ANG" },
    { label: "XPF", value: "XPF" },
    { label: "NZD", value: "NZD" },
    { label: "NIO", value: "NIO" },
    { label: "XOF", value: "XOF" },
    { label: "NGN", value: "NGN" },
    { label: "NZD", value: "NZD" },
    { label: "AUD", value: "AUD" },
    { label: "USD", value: "USD" },
    { label: "NOK", value: "NOK" },
    { label: "ILS", value: "ILS" },
    { label: "OMR", value: "OMR" },
    { label: "PKR", value: "PKR" },
    { label: "USD", value: "USD" },
    { label: "PAB", value: "PAB" },
    { label: "PGK", value: "PGK" },
    { label: "PYG", value: "PYG" },
    { label: "PEN", value: "PEN" },
    { label: "PHP", value: "PHP" },
    { label: "NZD", value: "NZD" },
    { label: "PLN", value: "PLN" },
    { label: "EUR", value: "EUR" },
    { label: "USD", value: "USD" },
    { label: "QAR", value: "QAR" },
    { label: "EUR", value: "EUR" },
    { label: "RON", value: "RON" },
    { label: "RUB", value: "RUB" },
    { label: "RWF", value: "RWF" },
    { label: "ANG", value: "ANG" },
    { label: "SHP", value: "SHP" },
    { label: "XCD", value: "XCD" },
    { label: "XCD", value: "XCD" },
    { label: "EUR", value: "EUR" },
    { label: "EUR", value: "EUR" },
    { label: "XCD", value: "XCD" },
    { label: "WST", value: "WST" },
    { label: "EUR", value: "EUR" },
    { label: "STD", value: "STD" },
    { label: "SAR", value: "SAR" },
    { label: "XOF", value: "XOF" },
    { label: "RSD", value: "RSD" },
    { label: "SCR", value: "SCR" },
    { label: "SLL", value: "SLL" },
    { label: "SGD", value: "SGD" },
    { label: "ANG", value: "ANG" },
    { label: "EUR", value: "EUR" },
    { label: "EUR", value: "EUR" },
    { label: "SBD", value: "SBD" },
    { label: "SOS", value: "SOS" },
    { label: "ZAR", value: "ZAR" },
    { label: "GBP", value: "GBP" },
    { label: "EUR", value: "EUR" },
    { label: "LKR", value: "LKR" },
    { label: "SDD", value: "SDD" },
    { label: "SRD", value: "SRD" },
    { label: "NOK", value: "NOK" },
    { label: "SZL", value: "SZL" },
    { label: "SEK", value: "SEK" },
    { label: "CHE", value: "CHE" },
    { label: "SYP", value: "SYP" },
    { label: "XPF", value: "XPF" },
    { label: "TWD", value: "TWD" },
    { label: "TJS", value: "TJS" },
    { label: "TZS", value: "TZS" },
    { label: "THB", value: "THB" },
    { label: "USD", value: "USD" },
    { label: "XOF", value: "XOF" },
    { label: "NZD", value: "NZD" },
    { label: "TOP", value: "TOP" },
    { label: "TTD", value: "TTD" },
    { label: "TND", value: "TND" },
    { label: "TRY", value: "TRY" },
    { label: "TMT", value: "TMT" },
    { label: "USD", value: "USD" },
    { label: "AUD", value: "AUD" },
    { label: "UGX", value: "UGX" },
    { label: "UAH", value: "UAH" },
    { label: "AED", value: "AED" },
    { label: "GBP", value: "GBP" },
    { label: "USD", value: "USD" },
    { label: "UYU", value: "UYU" },
    { label: "UZS", value: "UZS" },
    { label: "VUV", value: "VUV" },
    { label: "VEB", value: "VEB" },
    { label: "VND", value: "VND" },
    { label: "USD", value: "USD" },
    { label: "USD", value: "USD" },
    { label: "XPF", value: "XPF" },
    { label: "MAD", value: "MAD" },
    { label: "YER", value: "YER" },
    { label: "ZMK", value: "ZMK" },
    { label: "ZWD", value: "ZWD" },
  ];
   const countries = [
    {
        label: "Afganistan",
        value: "AF",
        "CURRENCY_CODE": "AFN",
        "COUNTRY_NUM": 346
    },
    {
        label: "Albania",
        value: "AL",
        "CURRENCY_CODE": "ALL",
        "COUNTRY_NUM": 347
    },
    {
        label: "Algeria",
        value: "DZ",
        "CURRENCY_CODE": "DZD",
        "COUNTRY_NUM": 348
    },
    {
        label: "American Samoa",
        value: "AS",
        "CURRENCY_CODE": "USD",
        "COUNTRY_NUM": 349
    },
    {
        label: "Andorra",
        value: "AD",
        "CURRENCY_CODE": "EUR",
        "COUNTRY_NUM": 350
    },
    {
        label: "Angola",
        value: "AO",
        "CURRENCY_CODE": "AOA",
        "COUNTRY_NUM": 351
    },
    {
        label: "Anguilla",
        value: "AI",
        "CURRENCY_CODE": "XCD",
        "COUNTRY_NUM": 352
    },
    {
        label: "Antarctica",
        value: "AQ",
        "CURRENCY_CODE": "NOK",
        "COUNTRY_NUM": 353
    },
    {
        label: "Antigua and Barbuda",
        value: "AG",
        "CURRENCY_CODE": "XCD",
        "COUNTRY_NUM": 100
    },
    {
        label: "Argentina",
        value: "AR",
        "CURRENCY_CODE": "ARS",
        "COUNTRY_NUM": 101
    },
    {
        label: "Armenia",
        value: "AM",
        "CURRENCY_CODE": "AMD",
        "COUNTRY_NUM": 102
    },
    {
        label: "Aruba",
        value: "AW",
        "CURRENCY_CODE": "AWG",
        "COUNTRY_NUM": 103
    },
    {
        label: "Australia",
        value: "AU",
        "CURRENCY_CODE": "AUD",
        "COUNTRY_NUM": 104
    },
    {
        label: "Austria",
        value: "AT",
        "CURRENCY_CODE": "EUR",
        "COUNTRY_NUM": 105
    },
    {
        label: "Azerbaijan",
        value: "AZ",
        "CURRENCY_CODE": "AZN",
        "COUNTRY_NUM": 106
    },
    {
        label: "Azores",
        value: "ZO",
        "CURRENCY_CODE": "EUR",
        "COUNTRY_NUM": 107
    },
    {
        label: "Bahamas",
        value: "BS",
        "CURRENCY_CODE": "BSD",
        "COUNTRY_NUM": 108
    },
    {
        label: "Bahrain",
        value: "BH",
        "CURRENCY_CODE": "BHD",
        "COUNTRY_NUM": 109
    },
    {
        label: "Bangladesh",
        value: "BD",
        "CURRENCY_CODE": "BDT",
        "COUNTRY_NUM": 110
    },
    {
        label: "Barbados",
        value: "BB",
        "CURRENCY_CODE": "BBD",
        "COUNTRY_NUM": 111
    },
    {
        label: "Belarus",
        value: "BY",
        "CURRENCY_CODE": "BYR",
        "COUNTRY_NUM": 112
    },
    {
        label: "Belgium",
        value: "BE",
        "CURRENCY_CODE": "EUR",
        "COUNTRY_NUM": 113
    },
    {
        label: "Belize",
        value: "BZ",
        "CURRENCY_CODE": "BZD",
        "COUNTRY_NUM": 114
    },
    {
        label: "Benin",
        value: "BJ",
        "CURRENCY_CODE": "XOF",
        "COUNTRY_NUM": 115
    },
    {
        label: "Bermuda",
        value: "BM",
        "CURRENCY_CODE": "BMD",
        "COUNTRY_NUM": 116
    },
    {
        label: "Bhutan",
        value: "BT",
        "CURRENCY_CODE": "BTN",
        "COUNTRY_NUM": 117
    },
    {
        label: "Bolivia",
        value: "BO",
        "CURRENCY_CODE": "BOB",
        "COUNTRY_NUM": 118
    },
    {
        label: "Bonaire",
        value: "AN",
        "CURRENCY_CODE": "ANG",
        "COUNTRY_NUM": 119
    },
    {
        label: "Bonaire, St Eustatius and Saba",
        value: "BQ",
        "CURRENCY_CODE": "ANG",
        "COUNTRY_NUM": 354
    },
    {
        label: "Bosnia and Herzegovina",
        value: "BA",
        "CURRENCY_CODE": "BAM",
        "COUNTRY_NUM": 120
    },
    {
        label: "Botswana",
        value: "BW",
        "CURRENCY_CODE": "BWP",
        "COUNTRY_NUM": 121
    },
    {
        label: "Bouvet Island",
        value: "BV",
        "CURRENCY_CODE": "NOK",
        "COUNTRY_NUM": 122
    },
    {
        label: "Brazil",
        value: "BR",
        "CURRENCY_CODE": "BRL",
        "COUNTRY_NUM": 123
    },
    {
        label: "British Indian Ocean Territory",
        value: "IO",
        "CURRENCY_CODE": "USD",
        "COUNTRY_NUM": 124
    },
    {
        label: "Brunei Darussalam",
        value: "BN",
        "CURRENCY_CODE": "BND",
        "COUNTRY_NUM": 126
    },
    {
        label: "Bulgaria",
        value: "BG",
        "CURRENCY_CODE": "BGN",
        "COUNTRY_NUM": 127
    },
    {
        label: "Burkina Faso",
        value: "BF",
        "CURRENCY_CODE": "XOF",
        "COUNTRY_NUM": 128
    },
    {
        label: "Burundi",
        value: "BI",
        "CURRENCY_CODE": "BIF",
        "COUNTRY_NUM": 129
    },
    {
        label: "C?te D\u0027ivoire",
        value: "CI",
        "CURRENCY_CODE": "XOF",
        "COUNTRY_NUM": 147
    },
    {
        label: "Cambodia",
        value: "KH",
        "CURRENCY_CODE": "KHR",
        "COUNTRY_NUM": 130
    },
    {
        label: "Cameroon",
        value: "CM",
        "CURRENCY_CODE": "XAF",
        "COUNTRY_NUM": 131
    },
    {
        label: "Canada",
        value: "CA",
        "CURRENCY_CODE": "CAD",
        "COUNTRY_NUM": 132
    },
    {
        label: "Cape Verde",
        value: "CV",
        "CURRENCY_CODE": "CVE",
        "COUNTRY_NUM": 134
    },
    {
        label: "Cayman Islands",
        value: "KY",
        "CURRENCY_CODE": "KYD",
        "COUNTRY_NUM": 135
    },
    {
        label: "Central African Republic",
        value: "CF",
        "CURRENCY_CODE": "XAF",
        "COUNTRY_NUM": 136
    },
    {
        label: "Chad",
        value: "TD",
        "CURRENCY_CODE": "XAF",
        "COUNTRY_NUM": 137
    },
    {
        label: "Chile",
        value: "CL",
        "CURRENCY_CODE": "CLP",
        "COUNTRY_NUM": 138
    },
    {
        label: "China",
        value: "CN",
        "CURRENCY_CODE": "RMB",
        "COUNTRY_NUM": 139
    },
    {
        label: "Christmas Island",
        value: "CX",
        "CURRENCY_CODE": "AUD",
        "COUNTRY_NUM": 140
    },
    {
        label: "Cocos (Keeling) Islands",
        value: "CC",
        "CURRENCY_CODE": "AUD",
        "COUNTRY_NUM": 141
    },
    {
        label: "Colombia",
        value: "CO",
        "CURRENCY_CODE": "COP",
        "COUNTRY_NUM": 142
    },
    {
        label: "Comoros",
        value: "KM",
        "CURRENCY_CODE": "KMF",
        "COUNTRY_NUM": 143
    },
    {
        label: "Congo",
        value: "CG",
        "CURRENCY_CODE": "XAF",
        "COUNTRY_NUM": 144
    },
    {
        label: "Congo, Democratic Republic of the",
        value: "CD",
        "CURRENCY_CODE": "CDF",
        "COUNTRY_NUM": 355
    },
    {
        label: "Cook Islands",
        value: "CK",
        "CURRENCY_CODE": "NZD",
        "COUNTRY_NUM": 145
    },
    {
        label: "Costa Rica",
        value: "CR",
        "CURRENCY_CODE": "CRC",
        "COUNTRY_NUM": 146
    },
    {
        label: "Croatia",
        value: "HR",
        "CURRENCY_CODE": "HRK",
        "COUNTRY_NUM": 148
    },
    {
        label: "Cuba",
        value: "CU",
        "CURRENCY_CODE": "CUP",
        "COUNTRY_NUM": 149
    },
    {
        label: "Curacao",
        value: "CW",
        "CURRENCY_CODE": "ANG",
        "COUNTRY_NUM": 150
    },
    {
        label: "Cyprus",
        value: "CY",
        "CURRENCY_CODE": "EUR",
        "COUNTRY_NUM": 151
    },
    {
        label: "Czech Republic",
        value: "CZ",
        "CURRENCY_CODE": "CZK",
        "COUNTRY_NUM": 152
    },
    {
        label: "Denmark",
        value: "DK",
        "CURRENCY_CODE": "DKK",
        "COUNTRY_NUM": 153
    },
    {
        label: "Djibouti",
        value: "DJ",
        "CURRENCY_CODE": "DJF",
        "COUNTRY_NUM": 154
    },
    {
        label: "Dominica",
        value: "DM",
        "CURRENCY_CODE": "XCD",
        "COUNTRY_NUM": 155
    },
    {
        label: "Dominican Republic",
        value: "DO",
        "CURRENCY_CODE": "DOP",
        "COUNTRY_NUM": 156
    },
    {
        label: "East Timor",
        value: "EM",
        "CURRENCY_CODE": "USD",
        "COUNTRY_NUM": 157
    },
    {
        label: "Ecuador",
        value: "EC",
        "CURRENCY_CODE": "USD",
        "COUNTRY_NUM": 158
    },
    {
        label: "Egypt",
        value: "EG",
        "CURRENCY_CODE": "EGP",
        "COUNTRY_NUM": 159
    },
    {
        label: "El Salvador",
        value: "SV",
        "CURRENCY_CODE": "SVC",
        "COUNTRY_NUM": 160
    },
    {
        label: "Equatorial Guinea",
        value: "GQ",
        "CURRENCY_CODE": "XAF",
        "COUNTRY_NUM": 161
    },
    {
        label: "Eritrea",
        value: "ER",
        "CURRENCY_CODE": "ERN",
        "COUNTRY_NUM": 162
    },
    {
        label: "Estonia",
        value: "EE",
        "CURRENCY_CODE": "EEK",
        "COUNTRY_NUM": 163
    },
    {
        label: "Ethiopia",
        value: "ET",
        "CURRENCY_CODE": "ETB",
        "COUNTRY_NUM": 164
    },
    {
        label: "Europe",
        value: "EP",
        "CURRENCY_CODE": "EUR",
        "COUNTRY_NUM": 165
    },
    {
        label: "Falkland Islands (Malvinas)",
        value: "FK",
        "CURRENCY_CODE": "FKP",
        "COUNTRY_NUM": 166
    },
    {
        label: "Faroe Islands",
        value: "FO",
        "CURRENCY_CODE": "DKK",
        "COUNTRY_NUM": 167
    },
    {
        label: "Fiji",
        value: "FJ",
        "CURRENCY_CODE": "FJD",
        "COUNTRY_NUM": 168
    },
    {
        label: "Finland",
        value: "FI",
        "CURRENCY_CODE": "EUR",
        "COUNTRY_NUM": 169
    },
    {
        label: "France",
        value: "FR",
        "CURRENCY_CODE": "EUR",
        "COUNTRY_NUM": 170
    },
    {
        label: "French Guiana",
        value: "GF",
        "CURRENCY_CODE": "EUR",
        "COUNTRY_NUM": 171
    },
    {
        label: "French Southern Territories",
        value: "TF",
        "CURRENCY_CODE": "EUR",
        "COUNTRY_NUM": 173
    },
    {
        label: "Gabon",
        value: "GA",
        "CURRENCY_CODE": "XAF",
        "COUNTRY_NUM": 174
    },
    {
        label: "Gambia",
        value: "GM",
        "CURRENCY_CODE": "GMD",
        "COUNTRY_NUM": 175
    },
    {
        label: "Georgia",
        value: "GE",
        "CURRENCY_CODE": "GEL",
        "COUNTRY_NUM": 176
    },
    {
        label: "Germany",
        value: "DE",
        "CURRENCY_CODE": "EUR",
        "COUNTRY_NUM": 177
    },
    {
        label: "Ghana",
        value: "GH",
        "CURRENCY_CODE": "GHC",
        "COUNTRY_NUM": 178
    },
    {
        label: "Gibraltar",
        value: "GI",
        "CURRENCY_CODE": "GIP",
        "COUNTRY_NUM": 179
    },
    {
        label: "Greece",
        value: "GR",
        "CURRENCY_CODE": "EUR",
        "COUNTRY_NUM": 180
    },
    {
        label: "Greenland",
        value: "GL",
        "CURRENCY_CODE": "DKK",
        "COUNTRY_NUM": 181
    },
    {
        label: "Grenada",
        value: "GD",
        "CURRENCY_CODE": "XCD",
        "COUNTRY_NUM": 182
    },
    {
        label: "Guadeloupe",
        value: "GP",
        "CURRENCY_CODE": "EUR",
        "COUNTRY_NUM": 183
    },
    {
        label: "Guam",
        value: "GU",
        "CURRENCY_CODE": "USD",
        "COUNTRY_NUM": 184
    },
    {
        label: "Guatemala",
        value: "GT",
        "CURRENCY_CODE": "GTQ",
        "COUNTRY_NUM": 185
    },
    {
        label: "Guernsey",
        value: "GG",
        "CURRENCY_CODE": "GBP",
        "COUNTRY_NUM": 186
    },
    {
        label: "Guinea",
        value: "GN",
        "CURRENCY_CODE": "GNF",
        "COUNTRY_NUM": 187
    },
    {
        label: "Guinea-Bissau",
        value: "GW",
        "CURRENCY_CODE": "XOF",
        "COUNTRY_NUM": 188
    },
    {
        label: "Guyana",
        value: "GY",
        "CURRENCY_CODE": "GYD",
        "COUNTRY_NUM": 189
    },
    {
        label: "Haiti",
        value: "HT",
        "CURRENCY_CODE": "HTG",
        "COUNTRY_NUM": 190
    },
    {
        label: "Heard Island and McDonald Islands",
        value: "HM",
        "CURRENCY_CODE": "AUD",
        "COUNTRY_NUM": 356
    },
    {
        label: "Holland",
        value: "HL",
        "CURRENCY_CODE": "EUR",
        "COUNTRY_NUM": 191
    },
    {
        label: "Holy See (Vatican City State)",
        value: "VA",
        "CURRENCY_CODE": "EUR",
        "COUNTRY_NUM": 192
    },
    {
        label: "Honduras",
        value: "HN",
        "CURRENCY_CODE": "HNL",
        "COUNTRY_NUM": 193
    },
    {
        label: "Hong Kong",
        value: "HK",
        "CURRENCY_CODE": "HKD",
        "COUNTRY_NUM": 194
    },
    {
        label: "Hungary",
        value: "HU",
        "CURRENCY_CODE": "HUF",
        "COUNTRY_NUM": 195
    },
    {
        label: "Iceland",
        value: "IS",
        "CURRENCY_CODE": "ISK",
        "COUNTRY_NUM": 196
    },
    {
        label: "India",
        value: "IN",
        "CURRENCY_CODE": "INR",
        "COUNTRY_NUM": 197
    },
    {
        label: "Indonesia",
        value: "ID",
        "CURRENCY_CODE": "IDR",
        "COUNTRY_NUM": 198
    },
    {
        label: "Iran, Islamic Republic of",
        value: "IR",
        "CURRENCY_CODE": "IRR",
        "COUNTRY_NUM": 199
    },
    {
        label: "Iraq",
        value: "IQ",
        "CURRENCY_CODE": "IQD",
        "COUNTRY_NUM": 200
    },
    {
        label: "Ireland",
        value: "IE",
        "CURRENCY_CODE": "EUR",
        "COUNTRY_NUM": 201
    },
    {
        label: "Isle of Man",
        value: "IM",
        "CURRENCY_CODE": "GBP",
        "COUNTRY_NUM": 202
    },
    {
        label: "Israel",
        value: "IL",
        "CURRENCY_CODE": "ILS",
        "COUNTRY_NUM": 203
    },
    {
        label: "Italy",
        value: "IT",
        "CURRENCY_CODE": "EUR",
        "COUNTRY_NUM": 204
    },
    {
        label: "Ivory Coast",
        value: "IC",
        "CURRENCY_CODE": "XOF",
        "COUNTRY_NUM": 205
    },
    {
        label: "Jamaica",
        value: "JM",
        "CURRENCY_CODE": "JMD",
        "COUNTRY_NUM": 206
    },
    {
        label: "Japan",
        value: "JP",
        "CURRENCY_CODE": "JPY",
        "COUNTRY_NUM": 207
    },
    {
        label: "Jersey",
        value: "JE",
        "CURRENCY_CODE": "GBP",
        "COUNTRY_NUM": 208
    },
    {
        label: "Jordan",
        value: "JO",
        "CURRENCY_CODE": "JOD",
        "COUNTRY_NUM": 209
    },
    {
        label: "Kazakhstan",
        value: "KZ",
        "CURRENCY_CODE": "KZT",
        "COUNTRY_NUM": 210
    },
    {
        label: "Kenya",
        value: "KE",
        "CURRENCY_CODE": "KES",
        "COUNTRY_NUM": 211
    },
    {
        label: "Kiribati",
        value: "KI",
        "CURRENCY_CODE": "AUD",
        "COUNTRY_NUM": 212
    },
    {
        label: "Korea, Democratic People\u0027s Republic of",
        value: "KP",
        "CURRENCY_CODE": "KPW",
        "COUNTRY_NUM": 357
    },
    {
        label: "Korea, Republic of",
        value: "KR",
        "CURRENCY_CODE": "KRW",
        "COUNTRY_NUM": 213
    },
    {
        label: "Kuwait",
        value: "KW",
        "CURRENCY_CODE": "KWD",
        "COUNTRY_NUM": 215
    },
    {
        label: "Kyrgyzstan",
        value: "KG",
        "CURRENCY_CODE": "KGS",
        "COUNTRY_NUM": 216
    },
    {
        label: "Lao People\u0027s Democratic Republic",
        value: "LA",
        "CURRENCY_CODE": "LAK",
        "COUNTRY_NUM": 358
    },
    {
        label: "Latvia",
        value: "LV",
        "CURRENCY_CODE": "LVL",
        "COUNTRY_NUM": 217
    },
    {
        label: "Lebanon",
        value: "LB",
        "CURRENCY_CODE": "LBP",
        "COUNTRY_NUM": 218
    },
    {
        label: "Lesotho",
        value: "LS",
        "CURRENCY_CODE": "LSL",
        "COUNTRY_NUM": 219
    },
    {
        label: "Liberia",
        value: "LR",
        "CURRENCY_CODE": "LRD",
        "COUNTRY_NUM": 220
    },
    {
        label: "Libya",
        value: "LY",
        "CURRENCY_CODE": "LYD",
        "COUNTRY_NUM": 221
    },
    {
        label: "Libyan Arab Jamahiriya",
        value: "LJ",
        "CURRENCY_CODE": "LYD",
        "COUNTRY_NUM": 222
    },
    {
        label: "Liechtenstein",
        value: "LI",
        "CURRENCY_CODE": "CHF",
        "COUNTRY_NUM": 223
    },
    {
        label: "Lithuania",
        value: "LT",
        "CURRENCY_CODE": "LTL",
        "COUNTRY_NUM": 224
    },
    {
        label: "Luxembourg",
        value: "LU",
        "CURRENCY_CODE": "EUR",
        "COUNTRY_NUM": 225
    },
    {
        label: "Macao",
        value: "MO",
        "CURRENCY_CODE": "MOP",
        "COUNTRY_NUM": 226
    },
    {
        label: "Macedonia, The Former Yugoslav Republic of",
        value: "MK",
        "CURRENCY_CODE": "MKD",
        "COUNTRY_NUM": 359
    },
    {
        label: "Madagascar",
        value: "MG",
        "CURRENCY_CODE": "MGA",
        "COUNTRY_NUM": 227
    },
    {
        label: "Malawi",
        value: "MW",
        "CURRENCY_CODE": "MWK",
        "COUNTRY_NUM": 229
    },
    {
        label: "Malaysia",
        value: "MY",
        "CURRENCY_CODE": "MYR",
        "COUNTRY_NUM": 230
    },
    {
        label: "Maldives",
        value: "MV",
        "CURRENCY_CODE": "MVR",
        "COUNTRY_NUM": 231
    },
    {
        label: "Mali",
        value: "ML",
        "CURRENCY_CODE": "XOF",
        "COUNTRY_NUM": 232
    },
    {
        label: "Malta",
        value: "MT",
        "CURRENCY_CODE": "EUR",
        "COUNTRY_NUM": 233
    },
    {
        label: "Marshall Islands",
        value: "MH",
        "CURRENCY_CODE": "USD",
        "COUNTRY_NUM": 234
    },
    {
        label: "Martinique",
        value: "MQ",
        "CURRENCY_CODE": "EUR",
        "COUNTRY_NUM": 235
    },
    {
        label: "Mauritania",
        value: "MR",
        "CURRENCY_CODE": "MRO",
        "COUNTRY_NUM": 236
    },
    {
        label: "Mauritius",
        value: "MU",
        "CURRENCY_CODE": "MUR",
        "COUNTRY_NUM": 237
    },
    {
        label: "Mayotte",
        value: "YT",
        "CURRENCY_CODE": "EUR",
        "COUNTRY_NUM": 238
    },
    {
        label: "Mexico",
        value: "MX",
        "CURRENCY_CODE": "MXN",
        "COUNTRY_NUM": 239
    },
    {
        label: "Micronesia, Federated States of",
        value: "FM",
        "CURRENCY_CODE": "USD",
        "COUNTRY_NUM": 360
    },
    {
        label: "Moldova, Republic of",
        value: "MD",
        "CURRENCY_CODE": "MDL",
        "COUNTRY_NUM": 240
    },
    {
        label: "Monaco",
        value: "MC",
        "CURRENCY_CODE": "EUR",
        "COUNTRY_NUM": 241
    },
    {
        label: "Mongolia",
        value: "MN",
        "CURRENCY_CODE": "MNT",
        "COUNTRY_NUM": 242
    },
    {
        label: "Montenegro",
        value: "ME",
        "CURRENCY_CODE": "EUR",
        "COUNTRY_NUM": 243
    },
    {
        label: "Montserrat",
        value: "MS",
        "CURRENCY_CODE": "XCD",
        "COUNTRY_NUM": 244
    },
    {
        label: "Morocco",
        value: "MA",
        "CURRENCY_CODE": "MAD",
        "COUNTRY_NUM": 245
    },
    {
        label: "Mozambique",
        value: "MZ",
        "CURRENCY_CODE": "MZN",
        "COUNTRY_NUM": 246
    },
    {
        label: "Myanmar",
        value: "MM",
        "CURRENCY_CODE": "MMK",
        "COUNTRY_NUM": 247
    },
    {
        label: "Namibia",
        value: "NA",
        "CURRENCY_CODE": "NAD",
        "COUNTRY_NUM": 248
    },
    {
        label: "Nauru",
        value: "NR",
        "CURRENCY_CODE": "AUD",
        "COUNTRY_NUM": 249
    },
    {
        label: "Nepal",
        value: "NP",
        "CURRENCY_CODE": "NPR",
        "COUNTRY_NUM": 250
    },
    {
        label: "Netherlands",
        value: "NL",
        "CURRENCY_CODE": "EUR",
        "COUNTRY_NUM": 251
    },
    {
        label: "Netherlands Antilles",
        value: "NT",
        "CURRENCY_CODE": "ANG",
        "COUNTRY_NUM": 252
    },
    {
        label: "New Caledonia",
        value: "NC",
        "CURRENCY_CODE": "XPF",
        "COUNTRY_NUM": 253
    },
    {
        label: "New Zealand",
        value: "NZ",
        "CURRENCY_CODE": "NZD",
        "COUNTRY_NUM": 254
    },
    {
        label: "Nicaragua",
        value: "NI",
        "CURRENCY_CODE": "NIO",
        "COUNTRY_NUM": 255
    },
    {
        label: "Niger",
        value: "NE",
        "CURRENCY_CODE": "XOF",
        "COUNTRY_NUM": 256
    },
    {
        label: "Nigeria",
        value: "NG",
        "CURRENCY_CODE": "NGN",
        "COUNTRY_NUM": 257
    },
    {
        label: "Niue",
        value: "NU",
        "CURRENCY_CODE": "NZD",
        "COUNTRY_NUM": 258
    },
    {
        label: "Norfolk Island",
        value: "NF",
        "CURRENCY_CODE": "AUD",
        "COUNTRY_NUM": 259
    },
    {
        label: "Northern Mariana Islands",
        value: "MP",
        "CURRENCY_CODE": "USD",
        "COUNTRY_NUM": 260
    },
    {
        label: "Norway",
        value: "NO",
        "CURRENCY_CODE": "NOK",
        "COUNTRY_NUM": 261
    },
    {
        label: "Occupied Palestinian Territory",
        value: "PS",
        "CURRENCY_CODE": "ILS",
        "COUNTRY_NUM": 361
    },
    {
        label: "Oman",
        value: "OM",
        "CURRENCY_CODE": "OMR",
        "COUNTRY_NUM": 262
    },
    {
        label: "Pakistan",
        value: "PK",
        "CURRENCY_CODE": "PKR",
        "COUNTRY_NUM": 263
    },
    {
        label: "Palau",
        value: "PW",
        "CURRENCY_CODE": "USD",
        "COUNTRY_NUM": 264
    },
    {
        label: "Panama",
        value: "PA",
        "CURRENCY_CODE": "PAB",
        "COUNTRY_NUM": 265
    },
    {
        label: "Papua New Guinea",
        value: "PG",
        "CURRENCY_CODE": "PGK",
        "COUNTRY_NUM": 266
    },
    {
        label: "Paraguay",
        value: "PY",
        "CURRENCY_CODE": "PYG",
        "COUNTRY_NUM": 267
    },
    {
        label: "Peru",
        value: "PE",
        "CURRENCY_CODE": "PEN",
        "COUNTRY_NUM": 268
    },
    {
        label: "Philippines",
        value: "PH",
        "CURRENCY_CODE": "PHP",
        "COUNTRY_NUM": 269
    },
    {
        label: "Pitcairn",
        value: "PN",
        "CURRENCY_CODE": "NZD",
        "COUNTRY_NUM": 270
    },
    {
        label: "Poland",
        value: "PL",
        "CURRENCY_CODE": "PLN",
        "COUNTRY_NUM": 271
    },
    {
        label: "Portugal",
        value: "PT",
        "CURRENCY_CODE": "EUR",
        "COUNTRY_NUM": 273
    },
    {
        label: "Puerto Rico",
        value: "PR",
        "CURRENCY_CODE": "USD",
        "COUNTRY_NUM": 274
    },
    {
        label: "Qatar",
        value: "QA",
        "CURRENCY_CODE": "QAR",
        "COUNTRY_NUM": 275
    },
    {
        label: "R?union",
        value: "RE",
        "CURRENCY_CODE": "EUR",
        "COUNTRY_NUM": 276
    },
    {
        label: "Romania",
        value: "RO",
        "CURRENCY_CODE": "RON",
        "COUNTRY_NUM": 277
    },
    {
        label: "Russian Federation",
        value: "RU",
        "CURRENCY_CODE": "RUB",
        "COUNTRY_NUM": 279
    },
    {
        label: "Rwanda",
        value: "RW",
        "CURRENCY_CODE": "RWF",
        "COUNTRY_NUM": 280
    },
    {
        label: "Saba",
        value: "AB",
        "CURRENCY_CODE": "ANG",
        "COUNTRY_NUM": 281
    },
    {
        label: "Saint Helena",
        value: "SH",
        "CURRENCY_CODE": "SHP",
        "COUNTRY_NUM": 282
    },
    {
        label: "Saint Kitts and Nevis",
        value: "KN",
        "CURRENCY_CODE": "XCD",
        "COUNTRY_NUM": 283
    },
    {
        label: "Saint Lucia",
        value: "LC",
        "CURRENCY_CODE": "XCD",
        "COUNTRY_NUM": 284
    },
    {
        label: "Saint Martin (French part)",
        value: "MF",
        "CURRENCY_CODE": "EUR",
        "COUNTRY_NUM": 362
    },
    {
        label: "Saint Pierre and Miquelon",
        value: "PM",
        "CURRENCY_CODE": "EUR",
        "COUNTRY_NUM": 285
    },
    {
        label: "Saint Vincent and the Grenadines",
        value: "VC",
        "CURRENCY_CODE": "XCD",
        "COUNTRY_NUM": 363
    },
    {
        label: "Samoa",
        value: "WS",
        "CURRENCY_CODE": "WST",
        "COUNTRY_NUM": 287
    },
    {
        label: "San Marino",
        value: "SM",
        "CURRENCY_CODE": "EUR",
        "COUNTRY_NUM": 288
    },
    {
        label: "Sao Tome and Principe",
        value: "ST",
        "CURRENCY_CODE": "STD",
        "COUNTRY_NUM": 289
    },
    {
        label: "Saudi Arabia",
        value: "SA",
        "CURRENCY_CODE": "SAR",
        "COUNTRY_NUM": 290
    },
    {
        label: "Senegal",
        value: "SN",
        "CURRENCY_CODE": "XOF",
        "COUNTRY_NUM": 291
    },
    {
        label: "Serbia",
        value: "RS",
        "CURRENCY_CODE": "RSD",
        "COUNTRY_NUM": 292
    },
    {
        label: "Seychelles",
        value: "SC",
        "CURRENCY_CODE": "SCR",
        "COUNTRY_NUM": 294
    },
    {
        label: "Sierra Leone",
        value: "SL",
        "CURRENCY_CODE": "SLL",
        "COUNTRY_NUM": 295
    },
    {
        label: "Singapore",
        value: "SG",
        "CURRENCY_CODE": "SGD",
        "COUNTRY_NUM": 296
    },
    {
        label: "Sint Maarten (Dutch part)",
        value: "SX",
        "CURRENCY_CODE": "ANG",
        "COUNTRY_NUM": 364
    },
    {
        label: "Slovakia",
        value: "SK",
        "CURRENCY_CODE": "EUR",
        "COUNTRY_NUM": 297
    },
    {
        label: "Slovenia",
        value: "SI",
        "CURRENCY_CODE": "EUR",
        "COUNTRY_NUM": 298
    },
    {
        label: "Solomon Islands",
        value: "SB",
        "CURRENCY_CODE": "SBD",
        "COUNTRY_NUM": 299
    },
    {
        label: "Somalia",
        value: "SO",
        "CURRENCY_CODE": "SOS",
        "COUNTRY_NUM": 300
    },
    {
        label: "South Africa",
        value: "ZA",
        "CURRENCY_CODE": "ZAR",
        "COUNTRY_NUM": 301
    },
    {
        label: "South Georgia and the South Sandwich Islands ",
        value: "GS",
        "CURRENCY_CODE": "GBP",
        "COUNTRY_NUM": 365
    },
    {
        label: "Spain",
        value: "ES",
        "CURRENCY_CODE": "EUR",
        "COUNTRY_NUM": 302
    },
    {
        label: "Sri Lanka",
        value: "LK",
        "CURRENCY_CODE": "LKR",
        "COUNTRY_NUM": 303
    },
    {
        label: "Sudan",
        value: "SD",
        "CURRENCY_CODE": "SDD",
        "COUNTRY_NUM": 304
    },
    {
        label: "Suriname",
        value: "SR",
        "CURRENCY_CODE": "SRD",
        "COUNTRY_NUM": 305
    },
    {
        label: "Svalbard and Jan Mayen",
        value: "SJ",
        "CURRENCY_CODE": "NOK",
        "COUNTRY_NUM": 306
    },
    {
        label: "Swaziland",
        value: "SZ",
        "CURRENCY_CODE": "SZL",
        "COUNTRY_NUM": 307
    },
    {
        label: "Sweden",
        value: "SE",
        "CURRENCY_CODE": "SEK",
        "COUNTRY_NUM": 308
    },
    {
        label: "Switzerland",
        value: "CH",
        "CURRENCY_CODE": "CHE",
        "COUNTRY_NUM": 309
    },
    {
        label: "Syrian Arab Republic",
        value: "SY",
        "CURRENCY_CODE": "SYP",
        "COUNTRY_NUM": 310
    },
    {
        label: "Tahiti",
        value: "PF",
        "CURRENCY_CODE": "XPF",
        "COUNTRY_NUM": 311
    },
    {
        label: "Taiwan, Province of China",
        value: "TW",
        "CURRENCY_CODE": "TWD",
        "COUNTRY_NUM": 312
    },
    {
        label: "Tajikistan",
        value: "TJ",
        "CURRENCY_CODE": "TJS",
        "COUNTRY_NUM": 313
    },
    {
        label: "Tanzania, United Republic of",
        value: "TZ",
        "CURRENCY_CODE": "TZS",
        "COUNTRY_NUM": 314
    },
    {
        label: "Thailand",
        value: "TH",
        "CURRENCY_CODE": "THB",
        "COUNTRY_NUM": 315
    },
    {
        label: "Timor-Leste",
        value: "TL",
        "CURRENCY_CODE": "USD",
        "COUNTRY_NUM": 316
    },
    {
        label: "Togo",
        value: "TG",
        "CURRENCY_CODE": "XOF",
        "COUNTRY_NUM": 318
    },
    {
        label: "Tokelau",
        value: "TK",
        "CURRENCY_CODE": "NZD",
        "COUNTRY_NUM": 319
    },
    {
        label: "Tonga",
        value: "TO",
        "CURRENCY_CODE": "TOP",
        "COUNTRY_NUM": 320
    },
    {
        label: "Trinidad and Tobago",
        value: "TT",
        "CURRENCY_CODE": "TTD",
        "COUNTRY_NUM": 322
    },
    {
        label: "Tunisia",
        value: "TN",
        "CURRENCY_CODE": "TND",
        "COUNTRY_NUM": 324
    },
    {
        label: "Turkey",
        value: "TR",
        "CURRENCY_CODE": "TRY",
        "COUNTRY_NUM": 325
    },
    {
        label: "Turkmenistan",
        value: "TM",
        "CURRENCY_CODE": "TMT",
        "COUNTRY_NUM": 326
    },
    {
        label: "Turks and Caicos Islands",
        value: "TC",
        "CURRENCY_CODE": "USD",
        "COUNTRY_NUM": 327
    },
    {
        label: "Tuvalu",
        value: "TV",
        "CURRENCY_CODE": "AUD",
        "COUNTRY_NUM": 328
    },
    {
        label: "Uganda",
        value: "UG",
        "CURRENCY_CODE": "UGX",
        "COUNTRY_NUM": 329
    },
    {
        label: "Ukraine",
        value: "UA",
        "CURRENCY_CODE": "UAH",
        "COUNTRY_NUM": 330
    },
    {
        label: "United Arab Emirates",
        value: "AE",
        "CURRENCY_CODE": "AED",
        "COUNTRY_NUM": 331
    },
    {
        label: "United Kingdom",
        value: "GB",
        "CURRENCY_CODE": "GBP",
        "COUNTRY_NUM": 332
    },
    {
        label: "United States",
        value: "US",
        "CURRENCY_CODE": "USD",
        "COUNTRY_NUM": 333
    },
    {
        label: "Uruguay",
        value: "UY",
        "CURRENCY_CODE": "UYU",
        "COUNTRY_NUM": 334
    },
    {
        label: "Uzbekistan",
        value: "UZ",
        "CURRENCY_CODE": "UZS",
        "COUNTRY_NUM": 335
    },
    {
        label: "Vanuatu",
        value: "VU",
        "CURRENCY_CODE": "VUV",
        "COUNTRY_NUM": 336
    },
    {
        label: "Venezuela",
        value: "VE",
        "CURRENCY_CODE": "VEB",
        "COUNTRY_NUM": 337
    },
    {
        label: "Viet Nam",
        value: "VN",
        "CURRENCY_CODE": "VND",
        "COUNTRY_NUM": 338
    },
    {
        label: "Virgin Islands, British",
        value: "VB",
        "CURRENCY_CODE": "USD",
        "COUNTRY_NUM": 339
    },
    {
        label: "Virgin Islands, U.S.",
        value: "VI",
        "CURRENCY_CODE": "USD",
        "COUNTRY_NUM": 340
    },
    {
        label: "Wallis and Futuna",
        value: "WF",
        "CURRENCY_CODE": "XPF",
        "COUNTRY_NUM": 341
    },
    {
        label: "Western Sahara",
        value: "EH",
        "CURRENCY_CODE": "MAD",
        "COUNTRY_NUM": 342
    },
    {
        label: "Yemen",
        value: "YE",
        "CURRENCY_CODE": "YER",
        "COUNTRY_NUM": 343
    },
    {
        label: "Zambia",
        value: "ZM",
        "CURRENCY_CODE": "ZMK",
        "COUNTRY_NUM": 344
    },
    {
        label: "Zimbabwe",
        value: "ZW",
        "CURRENCY_CODE": "ZWD",
        "COUNTRY_NUM": 345
    }
];


  export default countries;