<p-table [value]="shippingDocuments" class="p-datatable-sm mt-3">
    <ng-template pTemplate="header">
      <tr>
        <th style="width: 15%; !important">#</th>
        <th>Document Provider</th>
        <th>Document Type</th>
        <th>Description</th>
        <th style="width:25%; !important;text-align:center !important">Action</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-product let-i="rowIndex">
      <tr>
        <td>{{ i + 1 }}</td>
        <td>{{ product.contentType === 'LABEL' ? 'label' : 'report' }}</td>
        <td>{{ product.docType }}</td>
        <td>{{ product.contentType }}</td>
        <td>
          <span style="display: flex;align-items: center;justify-content: center;">
            <i class="pi pi-eye action-icon" (click)="previewDocument(product.encodedLabel, product.docType)"></i>
            <i class="pi pi-download action-icon" (click)="downloadDocument(product.encodedLabel, product.description, product.docType)"></i>
            <i class="pi pi-print action-icon" (click)="printDocument(product.encodedLabel, product.docType)"></i>
        </span>
        </td>
      </tr>
    </ng-template>
  </p-table>
  
<!-- Dialog for PDF Preview -->
<p-dialog header="Document Preview" [(visible)]="isPdfDialogVisible" [modal]="true" [style]="{width: '100vw', height: '100vh'}" [responsive]="true" (onHide)="onPdfDialogHide()">
    <div>
      <iframe [src]="previewUrl" style="width: 100%; height:90vh"></iframe>
    </div>
  </p-dialog>
  
  <!-- Dialog for Image Preview -->
  <p-dialog header="Document Preview" [(visible)]="isImageDialogVisible" [modal]="true" [style]="{width: '50vw', height: '100vh'}" [responsive]="true" (onHide)="onImageDialogHide()">
    <div style="width: 100%; height: 100%; display: flex; align-items: center; justify-content: center;">
      <img [src]="previewUrl" style="width: 100%; height: 100%; object-fit: contain;" />
    </div>
  </p-dialog>
  
  
  
  
  