import { Component, OnInit, ViewChild } from '@angular/core';
//import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ERPSHIPService } from '../erpship.service';
import { MessageService } from 'primeng/api';

import { JsonPipe, Location } from '@angular/common'

@Component({
  selector: 'app-newroutingrule',
  templateUrl: './newroutingrule.component.html',
  styleUrls: ['./newroutingrule.component.scss'], providers: [MessageService],
})
export class NewroutingruleComponent {
  newRule_flag = false;
  locations = [];
  //rules:any=[];
  ruledata:any = {
    RuleName: null,
    LocationId: null,
    Priority: null,
    ValidFrom: new Date(),
    ValidTo: new Date(),
    IsActive: true,
    tabledata: [],
    shippingProcess: {
      carrierDetails: [],
      carrierSelectionTyper: null,
      shippingNotes: ''
    },
    
    
  };
  carrierDetails: any = [];
  newCarrier = { 'CarrierName': '', 'CarrierService': '', 'Shipping Account': '', 'Billing Account': '' };
rule_defaultValues:any ={}
  xfields: any[] = [];
  tabledata: any[] = [];
  condition = ['=', '>', '<', '>=', '<=', '!=', 'Contains', 'Starts With', 'Ends With', 'In Between'];
  tableRecord = { "fieldName": '', 'Condition': '', 'fromRange': '', 'toRange': '' }
  isDataLoaded = false;
  parameterId = 0;
  tabNames: any[] = [];
  newTabDialog_flag = false;
  newtab: any = {}
  Original_LocationMasterData: any;
  locationMasterData: any;
  carriers: any = [];
  carrierServices: any = [];
  accounts: any = [];
  currentRowData: any = {}

  defaultFields: any = [];
  defaultObjects:any=[];

  paymentTypes = [
    { name: 'Sender', code: 'Sender' },
    { name: 'Receipient', code: 'Receipient' },
    { name: 'Third Party', code: 'ThirdParty' },
    { name: 'Collect', code: 'Collect' },
  ];

  constructor(private router: Router, public messageService: MessageService, private xfservice: ERPSHIPService, private route: ActivatedRoute, private location: Location) {
    this.xfservice.processAuth().subscribe((data) => { }, error => {
      console.log("No token found")
      this.router.navigate(['']);
    });

  }
  ngOnInit(): void {
    this.xfservice.getLocationMasterData().subscribe((data) => {
      //var result = [];
      //result = data;
      this.Original_LocationMasterData = JSON.parse(JSON.stringify(data));
      this.locationMasterData = data;
      this.locations = Object.assign(this.locationMasterData['location']);
      //console.log(this.locations)




      this.route.params.subscribe((params: any) => {
        this.parameterId = params['id'];
        if (this.parameterId == 0 || this.parameterId == undefined) {
          this.xfservice.getScreenTemplateByName('RoutingGuideFields').subscribe((data: any) => {
            this.xfields = data[0].details;
            this.xfields.forEach((element: any) => {
              if (element.visible == true) {
                element['fields'].forEach((item: any) => {
                  if (item['TableVisible'] == true) {
                    this.tableRecord.fieldName = element.fieldHeader + '->' + item.fieldName;
                    this.tabledata.push(JSON.parse(JSON.stringify(this.tableRecord)));


                  }

                })
              }
            })
            this.xfservice.getScreenTemplateByName('newrgruletabs').subscribe((data: any) => {
              this.tabNames = data[0].details;
            })

            this.xfservice.getScreenTemplateByName('RoutingGuideDefaults').subscribe((data: any) => {
              const default_fields = data[0].details;
              default_fields.forEach((element: any) => {
                if (element.visible == true) {
                  element['fields'].forEach((item: any) => {
                    if (item['TableVisible'] == true) {
                       const defaultObj:any={}
                       defaultObj['fieldName'] = element.fieldHeader + '->' + item.fieldName;
                       defaultObj['value']='';
                       this.defaultObjects.push(defaultObj);
                      this.defaultFields.push(item)
                    }
                  })
                }
              })
              console.log( this.defaultFields)
            })

          })

        }
        else {
          this.xfservice.getRgRules(this.parameterId).subscribe((data: any) => {
            // console.log(data);

            this.ruledata = data;
            this.ruledata.ValidFrom = new Date(data['ValidFrom']);
            this.ruledata.ValidTo = new Date(data['ValidTo']);

            this.tabledata = this.ruledata.tabledata;
            this.carrierDetails = this.ruledata.shippingProcess.carrierDetails



            this.xfservice.getScreenTemplateByName('RoutingGuideDefaults').subscribe((data: any) => {
              const default_fields = data[0].details;
              default_fields.forEach((element: any) => {
                if (element.visible == true) {
                  element['fields'].forEach((item: any) => {
                    if (item['TableVisible'] == true) {
                       const defaultObj:any={}
                       defaultObj['fieldName'] = element.fieldHeader + '->' + item.fieldName;
                       defaultObj['value']='';
                       this.defaultObjects.push(defaultObj);
                      this.defaultFields.push(item)
                    }
                  })
                }
              })

              this.defaultObjects = this.ruledata.defaults;
              
            })

            this.xfservice.getScreenTemplateByName('newrgruletabs').subscribe((data: any) => {

            })
          })

        }

      })
    })


  }



  //to save rule into the database

  saveRule() {
    this.ruledata.tabledata = this.tabledata as never;
    this.ruledata.shippingProcess.carrierDetails = this.carrierDetails
this.ruledata['defaults']= this.defaultObjects

    this.xfservice.saveRGRules(this.ruledata).subscribe((data: any) => {
      this.xfservice.getLocationMasterData().subscribe((data) => {
        this.ruledata = {
          RuleName: null,
          LocationId: null,
          Priority: null,
          ValidFrom: new Date(),
          ValidTo: new Date(),
          IsActive: true,
          tabledata: [],
          shippingProcess: {
            carrierDetails: [],
            carrierSelectionTyper: null,
            shippingNotes: ''
          }
        };
        //var result = [];
        //result = data;
        this.Original_LocationMasterData = JSON.parse(JSON.stringify(data));
        this.locationMasterData = data;
        this.locations = Object.assign(this.locationMasterData['location']);
        this.xfservice.getScreenTemplateByName('RoutingGuideFields').subscribe((data: any) => {
          this.xfields = data[0].details;
          this.xfields.forEach((element: any) => {
            if (element.visible == true) {
              element['fields'].forEach((item: any) => {
                if (item['TableVisible'] == true) {
                  this.tableRecord.fieldName = element.fieldHeader + '->' + item.fieldName;
                  this.tabledata.push(JSON.parse(JSON.stringify(this.tableRecord)));


                }

              })
            }
          })
          this.xfservice.getScreenTemplateByName('newrgruletabs').subscribe((data: any) => {
            this.tabNames = data[0].details;

            //console.log(this.tabNames);

          })
        })

      })
      this.messageService.add({
        key: 'tc',
        severity: 'success',
        summary: 'Template',
        detail: 'Routing Guide Rules are successfully saved.',
      });
      this.location.back()

    }

    )



  }

  //To add a new tab for tabview

  addTab() {
    this.tabNames.push(Object.assign(this.newtab));
    this.xfservice.saveScreenTemplateByName('newrgruletabs', this.tabNames).subscribe((data: any) => {

      this.messageService.add({
        key: 'tc',
        severity: 'success',
        summary: 'Template',
        detail: 'New Tab name added successfully.',
      });
    })
    this.newtab = {};
    this.newTabDialog_flag = false;

  }

  //To updates tab Names in tabview
  updateTabNames() {
    this.xfservice.saveScreenTemplateByName('newrgruletabs', this.tabNames).subscribe((data: any) => {

      this.messageService.add({
        key: 'tc',
        severity: 'success',
        summary: 'Template',
        detail: 'Tab Names are successfully saved.',
      });
    })
  }
  //To create new row for table in shipping Process tab
  addNewCarrier() {



    this.carrierDetails.push(JSON.parse(JSON.stringify(this.newCarrier)))


  }

  //To delete a row from table in Shipping Process tab
  deleteCarrier(carrier: any) {
    this.carrierDetails = this.carrierDetails.filter((item: any) => item != carrier)
    console.log(this.carrierDetails)
  }
  //To assign carrier name for selected record

  assigncarrier(carrier: any, event: any, element: any) {
    this.currentRowData.CarrierName = carrier;
    element.hide(event)

  }

  //to assign carrier Service for selected Record
  assigncarrierservice(service: any, event: any, element: any) {
    this.currentRowData.CarrierService = service.ServiceName;
    this.currentRowData.ServiceCode = service.ServiceCode;
    element.hide(event)
  }

  //to assign carrier Service for selected Record
  assigncarrierAccount(account: any, event: any, element: any) {
    this.currentRowData['ShippingAccount'] = account.name;

    element.hide(event)
  }


  // To Load carriers based on selected location
  loadCarriers(data: any, event: any, element: any) {
    this.currentRowData = data;
    this.carriers = [];
    this.locationMasterData['carriers'].forEach((item: any) => {
      if (item['LocationId'] == this.ruledata.LocationId && item['IsActive'] == true) {
        this.carriers.push(item['CarrierName'])
      }
    })
    element.show(event)

  }

  //to load services based on selected carrier
  loadCarrierService(data: any, event: any, element: any) {
    this.carrierServices = [];
    this.locationMasterData['carrierservices'].forEach((item: any) => {
      if (item['LocationId'] == this.ruledata.LocationId && item['IsActive'] == true && item['CarrierName'] == data['CarrierName']) {
        this.carrierServices.push({ 'ServiceName': item['ServiceName'], 'ServiceCode': item['ServiceCode'] })
      }
    })
    element.show(event);
  }


  // to Load Carrier Accounts
  //to load services based on selected carrier
  loadCarrierAccounts(data: any, event: any, element: any) {
    this.accounts = [];
    this.locationMasterData['carrieraccounts'].forEach((item: any) => {
      if (item['LocationId'] == this.ruledata.LocationId && item['IsActive'] == true && item['CarrierName'] == data['CarrierName']) {
        this.accounts.push({ 'name': item['Account'], 'code': item['Account'] })
      }
    })
    element.show(event);
  }

}
