import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GetAllDataService {
  // Create BehaviorSubjects to hold data from each component
  private dashboardDataSubject = new BehaviorSubject<any>(null);
  private shipToFromDataSubject = new BehaviorSubject<any>(null);
  private routingAndBillingDataSubject = new BehaviorSubject<any>(null);
  private packNowDataSubject = new BehaviorSubject<any>(null);


  // Expose BehaviorSubjects as Observables for components to subscribe to
  dashboardData$ = this.dashboardDataSubject.asObservable();
  shipToFromData$ = this.shipToFromDataSubject.asObservable();
  routingAndBillingData$ = this.routingAndBillingDataSubject.asObservable();
  packNowData$ = this.packNowDataSubject.asObservable();

  // Methods to update data from each component
  setDashboardData(data: any) {
    this.dashboardDataSubject.next(data);
  }

  setShipToFromData(data: any) {
    this.shipToFromDataSubject.next(data);
  }

  setRoutingAndBillingData(data: any) {
    this.routingAndBillingDataSubject.next(data);
  }

  setPackingInfoData(data: any) {
    this.packNowDataSubject.next(data);
  }

  // Method to get all data
  getAllData() {
    return {
      dashboardData: this.dashboardDataSubject.getValue(),
      packNowData: this.packNowDataSubject.getValue(),
      shipToFromData: this.shipToFromDataSubject.getValue(),
      routingAndBillingData: this.routingAndBillingDataSubject.getValue()
    };
  }
}
