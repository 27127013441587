<p-toast></p-toast>
<p-toast position="top-left" key="tl"></p-toast>
<p-toast position="top-center" key="tc"></p-toast>
<p-toast position="bottom-center" key="bc"></p-toast>

<p-toast position="bottom-center" key="c" [baseZIndex]="5000">
  <ng-template let-message pTemplate="message">
    <div class="p-flex p-flex-column" style="flex: 1">
      <div class="p-text-center">
        <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
        <h4>{{ message.summary }}</h4>
        <p>{{ message.detail }}</p>
      </div>
      <div class="p-grid p-fluid">
        <div class="p-col-6">
          <button type="button" pButton label="Yes" class="p-button-success"></button>
        </div>
        <div class="p-col-6">
          <button type="button" pButton label="No" class="p-button-secondary"></button>
        </div>
      </div>
    </div>
  </ng-template>
</p-toast>








<div class="card" [style]="{
    padding: '0em',
    overflow: 'hidden',
    height: '100vh'
  }" *ngIf="isDataLoaded">
  <p-toolbar [style]="{ height: '4em', width: '100%', padding: '0px'}">


    <div class="p-toolbar-group-left" style="margin-right:10px; padding: 10px;">
      <label class="mr-2 titlefont" id="title"
        (click)="openBlockProperties('HeaderInfo')">{{ShipScreen.HeaderInfo.fields[0].title}}</label>



      <span class="p-float-label mr-2">
        <!-- <p-dropdown [options]="LocationMasterData.erp" [(ngModel)]="shipment.HeaderInfo.FeederSystem" optionLabel="Name"
          optionValue="Name" placeholder="Connect To" [style]="{ width: '100%' }">   , background: ' #3f51b5'
        </p-dropdown> -->
        <p-dropdown [options]="LocationMasterData.erp" [(ngModel)]="shipment.HeaderInfo['ERP']" optionLabel="Name"
          [showClear]="true" optionValue="Name" placeholder="Connect To" [style]="{ width: '100%' }"
          class="p-inputtext-sm">
        </p-dropdown>
      </span>

      <span class="p-float-label mr-2">
        <p-dropdown [options]="LocationMasterData.ordertypes" optionLabel="Name" optionValue="Name" [showClear]="true"
          placeholder="Document Type" [(ngModel)]="shipment.HeaderInfo.DocumentType" [style]="{ width: '100%' }"
          class="p-inputtext-sm">
        </p-dropdown>
      </span>

      <span class="p-float-label mr-2">
        <input type="text" id="Document#" pInputText [(ngModel)]="shipment.HeaderInfo.DocumentNumber"
          class="p-inputtext-sm" />
        <label for="Document#">Docuemnt#</label>
      </span>
      <button pButton pRipple type="button" icon="pi pi-search" (click)="openHistoryDialog()"
        class="p-inputtext-sm mr-1" pTooltip="Open History"></button>
      <button pButton pRipple type="button" label="Get Document" [disabled]="!isdis == false"
        class="p-inputtext-sm mr-1" (click)="getShipReqByDocNo()"></button>

    </div>





    <div class="p-toolbar-group-right" style="margin-right:10px; padding: 10px;">





      <span class="p-float-label mr-1">
        <p-dropdown [options]="LocationMasterData.location" [autoDisplayFirst]="false" [disabled]="true"
          [(ngModel)]="shipment.HeaderInfo.Location" optionLabel="LocationName" id="Location" optionValue="LocationId"
          [style]="{ width: '100%' }" class="p-inputtext-sm">
        </p-dropdown>
        <label for="Location">Locations</label>
      </span>


      <span class="p-float-label mr-4">
        <button pButton alignFrozen="right" pFrozenColumn [frozen]="true" pRipple icon="pi pi-search"
          class="p-button-rounded  p-button-sm" (click)="locop.toggle($event)" [disabled]="!isdis == false"></button>
      </span>


      <span class="p-float-label">
        <p-calendar id="shipdate" [(ngModel)]="shipment.HeaderInfo.ShipDate" [showIcon]="true" inputId="icon"
          [readonlyInput]="true" class="mr-2"></p-calendar>
        <label for="shipdate">Ship Date</label>
      </span>
      <button pButton type="button" label="Void" class=" mr-2 p-inputtext-sm"></button>
      <!-- class="p-button-danger mr-2" -->
      <button pButton type="button" label="New " class=" mr-2 p-inputtext-sm" (click)="clear()"></button>
      <p-button icon="pi pi-times" (onClick)="closeShipScreen()" class="mr-2 p-inputtext-sm"></p-button>

      <i class="pi pi-cog  right-wrapper" style="font-size: 20px" (click)="requiredData.toggle($event)"
        *ngIf="getUserDetails() ==='SystemAdmin'"></i>
    </div>

  </p-toolbar>

  <div class="grid" *ngIf="isDataLoaded">
    <div class="col-12 md:col-6">
      <p-tabView>
        <!-- SHIP TO ADDRESS-->
        <p-tabPanel>

          <ng-template pTemplate="header">
            <span class="mr-2">Ship To </span>
            <i class="pi pi-cog  right-wrapper" style="font-size: 20px" (click)="openBlockProperties('ShipTo')"
              *ngIf="getUserDetails() ==='SystemAdmin'"></i>
          </ng-template>
          <div class="grid p-fluid mt-3">
            <ng-template ngFor let-field [ngForOf]="ShipScreen.ShipTo.fields">
              <ng-template [ngIf]="field.fieldType == 'Textbox'">
                <div class="field col-12  {{ 'md:' + field.width }}  " [hidden]="!field.visible">
                  <span class="p-float-label p-input-icon-{{ field.iconPosition }}">
                    <input [type]="field.datatype" pInputText [(ngModel)]="shipment.ShipTo[field.fieldName]"
                      (ngModelChange)="fieldValueChange('ShipTo',field.fieldName,shipment.ShipTo[field.fieldName])"
                      [required]="field.required" [disabled]="!isdis == false" />

                    <label>{{ field.title }} </label>
                    <ng-template [ngIf]="field.hasIcon == true">
                      <i class="pi {{' ' + field.icon}}" (click)="openAddressBook('ShipTo')"></i>
                    </ng-template>
                  </span>


                </div>
              </ng-template>


              <ng-template [ngIf]="field.fieldType == 'Button'">
                <div class="field col-12 {{'md:' + field.width }}">
                  <ng-template [ngIf]="field.hasIcon == true">
                    <button pButton type="button" [label]="field.title" [icon]="field.icon" class="mr-2"
                      [iconPos]="field.iconPosition"
                      (click)="processButtonClick('ShipTo',field.fieldName,shipment.CarrierDetails[field.fieldName],field.RelatedFields,field.ConditionFieldName)"></button>
                  </ng-template>
                  <ng-template [ngIf]="field.hasIcon == false">


                    <button pButton type="button" [label]="field.title" class="mr-2" [loading]="addressvalidationload"
                      (click)="processButtonClick('ShipTo',field.fieldName,shipment.CarrierDetails[field.fieldName],field.RelatedFields,field.ConditionFieldName)"></button>
                  </ng-template>
                </div>

              </ng-template>


              <ng-template [ngIf]="field.fieldType == 'Dropdown'">
                <div class="field col-12 {{'md:' + field.width }}">
                  <span class="p-float-label">

                    <p-dropdown [options]="LocationMasterData[field.source]" [optionLabel]="field.optionName"
                      [optionValue]="field.optionValue" [(ngModel)]="shipment.ShipTo[field.fieldName]"
                      [autoDisplayFirst]="false" [filter]="true" id="ShipToCountry" (onChange)="changeCountry($event)"
                      [style]="{ width: '100%' }" (keyup.esc)="onKeyUp($event , field.fieldName ,'ShipTo')"
                      [disabled]="!isdis == false"> </p-dropdown>
                    <label for="ShipToCountry">{{ field.title }}</label>
                  </span>
                </div>
              </ng-template>
            </ng-template>
          </div>
        </p-tabPanel>

        <!-- SHIP FROM ADDRESS-->

        <p-tabPanel>
          <ng-template pTemplate="header">
            <span class="mr-2">Ship From </span>
            <i class="pi pi-cog  right-wrapper" style="font-size: 20px" (click)="openBlockProperties('ShipFrom')"
              *ngIf="getUserDetails() ==='SystemAdmin'"></i>
          </ng-template>
          <div class="grid p-fluid mt-3">
            <ng-template ngFor let-field [ngForOf]="ShipScreen.ShipFrom.fields">
              <ng-template [ngIf]="field.fieldType == 'Textbox'">
                <div class="field col-12  {{ 'md:' + field.width }}  ">
                  <span class="p-float-label p-input-icon-{{ field.iconPosition }}">
                    <input [type]="field.datatype" pInputText [(ngModel)]="shipment.ShipFrom[field.fieldName]"
                      [required]="field.required" [disabled]="!isdis == false" />
                    <label>{{ field.title }}</label>
                    <ng-template [ngIf]="field.hasIcon == true">
                      <i class="pi {{' ' + field.icon}}" (click)="openAddressBook('ShipFrom')"></i>
                    </ng-template>
                  </span>
                </div>
              </ng-template>
              <ng-template [ngIf]="field.fieldType == 'Dropdown'">

                <div class="field col-12 {{'md:' + field.width }}">

                  <span class="p-float-label">

                    <p-dropdown [options]="LocationMasterData[field.source]" [optionLabel]="field.optionName"
                      [optionValue]="field.optionValue" [(ngModel)]="shipment.ShipFrom[field.fieldName]"
                      [autoDisplayFirst]="false" [filter]="true" id="ShipToCountry" (onChange)="changeCountry($event)"
                      [style]="{ width: '100%' }" [disabled]="!isdis == false"></p-dropdown>
                    <label for="ShipToCountry">{{ field.title }}</label>
                  </span>
                </div>
              </ng-template>
            </ng-template>
          </div>
        </p-tabPanel>

        <!-- SHIPPER  ADDRESS-->

        <p-tabPanel>
          <ng-template pTemplate="header">
            <span class="mr-2">Shipper </span>
            <i class="pi pi-cog  right-wrapper" style="font-size: 20px" (click)="openBlockProperties('Shipper')"
              *ngIf="getUserDetails() ==='SystemAdmin'"></i>
          </ng-template>
          <div class="grid p-fluid mt-3">
            <!-- <ng-template ngFor let-i="index" let-c="count" let-contact [ngForOf]="contacts | async">-->
            <ng-template ngFor let-field [ngForOf]="ShipScreen.Shipper.fields">
              <ng-template [ngIf]="field.fieldType == 'Textbox'">
                <div class="field col-12  {{ 'md:' + field.width }}  ">
                  <span class="p-float-label p-input-icon-{{ field.iconPosition }}">
                    <input [type]="field.datatype" pInputText [(ngModel)]="shipment.Shipper[field.fieldName]"
                      [required]="field.required" [disabled]="!isdis == false" />
                    <label>{{ field.title }}</label>
                    <ng-template [ngIf]="field.hasIcon == true">
                      <i class="pi {{' ' + field.icon}}" (click)="openAddressBook('Shipper')"></i>
                    </ng-template>
                  </span>
                </div>
              </ng-template>
              <ng-template [ngIf]="field.fieldType == 'Dropdown'">
                <div class="field col-12 {{'md:' + field.width }}">
                  <span class="p-float-label">
                    <p-dropdown [options]="LocationMasterData[field.source]" [optionLabel]="field.optionName"
                      [optionValue]="field.optionValue" [(ngModel)]="shipment.Shipper[field.fieldName]"
                      [autoDisplayFirst]="false" [filter]="true" id="ShipToCountry" (onChange)="changeCountry($event)"
                      [style]="{ width: '100%' }" [disabled]="!isdis == false"></p-dropdown>
                    <label for="ShipToCountry">{{ field.title }}</label>
                  </span>
                </div>
              </ng-template>
            </ng-template>
          </div>
        </p-tabPanel>

        <!-- SOLD TO  ADDRESS-->

        <p-tabPanel>
          <ng-template pTemplate="header">
            <span class="mr-2">SoldTo </span>
            <i class="pi pi-cog  right-wrapper" style="font-size: 20px" (click)="openBlockProperties('SoldTo')"
              *ngIf="getUserDetails() ==='SystemAdmin'"></i>
          </ng-template>
          <div class="grid p-fluid mt-3">
            <ng-template ngFor let-field [ngForOf]="ShipScreen.SoldTo.fields">
              <ng-template [ngIf]="field.fieldType == 'Textbox'">
                <div class="field col-12  {{ 'md:' + field.width }}  ">
                  <span class="p-float-label p-input-icon-{{ field.iconPosition }}">
                    <input [type]="field.datatype" pInputText [(ngModel)]="shipment.SoldTo[field.fieldName]"
                      [required]="field.required" [disabled]="!isdis == false" />
                    <label>{{ field.title }}</label>
                    <ng-template [ngIf]="field.hasIcon == true">
                      <i class="pi {{' ' + field.icon}}" (click)="openAddressBook('SoldTo')"></i>
                    </ng-template>
                  </span>
                </div>
              </ng-template>
              <ng-template [ngIf]="field.fieldType == 'Dropdown'">
                <div class="field col-12 {{'md:' + field.width }}">
                  <span class="p-float-label">
                    <p-dropdown [options]="LocationMasterData[field.source]" [optionLabel]="field.optionName"
                      [optionValue]="field.optionValue" [(ngModel)]="shipment.SoldTo[field.fieldName]"
                      [autoDisplayFirst]="false" [filter]="true" id="ShipToCountry" (onChange)="changeCountry($event)"
                      [style]="{ width: '100%' }" [disabled]="!isdis == false"></p-dropdown>
                    <label for="ShipToCountry">{{ field.title }}</label>
                  </span>
                </div>
              </ng-template>
            </ng-template>
          </div>
        </p-tabPanel>

      </p-tabView>
    </div>


    <!-- CARRIER BLOCK START HERE-->





    <div class="col-12 md:col-6">
      <p-tabView>
        <p-tabPanel>

          <ng-template pTemplate="header">
            <span class="mr-2">Carrier Information </span>
            <i class="pi pi-cog  right-wrapper mr-4" style="font-size: 20px"
              (click)="openBlockProperties('CarrierDetails')" *ngIf="getUserDetails() ==='SystemAdmin'"></i>
          </ng-template>




          <div class="grid p-fluid mt-3">
            <ng-template ngFor let-field [ngForOf]="ShipScreen.CarrierDetails.fields">
              <ng-template [ngIf]="field.fieldType == 'Textbox'">
                <div class="field col-12  {{ 'md:' + field.width }}  " [hidden]="!field.visible">
                  <span class="p-float-label p-input-icon-{{ field.iconPosition }}">
                    <input [type]="field.datatype" pInputText [(ngModel)]="shipment.CarrierDetails[field.fieldName]"
                      [required]="field.required" [disabled]="!isdis == false" />

                    <label>{{ field.title }}</label>
                    <ng-template [ngIf]="field.hasIcon == true">
                      <i class="pi {{' ' + field.icon}}"></i>
                    </ng-template>


                  </span>
                </div>
              </ng-template>
              <ng-template [ngIf]="field.fieldType == 'Dropdown'">
                <div class="field col-12 {{'md:' + field.width }}" [hidden]="!field.visible">
                  <span class="p-float-label">
                    <p-dropdown [options]="LocationMasterData[field.source]"
                      [(ngModel)]="shipment.CarrierDetails[field.fieldName]" [autoDisplayFirst]="false"
                      [showClear]="true" [optionLabel]="field.optionName" [filter]="true"
                      [optionValue]="field.optionValue" [style]="{ width: '100%' }" [disabled]="!isdis == false"
                      (onChange)="processChange('CarrierDetails',field.fieldName,shipment.CarrierDetails[field.fieldName],field.RelatedFields,field.ConditionFieldName)">
                    </p-dropdown>
                    <label [for]="field.fieldName">{{ field.title }}</label>
                  </span>
                </div>
              </ng-template>


              <ng-template [ngIf]="field.fieldType == 'Button'">
                <div class="field col-12 {{'md:' + field.width }}" [hidden]="!field.visible">
                  <span class="p-float-label">
                    <ng-template [ngIf]="field.hasIcon == true">
                      <button pButton type="button" [label]="field.title" [icon]="field.icon"
                        [iconPos]="field.iconPosition" [disabled]="!isdis == false"
                        (click)="processButtonClick('CarrierDetails',field.fieldName,shipment.CarrierDetails[field.fieldName],field.RelatedFields,field.ConditionFieldName)"></button>
                    </ng-template>
                    <ng-template [ngIf]="field.hasIcon == false">
                      <button pButton type="button" [label]="field.title"
                        (click)="processButtonClick('CarrierDetails',field.fieldName,shipment.CarrierDetails[field.fieldName],field.RelatedFields,field.ConditionFieldName)"></button>
                    </ng-template>


                  </span>
                </div>
              </ng-template>


            </ng-template>
          </div>
        </p-tabPanel>

        <!-- SHIPMENT SPECIAL SERVICE TAB-->

        <p-tabPanel header="Special Services">

          <p-tabView [scrollable]="true">
            <ng-template ngFor let-ss [ngForOf]="specialServiceData.Shipment">

              <ng-template [ngIf]="ss.isActive">
                <p-tabPanel [header]="ss.SSName">
                  <div class="grid p-fluid mt-3">
                    <!-- To Display Textbox -->
                    <ng-template ngFor let-field [ngForOf]="ss.fields">

                      <ng-template [ngIf]="field.isActive">
                        <ng-template [ngIf]="field.fieldType == 'Textbox'">
                          <div class="field col-12  {{ ' md:' + field.width }}  " [hidden]="!field.visible">
                            <span class="p-float-label">

                              <input [type]="field.datatype" pInputText
                                [(ngModel)]="shipment.ShipmentLevelServices[ ss.SSName +'_'+ field.fieldName ] " />
                              <!-- <input [type]="field.datatype" pInputText /> -->
                              <label>{{ field.title }}</label>
                            </span>
                          </div>
                        </ng-template>
                        <!-- To display Dropdown -->
                        <ng-template [ngIf]="field.fieldType == 'Dropdown'">
                          <div class="field col-12 {{'md:' + field.width }}" [hidden]="!field.visible">
                            <span class="p-float-label">

                              <p-dropdown [options]="field.source" [autoDisplayFirst]="false" [showClear]="true"
                                [(ngModel)]="shipment.ShipmentLevelServices[ ss.SSName +'_'+ field.fieldName ]"
                                [optionLabel]="field.optionName" [filter]="true" [optionValue]="field.optionValue"
                                [style]="{ width: '100%' }">
                              </p-dropdown>
                              <label [for]="field.fieldName">{{ field.title }}</label>
                            </span>
                          </div>
                        </ng-template>
                        <!-- To Display Button -->
                        <ng-template [ngIf]="field.fieldType == 'Button'">
                          <div class="field col-12 {{'md:' + field.width }}" [hidden]="!field.visible">
                            <span class="p-float-label">
                              <button pButton type="button" [label]="field.title"></button>
                            </span>
                          </div>
                        </ng-template>
                        <!-- To display Checkbox -->
                        <ng-template [ngIf]="field.fieldType == 'Checkbox'">
                          <div class="field col-12 {{'md:' + field.width }}" [hidden]="!field.visible">
                            <p-checkbox class="mr-2" [binary]="true" inputid="field.fieldName"
                              [(ngModel)]="shipment.ShipmentLevelServices[ ss.SSName +'_'+ field.fieldName ]"></p-checkbox>
                            <label class="mr-2" for="field.fieldname">{{ field.title }}</label>
                          </div>
                        </ng-template>
                        <!-- To Display MultiSelect -->
                        <ng-template [ngIf]="field.fieldType == 'MultiSelect'">
                          <div class="field col-12 {{'md:' + field.width }}" [hidden]="!field.visible">
                            <span class="p-float-label">
                              <p-multiSelect [options]="field.source" optionLabel="name"
                                [(ngModel)]="shipment.ShipmentLevelServices[ ss.SSName +'_'+ field.fieldName ]"
                                optionValue="name"></p-multiSelect>
                              <label for="field.fieldName">{{ field.title }}</label>
                            </span>
                          </div>
                        </ng-template>

                      </ng-template>

                    </ng-template>
                  </div>
                </p-tabPanel>
              </ng-template>

            </ng-template>
          </p-tabView>




        </p-tabPanel>



      </p-tabView>
    </div>
  </div>

  <!--  2nd part of screen -->
  <div class="card" [style]="{ padding: '0px' }">
    <p-tabView>
      <p-tabPanel header="Packing" [style]="{ padding: '0px' }">
        <div class="grid" [style]="{ padding: '0px', height: '50vh'}">
          <!--    PACKING TAB BUTTONS DIV START -->
          <div class="card" [style]="{padding: '0px',  height: '15px', width: '100%' , float:'left'}"
            *ngIf="isDataLoaded">


            <div [style]="{padding: '0px',  width: '50%',  float: 'left'}">
              <button pButton type="button" (click)="openMasterProducts()" icon="pi pi-search-plus" label="Add Product"
                class="p-button-sm" iconPos="right" [disabled]="!isdis == false"></button>

              <!-- <p-checkbox [(ngModel)]="autoPack" [binary]="true" id="autopack"></p-checkbox>
              <label for="autopack">Auto Pack</label>
              <p-checkbox [(ngModel)]="autoShip" [binary]="true" id="autoship"></p-checkbox>
              <label for="autoship">Auto Ship</label> -->







              <i class="pi pi-cog  right-wrapper" style="font-size: 20px"
                (click)="openTableProperties(ShipScreen.Items.columns , 'Item Grid')"
                *ngIf="getUserDetails() ==='SystemAdmin'"></i>
            </div>
            <div [style]="{padding: '0px',  width: '50%', float: 'left'}">
              <i class="pi pi-cog  right-wrapper" style="font-size: 20px"
                (click)="openTableProperties(ShipScreen.Packages.columns,'package Grid')"
                *ngIf="getUserDetails() ==='SystemAdmin'"></i>
            </div>





          </div>

          <!--    PACKING TAB BUTTONS DIV END-->
          <div class="card" [style]="{
              padding: '0px',
              height: '40vh',
              width: '50%',
              float: 'left'
            }" *ngIf="isDataLoaded">
            <p-table [columns]="ShipScreen.Items.columns" [value]="shipment.Items"
              [style]="{ width: '100%', height: '80vh', overflow: 'scroll' }"
              styleClass="p-datatable-sm p-datatable-gridlines p-datatable-striped " responsiveLayout="stack"
              (onEditComplete)="onEditComplete($event)"
              (keyup.esc)="onKeyUp($event , ShipScreen.Items.columns ,'Table')">
              <ng-template pTemplate="header" let-columns>
                <tr>
                  <ng-template ngFor let-col [ngForOf]="columns">
                    <ng-template [ngIf]="col.visible === true">
                      <th>{{ col.header }}</th>
                    </ng-template>

                  </ng-template>

                  <th></th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData let-columns="columns">
                <tr>
                  <ng-template ngFor let-col [ngForOf]="columns" let-i="index">
                    <ng-template [ngIf]="col.visible === true">


                      <ng-template [ngIf]="col.fieldType === 'Textbox'">
                        <td pEditableColumn [pEditableColumn]="rowData" [pEditableColumnField]="col.fieldName">
                          <p-cellEditor>
                            <ng-template pTemplate="input">
                              <input pInputText [type]="col.datatype" [style]="{ border: '0px' }"
                                [readonly]="!col.editable" [(ngModel)]="rowData[col.fieldName]" [ngStyle]="{
                                  'background-color':
                                    rowData.Partial > rowData.Balance
                                      ? 'red'
                                      : null
                                }" />
                            </ng-template>
                            <ng-template pTemplate="output">
                              {{ rowData[col.fieldName] }}
                            </ng-template>
                          </p-cellEditor>
                        </td>
                      </ng-template>

                      <ng-template [ngIf]="col.fieldType === 'Checkbox'">
                        <td>
                          <p-checkbox [(ngModel)]="rowData[col.fieldName]" [binary]="true"></p-checkbox>
                        </td>
                      </ng-template>


                    </ng-template>




                  </ng-template>
                  <td>
                    <button pButton alignFrozen="right" pFrozenColumn [frozen]="true" pRipple icon="pi pi-trash"
                      class="p-button-rounded p-button-danger p-button-sm" (click)="deleteItem(rowData)"></button>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>

          <!------PACKING TABLE -->

          <div class="card" [style]="{
              padding: '0px',
              height: '60vh',
              width: '50%',
              scroll: true
            }" *ngIf="isDataLoaded">

            <p-table [columns]="ShipScreen.Packages.columns" [value]="shipment.Packages"
              [style]="{ width: '100%', height: '80vh', overflow: 'scroll' }"
              styleClass="p-datatable-sm p-datatable-gridlines p-datatable-striped " responsiveLayout="stack">
              <ng-template pTemplate="header" let-columns>
                <tr>
                  <ng-template ngFor let-col [ngForOf]="columns">
                    <ng-template [ngIf]="col.visible === true">
                      <th>{{ col.header }}</th>
                    </ng-template>



                  </ng-template>

                  <th>options</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData let-columns="columns">
                <tr>
                  <ng-template ngFor let-col [ngForOf]="columns" let-i="index">

                    <ng-template [ngIf]="col.visible === true">
                      <ng-template [ngIf]="(col.fieldType === 'Textbox')  || (col.fieldType === 'TextBox')">
                        <td pEditableColumn>
                          <p-cellEditor>
                            <ng-template pTemplate="input">
                              <input pInputText type="text" [style]="{ border: '0px' }" [readonly]="!col.editable"
                                [(ngModel)]="rowData[col.fieldName]" />
                            </ng-template>
                            <ng-template pTemplate="output">
                              {{ rowData[col.fieldName] }}
                            </ng-template>
                          </p-cellEditor>
                        </td>
                      </ng-template>

                      <ng-template [ngIf]="col.fieldType === 'Checkbox'">
                        <td>
                          <p-checkbox [(ngModel)]="rowData[col.fieldName]" [binary]="true"></p-checkbox>
                        </td>
                      </ng-template>

                      <ng-template [ngIf]="col.fieldType === 'Dropdown'">
                        <td [style]="{width:'150px'}">
                          <p-dropdown [options]="LocationMasterData.dimensions" [(ngModel)]="rowData[col.fieldName]"
                            appendTo="body" optionLabel="Name" [editable]="true" [filter]="true" optionValue="Name"
                            [style]="{ width: '100%' }">
                          </p-dropdown>
                        </td>
                      </ng-template>


                    </ng-template>


                  </ng-template>

                  <td [style]="{width:'150px'}">

                    <button pButton pRipple icon="pi pi-search-plus" class="p-button-rounded  p-button-sm mr-2"
                      (click)="openPackageSpecialService(rowData)"></button>

                    <button pButton pRipple icon="pi pi-search" class="p-button-rounded  p-button-sm mr-2"
                      (click)="showHUDetails(rowData)"></button>

                    <button pButton pRipple icon="pi pi-trash" class="p-button-rounded p-button-danger p-button-sm mr-2"
                      (click)="deleteHu(rowData)" [disabled]="!isdis == false"></button>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>

        <!------------------------------------------------------------>
      </p-tabPanel>

      <p-tabPanel>
        <ng-template pTemplate="header">
          <span class="mr-2">International Details </span>
          <i class="pi pi-cog  right-wrapper" style="font-size: 20px"
            (click)="openBlockProperties('InternationalDetails')" *ngIf="getUserDetails() ==='SystemAdmin'"></i>
        </ng-template>

        <div class="grid p-fluid mt-3">

          <ng-template [ngIf]="shipment.InternationalDetails">
            <!-- To Display Textbox -->
            <ng-template ngFor let-field [ngForOf]="this.ShipScreen.InternationalDetails.fields">



              <ng-template [ngIf]="field.fieldType == 'Textbox'">
                <div class="field col-12  {{ ' md:' + field.width }}  " [hidden]="!field.visible">
                  <span class="p-float-label">

                    <input [type]="field.datatype" pInputText
                      [(ngModel)]="shipment.InternationalDetails[field.fieldName]" />
                    <!-- <input [type]="field.datatype" pInputText /> -->
                    <label>{{ field.title }}</label>
                  </span>
                </div>
              </ng-template>
              <!-- To display Dropdown -->
              <ng-template [ngIf]="field.fieldType == 'Dropdown'">
                <div class="field col-12 {{'md:' + field.width }}" [hidden]="!field.visible">
                  <span class="p-float-label">

                    <p-dropdown [options]="field.defaultValue" [autoDisplayFirst]="false" [showClear]="true"
                      [optionLabel]="field.optionLabel" [filter]="true" [optionValue]="field.optionName"
                      [(ngModel)]="shipment.InternationalDetails[field.fieldName]" [style]="{ width: '100%' }">
                    </p-dropdown>
                    <label [for]="field.fieldName">{{ field.title }}</label>
                  </span>
                </div>
              </ng-template>


            </ng-template>
          </ng-template>



        </div>



        <!-- COMMODITY DETAILS START-->

        <div class="card" [style]="{
          padding: '0px',
       
          width: '100%',
          float: 'left'
        }" *ngIf="isDataLoaded">


          <p-table [columns]="ShipScreen.Items.columns" [value]="shipment.InternationalDetails.ItemInfo"
            [style]="{ width: '100%', height: '80vh', overflow: 'scroll' }"
            styleClass="p-datatable-sm p-datatable-gridlines p-datatable-striped " responsiveLayout="stack">
            <ng-template pTemplate="header" let-columns>
              <tr>
                <ng-template ngFor let-col [ngForOf]="columns">
                  <ng-template
                    [ngIf]="(col.header != 'S.no') && (col.header != 'Quantity') && (col.header != 'Balance') && (col.header !='DG') && (col.header !='Dimension') && (col.header !='UN#') && (col.header !='Class') && (col.header !='NMFC') && (col.header !='Category') && (col.header !='Serial') && (col.header !='Batch') && (col.header !='Stackable')">
                    <ng-container *ngIf="col.header =='Partial'; then parial; else nonPartial">
                    </ng-container>
                    <!-- <th>{{ col.header }}</th> -->
                  </ng-template>

                  <ng-template #parial>
                    <th>Quantity</th>
                  </ng-template>
                  <ng-template #nonPartial>
                    <th>{{ col.header }}</th>
                  </ng-template>




                </ng-template>

                <th></th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-columns="columns">
              <tr>
                <ng-template ngFor let-col [ngForOf]="columns" let-i="index">

                  <ng-template
                    [ngIf]="(col.header != 'S.no') && (col.header != 'Quantity') && (col.header != 'Balance') && (col.header !='DG') && (col.header !='Dimension') && (col.header !='UN#') && (col.header !='Class') && (col.header !='NMFC') && (col.header !='Category') && (col.header !='Serial') && (col.header !='Batch') && (col.header !='Stackable')">

                    <ng-template [ngIf]="col.fieldType === 'Textbox'">
                      <td pEditableColumn [pEditableColumn]="rowData" [pEditableColumnField]="col.fieldName">
                        <p-cellEditor>
                          <ng-template pTemplate="input">
                            <input pInputText [type]="col.datatype" [readonly]="!col.editable"
                              [(ngModel)]="rowData[col.fieldName]" />
                          </ng-template>
                          <ng-template pTemplate="output">
                            {{ rowData[col.fieldName] }}
                          </ng-template>
                        </p-cellEditor>
                      </td>
                    </ng-template>
                    <ng-template [ngIf]="col.fieldType === 'Checkbox'">
                      <td>
                        <p-checkbox [(ngModel)]="rowData[col.fieldName]" [binary]="true"></p-checkbox>
                      </td>
                    </ng-template>

                  </ng-template>




                </ng-template>
                <!-- <td>
                <button pButton alignFrozen="right" pFrozenColumn [frozen]="true" pRipple icon="pi pi-trash"
                  class="p-button-rounded p-button-danger p-button-sm" (click)="deleteItem(rowData)"></button>
              </td> -->
              </tr>
            </ng-template>
          </p-table>
        </div>

        <!-- COMMODITY DETAILS END-->


      </p-tabPanel>
      <p-tabPanel header="Shipping Documents">



        <i class="pi pi-cog  right-wrapper" style="font-size: 20px"
          (click)="openTableProperties(ShipScreen.shippingDocuments['columns'] ,'shippingDocuments')"
          *ngIf="getUserDetails() ==='SystemAdmin'"></i>
        <div class="card">




          <p-table [columns]="ShipScreen.shippingDocuments.columns" [value]="shipment.shippingDocuments"
            [style]="{ width: '100%', height: '80vh', overflow: 'scroll' }"
            styleClass="p-datatable-sm p-datatable-gridlines p-datatable-striped " responsiveLayout="stack">
            <ng-template pTemplate="header" let-columns>
              <tr>
                <ng-template ngFor let-col [ngForOf]="columns">
                  <ng-template [ngIf]="col.visible === true">
                    <th>{{ col.header }}</th>
                  </ng-template>



                </ng-template>

                <th>options</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-columns="columns">
              <tr>
                <ng-template ngFor let-col [ngForOf]="columns" let-i="index">

                  <ng-template [ngIf]="col.visible === true">
                    <ng-template [ngIf]="(col.fieldType === 'Textbox')  || (col.fieldType === 'TextBox')">
                      <td pEditableColumn>
                        <p-cellEditor>
                          <ng-template pTemplate="input">
                            <input pInputText type="text" [style]="{ border: '0px' }" [readonly]="!col.editable"
                              [(ngModel)]="rowData[col.fieldName]" />
                          </ng-template>
                          <ng-template pTemplate="output">
                            {{ rowData[col.fieldName] }}
                          </ng-template>
                        </p-cellEditor>
                      </td>
                    </ng-template>

                    <ng-template [ngIf]="col.fieldType === 'Checkbox'">
                      <td>
                        <p-checkbox [(ngModel)]="rowData[col.fieldName]" [binary]="true"></p-checkbox>
                      </td>
                    </ng-template>

                    <ng-template [ngIf]="col.fieldType === 'Dropdown'">
                      <td [style]="{width:'150px'}">
                        <p-dropdown [options]="LocationMasterData.dimensions" [(ngModel)]="rowData[col.fieldName]"
                          appendTo="body" optionLabel="Name" [editable]="true" [filter]="true" optionValue="Name"
                          [style]="{ width: '100%' }">
                        </p-dropdown>
                      </td>
                    </ng-template>


                  </ng-template>


                </ng-template>

                <td [style]="{width:'150px'}">

                  <button pButton pRipple icon="pi pi-search-plus" class="p-button-rounded  p-button-sm mr-2"
                    (click)="showLabel(rowData)"></button>

                  <button pButton pRipple icon="pi pi-print" class="p-button-rounded  p-button-sm mr-2"
                    (click)="printLabel(rowData)"></button>


                </td>
              </tr>
            </ng-template>
          </p-table>





        </div>
      </p-tabPanel>
      <p-tabPanel header="Shipping Charges">


        <i class="pi pi-cog  right-wrapper" style="font-size: 20px"
          (click)="openTableProperties(ShipScreen.shippingCharges['columns'] ,'shippingCharges')"
          *ngIf="getUserDetails() ==='SystemAdmin'"></i>


        <div class="card">


          <p-table [columns]="ShipScreen.shippingCharges.columns" [value]="shipment.shippingCharges"
            [style]="{ width: '100%', height: '80vh', overflow: 'scroll' }"
            styleClass="p-datatable-sm p-datatable-gridlines p-datatable-striped " responsiveLayout="stack">
            <ng-template pTemplate="header" let-columns>
              <tr>
                <ng-template ngFor let-col [ngForOf]="columns">
                  <ng-template [ngIf]="col.visible === true">
                    <th>{{ col.header }}</th>
                  </ng-template>



                </ng-template>


              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-columns="columns">
              <tr>
                <ng-template ngFor let-col [ngForOf]="columns" let-i="index">

                  <ng-template [ngIf]="col.visible === true">
                    <ng-template [ngIf]="(col.fieldType === 'Textbox')  || (col.fieldType === 'TextBox')">
                      <td pEditableColumn>
                        <p-cellEditor>
                          <ng-template pTemplate="input">
                            <input pInputText type="text" [style]="{ border: '0px' }" [readonly]="!col.editable"
                              [(ngModel)]="rowData[col.fieldName]" />
                          </ng-template>
                          <ng-template pTemplate="output">
                            {{ rowData[col.fieldName] }}
                          </ng-template>
                        </p-cellEditor>
                      </td>
                    </ng-template>

                    <ng-template [ngIf]="col.fieldType === 'Checkbox'">
                      <td>
                        <p-checkbox [(ngModel)]="rowData[col.fieldName]" [binary]="true"></p-checkbox>
                      </td>
                    </ng-template>

                    <ng-template [ngIf]="col.fieldType === 'Dropdown'">
                      <td [style]="{width:'150px'}">
                        <p-dropdown [options]="LocationMasterData.dimensions" [(ngModel)]="rowData[col.fieldName]"
                          appendTo="body" optionLabel="Name" [editable]="true" [filter]="true" optionValue="Name"
                          [style]="{ width: '100%' }">
                        </p-dropdown>
                      </td>
                    </ng-template>


                  </ng-template>


                </ng-template>


              </tr>
            </ng-template>
          </p-table>




        </div>
      </p-tabPanel>
    </p-tabView>
  </div>


  <!-- FOOTER START-->
  <div class="col-12 md:col-6  footer">

    <div class="grid" *ngIf="isDataLoaded">
      <div class="col-12 md:col-6">

        <i class="pi pi-cog  mr-2" style="font-size: 20px" (click)="openBlockProperties('FOOTER_LEFT')"
          *ngIf="getUserDetails() ==='SystemAdmin'"></i>

        <p-button label="Pack" (onClick)="createHu()" class="mr-2" [disabled]="!isdis == false"></p-button>
        <p-button label="Pack All" (onClick)="createHu_packall()" class="mr-2" [disabled]="!isdis == false"></p-button>
        <p-button label="Repeat Pack" (onClick)="calculateBoxes()" class="mr-2" [disabled]="!isdis == false"></p-button>


        <p-button label="Freight Shop" [loading]="rateloading" (onClick)="processFreightShop()" class="mr-2"
          [disabled]="!isdis == false"></p-button>

        <ng-template ngFor let-field [ngForOf]="ShipScreen.FOOTER_LEFT.fields">
          <ng-template [ngIf]="field.standard !== true">
            <ng-template [ngIf]="field.fieldType == 'Button'">
              <ng-template [ngIf]="field.hasIcon == true">
                <button pButton type="button" [label]="field.title" [icon]="field.icon" class="mr-2"
                  [iconPos]="field.iconPosition"
                  (click)="processButtonClick('FOOTER_LEFT',field.fieldName,shipment.CarrierDetails[field.fieldName],field.RelatedFields,field.ConditionFieldName)"></button>
              </ng-template>
              <ng-template [ngIf]="field.hasIcon == false">
                <button pButton type="button" [label]="field.title" class="mr-2"
                  (click)="processButtonClick('FOOTER_LEFT',field.fieldName,shipment.CarrierDetails[field.fieldName],field.RelatedFields,field.ConditionFieldName)"></button>
              </ng-template>
            </ng-template>
          </ng-template>

        </ng-template>


      </div>



      <div class="col-12 md:col-6">
        <i class="pi pi-cog  right-wrapper" style="font-size: 20px" (click)="openBlockProperties('FOOTER_RIGHT')"
          *ngIf="getUserDetails() ==='SystemAdmin'"></i>
        <!-- <p-button [label]="saveButtontitle" (onClick)="Save()" class="mr-2  right-wrapper"></p-button> -->
        <p-button labe="Ship" (onClick)="getCarrierByRoutingRules()" class="mr-2  right-wrapper"
          [disabled]="!isdis == false">Get Carrier</p-button>

        <p-button labe="Ship" [loading]="shiploading" (onClick)="Ship()" class="mr-2  right-wrapper"
          [disabled]="!isdis == false">Ship</p-button>
      </div>
    </div>

    <!-- -->


  </div>


  <!-- FOOTER END-->

  <!--  HU DETAILS DIALOG START -->
  <p-dialog [(visible)]="hudetails_dialog" [style]="{ width: '70%' }" header="HU Details" [modal]="true"
    styleClass="p-fluid">
    <div class="card" [style]="{
              padding: '0px',
              height: '50vh',
              width: '99%',

              float: 'left'
            }" *ngIf="isDataLoaded">
      <p-table [columns]="ShipScreen.Items.columns" [value]="selectedHUItems"
        [style]="{ width: '100%', height: '49vh', overflow: 'scroll' }"
        styleClass="p-datatable-sm p-datatable-gridlines p-datatable-striped " responsiveLayout="stack">
        <ng-template pTemplate="header" let-columns>

          <tr>
            <ng-template ngFor let-col [ngForOf]="columns">
              <th>{{ col.header }}</th>
            </ng-template>

            <th></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns">
          <tr>
            <ng-template ngFor let-col [ngForOf]="columns" let-i="index">
              <ng-template [ngIf]="col.fieldType === 'Textbox'">
                <td>
                  {{ rowData[col.fieldName] }}
                </td>
              </ng-template>

              <ng-template [ngIf]="col.fieldType === 'Checkbox'">
                <td>
                  <p-checkbox [(ngModel)]="rowData[col.fieldName]" [binary]="true" [disabled]="true"></p-checkbox>
                </td>
              </ng-template>
            </ng-template>
            <td>
              <button pButton alignFrozen="right" pFrozenColumn [frozen]="true" pRipple icon="pi pi-trash"
                class="p-button-rounded p-button-danger p-button-sm" (click)="showHUDetails(rowData)"></button>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </p-dialog>
  <!--  HU DETAILS DIALOG END -->


  <!-- MASTER PRODUCTS DIALOG START-->
  <p-dialog [(visible)]="MasterProduct_dialog" [style]="{ width: '70%' }" header="Product Details" [modal]="true"
    styleClass="p-fluid">
    <div class="card" [style]="{
              padding: '0px',
              height: '70vh',
              width: '99%',

              float: 'left'
            }" *ngIf="isDataLoaded">
      <p-table #dt1 [columns]="productscol" [value]="Master_products" [style]="{ width: '100%', height: '90%' }"
        styleClass="p-datatable-sm p-datatable-gridlines p-datatable-striped " responsiveLayout="stack"
        [globalFilterFields]="['ItemNo','ProductNo','Description','Dimension','UN','Category']" [paginator]="true"
        [rows]="10">

        <!----------------------------------------->

        <ng-template pTemplate="caption">
          <div style="text-align: right">
            <i class="pi pi-search" style="margin:4px 4px 0 0"></i>

            <input type="text" pInputText size="50" placeholder="Global Filter"
              (input)="applyFilterGlobal($event, 'contains')" style="width:auto">
          </div>
        </ng-template>


        <!---------------------------------------------->







        <ng-template pTemplate="header" let-columns>
          <tr>
            <th alignFrozen="left" pFrozenColumn [frozen]="true">Select</th>
            <ng-template ngFor let-col [ngForOf]="columns">
              <th pSortableColumn="{{ col.header }}">{{ col.header }}<p-sortIcon field="{{ col.fieldName }}">
                </p-sortIcon>
              </th>
            </ng-template>


          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns">
          <tr>
            <td>
              <button pButton type="button" icon="pi pi-check" iconPos="left" class="p-button-rounded p-button-sm"
                alignFrozen="left" pFrozenColumn [frozen]="true" (click)="addProduct(rowData)"></button>
            </td>
            <ng-template ngFor let-col [ngForOf]="columns" let-i="index">
              <ng-template [ngIf]="col.fieldType === 'Textbox'">
                <td>
                  {{ rowData[col.field] }}
                </td>
              </ng-template>
              <ng-template [ngIf]="col.fieldType === 'Checkbox'">
                <td>
                  <p-checkbox [(ngModel)]="rowData[col.fieldName]" [binary]="true" [disabled]="true"></p-checkbox>
                </td>
              </ng-template>
            </ng-template>

          </tr>
        </ng-template>
      </p-table>
    </div>
  </p-dialog>
  <!-- MASTER PRODUCTS DIALOG  END-->




  <!-- ADDRESSBOOK  DIALOG START-->
  <p-dialog [(visible)]="AddressBook_dialog" [style]="{ width: '70%' }" header="Address Book" [modal]="true"
    styleClass="p-fluid">
    <!-- <div class="card" [style]="{
              padding: '0px',
              height: '70vh',
              width: '99%',

              float: 'left'
            }" *ngIf="isDataLoaded"> -->
    <p-table #dt2 [columns]="addressbookcols" [value]="Master_addressBook" [style]="{ width: '100%', height: '90%' }"
      styleClass="p-datatable-sm p-datatable-gridlines p-datatable-striped " responsiveLayout="stack" styleClass="mt-3"
      [globalFilterFields]="addressbookfilters" [paginator]="true" [rows]="10">

      <!----------------------------------------->

      <ng-template pTemplate="caption">
        <div style="text-align: right">
          <i class="pi pi-search" style="margin:4px 4px 0 0"></i>

          <input type="text" pInputText size="50" placeholder="Global Filter"
            (input)="applyFilterGlobal_Address($event, 'contains')" style="width:auto">
        </div>
      </ng-template>


      <!---------------------------------------------->







      <ng-template pTemplate="header" let-columns>
        <tr>
          <th alignFrozen="left" pFrozenColumn [frozen]="true">Select</th>
          <ng-template ngFor let-col [ngForOf]="columns">
            <th pSortableColumn="{{ col.field }}">{{ col.header }}<p-sortIcon field="{{ col.field }}"></p-sortIcon>
            </th>
          </ng-template>


        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr>
          <td alignFrozen="left" pFrozenColumn [frozen]="true">
            <button pButton type="button" icon="pi pi-check" iconPos="left" class="p-button-rounded p-button-sm"
              (click)="assignAddress(rowData)"></button>
          </td>
          <ng-template ngFor let-col [ngForOf]="columns" let-i="index">
            <td>{{ rowData[col.field] }}</td>
          </ng-template>

        </tr>
      </ng-template>
    </p-table>
    <!-- </div> -->
  </p-dialog>
  <!-- ADDRESSBOOK DIALOG  END-->





  <!-- FreightShop  DIALOG START-->
  <p-dialog [(visible)]="FreightShop_dialog" [style]="{ width: '90%' }" header="Freight_Shop" [modal]="true"
    styleClass="p-fluid">

    <p-table #fsdt [value]="FreightShopResult" scrollHeight="600px" [style]="{ width: '100%'}" [scrollable]="true"
      styleClass="p-datatable-sm p-datatable-gridlines p-datatable-striped " responsiveLayout="stack" styleClass="mt-3"
      [globalFilterFields]="['Carrier','serviceCode','serviceName','publishedFreight','discountFreight']"
      [paginator]="false" dataKey="serviceCode">

      <!-------------------[rows]="10"---------------------->

      <ng-template pTemplate="caption">
        <div style="text-align: right">
          <i class="pi pi-search" style="margin:4px 4px 0 0"></i>
          <input type="text" pInputText size="50" placeholder="Global Filter"
            (input)="applyFilterGlobal_FreightShop($event, 'contains')" style="width:auto">
        </div>
      </ng-template>


      <!---------------------------------------------->







      <ng-template pTemplate="header" let-columns>
        <tr>
          <th style="width: 3rem">
            <!-- <p-tableRadioButton></p-tableRadioButton> -->
          </th>
          <th style="width: 5rem"></th>
          <th pSortableColumn="Carrier">Carrier<p-sortIcon field="Carrier"></p-sortIcon></th>
          <th>Service</th>
          <th>Description</th>
          <th pSortableColumn="publishedFreight">Published Freight<p-sortIcon field="publishedFreight"></p-sortIcon>
          </th>
          <th pSortableColumn="discountFreight">Discount Freight<p-sortIcon field="discountFreight"></p-sortIcon></th>
          <th pSortableColumn="expectedDeliveryDate">Estimateted Delivery Date<p-sortIcon
              field="expectedDeliveryDate"></p-sortIcon></th>
        </tr>
      </ng-template>


      <ng-template pTemplate="body" let-rowData let-expanded="expanded">
        <tr>
          <td>
            <!-- <p-tableRadioButton [value]="rowData"></p-tableRadioButton> -->
            <button pButton type="button" icon="pi pi-check" iconPos="left" class="p-button-rounded p-button-sm"
              (click)="assigneServiceFromFreightshop(rowData)"></button>
          </td>
          <td>
            <button type="button" pButton pRipple [pRowToggler]="rowData"
              class="p-button-text p-button-rounded p-button-plain"
              [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
          </td>
          <td>{{ rowData['Carrier'] }}</td>
          <td>{{ rowData['serviceCode'] }}</td>
          <td>{{ rowData['serviceName'] }}</td>
          <td [ngClass]="{  color: 'green' }">{{ rowData['publishedFreight'] | currency: rowData['currency'] }}</td>
          <td>{{ rowData['discountFreight'] | currency:rowData['currency'] }}</td>
          <td>{{ rowData['expectedDeliveryDate']}}</td>
        </tr>
      </ng-template>


      <ng-template pTemplate="rowexpansion" let-rowData>
        <tr>
          <td colspan="18">
            <div class="p-3">
              <p-table [value]="rowData.surCharges" dataKey="type" [scrollable]="true" responsiveLayout="stack">
                <ng-template pTemplate="caption">
                  <legend>Surcharge(s)</legend>
                </ng-template>
                <ng-template pTemplate="header">
        <tr>

          <th>Type</th>
          <th>Description</th>
          <th>Amount</th>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-surCharge let-expandedPackage="expanded">
        <tr>

          <td>{{ surCharge['type'] }}</td>
          <td>{{ surCharge['description'] }}</td>
          <td>{{ surCharge['amount'] | currency:rowData['currency'] }}</td>
        </tr>
      </ng-template>

      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="6">There are SurCharge(s).</td>
        </tr>
      </ng-template>
    </p-table>
</div>
</td>
</tr>
</ng-template>





</p-table>


<!-- <button type="button" pButton pRipple label="Select" icon="pi pi-check" class="p-button-sm"
(click)="assigneServiceFromFreightshop('')"></button> -->

</p-dialog>
<!-- FreightShop DIALOG  END-->




<!-- TABLE PROPERTIES  DIALOG START-->
<p-dialog [(visible)]="tableProperties_dialog" [style]="{ width: '70%' }" [modal]="true" styleClass="p-fluid">
  <p-header>{{ currentSelectedTable + " Properties"}}</p-header>
  <p-toolbar>
    <ng-template pTemplate="right">
      <button pButton pRipple label="New Field" icon="pi pi-plus" class="p-button-primary p-mr-1"
        (click)="openNewColumProperty()"></button>
    </ng-template>

    <ng-template pTemplate="left">
    </ng-template>
  </p-toolbar>

  <div class="card" [style]="{
              padding: '0px',
              height: '70vh',
              width: '100%',
              float: 'left',
              overflow: 'scroll'
            }" *ngIf="isDataLoaded">

    <p-table [value]="currentSelectedTableData">
      <ng-template pTemplate="header">
        <tr>
          <th>Is Visible</th>
          <th>Is Editable</th>
          <th>Coulm Header</th>
          <th>Field Name</th>
          <th>UI Type</th>
          <th>Data Type</th>


        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-fielddata>
        <tr>
          <td>
            <p-checkbox [(ngModel)]="fielddata.visible" [binary]="true" [disabled]="false"></p-checkbox>{{
            fielddata.visible }}
          </td>
          <td>
            <p-checkbox [(ngModel)]="fielddata.editable" [binary]="true" [disabled]="false"></p-checkbox>{{
            fielddata.editable }}
          </td>
          <td pEditableColumn>
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input pInputText type="text" [style]="{ border: '0px' }" [readonly]="false"
                  [(ngModel)]="fielddata.header" />
              </ng-template>
              <ng-template pTemplate="output">
                {{ fielddata.header }}
              </ng-template>
            </p-cellEditor>
          </td>


          <td>{{fielddata.fieldName}}</td>
          <td>{{fielddata.fieldType}}</td>
          <td>{{fielddata.datatype}}</td>


        </tr>
      </ng-template>
    </p-table>



  </div>
  <button type="button" pButton pRipple label="Save Changes" icon="pi pi-check" class="p-button-sm"
    (click)="SaveTemplate('ShipScreen')"></button>
</p-dialog>
<!-- TABLE PROPERTIES DIALOG  END-->














<!-- FIELD PROPERTY DIALOG START-->


<p-sidebar [(visible)]="property_dialog" position="right" [baseZIndex]="10000" [style]="{padding:'0px'}"
  *ngIf="getUserDetails() ==='SystemAdmin'">
  <h4>{{currentSelected_Block_Name + ' : ' }} Properties</h4>
  <div class="grid p-fluid mt-3 " [style]="{margin:'0px' , padding:'0px'}">
    <div class="field col-12 md:col-8   ">
      <span class="p-float-label">
        <p-dropdown id="fields" [autoDisplayFirst]="false" [options]="currentSelectedBlock" optionLabel="fieldName"
          optionValue="fieldName" [(ngModel)]="currentSelected_Block_Field_Name" (onChange)="loadFieldProperties()"
          [style]="{ width: '100%' }">
        </p-dropdown>
        <label for="fields">Fields :</label>
      </span>
    </div>


    <div class="field col-12 md:col-2 ">
      <span class="p-float-label">
        <button pButton type="button" icon="pi pi-plus" iconPos="left" tooltipPosition="top" (click)="processNewField()"
          pTooltip="Add New Field"></button>
      </span>
    </div>

    <div class="field col-12 md:col-2   ">
      <span class="p-float-label">
        <button pButton type="button" icon="pi pi-bars" tooltipPosition="top" (click)="fieldsReorder_dialog=true"
          pTooltip="Re-Order Fields"></button>
      </span>
    </div>






    <div class="field col-12 md:col-12">
      <p-accordion>
        <!-- FIELD HEADER-->
        <p-accordionTab header="Field Properties" [selected]="propertyFieldSelected">
          <div class="grid p-fluid mt-3">


            <div class="field col-12 md:col-12">
              <span class="p-float-label">
                <input type="text" id="title" pInputText [(ngModel)]="this.currentSelctedField.title"
                  [disabled]="false" />
                <label for="title">Title</label>
              </span>
            </div>




            <div class="field col-12 md:col-12">
              <span class="p-float-label">
                <p-dropdown id="UITYPE" [autoDisplayFirst]="false" [options]="fieldUITypes"
                  [(ngModel)]="this.currentSelctedField.fieldType" optionLabel="name" optionValue="name"
                  [style]="{ width: '100%' }">
                </p-dropdown>
                <label for="UITYPE">UI Type</label>
              </span>
            </div>


            <div class="field col-12 md:col-12" *ngIf="this.currentSelctedField.fieldType != 'label'">
              <span class="p-float-label">
                <p-dropdown id="datatype" [options]="fieldDataTypes" [(ngModel)]="this.currentSelctedField.datatype"
                  optionLabel="name" optionValue="name" [style]="{ width: '100%' }">
                </p-dropdown>
                <label for="datatype">Data Type</label>
              </span>
            </div>

            <div class="field col-12 md:col-12" *ngIf="this.currentSelctedField.fieldType != 'label'">
              <span class="p-float-label">
                <input type="text" id="width" pInputText [(ngModel)]="this.currentSelctedField.width"
                  [disabled]="false" />
                <label for="width">Field Width (Ex: col-3, co-4)</label>
              </span>
            </div>


            <div class="field col-12 md:col-12" *ngIf="this.currentSelctedField.fieldType != 'label'">
              <span class="p-float-label   p-input-icon-right">

                <p-dropdown id="source" [options]="dataSourceList" [(ngModel)]="this.currentSelctedField.source"
                  [autoDisplayFirst]="false" optionLabel="name" optionValue="name" [style]="{ width: '100%' }">
                </p-dropdown>
                <label for="source">Data Source</label>

              </span>
            </div>
            <!--
    <p-dropdown id="source" [options]="getDataSourceList()" [(ngModel)]="this.currentSelctedField.source"
                    [autoDisplayFirst]="false" optionLabel="name" optionValue="name" [style]="{ width: '100%' }">
                  </p-dropdown>
                  <label for="source">Data Source</label>

-->

            <!-- <div class="field col-12 md:col-6">
                <span class="p-float-label">
                  <input type="text" id="Source" pInputText [(ngModel)]="this.currentSelctedField.source"
                    [disabled]="false" />
                  <label for="Source">Source</label>
                </span>
              </div> -->

            <div class="field col-12 md:col-6" *ngIf="this.currentSelctedField.fieldType != 'label'">
              <span class="p-float-label">


                <p-dropdown id="optionName" [options]="getOptioneNames()"
                  [(ngModel)]="this.currentSelctedField.optionName" [autoDisplayFirst]="false" optionLabel="name"
                  optionValue="name" [style]="{ width: '100%' }">
                </p-dropdown>
                <label for="optionName">Option Name</label>


              </span>
            </div>


            <div class="field col-12 md:col-6" *ngIf="this.currentSelctedField.fieldType != 'label'">
              <span class="p-float-label ">


                <p-dropdown appendTo="body" id="optionNames" [options]="getOptioneNames()"
                  [(ngModel)]="this.currentSelctedField.optionValue" [autoDisplayFirst]="false" optionLabel="name"
                  optionValue="name" [style]="{ width: '100%' }">
                </p-dropdown>
                <label for="optionValues">Option Values</label>


              </span>
            </div>



            <div class="field col-12 md:col-12" *ngIf="this.currentSelctedField.fieldType != 'label'">
              <span class="p-float-label">
                <input type="text" id="DefaultValue" pInputText [(ngModel)]="this.currentSelctedField.defaultValue"
                  [disabled]="false" />
                <label for="DefaultValue">Default Value</label>
              </span>
            </div>


            <div class="field col-12 md:col-12" *ngIf="this.currentSelctedField.fieldType != 'label'">
              <span class="p-float-label">
                <input type="text" id="errormessage" pInputText [(ngModel)]="this.currentSelctedField.errorMessage"
                  [disabled]="false" />
                <label for="errormessage">Error Message</label>
              </span>
            </div>


            <!-- Is Editable-->
            <div class="field col-12 md:col-6" *ngIf="this.currentSelctedField.fieldType != 'label'">
              <span> <label for="editable">Is Editable</label></span>
              <span class="p-float-label">
                <p-checkbox id="editable" [(ngModel)]="this.currentSelctedField.editable" [binary]="true"
                  [disabled]="false"></p-checkbox>
              </span>
            </div>
            <!-- IS REQUIRED -->
            <div class="field col-12 md:col-6" *ngIf="this.currentSelctedField.fieldType != 'label'">
              <span> <label for="editable">Is Required</label></span>
              <span class="p-float-label">
                <p-checkbox id="required" [(ngModel)]="this.currentSelctedField.required" [binary]="true"
                  [disabled]="false"></p-checkbox>
              </span>
            </div>
            <!-- IS VISIBLE-->
            <div class="field col-12 md:col-6" *ngIf="this.currentSelctedField.fieldType != 'label'">
              <span><label for="visible">Visible</label></span>
              <span class="p-float-label">
                <p-checkbox id="visible" [(ngModel)]="this.currentSelctedField.visible" [binary]="true"
                  [disabled]="false">
                </p-checkbox>
              </span>
            </div>
            <!-- IS STANDARD FIELD-->
            <div class="field col-12 md:col-6" *ngIf="this.currentSelctedField.fieldType != 'label'">
              <span><label for="visible">Standard Field</label></span>
              <span class="p-float-label">
                <p-checkbox id="visible" [(ngModel)]="this.currentSelctedField.standard" [binary]="true"
                  [disabled]="true">
                </p-checkbox>
              </span>
            </div>
            <!-- IS UDF -->
            <div class="field col-12 md:col-4" *ngIf="this.currentSelctedField.fieldType != 'label'">
              <span><label for="visible">UDF</label></span>
              <span class="p-float-label">
                <p-checkbox id="visible" [(ngModel)]="this.currentSelctedField.UDF" [disabled]="true">
                </p-checkbox>
              </span>
            </div>

            <div class="field col-12 md:col-8"
              *ngIf="this.currentSelctedField.fieldType != 'label'  && this.currentSelctedField.UDF">

              <span class="p-float-label">
                <button pButton alignFrozen="right" pFrozenColumn [frozen]="true" pRipple icon="pi pi-trash"
                  label="Delete Field" class=" p-button-danger p-button-sm" (click)="deleteFieldFromBlock()"></button>
              </span>
            </div>



            <!--  -->



          </div>
        </p-accordionTab>


        <!-- ICON PROPERTY-->
        <p-accordionTab header="Icon" *ngIf="this.currentSelctedField.fieldType != 'label'">
          <div class="grid p-fluid mt-3">
            <div class="field col-12 md:col-12">
              <span><label for="visible">Has Icon</label></span>
              <span class="p-float-label">
                <p-checkbox id="visible" [(ngModel)]="this.currentSelctedField.hasIcon" [binary]="true"
                  [disabled]="false">
                </p-checkbox>
              </span>
            </div>

            <div class="field col-12 md:col-12">
              <span class="p-float-label">

                <p-dropdown id="iconposition" [options]="iconPositions"
                  [(ngModel)]="this.currentSelctedField.iconPosition" optionLabel="name" optionValue="name"
                  [autoDisplayFirst]="false" [showClear]="true" [style]="{ width: '100%' }">
                </p-dropdown>
                <label for="iconposition">Icon Position</label>
              </span>
            </div>


            <div class="field col-12 md:col-12">
              <span class="p-float-label">
                <input type="text" id="iconname" pInputText [(ngModel)]="this.currentSelctedField.icon"
                  [disabled]="false" />

                <label for="iconname">Icon </label>
              </span>
            </div>
          </div>
        </p-accordionTab>
        <!-- VALIDATION PROPERTY-->
        <p-accordionTab header="Validations" *ngIf="this.currentSelctedField.fieldType != 'label'">

          <div class="grid p-fluid mt-3">
            <div class="field col-12 md:col-12">
              <span class="p-float-label">
                <input type="number" id="MINLENGTH" pInputText [(ngModel)]="this.currentSelctedField.minLength"
                  [disabled]="false" />
                <label for="MINLENGTH">Minimum Length</label>
              </span>
            </div>



            <div class="field col-12 md:col-12">
              <span class="p-float-label">
                <input type="number" id="MAXLENGTH" pInputText [(ngModel)]="this.currentSelctedField.maxLength"
                  [disabled]="false" />
                <label for="MAXLENGTH">Maximum Length</label>
              </span>
            </div>



            <div class="field col-12 md:col-12">
              <span> <label for="editable">Email Validation</label></span>
              <span class="p-float-label">
                <p-checkbox id="emailvalidator" [(ngModel)]="this.currentSelctedField.emailValidate" [binary]="true"
                  [disabled]="false"></p-checkbox>
              </span>
            </div>



            <div class="field col-12 md:col-12">
              <span class="p-float-label">
                <input type="text" id="validationpattern" pInputText
                  [(ngModel)]="this.currentSelctedField.ValidationPattern" [disabled]="false" />
                <label for="validationpattern">Custom Pattern</label>
              </span>
            </div>
          </div>
        </p-accordionTab>

        <!-- RELEATED FIEDLS-->
        <p-accordionTab header="Releated/Child fields" *ngIf="this.currentSelctedField.fieldType != 'label'">
          <div class="grid p-fluid mt-3">
            <div class="field col-12 md:col-12">

              <span class="p-float-label">
                <textarea id="Releated" [rows]="5" [cols]="30" pInputTextarea [autoResize]="false"
                  [(ngModel)]="this.currentSelctedField.RelatedFields"></textarea>


                <label for="Releated">Related Fields</label>
              </span>

            </div>


            <div class="field col-12 md:col-12">

              <span class="p-float-label">

                <input type="text" id="ConditionFieldName" pInputText
                  [(ngModel)]="this.currentSelctedField.ConditionFieldName" [disabled]="false" />
                <label for="ConditionFieldName">Mapping Fields Name</label>
              </span>

            </div>
          </div>

        </p-accordionTab>


        <!-- EVENTS ON FIELD-->

        <p-accordionTab header="Events" *ngIf="this.currentSelctedField.fieldType != 'label'">
          <div class="grid p-fluid mt-3">


            <div class="field col-12 md:col-6">
              <span class="p-float-label">
                <button pButton pRipple label="Before Events" icon="pi pi-check" class="p-button-sm"
                  (click)="openEventDialog('Before_event')"></button>
              </span>
            </div>

            <div class="field col-12 md:col-6">
              <span class="p-float-label">
                <button pButton pRipple label="After Events" icon="pi pi-check" class="p-button-sm"
                  (click)="openEventDialog('After_event')"></button>
              </span>
            </div>

          </div>
        </p-accordionTab>



      </p-accordion>






    </div>
    <button pButton pRipple label="Update" icon="pi pi-check" class="p-button-sm"
      (click)="SaveTemplate('ShipScreen')"></button>

  </div>

</p-sidebar>


<!-- LOCATION OVERLAY PANEL-->

<p-overlayPanel [showTransitionOptions]="'0ms'" [hideTransitionOptions]="'0ms'" [style]="{width: '50%'}" #locop>
  <ng-template pTemplate>

    <p-table #loc [columns]="xfields" [value]="Original_LocationMasterData.location" sortMode="single"
      responsiveLayout="scroll" [scrollable]="true" styleClass="mt-3" [globalFilterFields]="xcolumns">

      <ng-template pTemplate="caption">
        <div class="flex">

          <div class="ml-auto mr-2">
            <i class="pi pi-search" style="margin:4px 4px 0 0"></i>
            <input type="text" pInputText size="50" placeholder="Global Filter"
              (input)="applyFilterGlobal_Location($event, 'contains')" style="width:auto">
          </div>
        </div>
      </ng-template>

      <ng-template pTemplate="header" let-columns>
        <tr>
          <th alignFrozen="left" pFrozenColumn [frozen]="true">Select</th>
          <ng-container *ngFor="let xf of xfields; let i = index">
            <ng-template [ngIf]="xf.visible === true">
              <th pSortableColumn="{{xf.fieldName }}">
                {{ xf.fieldHeader }}
                <p-sortIcon field="{{ xf.fieldName }}"></p-sortIcon>
              </th>
            </ng-template>
          </ng-container>


        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="xfields">
        <tr>
          <td alignFrozen="left" pFrozenColumn [frozen]="true">
            <button pButton pRipple icon="pi pi-check" class="p-button-rounded p-button-primary p-mr-2"
              (click)="assignLocation(rowData , $event , locop)"></button>
          </td>
          <ng-template ngFor let-xf [ngForOf]="xfields" let-i="index">
            <ng-template [ngIf]="xf.visible === true">
              <td> {{ rowData[xf.fieldName] }}</td>
            </ng-template>
          </ng-template>

        </tr>
      </ng-template>
    </p-table>


  </ng-template>
</p-overlayPanel>


<!-- PACKAGE LEVEL SPECIAL SERVICE-->

<p-dialog [(visible)]="packageSpecialService_Dialog" [style]="{ width: '70%' }" header="PACKAGE LEVEL SPECIAL SERVICES"
  [modal]="true" styleClass="p-fluid">

  <p-tabView>
    <ng-template [ngIf]="selectedHu">
      <ng-template ngFor let-ss [ngForOf]="specialServiceData.Package">
        <ng-template [ngIf]="ss.isActive">
          <p-tabPanel [header]="ss.SSName">
            <div class="grid p-fluid mt-3">
              <!-- To Display Textbox -->
              <ng-template ngFor let-field [ngForOf]="ss.fields">

                <ng-template [ngIf]="field.isActive">
                  <ng-template [ngIf]="field.fieldType == 'Textbox'">
                    <div class="field col-12  {{ ' md:' + field.width }}  " [hidden]="!field.visible">
                      <span class="p-float-label">
                        <!-- -->
                        <input [type]="field.datatype" pInputText
                          [(ngModel)]="selectedHu.SpecialServices[ss.SSName +'_'+ field.fieldName ]" />
                        <label>{{ field.title }}</label>
                      </span>
                    </div>
                  </ng-template>
                  <!-- To display Dropdown -->
                  <ng-template [ngIf]="field.fieldType == 'Dropdown'">
                    <div class="field col-12 {{'md:' + field.width }}" [hidden]="!field.visible">
                      <span class="p-float-label">
                        <!-- -->
                        <p-dropdown [options]="field.source" [autoDisplayFirst]="false" [showClear]="true"
                          appendTo="body" [(ngModel)]="selectedHu.SpecialServices[ss.SSName +'_'+ field.fieldName ]"
                          [optionLabel]="field.optionName" [filter]="true" [optionValue]="field.optionValue"
                          [style]="{ width: '100%' }">
                        </p-dropdown>
                        <label [for]="field.fieldName">{{ field.title }}</label>
                      </span>
                    </div>
                  </ng-template>
                  <!-- To Display Button -->
                  <ng-template [ngIf]="field.fieldType == 'Button'">
                    <div class="field col-12 {{'md:' + field.width }}" [hidden]="!field.visible">
                      <span class="p-float-label">
                        <button pButton type="button" [label]="field.title"></button>
                      </span>
                    </div>
                  </ng-template>
                  <!-- To display Checkbox -->
                  <ng-template [ngIf]="field.fieldType == 'Checkbox'">
                    <!-- -->
                    <div class="field col-12 {{'md:' + field.width }}" [hidden]="!field.visible">
                      <p-checkbox class="mr-2" [binary]="true" inputid="field.fieldName"
                        [(ngModel)]="selectedHu.SpecialServices[ss.SSName +'_'+ field.fieldName ]"></p-checkbox>
                      <label class="mr-2" for="field.fieldname">{{ field.title }}</label>
                    </div>
                  </ng-template>
                  <!-- To Display MultiSelect -->
                  <ng-template [ngIf]="field.fieldType == 'MultiSelect'">
                    <div class="field col-12 {{'md:' + field.width }}" [hidden]="!field.visible">
                      <span class="p-float-label">
                        <!---->
                        <p-multiSelect [options]="field.source" optionLabel="name"
                          [(ngModel)]="selectedHu.SpecialServices[ss.SSName +'_'+ field.fieldName ]"
                          optionValue="name"></p-multiSelect>
                        <label for="field.fieldName">{{ field.title }}</label>
                      </span>
                    </div>
                  </ng-template>

                </ng-template>

              </ng-template>
            </div>
          </p-tabPanel>
        </ng-template>

      </ng-template>
    </ng-template>

  </p-tabView>


</p-dialog>



<!-- New Field Dialog-->

<p-dialog [(visible)]="newField_Dialog" [style]="{ width: '50%' }" header="New Field/Button" [modal]="true"
  styleClass="p-fluid">
  <div class="p-fluid p-formgrid grid mt-3">
    <div class="field col-15 md:col-8">
      <span class="p-float-label">
        <input type="text" pInputText [(ngModel)]="newField" id="newfield" />
        <label for="newfield">New Field Name:</label>
      </span>
    </div>

    <div class="field col-15 md:col-4">
      <span class="p-float-label">
        <button pButton type="button" icon="pi pi-check" iconPos="left" label="Add New Field"
          (click)="addNewField()"></button>
      </span>
    </div>

  </div>


  <div class="p-fluid p-formgrid grid mt-3">
    <div class="field col-15 md:col-8">
      <span class="p-float-label">
        <input type="text" pInputText [(ngModel)]="newField" id="newfield" />
        <label for="newfield">New Button:</label>
      </span>
    </div>

    <div class="field col-15 md:col-4">
      <span class="p-float-label">
        <button pButton type="button" icon="pi pi-check" iconPos="left" label="Add New Field"
          (click)="addNewButton()"></button>
      </span>
    </div>

  </div>









</p-dialog>



<!-- REQUIRED MASTER DATA -->

<p-overlayPanel [showTransitionOptions]="'0ms'" [hideTransitionOptions]="'0ms'" [style]="{width: '300px'}"
  #requiredData>
  <ng-template pTemplate>
    <div class="card">
      <div class="p-fluid p-formgrid grid">
        <div class="field col-15 md:col-12">
          <span class="p-float-label">
            <p-multiSelect [options]="allForms" [(ngModel)]="ShipScreen.MasterData" optionLabel="name"
              optionValue="code" display="chip"></p-multiSelect>
            <label for="masterdata">Required Master Data</label>
          </span>
        </div>
      </div>

    </div>
    <button pButton pRipple label="Update" icon="pi pi-check" class="p-button-sm"
      (click)="SaveRequiredMasterData('ShipScreen', $event , requiredData)"></button>
  </ng-template>
</p-overlayPanel>


<!-- Event Dialog Popup-->

<p-dialog [(visible)]="event_Dialog" [style]="{ width: '90%', height:'90%' }" [header]="currentEventType" [modal]="true"
  styleClass="p-fluid">

  <div class="editor-container" fxLayout="column">

    <ngx-monaco-editor fxFlex [options]="editorOptions" [(ngModel)]="code" (init)="editorInit($event)" height="90%">
    </ngx-monaco-editor>
  </div>


  <button pButton pRipple label="Save Events" icon="pi pi-check" class="p-button-sm"
    (click)="saveEvent(currentSelected_Block_Name, currentSelected_Block_Field_Name)"></button>
</p-dialog>



<!-- NEW COLUMN PROPERTY FOR TABLES/GRID-->
<p-dialog [(visible)]="newGridColumns_Dialog" [style]="{ width: '50%' }" header="New Column" [modal]="true"
  styleClass="p-fluid">
  <div class="grid p-fluid mt-3">
    <div class="field col-12 md:col-3">
      <span class="p-float-label">
        <input type="text" pInputText [(ngModel)]="newColumn['fieldName']" id="fieldName" />
        <label for="newfield">Column Name:</label>
      </span>
    </div>

    <div class="field col-12 md:col-3">
      <span class="p-float-label">
        <input type="text" pInputText [(ngModel)]="newColumn['header']" id="newfield" />
        <label for="newfield">Header/Title</label>
      </span>
    </div>

    <div class="field col-12 md:col-3">
      <span class="p-float-label">
        <input type="text" pInputText [(ngModel)]="newColumn['fieldType']" id="newfield" />
        <label for="newfield">UI Type</label>
      </span>
    </div>

    <div class="field col-12 md:col-3">
      <span class="p-float-label">
        <button pButton type="button" icon="pi pi-check" iconPos="left" label="Add New Field"
          (click)="addnewColumnToGrid()"></button>
      </span>
    </div>

  </div>




</p-dialog>
<!-- New Column property dialog eng here -->


<!-- HISTORY OPEN & MANIFEST LIST DIALOG-->

<p-dialog [(visible)]="history_Dialog" [style]="{ width: '90%' }" header="History/Open orders" [modal]="true"
  styleClass="p-fluid">

  <div class="flex">
    <button pButton pRipple type="button" label="Shipments" class="mr-1" (click)="getShipments('shipments')"></button>
    <button pButton pRipple type="button" label="Open Orders" class="mr-1"
      (click)="getShipments('shiprequest')"></button>

  </div>


  <!-- MANIEFST TABLE-->
  <p-table #manifestdt [value]="historyOrders" [tableStyle]="{'min-width': '75rem'}"
    [(selection)]="selectedHistoryOrder" selectionMode="single" [paginator]="true" [rows]="10"
    [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,25,50]"
    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" dataKey="id" [scrollable]="true"
    responsiveLayout="stack" styleClass="p-datatable-sm p-datatable-gridlines p-datatable-striped "
    [globalFilterFields]="global_history_xcolumns">
    <ng-template pTemplate="caption">
      <div class="flex align-items-center justify-content-between">

        <p-button icon="pi pi-filter" (click)="historyop.toggle($event)"></p-button>
        <div class="ml-auto mr-2">
          <i class="pi pi-search" style="margin:4px 4px 0 0"></i>
          <input type="text" pInputText size="50" placeholder="Global Filter"
            (input)="applyManifestFilterGlobal($event, 'contains')" style="width:auto">
        </div>
        <p-button icon="pi pi-cog" (click)="openHistoryTableProperties()"></p-button>
      </div>
    </ng-template>

    <ng-template pTemplate="header">
      <tr>
        <th style="width: 3rem">
          <p-tableRadioButton></p-tableRadioButton>
        </th>
        <th style="width: 5rem"></th>
        <th pSortableColumn="id">Id<p-sortIcon field="id"></p-sortIcon></th>

        <ng-container *ngFor="let xf of history_xfields; let i = index">
          <ng-template [ngIf]="xf.visible==true">
            <ng-container *ngFor="let field of xf.fields; let j = index">
              <ng-template [ngIf]="field['TableVisible'] === true">
                <th pSortableColumn="{{field.title }}">
                  {{ xf['IncludeHeader'] + field.title }}
                  <p-sortIcon field="{{ field.title }}"></p-sortIcon>
                </th>
              </ng-template>
            </ng-container>
          </ng-template>
        </ng-container>
        <th></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-shipRequest let-expanded="expanded" let-columns="history_xfields">


      <tr>
        <td>
          <p-tableRadioButton [value]="shipRequest"></p-tableRadioButton>
          <!-- <p-tableCheckbox [value]="shipRequest"></p-tableCheckbox> -->
        </td>
        <td>
          <button type="button" pButton pRipple [pRowToggler]="shipRequest"
            class="p-button-text p-button-rounded p-button-plain"
            [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
        </td>
        <td>{{ shipRequest.id }} </td>
        <ng-template [ngIf]="history_xfields[0].visible==true">
          <ng-container *ngFor="let field of history_xfields[0].fields; let j = index">
            <ng-template [ngIf]="field['TableVisible'] === true">
              <td> {{ shipRequest.details.HeaderInfo[field.fieldName] }} </td>
            </ng-template>
          </ng-container>
        </ng-template>
        <ng-template [ngIf]="history_xfields[1].visible==true">
          <ng-container *ngFor="let field of history_xfields[1].fields; let j = index">
            <ng-template [ngIf]="field['TableVisible'] === true">
              <td> {{ shipRequest.details.Shipper[field.fieldName] }}</td>
            </ng-template>
          </ng-container>

        </ng-template>

        <ng-template [ngIf]="history_xfields[2].visible==true">

          <ng-container *ngFor="let field of history_xfields[2].fields; let j = index">
            <ng-template [ngIf]="field['TableVisible'] === true">
              <td> {{ shipRequest.details.ShipTo[field.fieldName] }}</td>
            </ng-template>
          </ng-container>
        </ng-template>
        <ng-template [ngIf]="history_xfields[3].visible==true">

          <ng-container *ngFor="let field of history_xfields[3].fields; let j = index">
            <ng-template [ngIf]="field['TableVisible'] === true">
              <td> {{ shipRequest.details.ShipFrom[field.fieldName] }}</td>
            </ng-template>
          </ng-container>
        </ng-template>
        <ng-template [ngIf]="history_xfields[4].visible==true">
          <ng-container *ngFor="let field of history_xfields[4].fields; let j = index">
            <ng-template [ngIf]="field['TableVisible'] === true">
              <td> {{ shipRequest.details.SoldTo[field.fieldName] }}</td>
            </ng-template>
          </ng-container>
        </ng-template>
        <ng-template [ngIf]="history_xfields[5].visible==true">
          <ng-container *ngFor="let field of history_xfields[5].fields; let j = index">
            <ng-template [ngIf]="field['TableVisible'] === true">
              <td> {{ shipRequest.details.CarrierDetails[field.fieldName] }}</td>
            </ng-template>
          </ng-container>
        </ng-template>

      </tr>
    </ng-template>

    <ng-template pTemplate="rowexpansion" let-shipRequest>
      <tr>
        <td colspan="18">
          <div class="p-3">


            <p-table [value]="shipRequest.details.Packages" dataKey="Sno" [scrollable]="true" responsiveLayout="stack">
              <ng-template pTemplate="caption">
                <legend> {{'Total Packages: ' + shipRequest.details.Packages.length }}</legend>
              </ng-template>
              <ng-template pTemplate="header">
      <tr>
        <th style="width: 5rem"></th>
        <th>Sno</th>
        <th>HU</th>
        <th>Weight</th>
        <th>Dimension</th>


      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-package let-expandedPackage="expanded">

      <tr>
        <td>
          <button type="button" pButton pRipple [pRowToggler]="package"
            class="p-button-text p-button-rounded p-button-plain"
            [icon]="expandedPackage ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>

        </td>
        <td>{{ package['Sno'] }}</td>
        <td>{{ package['HU'] }}</td>
        <td>{{ package['Weight'] }}</td>
        <td>{{ package['Dimension'] }}</td>


      </tr>
    </ng-template>
    <ng-template pTemplate="rowexpansion" let-package>
      <tr>
        <td colspan="10">
          <div class="p-3">


            <p-table [value]="package['ItemsInfo']" dataKey="ItemNo">
              <ng-template pTemplate="caption">
                <legend> {{'Total Items: ' + package['ItemsInfo'].length }}</legend>
              </ng-template>
              <ng-template pTemplate="header">
      <tr>

        <th>ItemNo</th>
        <th>ProductNo</th>
        <th>Description</th>
        <th>UnitCost</th>
        <th>UnitWeight</th>
        <th>Dimension</th>
        <th>Quantity</th>
        <th>Partial</th>
        <th>Balance</th>


      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-item>

      <tr>

        <td>{{ item['ItemNo'] }}</td>
        <td>{{ item['ProductNo'] }}</td>
        <td>{{ item['Description'] }}</td>
        <td>{{ item['UnitCost'] }}</td>
        <td>{{ item['UnitWeight'] }}</td>
        <td>{{ item['Dimension'] }}</td>
        <td>{{ item['Quantity'] }}</td>
        <td>{{ item['Partial'] }}</td>
        <td>{{ item['Balance'] }}</td>



      </tr>
    </ng-template>

    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="6">There are no items for this Package yet.</td>
      </tr>
    </ng-template>
  </p-table>
  </div>
  </td>
  </tr>
  </ng-template>

  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="6">There are no packages for this shipment yet.</td>
    </tr>
  </ng-template>
  </p-table>
  </div>
  </td>
  </tr>
  </ng-template>




  <ng-template pTemplate="summary">
    <div class="p-d-flex p-ai-center p-jc-between">
      In total there are {{ historyOrders.length }} Shipments(s)

    </div>
  </ng-template>



  </p-table>

  <button pButton pRipple type="button" label="Select " class="mr-1" (click)="selectOrderorShipment()"></button>

</p-dialog>


<!-- HISTORY OPEN & MANIFEST LIST DIALOG END HERE-->




<!-- HISTORY TABLE PROPERTIES  DIALOG START-->
<p-dialog [(visible)]="HistorytableProperties_dialog" [style]="{ width: '70%' }" [modal]="true" styleClass="p-fluid">
  <p-header>{{ " Properties"}}</p-header>
  <div class="card" [style]="{
              padding: '0px',
              height: '70vh',
              width: '99%',
              float: 'left',
              overflow: 'scroll'
            }">

    <p-table [value]="history_xfields" styleClass="p-datatable-sm p-datatable-gridlines p-datatable-striped "
      dataKey="fieldHeader" responsiveLayout="stack">
      <ng-template pTemplate="header">
        <tr>
          <th style="width: 5rem"></th>
          <th>Visible</th>
          <th>Coulm Header</th>
          <th>Include Header Name</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-fielddata let-expanded="expanded">
        <tr>
          <td>
            <button type="button" pButton pRipple [pRowToggler]="fielddata"
              class="p-button-text p-button-rounded p-button-plain"
              [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
          </td>
          <td>
            <p-checkbox [(ngModel)]="fielddata.visible" [binary]="true" [disabled]="false"></p-checkbox>{{
            fielddata.visible }}
          </td>
          <td> {{ fielddata.fieldHeader }}</td>


          <td> <span class="p-float-label">

              <input pInputText [(ngModel)]="fielddata['IncludeHeader']" />
              <!-- <input [type]="field.datatype" pInputText /> -->

            </span></td>

        </tr>
      </ng-template>

      <ng-template pTemplate="rowexpansion" let-fielddata>
        <tr>

          <td colspan="3">
            <div class="p-3">

              <p-table [value]="fielddata.fields" dataKey="title">
                <ng-template pTemplate="header">
        <tr>
          <th>Visible</th>
          <th>Field</th>
          <th>Filter</th>

        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-field>

        <tr>
          <td>
            <p-checkbox [(ngModel)]="field['TableVisible']" [binary]="true" [disabled]="false"></p-checkbox>{{
            fielddata['TableVisible'] }}
          </td>

          <td>{{field.title}}</td>
          <td>
            <p-checkbox [(ngModel)]="field['filterField']" [binary]="true"
              [disabled]="false">{{field['filterField']}}</p-checkbox>
          </td>

        </tr>
      </ng-template>

    </p-table>
  </div>
  </td>
  </tr>
  </ng-template>

  </p-table>



  </div>
  <button type="button" pButton pRipple label="Save Changes" icon="pi pi-check" class="p-button-sm"
    (click)="SaveHistoryTemplate()"></button>
</p-dialog>
<!-- HISTORY TABLE PROPERTIES  DIALOG END-->



<!--  Preview Dialog -->
<p-dialog [(visible)]="LabelPreview_Dialog" [style]="{ width: '500px' }" [modal]="true" styleClass="p-fluid">
  <p-header>Shipping Documents</p-header>
  <div class="card">
    <img [src]="currentlabeldata" style="width:400px; height:600px " />
  </div>
</p-dialog>
<!-- Privew dialog end-->



<!--  AddressValidation Dialog -->
<p-dialog [(visible)]="AddressValidation_Dialog" [style]="{ width: '90%' }" [modal]="true" styleClass="p-fluid">
  <p-header>Address Validation</p-header>
  <div class="grid">
    <!--source Address-->
    <div class="col-12 md:col-6">

      <div class="card">
        <h5 style="color:black">Source Address</h5>
        <div class="grid p-fluid mt-4">
          <div class="field col-6  md:col-6">
            <span class="p-float-label">
              <input type='text' pInputText [(ngModel)]="addressResult.Address['COMPANY']" />
              <label>Company</label>
            </span>
          </div>

          <div class="field col-6  md:col-6">
            <span class="p-float-label">
              <input type='text' pInputText [(ngModel)]="addressResult.Address['CONTACT']" />
              <label>Contact</label>
            </span>
          </div>

          <div class="field col-6  md:col-6">
            <span class="p-float-label">
              <input type='text' pInputText [(ngModel)]="addressResult.Address['ADDRESS_LINE1']" />
              <label>Address Line1</label>
            </span>
          </div>

          <div class="field col-6  md:col-6">
            <span class="p-float-label">
              <input type='text' pInputText [(ngModel)]="addressResult.Address['ADDRESS_LINE2']" />
              <label>Address Line2 </label>
            </span>
          </div>

          <div class="field col-6  md:col-6">
            <span class="p-float-label">
              <input type='text' pInputText [(ngModel)]="addressResult.Address['CITY']" />
              <label>City</label>
            </span>
          </div>

          <div class="field col-6  md:col-6">
            <span class="p-float-label">
              <input type='text' pInputText [(ngModel)]="addressResult.Address['STATE']" />
              <label>State</label>
            </span>
          </div>

          <div class="field col-6  md:col-6">
            <span class="p-float-label">
              <input type='text' pInputText [(ngModel)]="addressResult.Address['ZIPCODE']" />
              <label>Zip Code</label>
            </span>
          </div>

          <div class="field col-6  md:col-6">
            <span class="p-float-label">
              <input type='text' pInputText [(ngModel)]="addressResult.Address['COUNTRY']" />
              <label>Country</label>
            </span>
          </div>

        </div>
      </div>




    </div>
    <!--Verified address-->
    <div class="col-12 md:col-6">
      <div class="card">
        <h5 style="color:black">Verified Address</h5>
        <div class="grid p-fluid mt-4">
          <div class="field col-6  md:col-6">
            <span class="p-float-label">
              <input type='text' pInputText [(ngModel)]="addressResult.VerifiedAddress['COMPANY']" />
              <label>Company</label>
            </span>
          </div>

          <div class="field col-6  md:col-6">
            <span class="p-float-label">
              <input type='text' pInputText [(ngModel)]="addressResult.VerifiedAddress['CONTACT']" />
              <label>Contact</label>
            </span>
          </div>

          <div class="field col-6  md:col-6">
            <span class="p-float-label">
              <input type='text' pInputText [(ngModel)]="addressResult.VerifiedAddress['ADDRESS_LINE1']" />
              <label>Address Line1</label>
            </span>
          </div>

          <div class="field col-6  md:col-6">
            <span class="p-float-label">
              <input type='text' pInputText [(ngModel)]="addressResult.VerifiedAddress['ADDRESS_LINE2']" />
              <label>Address Line2 </label>
            </span>
          </div>

          <div class="field col-6  md:col-6">
            <span class="p-float-label">
              <input type='text' pInputText [(ngModel)]="addressResult.VerifiedAddress['CITY']" />
              <label>City</label>
            </span>
          </div>

          <div class="field col-6  md:col-6">
            <span class="p-float-label">
              <input type='text' pInputText [(ngModel)]="addressResult.VerifiedAddress['STATE']" />
              <label>State</label>
            </span>
          </div>

          <div class="field col-6  md:col-6">
            <span class="p-float-label">
              <input type='text' pInputText [(ngModel)]="addressResult.VerifiedAddress['ZIPCODE']" />
              <label>Zip Code</label>
            </span>
          </div>

          <div class="field col-6  md:col-6">
            <span class="p-float-label">
              <input type='text' pInputText [(ngModel)]="addressResult.VerifiedAddress['COUNTRY']" />
              <label>Country</label>
            </span>
          </div>

        </div>
      </div>
    </div>
  </div>

  <button type="button" pButton pRipple label="Use Verified Address" icon="pi pi-check" class="p-button-sm"
    (click)="updateWithVerifiedAddress()"></button>
</p-dialog>
<!-- AddressValidation dialog end-->






<!-- HISTORY FILTER PANEL-->


<p-overlayPanel #historyop [showCloseIcon]="true" [dismissable]="true" [style]="{width: '450px'}">

  <div class="grid p-fluid">

    <div class="field col-12 md:col-6">
      <span class="p-float-label">
        <p-calendar inputId="from" [(ngModel)]="history_filters['shipDateFrom']"></p-calendar>
        <label for="from">From Date</label>

      </span>
    </div>
    <div class="field col-12 md:col-6">
      <span class="p-float-label">
        <p-calendar inputId="to" [(ngModel)]="history_filters['shipDateTo']"></p-calendar>
        <label for="to">To Date</label>
      </span>
    </div>
  </div>
  <div class="grid p-fluid mt-1">
    <ng-template ngFor let-xf [ngForOf]="history_filter_xfields" let-i="index">
      <ng-template ngFor let-field [ngForOf]="xf.fields" let-j="index">
        <ng-template [ngIf]="field['filterField']==true && field['fieldName']!='ShipDate'">

          <div class="field col-12 md:col-6">
            <span class="p-float-label">
              <input type="text" id="inputtext" pInputText
                [(ngModel)]="history_filters[xf.fieldHeader + '.' + field.fieldName]">
              <label for="inputtext">{{ field.title }}</label>
            </span>


          </div>
        </ng-template>
      </ng-template>
    </ng-template>
  </div>

  <!-- <div class="flex align-items-bottom-right"> -->
  <div class="grid p-fluid mt-1">
    <p-button label="Get Shipments" (click)="applyHistoryFilters('shipments',$event , historyop)"
      class="mr-1"></p-button>
    <p-button label="Get Open Orders" (click)="applyHistoryFilters('openorders', $event , historyop)"
      class="mr-1"></p-button>

  </div>


</p-overlayPanel>





<!-- HISTORY FILTER PANEL END-->


</div>

<!-- Fields Reorder Dialog -->
<p-dialog [(visible)]="fieldsReorder_dialog">
  <p-table [value]="currentSelectedBlock" [reorderableColumns]="true">
    <ng-template pTemplate="header">
      <tr>
        <th></th>
        <th>Field Name</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-field let-index="rowIndex">
      <tr [pReorderableRow]="index">
        <td>
          <span class="pi pi-bars"></span>
        </td>
        <td>{{field['fieldName']}}</td>
      </tr>
    </ng-template>

  </p-table>
</p-dialog>
<p-dialog [(visible)]="Repeat_pack_dialog_flag" [style]="{width:'50%', height:'25%' }">
  <div class="card">
    <div class="grid p-fluid mt-1">
      <div class="field col-12 md:col-6">
        <span class="p-float-label">
          <label>No.Of Maximum Possible Boxes </label>


        </span>
      </div>
      <div class="field col-12 md:col-6">

        <span class="p-float-label">
          <input type="text" pInputText [(ngModel)]="possibleBoxes">

        </span>
      </div>
    </div>
  </div>

</p-dialog>