<p-toast></p-toast>
<p-toast position="top-left" key="tl"></p-toast>
<p-toast position="top-center" key="tc"></p-toast>
<p-toast position="bottom-center" key="bc"></p-toast>

<p-toast position="bottom-center" key="c" [baseZIndex]="5000">
    <ng-template let-message pTemplate="message">
        <div class="p-flex p-flex-column" style="flex: 1">
            <div class="p-text-center">
                <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
                <h4>{{ message.summary }}</h4>
                <p>{{ message.detail }}</p>
            </div>
            <div class="p-grid p-fluid">
                <div class="p-col-6">
                    <button type="button" pButton label="Yes" class="p-button-success"></button>
                </div>
                <div class="p-col-6">
                    <button type="button" pButton label="No" class="p-button-secondary"></button>
                </div>
            </div>
        </div>
    </ng-template>
</p-toast>
<div class="card" *ngIf="isDataLoaded">
    <p-toolbar >
        <ng-template pTemplate="left"></ng-template>

        <ng-template pTemplate="right">
            <button pButton pRipple label="New" icon="pi pi-plus" class="p-button-primary p-mr-2"
                (click)="openNew()"></button>
        </ng-template>
    </p-toolbar>

</div>

<p-dialog [(visible)]="openNewDialogFlag" [style]="{ width: '70%' }">
    <div class="card">
        <p-table [value]="xfields" styleClass="p-datatable-sm p-datatable-gridlines p-datatable-striped "
            dataKey="fieldHeader" responsiveLayout="stack">
            <ng-template pTemplate="caption">
                <div class="flex align-items-center justify-content-between">
                    Routing Guide Fields
                </div>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th style="width: 5rem"></th>

                    <th>Coulmn Header</th>

                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-fielddata let-expanded="expanded">
                <ng-template [ngIf]="fielddata.visible==true">
                    <tr>


                        <td>
                            <button type="button" pButton pRipple [pRowToggler]="fielddata"
                                class="p-button-text p-button-rounded p-button-plain"
                                [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
                        </td>

                        <td> {{ fielddata.fieldHeader }}</td>


                    </tr>
                </ng-template>
            </ng-template>

            <ng-template pTemplate="rowexpansion" let-fielddata>
                <tr>

                    <td colspan="3">
                        <div class="p-3">

                            <p-table [value]="fielddata.fields" dataKey="title">
                                <ng-template pTemplate="header">
                <tr>

                    <th>Field</th>
                    <th>PriorityField</th>


                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-field>
                <ng-template [ngIf]="field['TableVisible']==true">
                    <tr>

                        <td>{{field.fieldName}}</td>
                        <td>
                            <p-checkbox [(ngModel)]="field['priorityField']" [binary]="true"
                                [disabled]="false"></p-checkbox>
                        </td>


                    </tr>
                </ng-template>
            </ng-template>

        </p-table>
    </div>
    </td>
    </tr>
    </ng-template>

    </p-table>
    <button pButton pRipple [label]="buttonTitle" class="p-button-primary" (click)="AddToList()"></button>
    </div>
    
        
    
</p-dialog>
<div class="card">
    <p-table #dt *ngIf="isDataLoaded" [value]="rgPriority.PriorityFieldList" responsiveLayout="scroll"
        [tableStyle]="{'min-width': '75rem'}" [scrollable]="true"
        responsiveLayout="stack" styleClass="p-datatable-sm p-datatable-gridlines p-datatable-striped " [reorderableColumns]="true">
        <ng-template pTemplate="header">
            <tr>
                <th style="width:3rem"></th>
                <th pReorderableColumn>Priority Fields </th>
                <th>IsActive</th>
                <th></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-priority let-i="rowIndex">
            <tr [pReorderableRow]="i">
                <td>
                    <span class="pi pi-bars"></span>
                </td>
                <td>{{priority['fields']}}</td>
                <td><p-checkbox [(ngModel)]="priority['IsActive']" [binary]="true" [disabled]="false"></p-checkbox></td>
                <td>
                    <button pButton pRipple icon="pi pi-pencil" class="p-button-primary p-mr-2"
                        (click)="edit(priority)"></button>
                </td>
            </tr>


        </ng-template>


    </p-table>

</div>
<div class="card">
    <button pButton pRipple label="Save" class="p-button-primary p-mr-2" (click)="savePriorityList()"></button>

</div>