import { Component,OnInit,ViewChild } from '@angular/core';
import { ERPSHIPService } from '../erpship.service';
import { MessageService } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'app-consolidation-template',
  templateUrl: './consolidation-template.component.html',
  styleUrls: ['./consolidation-template.component.scss'],providers: [MessageService],
})
export class ConsolidationTemplateComponent {
  newconsolidationflag=false
  consolidationTemplateData:any=[];
  selectedfields:any='';

  buttonTitle="Save"
  newRecord:any={};
  xcolumns = [{ "name": "FeederSystem" }, { "name": "DocumentNumber" }, { "name": "DocumentType" }, { "name": "ERP" }, { "name": "ShipTo_Company" },
  { "name": "ShipTo_Contact" }, { "name": "ShipTo_AddressLine1" }, { "name": "ShipTo_AddressLine2" }, { "name": "ShipTo_City" },
  { "name": "ShipTo_State" }, { "name": "ShipTo_ZipCode" }, { "name": "ShipTo_Country" }]
  isDataLoaded=false;
  
  constructor(private router: Router,  public messageService: MessageService,private xfservice: ERPSHIPService) {
    
  }
  ngOnInit(): void {
    this.xfservice.getConsolidationTemplate().subscribe((data:any)=>{
      this.consolidationTemplateData=data
      this.isDataLoaded = true;
    })
   
     
     
    }
  
  SaveTemplate(){
    if(this.newRecord['name']=='' || this.newRecord['fields']==''){
      alert('Please enter details')
    }
    else{
      this.xfservice.saveConsolidationTemplate(this.newRecord).subscribe((data)=>
        {
          this.xfservice.getConsolidationTemplate().subscribe(data=>this.consolidationTemplateData=data);
        })

     
    }
    this.newRecord={};
    this.newconsolidationflag=false;

  }

  editRecord(record:any){
    this.buttonTitle="Update"
    this.newRecord=record
    alert(JSON.stringify(this.newRecord))
    this.newconsolidationflag=true


  }



}
