<div class="container">
    <div class="inner-container">
        <div class="mt-4 logocontainer">
            <div class="text-center mb-3" style="height: inherit;">
                <img src="assets/images/companylogo.png" alt="Image" class="mt-4 logodiv" style="height: inherit;">
                <div class="text-900 text-3xl font-medium mb-2"></div>
            </div>
        </div>


        <div class="centered">
            <div class="surface-card p-4 formcontainer border-round">


                <h1 class="signinTextCenter" style="text-align: center;"><span class="signtxt">Sign In</span></h1>
                <div>
                    <label for="email1" class="login_text_Css block text-900 font-medium mb-2">Email/Phone</label>
                    <input id="email1" type="text" [(ngModel)]="payload.login" placeholder="Enter Email /Phone"
                        pInputText class="w-full mb-3 login-input">

                    <label for="password1" class="login_text_Css block text-900 font-medium mb-2">Password</label>
                    <input id="password1" [(ngModel)]="payload.password" type="password" placeholder="Enter Password"
                        pInputText class="w-full mb-3 login-input" (keydown.enter)="processlogin()">
                    <span class="eyeicon material-symbols-outlined">
                        visibility_off
                        </span>
                    <div class="flex align-items-center justify-content-between mb-4">
                        <div class="flex align-items-center">
                            <p-checkbox id="rememberme1" [binary]="true" styleClass="mr-2"></p-checkbox>
                            <label for="rememberme1" class="login_text_Css text-900">Remember me</label>
                        </div>
                    </div>

                    <button pButton pRipple label="Sign In"
                        class="w-full login-input-btn bg-color-030e4c" (click)="processlogin()"><span class="iconlock material-symbols-outlined">
                            lock
                            </span></button>
                    <div class="m-3 text-center">
                        <a class="login_text_Css font-medium no-underline ml-2 text-right cursor-pointer color-ec9b1b">Forgot
                            password?</a>
                    </div>
                    <div class=" divlink"></div>
                    <div class="mt-2" style="text-align: center;">

                        <!-- <span class="text-600 font-medium line-height-3">Don't have an account?</span>
                    <a class="font-medium no-underline ml-2 cursor-pointer color-ec9b1b" (click)="openRegister()">Create today!</a> -->
                        <span class=" login_text_Css signin-tittle text-600 font-medium line-height-3">New to eshipjet?</span> 
                        <a class="login_text_Css font-medium no-underline ml-2 cursor-pointer color-ec9b1b">Contact Sales</a>

                    </div>
                    <div class="mt-2 login_text_Css" style="text-align: center;">
                        Privacy Policy
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
<div class="footer-label">
    <div class="col-12 text-center">
        <span><i class="fa fa-copyright"></i> eshipjet2024. All rights reserved</span>
    </div>
</div>





<!-- <div  class="login-body">
  <div class="login-panel"> 
       
    </div>

    <div class="login-content">
        <div class=" w-full lg:w-5">
            <div class="text-center mb-5">

                <img src="assets/images/companylogo.jpg" alt="Image" width= "90" height="150"  class="mb-3">

                <div class="text-900 text-3xl font-medium mb-3"></div>
                <span class="text-600 font-medium line-height-3">Don't have an account?</span>
                <a class="font-medium no-underline ml-2 text-blue-500 cursor-pointer" (click)="openRegister()">Create today!</a>
            </div>
        
            <div>
                <label for="email1" class="block text-900 font-medium mb-2">Email/User Name</label>
                <input id="email1" type="text" [(ngModel)]="payload.login" placeholder="Email /User Name" pInputText class="w-full mb-3">
        
                <label for="password1" class="block text-900 font-medium mb-2">Password</label>
                <input id="password1" [(ngModel)]="payload.password" type="password" placeholder="Password" pInputText class="w-full mb-3">
        
                <div class="flex align-items-center justify-content-between mb-6">
                    <div class="flex align-items-center">
                        <p-checkbox id="rememberme1" [binary]="true" styleClass="mr-2"></p-checkbox>
                        <label for="rememberme1" class="text-900">Remember me</label>
                    </div>
                    <a class="font-medium no-underline ml-2 text-blue-500 text-right cursor-pointer">Forgot password?</a>
                </div>
        
                <button pButton pRipple label="Sign In" icon="pi pi-user" class="w-full" (click)="processlogin()"></button>
            </div>
        </div>
    </div> -->



<!-- <div class="login-content">
        

        <h1><span>SIGN IN</span> PRODUCTNAME</h1>
        <form (ngSubmit)="onSubmit()" [formGroup]="loginForm">
            <div>

                <img src="assets/images/companylogo.jpg" alt="Product Logo" />

            </div>
            
            <div *ngIf="!isValid" class="alert alert-danger">
                <p-message severity="error" text="Invalid UserID or Password"></p-message>
            </div>
            <div class="login-input-wrapper">
                <input type="text" placeholder="Username" formControlName="login" pInputText >
                <i class="pi pi-user"></i>
            </div>

            <div class="login-input-wrapper">
                <input type="password" placeholder="Password" formControlName="password" pInputText>
                <i class="pi pi-lock"></i>
            </div>
            <button pButton type="submit" label="Sign In" [disabled]="loginForm.invalid" class="mr-2"></button>
            <button pButton type='Button' label="Register" (click)="openRegister()"></button>
        </form>
    </div> -->
<!-- </div> -->

<p-dialog [(visible)]="openRegisterFormDialog" [style]="{ width: '70%' }" header="Register" [modal]="true"
    styleClass="p-fluid">

    <p-header>Register</p-header>
    <div class="card">
        <div class="grid p-fluid mt-3">
            <div class="field col-12 md:col-6">
                <span class="p-float-label">
                    <input type="text" id="company" pInputText [(ngModel)]="newcompany['company']">
                    <label for="company">Company Name</label>

                </span>
            </div>

            <div class="field col-12 md:col-6">
                <span class="p-float-label">
                    <input type="text" id="contact" pInputText [(ngModel)]="newcompany['contact']">
                    <label for="contact">Contact Name</label>

                </span>
            </div>
            <div class="field col-12 md:col-6">
                <span class="p-float-label">
                    <input type="text" id="Addrln1" pInputText [(ngModel)]="newcompany['addrln1']">
                    <label for="Addrln1">Address Line1</label>

                </span>
            </div>
            <div class="field col-12 md:col-6">
                <span class="p-float-label">
                    <input type="text" id="addrln2" pInputText [(ngModel)]="newcompany['addrln2']">
                    <label for="addrln2">Address Line2</label>

                </span>
            </div>
            <div class="field col-12 md:col-6">
                <span class="p-float-label">
                    <input type="text" id="city" pInputText [(ngModel)]="newcompany['city']">
                    <label for="city">City</label>

                </span>
            </div>
            <div class="field col-12 md:col-6">
                <span class="p-float-label">
                    <input type="text" id="state" pInputText [(ngModel)]="newcompany['state']">
                    <label for="state">State/Province Code</label>

                </span>
            </div>
            <div class="field col-12 md:col-6">
                <span class="p-float-label">
                    <input type="text" id="country" pInputText [(ngModel)]="newcompany['country']">
                    <label for="country">Country</label>

                </span>
            </div>
            <div class="field col-12 md:col-6">
                <span class="p-float-label">
                    <input type="text" id="zipcode" pInputText [(ngModel)]="newcompany['zipcode']">
                    <label for="zipcode">ZipCode</label>

                </span>
            </div>
            <div class="field col-12 md:col-6">
                <span class="p-float-label">
                    <input type="text" id="e-mail" pInputText [(ngModel)]="newcompany['e-mail']">
                    <label for="e-mail">E-mail</label>

                </span>
            </div>
            <div class="field col-12 md:col-6">
                <span class="p-float-label">
                    <input type="text" id="phoneno" pInputText [(ngModel)]="newcompany['phoneno']">
                    <label for="phoneno">Phone Number</label>

                </span>
            </div>
            <div class="field col-12 md:col-6">
                <span class="p-float-label">
                    <input type="text" id="fax" pInputText [(ngModel)]="newcompany['fax']">
                    <label for="fax">Fax Number</label>

                </span>
            </div>
        </div>
        <button pButton label="Register" (click)="saveCompany()"></button>
    </div>

</p-dialog>