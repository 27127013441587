import { Component, OnInit } from '@angular/core';
import { ERPSHIPService } from '../erpship.service';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-dash-board',
  templateUrl: './dash-board.component.html',
  styleUrls: ['./dash-board.component.scss']
})
export class DashBoardComponent implements OnInit {
  todate: Date = new Date();
  fromdate: Date = new Date();

  stackedFlag:boolean = true;
  data: any;
  options: any;

  data3: any;
  options3: any;

  trackdata: any;
  trackoptions: any;

  requestDateRange = {
    'fromdate': new Date(),
    'todate': new Date()
  }


  carrierColors: any = [];
  statusColors: any = [];

  constructor(private xfservice: ERPSHIPService, private router: Router, private route: ActivatedRoute) {

    this.requestDateRange.fromdate.setDate(this.requestDateRange.todate.getDate() - 7)



    this.xfservice.processAuth().subscribe((data) => { }, error => {
      this.router.navigate(['']);
    });

    this.xfservice.getDashBoardData(this.requestDateRange).subscribe((responsedata) => {


      this.xfservice
      .getxFormRecordsByFromName('colorcodes')
      .subscribe((data) => {
        data.forEach((item: any) => {
          this.statusColors.push(item.details);
        })

       // console.log( this.statusColors)
        this.chart1(responsedata)
        this.chart2(responsedata)
        this.chart3(responsedata)
      });



      this.xfservice
        .getxFormRecordsByFromName('carriers')
        .subscribe((data) => {
          data.forEach((item: any) => {
            this.carrierColors.push(item.details);
          })

         

        });




    });
  }


  processDashBoardrequest() {
    this.xfservice.getDashBoardData(this.requestDateRange).subscribe((responsedata) => {
      this.chart1(responsedata)
      this.chart2(responsedata)
      this.chart3(responsedata)
    });
  }

  chart1(responsedata: any) {
    var resultdata: any = {}
    resultdata = responsedata['getCarrierWiseCountByDate']
    resultdata['labels'].forEach((item: any) => { item = new Date(item).toLocaleDateString('en-US', { month: '2-digit', day: '2-digit' }) })
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue('--text-color');
    const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
    const surfaceBorder = documentStyle.getPropertyValue('--surface-border');

    this.data = {
      labels: [],
      datasets: []
    };

    this.options = {
      
      maintainAspectRatio: false,
      aspectRatio: 0.8,
      plugins: {
        tooltips: {
          mode: 'index',
          intersect: false
        },
        legend: {
          labels: {
            color: textColor
          }
        }
      },
      scales: {
        x: {
         stacked: this.stackedFlag,
          ticks: {
            color: textColorSecondary
          },
          grid: {
            color: surfaceBorder,
            drawBorder: false
          }
        },
        y: {
          stacked: this.stackedFlag,
          ticks: {
            color: textColorSecondary
          },
          grid: {
            color: surfaceBorder,
            drawBorder: false
          }
        }
      }
    };

    this.data['labels'] = resultdata['labels'];
    
    resultdata.carriers.forEach((car: any) => {
      var carrierdata: any = {}
      //carrierdata['type'] = 'bar';
      carrierdata['label'] = car;


      this.statusColors.forEach((item: any) => {
        if (item.status == car)
          carrierdata['backgroundColor'] = item.colorcode;

      })


     
      carrierdata['data'] = resultdata.datasets[car]


      this.data.datasets.push(carrierdata)
      

    })
  }

  chart2(responsedata: any) {

    var resultdata: any = responsedata['getCountByShipmentStatus'];
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue('--text-color');
    const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
    const surfaceBorder = documentStyle.getPropertyValue('--surface-border');

    this.trackdata = {
      labels: resultdata['labels'],
      datasets: [
        {
          label: 'Status',
          data: resultdata['data'],
          backgroundColor: [],


          borderWidth: 1
        }
      ]
    };

    resultdata.labels.forEach((item:any)=>{
      this.statusColors.filter((citem:any)=>{ 
       if( citem.status == item)
       {
        this.trackdata.datasets[0].backgroundColor.push(citem.colorcode)
       }
      })
      
    })

    this.trackoptions = {
      plugins: {
        legend: {
          labels: {
            color: textColor
          }
        }
      },
      scales: {
        y: {
          beginAtZero: true,
          ticks: {
            color: textColorSecondary
          },
          grid: {
            color: surfaceBorder,
            drawBorder: false
          }
        },
        x: {
          ticks: {
            color: textColorSecondary
          },
          grid: {
            color: surfaceBorder,
            drawBorder: false
          }
        }
      }
    };
  }


  chart3(responsedata:any){
    var resultdata: any = {}
    resultdata = responsedata['getCarrierWiseCountByDate']
    resultdata['labels'].forEach((item: any) => { item = new Date(item).toLocaleDateString('en-US', { month: '2-digit', day: '2-digit' }) })
   
        const documentStyle = getComputedStyle(document.documentElement);
        const textColor = documentStyle.getPropertyValue('--text-color');
        const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
        const surfaceBorder = documentStyle.getPropertyValue('--surface-border');
        
        this.data3 = {
            labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
            datasets: []
        };
        
        this.options3 = {
            maintainAspectRatio: false,
            aspectRatio: 0.6,
            plugins: {
                legend: {
                    labels: {
                        color: textColor
                    }
                }
            },
            scales: {
                x: {
                    ticks: {
                        color: textColorSecondary
                    },
                    grid: {
                        color: surfaceBorder
                    }
                },
                y: {
                    ticks: {
                        color: textColorSecondary
                    },
                    grid: {
                        color: surfaceBorder
                    }
                }
            }
        };

        this.data3['labels'] = resultdata['labels'];
        resultdata.carriers.forEach((car: any) => {
          var carrierdata: any = {}
          carrierdata['label'] = car;
          this.statusColors.forEach((item: any) => {
          if (item.status == car)
              carrierdata['borderColor'] = item.colorcode;
          })
          carrierdata['data'] = resultdata.datasets[car]
          carrierdata['fill']=true
          carrierdata['borderDash']=[5,10]
          this.data3.datasets.push(carrierdata)
    
        })

  }

  ngOnInit() {


  }

}
