
import { ShipNowService } from 'src/app/services/ship-now/ship-now.service';

import { Component, OnInit, AfterViewInit, ViewChild, ɵsetAllowDuplicateNgModuleIdsForTest, ElementRef, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { Table } from 'primeng/table'
import { HttpClient, withJsonpSupport } from '@angular/common/http';
import { ERPSHIPService } from 'src/app/erpship.service';
import { MessageService } from 'primeng/api';
import { Observable, forkJoin } from 'rxjs';
import { PrimeNGConfig } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';
import { JsonPipe, Location } from '@angular/common'
import {
  FormGroup,
  FormControl,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { ThisReceiver } from '@angular/compiler';


import { filter, take } from 'rxjs/operators';
import { HistoryService } from 'src/app/services/ship-now/history.service';
import { GetAllDataService } from 'src/app/services/ship-now/get-all-data.service';
import { WarningPopupComponent } from 'src/app/popup/warning-popup/warning-popup.component';

@Component({
  selector: 'app-dashboard-header',
  templateUrl: './dashboard-header.component.html',
  styleUrls: ['./dashboard-header.component.scss']
})
export class DashboardHeaderComponent implements OnInit {
  // Define an EventEmitter property with the @Output decorator
  @Output() dataEmitter = new EventEmitter<string>();
  @ViewChild('cursorFocus') cursorFocus!: ElementRef;
  @Output() triggerRefresh = new EventEmitter<void>();
  @ViewChild(WarningPopupComponent) warningPopup: WarningPopupComponent;
  showWarning: boolean = false;


  showSidebar: boolean = false;
  //filters
  history_filters: any = {};
  history_filter_xfields: any[] = [];


  shiphistoryloading: boolean = false;
  openorderloading: boolean = false;
  validateAddressloading: boolean = false


  //shipmentLevelSpecialServices
  shipmentLevelSpecialServices: any = {};
  packageLevelSpecialServices: any = {};
  specialServiceData: any = {}



  //TESTING PURPOSE & DELETE LATAR
  isSSLoded: boolean = true;
  ssdetails: any = {
    "holdAtLocationDetail": {
      "locationId": "",
      "locationContactAndAddress": {
        "address": {
          "streetLines": [
            "",
            ""
          ],
          "city": "",
          "stateOrProvinceCode": "",
          "postalCode": "",
          "countryCode": "",
          "residential": false
        },
        "contact": {
          "personName": "",
          "emailAddress": "",
          "phoneNumber": "",
          "phoneExtension": "",
          "companyName": "",
          "faxNumber": ""
        }
      },
      "locationType": ""
    }
  };


  //------------------------- TESTING PURPORSE END

 





  //dialog flags
  HistorytableProperties_dialog: boolean = false;
  
  history_Dialog: boolean = false;

  //history orders

  historyOrders: any[] = [];
  Org_historyOrders: any[] = [];
  selectedHistoryOrder: any;

  //for History
  history_xfields: any[] = [];
  history_xcolumns: any[] = [];
  global_history_xcolumns: any[] = [];

  //  for Location 
  xfields: any[] = [];
  xcolumns: any[] = [];


  //
  parameterId = -1;
  saveButtontitle = 'Save';

 
  //-----------------------------------
  dataSourceList: any[] = [];

  currentSelctedField: any = {}

  clearShip: any = {};
  shipment: any = {};

  //super master data
  Original_LocationMasterData: any;
  LocationMasterData: any;

  isDataLoaded: boolean = false;
  packShipDef: any = {};
  paymentTypes: any[] = [];


  shippingDocuments = [{ "DocumentProvider": "", "DocumentType": "", "Description": "", "Action": "" }]
  shippingCharges = [{ "SNo": "", "ChargeDescription": "", "Charge": "", "Currency": "" }]




  form: FormGroup;
  today: Date;
  connections: any=[{LocationId: '1', Name: 'Manual', IsActive: true, id: 1}];
  filters: any;
  deliveries: any;
  listVisible: boolean = false;
  menuVisible: boolean = false;
  searchDialogVisible: boolean = false;

  constructor(
    private router: Router,
    private xfservice: ERPSHIPService, public messageService: MessageService,
     private route: ActivatedRoute,
    private shipNowService: ShipNowService,
    private HistoryService: HistoryService,
    private dataService: GetAllDataService
  ) 
  {

    this.xfservice.getLocationMasterData().subscribe((data) => {
      this.xfservice.getPackShipDefaults().subscribe((defaultdata: any) => {
        this.packShipDef = defaultdata;       
      })

      data['paymentTypes'] = this.paymentTypes;
      this.LocationMasterData = data;
      this.Original_LocationMasterData = JSON.parse(JSON.stringify(data));
      
      Object.keys(this.Original_LocationMasterData).forEach(keyName => {
        let keydata: any = {};
        keydata['name'] = keyName;
        this.dataSourceList.push(keydata)
      });


      this.xfields = this.Original_LocationMasterData.LocationTemplate[0].details.fields;
      this.xcolumns = [];
      this.xfields.forEach(item => {
        this.xcolumns.push(item.fieldName);
      });

      //for historytemplates

      this.history_xfields = this.LocationMasterData['HistoryTable'][0].details


      this.history_xfields.forEach((xitem) => {
        xitem.fields.forEach((field: any) => {
          this.history_xcolumns.push(xitem.fieldHeader + '.' + field.fieldName)
        });

      });

      var cchip = JSON.stringify(this.shipment);
      this.global_history_xcolumns = this.getKeys(this.shipment)
      this.clearShip = JSON.parse(cchip);
      this.currentSelctedField = this.LocationMasterData['FieldModel'][0].details;
      this.route.params.subscribe((params) => {
        this.parameterId = -2
        this.parameterId = params['id'];
        if (this.parameterId == -2 || this.parameterId == undefined) {
          this.isDataLoaded = true;

        }
        else if (this.parameterId.toString().startsWith('COPY')) {
          this.saveButtontitle = 'Save'
          var prm = this.parameterId.toString().split("-");
          this.xfservice.getxFormRecordsByFromName('ShipRequest/' + prm[1]).subscribe((data) => {
            data[0].details.id = -1;
            this.shipment = data[0].details;
            this.shipment.HeaderInfo.ShipDate = new Date();
            var cchip = JSON.stringify(this.shipment);
            this.clearShip = JSON.parse(cchip);
            this.isDataLoaded = true;

          });

          this.isDataLoaded = true;

        } else if (this.parameterId > -1) {
          this.saveButtontitle = 'Update'
          this.xfservice.getxFormRecordsByFromName('ShipRequest/' + this.parameterId).subscribe((data) => {
            this.shipment = data[0].details;
            // this.shipment.HeaderInfo.ShipDate = new Date();

            var cchip = JSON.stringify(this.shipment);
            this.clearShip = JSON.parse(cchip);

           

          });

        }
        this.history_filter_xfields = this.LocationMasterData.HistoryTable[0].details;

        this.history_filter_xfields.forEach((item) => {
          item.fields.forEach((field: any) => {
            if (field['filterField'] == true && field['fieldName'] != "ShipDate") {
              this.history_filters[item.fieldHeader + '.' + field.fieldName] = '';
            }
            else if (field['fieldName'] == "ShipDate") {
              this.history_filters['shipDateFrom'] = '';
              this.history_filters['shipDateTo'] = '';
            }
          })
        })
      });
    });

    this.form = new FormGroup({
      pickupDate: new FormControl('', Validators.required),
      shipDate: new FormControl('', Validators.required),
      selectedConnection: new FormControl('', Validators.required),
      selectedFilter: new FormControl('', Validators.required),
      selectedDelivery: new FormControl('', Validators.required),
      searchTerm: new FormControl('', Validators.required),
      searchTermPopup:  new FormControl('', Validators.required)
    });

  }

  ngOnInit() {
    this.getShippingData();
    this.today = new Date();
    this.form.patchValue({
      pickupDate: this.today,
      shipDate: this.today
    });
    this.getShipments('shiprequest')
    this.form.controls['selectedFilter'].setValue({LocationId: '1', Name: 'Delivery', code: 'DL', IsActive: true, id: 1})
    this.form.controls['selectedConnection'].setValue(this.connections[0])
    this.form.valueChanges.subscribe(value => {
      this.populateData();
    });
  }
  ngAfterViewInit(): void {
    // Focus the input field
    this.cursorFocus.nativeElement.focus();
  }

  getShippingData(): void {
    try {
      this.shipNowService.getShippingData().toPromise()
        .then(data => {
          this.connections = this.connections.concat(data.erp);
          this.filters = data.ordertypes;
        })
        .catch(error => {
          console.error('Error fetching shipping data:', error);
        });     
    } catch (error) {
      console.error('Unexpected error:', error);
    }
  }

  toggleList() {
    this.listVisible = !this.listVisible;
  }

  selectFilter(filter: any) {
    this.form.controls['selectedFilter'].setValue(filter);
    this.listVisible = false;
  }
  

  toggleMenu() {
    this.menuVisible = !this.menuVisible;
  }

  navigateToDashboard() {
    this.router.navigate(['home/DashBoards']);
  }

  refreshPage() {
    window.location.reload();
  }

  showSearchPopup(): void {
    this.searchDialogVisible = true;
  }

  onDialogHide(): void {
    // Handle dialog hide event if needed
  }

  performSearch(): void {
    const searchValue = this.form.get('searchTermPopup').value;
    // Perform your search logic here
    this.searchDialogVisible = false; // Close the dialog after search
  }

  //select shipment or order 
  selectOrderorShipment(ssd: any) {
    this.shipment = this.clearShip;
    // this.shipment = this.selectedHistoryOrder.details;
    this.shipment = ssd.details
    this.shipment.HeaderInfo.ShipDate = new Date(this.shipment.HeaderInfo.ShipDate)

    if (this.shipment.CarrierDetails.Carrier) {
      this.xfservice.getSpecialServicesByCarrier(this.shipment.CarrierDetails.Carrier).subscribe((data: any) => {
        this.specialServiceData = data;
        this.isSSLoded = true;
      })
    }
    this.dataEmitter.emit(this.shipment);
    this.updateHeaderFormData(this.shipment)
    this.history_Dialog = false;
  }

  
  getShipments(fromobject: any) {
    this.historyOrders = []
    if (fromobject == 'shiprequest')
      this.openorderloading = true
    else
      this.shiphistoryloading = true
    this.xfservice
      .getxFormRecordsByFromName(fromobject)
      .subscribe((data) => {
        this.historyOrders = data.reverse();
        this.Org_historyOrders = data;
        if (fromobject == 'shiprequest')
          this.openorderloading = false
        else
          this.shiphistoryloading = false
      });

  }

  openHistoryTableProperties() {
    this.HistorytableProperties_dialog = true;
  }
  
  applyHistoryFilters(status: any, event: any, element: any): void {
    var fromdate = ''
    var toDate = ''
    var conditions: any = {};
    if (this.history_filters['shipDateFrom'] != '' && this.history_filters['shipDateto'] != '') {

      var shipDateFrom = this.history_filters['shipDateFrom'];
      var shipDateTo = this.history_filters['shipDateTo']
      fromdate += shipDateFrom.getFullYear();
      if ((shipDateFrom.getMonth() + 1) < 10) {
        fromdate += '-0' + (shipDateFrom.getMonth() + 1)
      }
      else
        fromdate += '-' + (shipDateFrom.getMonth() + 1)
      if (shipDateFrom.getDate() < 10)
        fromdate += '-0' + shipDateFrom.getDate();
      else
        fromdate += '-' + shipDateFrom.getDate();
      toDate += shipDateTo.getFullYear();
      if ((shipDateTo.getMonth() + 1) < 10) {
        toDate += '-0' + (shipDateTo.getMonth() + 1)
      }
      else
        toDate += '-' + (shipDateTo.getMonth() + 1)
      if (shipDateTo.getDate() < 10)
        toDate += '-0' + shipDateTo.getDate();
      else
        toDate += '-' + shipDateTo.getDate();


      conditions['shipdate'] = "details->'HeaderInfo'->>'ShipDate' between '" + fromdate + "' and '" + toDate + "'"
    }
    var keys = Object.keys(this.history_filters);
    // alert(keys.length)
    keys.forEach((item, index) => {

      var condition = '';
      var fields: any = [];
      var dataConditions = '';
      if (item != "shipDateFrom" && item != "shipDateTo") {
        if (this.history_filters[item] != '') {
          fields = item.split('.')
          condition += "upper(details->'" + fields[0] + "'->>'" + fields[1] + "')=upper('" + this.history_filters[item] + "')";

          dataConditions = dataConditions + ' ' + "upper(details->'" + fields[0] + "'->>'" + fields[1] + "')=upper('" + this.history_filters[item] + "')"
          conditions["condition" + index] = condition
        }
      }
    })

    this.xfservice.getShipmentstByFilters(JSON.stringify(conditions)).subscribe((data: any) => {

      this.Org_historyOrders = data;
      this.historyOrders = data;
      element.hide(event);

      this.isDataLoaded = true;

    })

  }
  openHistoryDialog() {
    this.historyOrders = [];
    this.history_Dialog = true;
  }
  
  
  
  
  getKeys(obj: any): string[] {
    const keys: string[] = [];
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        keys.push(key);
        if (typeof obj[key] === 'object' && obj[key] !== null) {
          const nestedKeys = this.getKeys(obj[key]);
          keys.push(...nestedKeys.map(nestedKey => `${key}.${nestedKey}`));
        }
      }
    }
    return keys;
  }

  applyManifestFilterGlobal($event: any, stringVal: any): void {
    if (($event.target as HTMLInputElement).value) {
      this.historyOrders = this.filterNestedArray(this.Org_historyOrders, ($event.target as HTMLInputElement).value)
    } else {
      this.historyOrders = Array.from(this.Org_historyOrders)
    }

  }

  
  filterNestedArray(jsonArray: any[], searchTerm: string): any[] {
    return jsonArray.filter(item => {
      if (typeof item === 'object' && item !== null) {
        return Object.values(item).some(value => this.filterNestedArray([value], searchTerm).length > 0);
      }
      return String(item).includes(searchTerm);
    });
  }

  filterShipment(search){
    var filterData = this.Org_historyOrders.filter(item =>
      item.details.HeaderInfo.DocumentNumber.toString().includes(search)
    );
    if (filterData.length==1){
      this.shipment=filterData[0].details
      const value = this.form.get('selectedFilter')?.value;
      this.dataEmitter.emit(this.shipment)
      this.updateHeaderFormData(this.shipment)
    }
    else{
      //emiting dummy data
      this.shipment=
      {
        "HeaderInfo": {
            "FeederSystem": "",
            "DocumentNumber": "",
            "DocumentType": "",
            "ShipDate": "",
            "ShipmentType": "",
            "Location": "",
            "ERP": ""
        },
        "Shipper": {
            "COMPANY": "",
            "CONTACT": "",
            "ADDRESS_LINE1": "",
            "ADDRESS_LINE2": "",
            "ADDRESS_LINE3": "",
            "CITY": "",
            "STATE": "",
            "ZIPCODE": "",
            "COUNTRY": "",
            "PHONE": "",
            "EMAIL": ""
        },
        "ShipTo": {
            "COMPANY": "",
            "CONTACT": "",
            "ADDRESS_LINE1": "",
            "ADDRESS_LINE2": null,
            "ADDRESS_LINE3": "",
            "CITY": "",
            "STATE": "",
            "ZIPCODE": "",
            "COUNTRY": "",
            "PHONE": "",
            "EMAIL": ""
        },
        "ShipFrom": {
            "COMPANY": "",
            "CONTACT": "",
            "ADDRESS_LINE1": "",
            "ADDRESS_LINE2": null,
            "ADDRESS_LINE3": "",
            "CITY": "",
            "STATE": "",
            "ZIPCODE": "",
            "COUNTRY": "",
            "PHONE": "",
            "EMAIL": ""
        },
        "SoldTo": {
            "COMPANY": "",
            "CONTACT": "",
            "ADDRESS_LINE1": "",
            "ADDRESS_LINE2": "",
            "ADDRESS_LINE3": "",
            "CITY": "",
            "STATE": "",
            "ZIPCODE": "",
            "COUNTRY": "",
            "PHONE": "",
            "EMAIL": ""
        },
        "CarrierDetails": {
            "Carrier": "",
            "ServiceName": "",
            "PaymentType": "",
            "ShippingAccount": "",
            "BillingAccount": "",
            "BillingCountry": "",
            "BillingZipCode": "",
            "Reference1": "",
            "Reference2": "",
            "Reference3": "",
            "Reference4": "",
            "Note": ""
        },
        "Items": [],
        "Packages": [
            {
                "ItemsInfo": [
                    {
                        "LocationId": "",
                        "ItemNo": "",
                        "ProductNo": "",
                        "Description": "",
                        "UnitCost": "",
                        "UnitWeight": "",
                        "Dimension": "",
                        "HTSCode": "",
                        "ECCN": null,
                        "UN": null,
                        "Class": null,
                        "NMFC": null,
                        "Category": null,
                        "IsSerial": null,
                        "IsDG": null,
                        "IsBatch": null,
                        "IsStackable": null,
                        "IsActive": true,
                        "id": '',
                        "Sno": '',
                        "Quantity": "",
                        "Partial": "",
                        "Balance": ""
                    }
                ],
                "SpecialServices": {},
                "Sno":null,
                "HU": null,
                "Weight": null,
                "Weightunits": null,
                "Dimension":null,
                "DimensionUnits": null
            }
        ],
        "ShipmentLevelServices": {},
        "InternationalDetails": {
            "ItemInfo": [
                {
                    "LocationId":null,
                    "ItemNo": null,
                    "ProductNo": null,
                    "Description":null,
                    "UnitCost": null,
                    "UnitWeight": null,
                    "Dimension": null,
                    "HTSCode": null,
                    "ECCN": null,
                    "UN": null,
                    "Class": null,
                    "NMFC": null,
                    "Category": null,
                    "IsSerial": null,
                    "IsDG": null,
                    "IsBatch": null,
                    "IsStackable": null,
                    "IsActive": null,
                    "id": null,
                    "Sno": null,
                    "Quantity": null,
                    "Partial": null,
                    "Balance": null
                }
            ]
        },
        "id": null
      }
      
      this.dataEmitter.emit(this.shipment)
      this.messageService.add({
        severity: 'warn',
        summary: 'Warning',
        detail: 'Order record not found',
        life: 3000
      });
     
    }

 
  }

  updateHeaderFormData(data){
    var selectedConnectionValue= this.connections.filter(item=>item.Name==data['HeaderInfo']['ERP'])
    var selectedFilterValue= this.filters.filter(item=>item.Name==data['HeaderInfo']['DocumentType'])
    this.form.get('searchTerm')?.setValue(data['HeaderInfo']['DocumentNumber']);
    
    if (selectedConnectionValue.length>0){
      this.form.get('selectedConnection')?.setValue(selectedConnectionValue[0]);
      }
    if (selectedFilterValue.length>0){
        this.form.get('selectedFilter')?.setValue(selectedFilterValue[0]);
        }
    
  }
  
  searchFilter(){
    const searchTerm = this.form.get('searchTerm')?.value;
    if(!searchTerm){
      this.showWarning = true;
      this.warningPopup.showWarning('Please Enter Document Number');
    }else{
    this.filterShipment(searchTerm);
    }
  }

  shipmentTypes: any[] = [
    { label: 'Open', value: 'Open' },
    { label: 'Planning', value: 'Planning' },
    { label: 'Planned', value: 'Planned' },
    { label: 'Shipped', value: 'Shipped' },
    { label: 'Cancelled', value: 'Cancelled' },
    { label: 'Ship Request Profiles', value: 'Ship Request Profiles' }
  ];
  selectedShipmentType: string = 'Shipped';

  shipments: any[] = [];

  // get filteredShipments() {
  //   return this.shipments.filter(shipment => shipment.status === this.selectedShipmentType);
  // }


  openTimelineSidebar()
  {
    this.showSidebar = true;
    this.getTimelineData();
  }

  getTimelineData(): void {
    try {
      this.shipNowService.getTimelineData().toPromise()
        .then(data => {
          console.log("shipments",data.details)
          this.shipments = data;
          // this.connections = data.erp;
          // this.filters = data.ordertypes;
        })
        .catch(error => {
          console.error('Error fetching shipping data:', error);
        });
    } catch (error) {
      console.error('Unexpected error:', error);
    }
  }

  get filteredShipments() {
    return this.shipments.filter(shipment =>
      shipment.details.TrackingStatus === this.selectedShipmentType
    );
  }

  onShipmentTypeChange(selectedType: string): void {
    this.selectedShipmentType = selectedType;
    // Trigger any additional logic if needed when the type changes
  }
  searchTerm : any;
  selectedConnections;
  selectHistory(shipment: any) {
    const shipDate = new Date(shipment.details.HeaderInfo.ShipDate);
    const feederSystem = shipment.details.HeaderInfo.FeederSystem;
    
    this.selectedConnections = this.connections.find(connection => connection.Name === feederSystem);
    // Log to ensure data is set
    const documentType = shipment.details.HeaderInfo.DocumentType;
    const selectedFilter = this.filters.find(filter => filter.Name === documentType);

    console.log('Selected documentType set in service:',documentType); // Log to ensure data is set
    this.form.controls['selectedFilter'].setValue(selectedFilter);
    this.HistoryService.setHistory(shipment);
    this.searchTerm = shipment.details.HeaderInfo.DocumentNumber;
    this.form.patchValue({
      pickupDate: shipDate,
      shipDate: this.today,
      selectedConnection: this.selectedConnections
    });
  }

  populateData()
  { 
    let erp = this.form.get('selectedConnection').value;
    let documentid:any = this.form.get('selectedFilter').value;
   
    const constructPayload = {
      "FeederSystem":erp.Name,
      "DocumentNumber": this.form.get('searchTerm').value,
      "DocumentType": documentid.Name,
      "ShipDate": this.history_filters.shipDateFrom,
      "ShipmentType": erp.name || "", // If there's a form control for ShipmentType
      "Location":  "1",       // If there's a form control for Location
      "ERP": erp.Name,
      "TotalWeight": "020" // Default to "020" if not set
    }
    this.dataService.setDashboardData(constructPayload)
  }
  
}
