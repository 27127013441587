<app-progress-status></app-progress-status>


<div class="card shiprequest_grid_css" *ngIf="isDataLoaded">
  <p-toolbar styleClass="p-mb-4">
    <!-- <button pButton pRipple label="New" icon="pi pi-plus" class="p-button-primary p-mr-2"
      [routerLink]="['/home/xShip']"></button> -->
    <button pButton type="button">
      <i class="pi pi-filter"></i>

      <i class="pi pi-chevron-down" style="margin-left: 10px;"></i>
    </button>
    <ng-template pTemplate="right">
      <span class="p-buttonset">
        <button type="button" pButton pRipple icon="pi pi-file" (click)="downloadFile()" class="mr-2" pTooltip="CSV"
          tooltipPosition="bottom"></button>

      </span>
      <button pButton type="button" icon="pi pi-cog"></button>
    </ng-template>
  </p-toolbar>




  <p-table [value]="shipRequests" [tableStyle]="{'min-width': '75rem'}" [(selection)]="selectedShipRequest"
    [resizableColumns]="true" styleClass="p-datatable-sm p-datatable-gridlines p-datatable-striped " [paginator]="true"
    [rows]="10" [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,25,50]" [rowHover]="true"
    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" dataKey="id">


    <ng-template pTemplate="header">
      <tr>
        <th style="width: 3rem">
          <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
        </th>
        <th pResizableColumn pSortableColumn="id">Id <p-sortIcon field="id"></p-sortIcon></th>
        <th pResizableColumn pSortableColumn="DocumentNumber">
          DocumentNumber <p-sortIcon field="DocumentNumber"></p-sortIcon>
        </th>
        <th pResizableColumn pSortableColumn="ShipDate">
          ShipDate <p-sortIcon field="ShipDate"></p-sortIcon>
        </th>



        <th></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-shipRequest>
      <tr>
        <td>
          <p-tableCheckbox [value]="shipRequest"></p-tableCheckbox>
        </td>
        <td>{{ shipRequest.id }}</td>
        <td>{{ shipRequest.HeaderInfo.DocumentNumber }}</td>
        <td>{{ shipRequest.HeaderInfo.ShipDate}}</td>


        <td>
          <p-splitButton class="bg-none" label="Ship" styleClass="p-button-text" appendTo="body"
            [model]="shipRequest.items" text></p-splitButton>
          <!-- <button pButton pRipple icon="pi pi-pencil" class="p-button-sm p-button-primary p-button-rounded p-mr-3"
            [routerLink]="['/home/xShip', shipRequest.id]" (click)="editShipRequest(shipRequest)"></button>
          <button pButton pRipple class="p-button-rounded p-button-primary p-mr-3" icon="pi pi-clone"
            [routerLink]="['/home/xShip', 'COPY-' + shipRequest.id]"></button> -->
          <!-- <button pButton pRipple icon="pi pi-pencil" class="p-button-sm p-button-primary p-button-rounded p-mr-3"
            [routerLink]="['/home/shiprequestmodal', shipRequest.id]" (click)="editShipRequest(shipRequest)"></button>
          <button pButton pRipple class="p-button-rounded p-button-primary p-mr-3" icon="pi pi-clone"
            [routerLink]="['/home/shiprequestmodal', 'COPY-' + shipRequest.id]"></button> -->
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="summary">
      <div class="p-d-flex p-ai-center p-jc-between">
        In total there are {{ shipRequests.length }} Ship Request(s)
      </div>
    </ng-template>
  </p-table>
</div>
<div class="text-center">
  <p class="footer-text pt-2"><span class="fa fa-copyright"></span> eshipjet2024. All rights reserved</p>
</div>