import { animate, style, transition, trigger } from '@angular/animations';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ChangeDetectorRef, Component, ViewEncapsulation,ViewChild } from '@angular/core';
import { ShipToFromComponent } from '../pages/dashboard/ship-to-from/ship-to-from.component';
import { WarningPopupComponent } from '../popup/warning-popup/warning-popup.component';
import { GetAllDataService } from '../services/ship-now/get-all-data.service';
import { ShipNowService } from '../services/ship-now/ship-now.service';
import { MessageService } from 'primeng/api';
import { StorageService } from '../services/storage.service';

@Component({
  selector: 'app-ship-now',
  templateUrl: './ship-now.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: [
    './ship-now.component.scss'],
    animations: [
      trigger('fadeIn', [
        transition(':enter', [
          style({ opacity: 0 }),
          animate('500ms ease-in', style({ opacity: 1 }))
        ])
      ])
    ]
})
export class ShipNowComponent {
  @ViewChild(ShipToFromComponent) childComponent: ShipToFromComponent;
  @ViewChild(WarningPopupComponent) warningPopup: WarningPopupComponent;
  showWarning: boolean = false;
  warningMessage: string = '';
  selectedOrder:any;
  pickupDate:any;
  shipDate:any;
  selectedConnection: any;
  connections: Array<any> = [
    { id: 1, name: 'd365' },
    { id: 2, name: 'Salesforce' },
    { id: 3, name: 'snowflake' },
  ];
  selectedFilter: any;
  filters: Array<any> = [
    { id: 1, name: 'Fedex' },
    { id: 2, name: 'BlueDart' },
    { id: 3, name: 'Delihvery' },
  ];
  inputSearch: string = '';
  clearShip: any = {};
  shipment: any = {}; //super master data

 
  refreshFlag = false;


  constructor(private cdr: ChangeDetectorRef,private dataService : GetAllDataService,private shipNowService : ShipNowService,private messageService : MessageService,private storageService : StorageService) {}
  // tabs = [
  //   { header: 'Ship From/To' },
  //   { header: 'Routing & Billing Details' },
  //   { header: 'Additional Info' },
  //   { header: 'Pack' },
  //   { header: 'International Details' },
  //   { header: 'DG' }
  // ];

  ngOnInit() {
    const savedTabIndex:any =  this.storageService.getItem('selectedTabIndex');
    this.selectedTabIndex = savedTabIndex ? parseInt(savedTabIndex, 10) : 0;
    this.selectedFilter = this.filters[0];

    this.shipment = {
      HeaderInfo: {
        FeederSystem: "",
        DocumentNumber: "",
        DocumentType: "",
        ShipDate: "",
        ShipmentType: "",
        Location: "",
        ERP: "",
        TotalWeight: ""
      },
      Shipper: {
        COMPANY: "",
        CONTACT: "",
        ADDRESS_LINE1: "",
        address_line2: "",
        address_line3: "",
        city: "",
        state: "",
        zipcode: "",
        country: "",
        phone: "",
        email: ""
      },
      ShipTo: {
        COMPANY: "",
        CONTACT: "",
        ADDRESS_LINE1: "",
        address_line2: "",
        address_line3: "",
        city: "",
        state: "",
        zipcode: "",
        country: "",
        phone: "",
        email: "",
        TAXID: ""
      },
      ShipFrom: {
        COMPANY: "",
        CONTACT: "",
        ADDRESS_LINE1: "",
        address_line2: "",
        address_line3: "",
        city: "",
        state: "",
        zipcode: "",
        country: "",
        phone: "",
        email: ""
      },
      SoldTo: {
        COMPANY: "",
        CONTACT: "",
        ADDRESS_LINE1: "",
        ADDRESS_LINE2: "",
        ADDRESS_LINE3: "",
        CITY: "",
        STATE: "",
        ZIPCODE: "",
        COUNTRY: "",
        PHONE: "",
        EMAIL: ""
      },
      CarrierDetails: {
        Carrier: "",
        ServiceName: "",
        PaymentType: "",
        ShippingAccount: "",
        BillingAccount: "",
        BillingCountry: "",
        BillingZipCode: "",
        Reference1: "",
        Reference2: "",
        Reference3: "",
        Reference4: "",
        Note: "",
        UserId: "",
        Password: "",
        AccessKey: ""
      },
      Items: [],
      Packages: [
        {
          ItemsInfo: [
            {
              ItemNo: "",
              ProductNo: "",
              Description: "",
              IsDG: null,
              UnitCost: "",
              UnitWeight: "",
              Dimension: "",
              HTSCode: "",
              ECCN: "",
              UN: "",
              Class: "",
              NMFC: "",
              Category: "",
              IsSerial: null,
              IsBatch: null,
              IsStackable: null,
              IsActive: true,
              LocationId: "",
              id: 1,
              Sno: 1,
              Quantity: "",
              Partial: "",
              Balance: ""
            }
          ],
          SpecialServices: {},
          Weightunits: "",
          DimensionUnits: "",
          Sno: 1,
          HU: null,
          Weight: "",
          Dimension: "",
          PackageSpecialServiceTyeps: []
        }
      ],
      ShipmentLevelServices: {},
      InternationalDetails: {
        ItemInfo: [
          {
            ItemNo: "",
            ProductNo: "",
            Description: "",
            IsDG: null,
            UnitCost: "",
            UnitWeight: "",
            Dimension: "",
            HTSCode: "",
            ECCN: "",
            UN: "",
            Class: "",
            NMFC: "",
            Category: "",
            IsSerial: null,
            IsBatch: null,
            IsStackable: null,
            IsActive: null,
            LocationId: "",
            id: 1,
            Sno: 1,
            Quantity: "",
            Partial: "",
            Balance: ""
          }
        ]
      },
      "id": 86,
      "ShipmentSpecialServiceTyeps": []
    }


  }
  selectedTabIndex = 0;

  tabs = [
    { label: 'Ship From/To', content: 'ship-to-from' },
    { label: 'Routing & Billing Details', content: 'routing-billing-detail' },
    { label: 'Additional Info', content: 'additional-info' },
    { label: 'Pack', content: 'ship-now-pack' },
    { label: 'International Details', content: 'international-details' },
    { label: 'DG', content: 'dg' },
    { label: 'Charges', content: 'charges' },
    { label: 'Documents', content: 'documents' },
    { label: 'Track', content: 'track' }
  ];

  drop(event: CdkDragDrop<string[]>) {
    const prevActive = this.tabs[this.selectedTabIndex];
    moveItemInArray(this.tabs, event.previousIndex, event.currentIndex);
    this.selectedTabIndex = this.tabs.indexOf(prevActive);
  }

  // Method to handle the data received from the child component
  receiveData(data: string) {
    this.selectedOrder=data;
  }

validateForm(formValues: any): boolean {
  
    if (!formValues.ShipTo.COUNTRY) {
      this.warningPopup.showWarning('Please Enter Ship To Country');
      return false;
    }
    if (!formValues.ShipTo.ADDRESS_LINE1) {
      this.warningPopup.showWarning('Please Enter Address Line 1');
      return false;
    }
    if (!formValues.ShipTo.CITY) {
      this.warningPopup.showWarning('Please Enter Ship To City');
      return false;
    }
    if (!formValues.ShipTo.ZIPCODE) {
      this.warningPopup.showWarning('Please Enter Ship To ZipCode');
      return false;
    }
    if (!formValues.ShipTo.STATE) {
      this.warningPopup.showWarning('Please Enter Ship To State');
      return false;
    }
    if (!formValues.ShipTo.COMPANY) {
      this.warningPopup.showWarning('Please Enter Company Name');
      return false;
    }
    if (!formValues.CarrierDetails.Carrier) {
      this.warningPopup.showWarning('Please select Ship Method');
      return false;
    }
    if (!formValues.HeaderInfo.DocumentNumber) {
      this.warningPopup.showWarning('Enter Delivery/Shipment #');
      return false;
    }
    for (let i = 0; i < formValues.Packages.length; i++){
    if (!formValues.Packages[i].Weight) {
      this.warningPopup.showWarning('Enter Package Weight');
      return false;
    }
  }
    return true;
  }

  onSubmit() {
    if (this.childComponent) {
      const formValues = this.childComponent.getFormValues();
      this.showWarning = true;
  
      // Validate form values
      const isValid = this.validateForm(formValues);
  
      if (isValid) {
        // If form values are valid, proceed with the API call
        this.makeAPICall();
      } else {
        // If form values are not valid, set showWarning to false
        this.showWarning = false;
      }

    }
  }
  
  triggerRefresh() {
    this.refreshFlag = !this.refreshFlag; // Toggle to trigger change detection
  }
  // collect ALl Data from Tabs 

  makeAPICall() {
    const allData = this.dataService.getAllData();
    // Extract data from the service
    const { dashboardData, shipToFromData, routingAndBillingData, packNowData } = allData;
    const todayDate = new Date().toISOString();
    const payload = {
      "HeaderInfo": {
        "FeederSystem": dashboardData?.FeederSystem ?? "",
        "DocumentNumber": dashboardData?.DocumentNumber ?? "", 
        "DocumentType": dashboardData?.DocumentType ?? "",
        "ShipDate": todayDate,
        "ShipmentType": dashboardData?.ShipmentType ?? "",
        "Location": dashboardData?.Location ?? "",
        "ERP": dashboardData?.ERP ?? "",
        "TotalWeight": dashboardData?.TotalWeight ?? ""
      },
      "Shipper": {
        "COMPANY": shipToFromData?.ShipFrom?.COMPANY ?? "",
        "CONTACT": shipToFromData?.ShipFrom?.CONTACT ?? "",
        "ADDRESS_LINE1": shipToFromData?.ShipFrom?.ADDRESS_LINE1 ?? "",
        "ADDRESS_LINE2": shipToFromData?.ShipFrom?.ADDRESS_LINE2 ?? "",
        "ADDRESS_LINE3": shipToFromData?.ShipFrom?.ADDRESS_LINE3 ?? "",
        "CITY": shipToFromData?.ShipFrom?.CITY ?? "",
        "STATE": shipToFromData?.ShipFrom?.STATE ?? "",
        "ZIPCODE": shipToFromData?.ShipFrom?.ZIPCODE ?? "",
        "COUNTRY": shipToFromData?.ShipFrom?.COUNTRY ?? "",
        "PHONE": shipToFromData?.ShipFrom?.PHONE ?? "",
        "EMAIL": shipToFromData?.ShipFrom?.EMAIL ?? ""
      },
      "ShipTo": {
        "COMPANY": shipToFromData?.ShipTo?.COMPANY ?? "",
        "CONTACT": shipToFromData?.ShipTo?.CONTACT ?? "",
        "ADDRESS_LINE1": shipToFromData?.ShipTo?.ADDRESS_LINE1 ?? "",
        "ADDRESS_LINE2": shipToFromData?.ShipTo?.ADDRESS_LINE2 ?? "test",
        "ADDRESS_LINE3": shipToFromData?.ShipTo?.ADDRESS_LINE3 ?? "",
        "CITY": shipToFromData?.ShipTo?.CITY ?? "",
        "STATE": shipToFromData?.ShipTo?.STATE ?? "",
        "ZIPCODE": shipToFromData?.ShipTo?.ZIPCODE ?? "",
        "COUNTRY": shipToFromData?.ShipTo?.COUNTRY ?? "",
        "PHONE": shipToFromData?.ShipTo?.PHONE ?? "",
        "EMAIL": shipToFromData?.ShipTo?.EMAIL ?? "",
        "TAXID": shipToFromData?.ShipTo?.TAXID ?? ""
      },
      "ShipFrom": {
        "COMPANY": shipToFromData?.ShipFrom?.COMPANY ?? "",
        "CONTACT": shipToFromData?.ShipFrom?.CONTACT ?? "",
        "ADDRESS_LINE1": shipToFromData?.ShipFrom?.ADDRESS_LINE1 ?? "",
        "ADDRESS_LINE2": shipToFromData?.ShipFrom?.ADDRESS_LINE2 ?? "test",
        "ADDRESS_LINE3": shipToFromData?.ShipFrom?.ADDRESS_LINE3 ?? "",
        "CITY": shipToFromData?.ShipFrom?.CITY ?? "",
        "STATE": shipToFromData?.ShipFrom?.STATE ?? "",
        "ZIPCODE": shipToFromData?.ShipFrom?.ZIPCODE ?? "",
        "COUNTRY": shipToFromData?.ShipFrom?.COUNTRY ?? "",
        "PHONE": shipToFromData?.ShipFrom?.PHONE ?? "",
        "EMAIL": shipToFromData?.ShipFrom?.EMAIL ?? ""
      },
      "SoldTo": {
        "COMPANY": "",
        "CONTACT": "",
        "ADDRESS_LINE1": "",
        "ADDRESS_LINE2": "",
        "ADDRESS_LINE3": "",
        "CITY": "",
        "STATE": "",
        "ZIPCODE": "",
        "COUNTRY": "",
        "PHONE": "",
        "EMAIL": ""
      },
      "CarrierDetails": {
        "Carrier": routingAndBillingData?.CarrierDetails?.Carrier?.CarrierName ?? "",
        "ServiceName": routingAndBillingData?.CarrierDetails?.ServiceName?.ServiceCode ??'FEDEX_GROUND',
        "PaymentType": routingAndBillingData?.CarrierDetails?.PaymentType?.value ?? "",
        "ShippingAccount": routingAndBillingData?.CarrierDetails?.ShippingAccount ?? "",
        "BillingAccount": routingAndBillingData?.CarrierDetails?.BillingAccount ?? "",
        "BillingCountry": routingAndBillingData?.CarrierDetails?.BillingCountry ?? "",
        "BillingZipCode": routingAndBillingData?.CarrierDetails?.BillingZipCode ?? "",
        "Reference1": routingAndBillingData?.CarrierDetails?.Reference1 ?? "",
        "Reference2": routingAndBillingData?.CarrierDetails?.Reference2 ?? "",
        "Reference3": routingAndBillingData?.CarrierDetails?.Reference3 ?? "",
        "Reference4": routingAndBillingData?.CarrierDetails?.Reference4 ?? "",
        "Note": routingAndBillingData?.CarrierDetails?.Note ?? "",
        "UserId": "l70c717f3eaf284dc9af42169e93874b6e",
        "Password": "7f271bf486084e8f8073945bb7e6a020",
        "AccessKey": routingAndBillingData?.CarrierDetails?.AccessKey ?? ""
      },
      "Packages": packNowData?.map(pkg => ({
        "ItemsInfo": pkg?.ItemsInfo?.map(item => ({
          "ItemNo": item?.ItemNo ?? "10X10X10",
          "ProductNo": item?.product ?? "PRD0001",
          "Description": item?.description ?? "ITEM 1 Description",
          "IsDG": item?.isDG ?? null,
          "UnitCost": item?.unitCost ?? "3.22",
          "UnitWeight": item?.unitWeight ?? "0.5",
          "Dimension": pkg?.dimension ?? "10X10X10",
          "HTSCode": item?.HTSCode ?? "HTSCODE IT0001",
          "ECCN": item?.ECCN ?? "ECCN IT 0001",
          "UN": item?.UN ?? "UN IT0001",
          "Class": item?.class ?? "III",
          "NMFC": item?.NMFC ?? "NMFC 0001",
          "Category": item?.Category ?? "CAT01",
          "IsSerial": item?.IsSerial ?? null,
          "IsBatch": item?.IsBatch ?? null,
          "IsStackable": item?.IsStackable ?? null,
          "IsActive": item?.IsActive ?? true,
          "LocationId": item?.LocationId ?? "",
          "id": item?.id ?? 1,
          "Sno": item?.Sno ?? 1,
          "Quantity": item?.totalQuantity ?? "10",
          "Partial": item?.partialQuantity ?? "10",
          "Balance": item?.balanceQuantity ?? "10"
        })) ?? [],
        "SpecialServices": pkg?.SpecialServices ?? {},
        "Weightunits": pkg?.weightUnits ?? "LBS",
        "DimensionUnits": pkg?.dimensionUnits ?? "IN",
        "Sno": pkg?.Sno ?? 1,
        "HU": pkg?.hu ?? null,
        "Weight": pkg?.weight ?? "20",
        "Dimension": pkg?.dimension ?? "5X10X10",
        "PackageSpecialServiceTyeps": pkg?.PackageSpecialServiceTyeps ?? []
      })) ?? [],
      "ShipmentLevelServices": packNowData?.ShipmentLevelServices ?? {},
      "id": packNowData?.id ?? 86,
      "ShipmentSpecialServiceTyeps": packNowData?.ShipmentSpecialServiceTyeps ?? [],
      "InternationalDetails": {}
    };
    if (this.validateData(payload)) {  
      // Make the first API call
      this.shipNowService.sendFedExRequest(payload).subscribe({
        next: (response) => {  
          this.storageService.setItem('shipmentData',response);
          // Make the second API call with the response from FedEx API
          this.shipNowService.sendShipRequestCarrier(response).subscribe({
            next: (shipRequestResponse) => {
             // this.storageService.setItem('selectedTabIndex',6);
             // this.selectedTabIndex = 6;
             this.ngOnInit();
              this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Shipment processed successfully!', life: 3000 });
            },
            error: (err) => {
              console.error('Error in Ship Request API call:', err);
              this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error processing ship request. Please try again.', life: 3000 });
            }
          });
        },
        error: (err) => {
          console.error('Error in FedEx API call:', err);
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error processing FedEx request. Please try again.', life: 3000 });
        }
      });
    }
  }

  validateData(data: any): boolean {
    let isValid = true;
  
    // Validate ServiceName
    if (!data.CarrierDetails.ServiceName) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Please select a service type from routing and billing details.',
        life: 3000
      });
      isValid = false;
    }
  
    // Check if Packages is empty
    if (!data.Packages || data.Packages.length === 0) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Please create a package in the packs tab.',
        life: 3000
      });
      isValid = false;
    }
  
    // Validate each package
    for (let i = 0; i < data.Packages.length; i++) {
      const pkg = data.Packages[i];
  
      // Validate Weight
      if (!pkg?.Weight || isNaN(Number(pkg.Weight)) || Number(pkg.Weight) <= 0) {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: `Please enter a valid weight for package ${i + 1}.`,
          life: 3000
        });
        isValid = false;
      }
    }
    return isValid;
  }  
  onTabChange(index: number): void {
    // Save the selected tab index to localStorage
    this.storageService.setItem('selectedTabIndex', index.toString());
  }
}
