<div class="chatbot-wrapper">
    <div class="header-content">
        <!-- <img src="path-to-logo.png" alt="Logo" class="logo" /> -->
        <h1 class="title">Shipper Copilot</h1>

    </div>

    <div class="chatbot-content">
        <div class="logo-center">
            <img src="../../assets/images/copilotlogo.png" alt="Copilot Logo" />
        </div>
        <div class="quick-actions">
            <div class="action-card" *ngFor="let action of actions">
                <img [src]="action.image" alt="{{ action.label }}" class="action-image" />
                <span>{{ action.label }}</span>
            </div>
        </div>
    </div>

    <div class="chat-input-wrapper">
        <img src="../../assets/images/chainlink.png" alt="Link Icon" class="input-icon" />
        <input type="text" class="chat-input" placeholder="Message ShipperCopilot" />
        <button class="send-button">
            <i class="pi pi-arrow-up"></i>
        </button>
    </div>
    <div class="copyright-text">
        &copy; eshipjet2024. All rights reserved.
      </div>
</div>