<p-toast></p-toast>
<p-toast position="top-left" key="tl"></p-toast>
<p-toast position="top-center" key="tc"></p-toast>
<p-toast position="bottom-center" key="bc"></p-toast>

<p-toast position="bottom-center" key="c" [baseZIndex]="5000">
    <ng-template let-message pTemplate="message">
        <div class="p-flex p-flex-column" style="flex: 1">
            <div class="p-text-center">
                <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
                <h4>{{ message.summary }}</h4>
                <p>{{ message.detail }}</p>
            </div>
            <div class="p-grid p-fluid">
                <div class="p-col-6">
                    <button type="button" pButton label="Yes" class="p-button-success"></button>
                </div>
                <div class="p-col-6">
                    <button type="button" pButton label="No" class="p-button-secondary"></button>
                </div>
            </div>
        </div>
    </ng-template>
</p-toast>


<p-toolbar styleClass="p-mb-4">
    <ng-template pTemplate="right">
        <button pButton pRipple label="New" icon="pi pi-plus" class="p-button-primary p-mr-2"
            (click)="openNewSpecialService()"></button>
    </ng-template>
    <ng-template pTemplate="left">
        <label class="pi pi-truck mr-2 " style="font-size: 2rem"> Special Service Configuration</label>
    </ng-template>
</p-toolbar>

<!-- TABLE TO DISPLAY LIST OF EXISTING SPECIAL SERVICES -->
<p-table #ft [value]="specialServiceList" [rows]="10" responsiveLayout="stack" [rowHover]="true" [paginator]="true"
    [globalFilterFields]="['carrier']">
    <ng-template pTemplate="header">
        <tr>
            <th pSortableColumn="id">
                ID <p-sortIcon field="id"></p-sortIcon>
            </th>
            <th pSortableColumn="carrier">
                Carrier <p-sortIcon field="carrier"></p-sortIcon>
            </th>
            <th></th>
        </tr>
    </ng-template>


    <ng-template pTemplate="body" let-carrierSS>
        <tr>
            <td>{{ carrierSS.id }}</td>
            <td>{{ carrierSS.carrier }}</td>

            <td>
                <button pButton pRipple icon="pi pi-pencil" class="p-button-rounded p-button-primary p-mr-2"
                    (click)="editSpecialService(carrierSS)"></button>
            </td>
        </tr>
    </ng-template>

</p-table>



<!-- SPECIAL SERVICE DIALOG TO ADD NEW SPECIAL SERVICE AND TO UPDATE XISTING SPECIAL SERVICE-->
<p-dialog [(visible)]="specialServiceDialog_flag" [style]="{ width: '95%',height: '90%' }"
    header="Special Service Configuration" [modal]="true" styleClass="p-fluid">
    <div class="card">
        <div class="grid p-fluid mt-3">
            <div class="field col-12 md:col-2">
                <span class="p-float-label">
                    <p-dropdown id="carrier" [autoDisplayFirst]="false" [options]="carrierList" optionLabel="carrier"
                        appendTo="body" optionValue="carrier" [(ngModel)]="currentSelectedCarrier"
                        (onChange)="processCarrierChange()" [style]="{ width: '100%' }">
                    </p-dropdown>
                    <label for="carrier">Carrier :</label>
                </span>
            </div>
            <div class="field col-12 md:col-2">
                <span class="p-float-label">
                    <p-dropdown id="SpecialServiceLevel" [autoDisplayFirst]="false"
                        [options]="[{'name':'Shipment'},{'name':'Package'}]" optionLabel="name" appendTo="body"
                        optionValue="name" [(ngModel)]="currentSelectedSpecialServiceLevel"
                        (onChange)="processSpecialServiceLevelChange()" [style]="{ width: '100%' }">
                    </p-dropdown>
                    <label for="SpecialServiceLevel">Special Service Level :</label>
                </span>
            </div>
            <div class="field col-12 md:col-2">
                <span class="p-float-label">
                    <input type="text" id="SpecialServiceName" pInputText [(ngModel)]="currentSpecialServiceName"
                        [disabled]="false" />
                    <label for="SpecialServiceName">Name</label>
                </span>
            </div>
            <div class="field col-12 md:col-2">
                <span class="p-float-label">
                    <input type="text" id="SpecialServiceName" pInputText [(ngModel)]="currentSpecialServiceTitle"
                        [disabled]="false" />
                    <label for="SpecialServiceName">Title</label>
                </span>
            </div>
            <div class="field col-12 md:col-1">
                <p-checkbox [binary]="true" inputId="isActive" [(ngModel)]="isActive"></p-checkbox>
                <label for="isActive" class="mr-2">Is Active</label>
            </div>
            <div class="field col-12 md:col-3">
                <span class="p-float-label">
                    <button pButton pRipple label="Add Special Service" icon="pi pi-plus" class="p-button-sm"
                        [disabled]="currentSpecialServiceName=='' || currentSelectedSpecialServiceLevel=='' || currentSelectedCarrier==''"
                        (click)="addSpecialService()"></button>
                </span>
            </div>
        </div>

        <ng-template
            [ngIf]="currentSelectedSpecialServiceLevel == 'Shipment' || currentSelectedSpecialServiceLevel == 'Package'  ">

            <div *ngIf="this.specialServiceData[this.currentSelectedSpecialServiceLevel].length > 0">
                <!--  TABLE START HERE -->

                <p-table [value]="this.specialServiceData[this.currentSelectedSpecialServiceLevel]" dataKey="SSName"
                    [style]="{ width: '100%', height: '60vh', overflow: 'scroll' }"
                    styleClass="p-datatable-sm p-datatable-gridlines p-datatable-striped " responsiveLayout="stack">
                    <ng-template pTemplate="header">
                        <tr>
                            <th style="width: 5rem"></th>
                            <th>Title</th>
                            <th>Special Service Name</th>
                            <th>Is Visible</th>
                            <th>Is Active</th>
                            <th> </th>

                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-SS let-expanded="expanded">
                        <tr>
                            <td>
                                <button type="button" pButton pRipple [pRowToggler]="SS"
                                    class="p-button-text p-button-rounded p-button-plain"
                                    [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
                            </td>
                            <td pEditableColumn>
                                <p-cellEditor>
                                    <ng-template pTemplate="input">
                                        <input pInputText type="text" [style]="{ border: '0px' }"
                                            [readonly]="!SS.editable" [(ngModel)]="SS['title']" />
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                        {{ SS['title'] }}
                                    </ng-template>
                                </p-cellEditor>
                            </td>


                            <td pEditableColumn>
                                <p-cellEditor>
                                    <ng-template pTemplate="input">
                                        <input pInputText type="text" [style]="{ border: '0px' }"
                                             [(ngModel)]="SS['SSName']" />
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                        {{ SS['SSName'] }}
                                    </ng-template>
                                </p-cellEditor>
                            </td>

                            <!-- <td>{{SS['SSName']}}</td> -->
                            <td>{{SS['IsVisible']}}</td>
                            <td>
                                <p-checkbox [(ngModel)]="SS['isActive']" [binary]="true"></p-checkbox>
                            </td>

                            <td>
                                <p-button type="button" icon="pi pi-plus" label="New  Field"
                                    class="p-button-primary p-mr-3"
                                    (click)="openNewFieldDialog(SS['SSName'])"></p-button>
                            </td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="rowexpansion" let-SS>
                        <tr>
                            <td colspan="7">
                                <div class="p-3">
                                    <p-table [value]="SS['fields']" dataKey="fieldName">
                                        <ng-template pTemplate="header">
                        <tr>
                            <th>Field Name </th>
                            <th>Field Type</th>
                            <th>Data Type</th>
                            <th></th>

                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-field>
                        <tr>

                            <td pEditableColumn>
                                <p-cellEditor>
                                    <ng-template pTemplate="input">
                                        <input pInputText type="text" [style]="{ border: '0px' }"
                                           [(ngModel)]="field.fieldName" />
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                        {{ field.fieldName }}
                                    </ng-template>
                                </p-cellEditor>
                            </td>


                            <!-- <td>{{field.fieldName}}</td> -->
                            <td>{{field.fieldType}}</td>
                            <td>{{field.datatype}}</td>
                            <td><p-button type="button" icon="pi pi-cog"
                                    (click)="openFieldProperties(field)"></p-button></td>

                        </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage">
                        <tr>
                            <td colspan="6">There are no fields for this {{ SS.title }} yet.</td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
            </td>
            </tr>
        </ng-template>
        </p-table>

        <!-- TABLE END HERE   -->
    </div>

    </ng-template>


    </div>

    <button pButton type="button" icon="pi pi-check" iconPos="left" [label]="saveButtonTitle"
        [disabled]=" currentSelectedSpecialServiceLevel=='' || currentSelectedCarrier==''"
        (click)="SaveTotalSpecialService()"></button>
</p-dialog>


<!-- NEW FIELD NAME DIALOG-->
<p-dialog [(visible)]="newFieldDialog_flag" header="New Field " [modal]="true" styleClass="p-fluid">
    <div class="p-fluid p-formgrid grid mt-3">
        <div class="field col-12 md:col-8">
            <span class="p-float-label">
                <input type="text" pInputText [(ngModel)]="newFieldName" id="newfield" />
                <label for="newfield">New Field Name:</label>
            </span>
        </div>

        <div class="field col-12 md:col-12">
            <span class="p-float-label">
                <button pButton type="button" icon="pi pi-check" iconPos="left" label="Add New Field"
                    (click)="addNewFieldFor()"></button>
            </span>
        </div>

    </div>
</p-dialog>

<!-- Field Properties Sidebar -->
<p-sidebar [(visible)]="displayFieldPropertiesFlag" position="right">
    <div class="card">
        <h4>Field Properties</h4>


        <div class="field md:col-12">
            <span class="p-float-label">
                <input id="title" type="text" pInputText [(ngModel)]="field.title">
                <label for="title">Title</label>
            </span>
        </div>
        <div class="field md:col-12">
            <span class="p-float-label">

                <p-dropdown
                    [options]="[{'name' : 'Textbox'},{'name' : 'Dropdown'},{'name' : 'Checkbox'},{'name' : 'MultiSelect'}]"
                    [(ngModel)]="field.fieldType" optionLabel="name" [editable]="false" [filter]="true"
                    optionValue="name" [autoDisplayFirst]="false" [style]="{ width: '100%' }">
                </p-dropdown>
                <!-- <input id="fieldType" type="text" pInputText [(ngModel)]="field.fieldType"> -->
                <label for="fieldType">fieldType</label>
            </span>
        </div>
        <div class="field md:col-12">
            <span class="p-float-label">
                <p-dropdown [options]="[{'name' : 'text'},{'name' : 'number'},{'name' : 'boolean'}]"
                    [(ngModel)]="field.datatype" optionLabel="name" [editable]="false" [filter]="true"
                    optionValue="name" [autoDisplayFirst]="false" [style]="{ width: '100%' }">
                </p-dropdown>
                <label for="datatype">DataType</label>
            </span>
        </div>

        <!-- <div class="field md:col-12">
            <span class="p-float-label">
                <input id="Model" type="text" pInputText [(ngModel)]="field.model">
                <label for="Model">Model</label>
            </span>
        </div> -->

        <div class="field md:col-12">
            <span class="p-float-label">
                <input id="width" type="text" pInputText [(ngModel)]="field.width">
                <label for="width">Width (Ex: col-4)</label>
            </span>
        </div>
        <div class="field md:col-12">
            <span class="p-float-label">
                <input id="source" type="text" pInputText [(ngModel)]="field.source">
                <label for="source">Source</label>
            </span>
        </div>
        <div class="field md:col-12">
            <span class="p-float-label">
                <input id="optionName" type="text" pInputText [(ngModel)]="field.optionName">
                <label for="optionName">optionName</label>
            </span>
        </div>
        <div class="field md:col-12">
            <span class="p-float-label">
                <input id="optionValue" type="text" pInputText [(ngModel)]="field.optionValue">
                <label for="optionValue">optionValue</label>
            </span>
        </div>
        <div class="field md:col-6">

            <p-checkbox [(ngModel)]="field.visible" [binary]="true" inputid="visible"></p-checkbox>
            <label class="mr-3" for="visible">Visible</label>

        </div>
        <div class="field md:col-6">

            <p-checkbox [(ngModel)]="field.isActive" [binary]="true" inputid="isActive"></p-checkbox>
            <label class="mr-3" for="isActive">Is Active</label>

        </div>


        <div class="field col-12 md:col-12">
            <button pButton type="button" label="Update" (click)="updateFieldProperties(field)"></button>
        </div>
    </div>
</p-sidebar>

<!-- ReArrange Fields -->