
import {currency} from "./commonData";
let signType = [
     
    { label: "Delivery Confirmation", value: "DeliveryConfirmation" },
    { label: "Signature Required", value: "SignatureRequired" },
    { label: "Adult Signature Required", value: "AdultSignatureRequired" },
    { label: "No Signature Required", value: "NoSignatureRequired" },
  ],
  endorsement = [
     
    { label: "Return Service Requested", value: "ReturnServiceRequested" },
    { label: "Forward Service Requested", value: "ForwardServiceRequested" },
    { label: "Address Service Requested", value: "AddressServiceRequested" },
    { label: "Change Service Requested", value: "ChangeServiceRequested" },
    {
      label: "Carrier - Leave If No Response",
      value: "Carrier-LeaveIfNoResponse",
    },
  ],
  codFundCode = [
     
    { label: "Cashier's Check", value: "Cashier'sCheck" },
    { label: "Personal Check", value: "PersonalCheck" },
  ],
  returnShipType = [
     
    { label: "UPS Print and Mail", value: "UPSPrintandMail" },
    { label: "UPS Return 1-Attempt", value: "UPSReturn1-Attempt" },
    { label: "UPS Return 3-Attempt", value: "UPSReturn3-Attempt" },
    { label: "UPS Electronic Return Label", value: "UPSElectronicReturnLabel" },
    { label: "UPS Print and Return Label", value: "UPSPrintandReturnLabel" },
  ],
  miscOptions = [
    { label: "Drop Of At Facility", value: "DropOfAtFacility" },
    { label: "Lift Gate Pickup", value: "LiftGatePickup" },
    { label: "Lift Gate Delivery", value: "LiftGateDelivery" },
  ];



let upsFields = [
  {
    name: "discountRebate",
    label: "Discount/Rebate",
    type: "text",
    cls: "",
  },
  {
    name: "SignatureType",
    label: "Signature Type",
    type: "dropdown",
    options: signType || [],
    cls: "",
  },
  {
    name: "declaredAmount",
    label: "Declared Amount",
    type: "text",
    cls: "",
  },
  {
    name: "declaredCurrency",
    label: "Currency",
    type: "dropdown",
    options: currency ||  [],
    cls: "",
  },
  {
    name: "codFundsCode",
    label: "COD Funds Code",
    type: "dropdown",
    options: codFundCode||  [],
    cls: "",
  },
  {
    name: "codAmount",
    label: "COD Amount",
    type: "text",
    cls: "",
  },
  {
    name: "codCurrency",
    label: "Currency",
    type: "dropdown",
    options: currency || [],
    cls: "",
  },
  {
    name: "returnShipmentType",
    label: "Return Shipment Type",
    type: "dropdown",
    options: returnShipType || [],
    cls: "",
  },
  {
    name: "returnServiceName",
    label: "Return Service Name",
    type: "dropdown",
    options: [],
    cls: "",
  },
  {
    name: "miscOptions",
    label: "Misc Options",
    type: "dropdown",
    options: miscOptions || [],
    cls: "",
  },
  {
    name: "Endorsement",
    label: "Endorsement",
    type: "dropdown",
    options: endorsement || [],
    cls: "",
  },
  {
    name: "freightCharge",
    label: "Freight Charge",
    type: "text",
    cls: "",
  },
  {
    name: "insuranceCharge",
    label: "Insurance Charge",
    type: "text",
    cls: "",
  },
  {
    name: "otherCharges",
    label: "Other Charges",
    type: "text",
    cls: "",
  },
  {
    name: "otherChargesDescription",
    label: "Other Charges Description",
    type: "text",
    cls: "",
  },
  {
    name: "emailNotification",
    label: "Email Notification",
    type: "checkbox",
    cls: "check-container",
  },
  {
    name: "exceptionNotification",
    label: "Exception Notification",
    type: "checkbox",
    cls: "check-container",
  },
  {
    name: "deliveryNotification",
    label: "Delivery Notification",
    type: "checkbox",
    cls: "check-container",
  },
  {
    name: "saturdayDelivery",
    label: "Saturday Delivery",
    type: "checkbox",
    cls: "check-container",
  },
  {
    name: "residentialFlag",
    label: "Residential Flag",
    type: "checkbox",
    cls: "check-container",
  },
  {
    name: "largePackageIndicator",
    label: "Large Package Indicator",
    type: "checkbox",
    cls: "check-container",
  },
  {
    name: "holdAtLocation",
    label: "Hold at location",
    type: "checkbox",
    cls: "check-container",
  },
  {
    name: "returnShipmentOnly",
    label: "Return Shipment Only",
    type: "checkbox",
    cls: "check-container",
  },
];
 export default upsFields;