import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
@Component({
  selector: 'app-copilot',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './copilot.component.html',
  styleUrl: './copilot.component.scss',
})
export class CopilotComponent {
  actions = [
    {
      label: 'Delayed Shipments',
      image: '../../assets/images/delayedshipment.png',
    },
    {
      label: 'High Priority Shipments',
      image: '../../assets/images/highpriorityshipment.png',
    },
    {
      label: 'Total Shipments processed today',
      image: '../../assets/images/lucide_truck.png',
    },
    {
      label: 'High Value Shipments',
      image: '../../assets/images/solar_box-outline.png',
    },
  ];
  // Additional logic to handle chat input and communication with the backend
  sendMessage(message: string) {
    // Logic to send the message to the chatbot
  }
}
