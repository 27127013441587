import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ERPSHIPService } from '../erpship.service';
import { ChangeDetectorRef } from '@angular/core';
import { saveAs } from 'file-saver-es';
import {
  FormGroup,
  FormControl,
  ValidatorFn,
  Validators,
} from '@angular/forms';

@Component({
  selector: 'app-ship-requestlist',
  templateUrl: './ship-request.component.html',
  styleUrls: ['./ship-request.component.scss'],
})
export class ShipRequestComponent implements OnInit {
  first = 0;
  rows = 10;
  isDataLoaded: boolean = false;
  passingid: any = '';
  shipRequests: any[] = [];
  selectedShipRequest: any[] = [];
  items: any[] = [];
  totalRecords = 0;
  constructor(
    private router: Router,
    private xfservice: ERPSHIPService,
    private cdr: ChangeDetectorRef
  ) {
    this.xfservice.processAuth().subscribe(
      (data) => {},
      (error) => {
        console.log('No token found');
        this.router.navigate(['']);
      }
    );
  }
  //Action from Table
  edit(shipRequest) {
    this.router.navigate(['/home/shiprequestmodal', shipRequest.id]);
    throw new Error('Method not implemented.');
  }
  status(shipRequest) {
    throw new Error('Method not implemented.');
  }
  copy(shipRequest) {
    this.router.navigate(['/home/shiprequestmodal', 'COPY-' + shipRequest.id]);
  }
  ship(shipRequest) {
    const documentNumber = 'DOC-' + shipRequest.id; // Replace with the actual logic to get the document number
    this.router.navigate(['/home/shipnow'], {
      queryParams: { docNumber: documentNumber },
    });
  }

  generateItems = (shipRequest) => {
    // this.cdr.detectChanges();
    return [
      {
        label: 'Copy',
        command: () => {
          this.copy(shipRequest);
        },
      },
      {
        label: 'Edit',
        command: () => {
          this.edit(shipRequest);
        },
      },
      {
        label: 'Update Status',
        command: () => {
          this.status(shipRequest);
        },
      },
      {
        label: 'Ship',
        command: () => {
          this.ship(shipRequest);
        },
      },
    ];
  };

  ngOnInit(): void {
    this.xfservice
      .getxFormRecordsByFromName('ShipRequest')
      .subscribe((data: any) => {
        data.sort((a, b) => b.id - a.id);
        data.forEach((item: any) => {
          item.details.items = this.generateItems(item.details);
          this.shipRequests.push(item.details);
        });
        this.totalRecords = data.length;
        this.isDataLoaded = true;
        // console.log('Total: ' + this.totalRecords);
      });
  }

  showSelectedRecords(): void {
    alert(this.selectedShipRequest.length);
    console.log(JSON.stringify(this.selectedShipRequest));
  }

  openNewShipRequest(): void {
    this.router.navigate(['/home/shipRequest']);
  }

  editShipRequest(data: any): void {}

  downloadFile(): void {
    alert('DOWNLAOD STARTED');
    console.log(this.toCsv(this.pivot(this.shipRequests)));
  }

  //DOWNLOAD NESTED OBJECT S

  pivot(arr: any) {
    var mp = new Map();

    function setValue(a: any, path: any, val: any) {
      if (Object(val) !== val) {
        // primitive value
        var pathStr = path.join('.');
        var i = (mp.has(pathStr) ? mp : mp.set(pathStr, mp.size)).get(pathStr);
        a[i] = val;
      } else {
        for (var key in val) {
          setValue(a, key == '0' ? path : path.concat(key), val[key]);
        }
      }

      return a;
    }

    var result = arr.map((obj: any) => setValue([], [], obj));
    return [[...mp.keys()], ...result];
  }

  toCsv(arr: any) {
    return arr
      .map((row: any) =>
        row
          .map((val: any) => (isNaN(val) ? JSON.stringify(val) : +val))
          .join(',')
      )
      .join('\n');
  }
}
