<!-- For Messaging Service -->

<p-toast></p-toast>
<p-toast position="top-left" key="tl"></p-toast>
<p-toast position="top-center" key="tc"></p-toast>
<p-toast position="bottom-center" key="bc"></p-toast>

<p-toast position="bottom-center" key="c" [baseZIndex]="5000">
    <ng-template let-message pTemplate="message">
        <div class="p-flex p-flex-column" style="flex: 1">
            <div class="p-text-center">
                <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
                <h4>{{ message.summary }}</h4>
                <p>{{ message.detail }}</p>
            </div>
            <div class="p-grid p-fluid">
                <div class="p-col-6">
                    <button type="button" pButton label="Yes" class="p-button-success"></button>
                </div>
                <div class="p-col-6">
                    <button type="button" pButton label="No" class="p-button-secondary"></button>
                </div>
            </div>
        </div>
    </ng-template>
</p-toast>
<!-- New rule form -->

<div class="card" [style]="{padding: '0px',  height: '50%'}">

    <!-- ToolBar Starting -->
    <p-toolbar styleClass="p-mb-2">
        <div class="p-toolbar-group-right">
            <div class="grid p-fluid mt-2">
                <div class="field col-12 md:col-2">
                    <span class="p-float-label">
                        <input type="text" id="rule" pInputText [(ngModel)]=ruledata.RuleName>
                        <label for="rule">Rule Name</label>
                    </span>
                </div>
                <div class="field col-12 md:col-2">
                    <span class="p-float-label">
                        <p-dropdown [options]="locations" optionLabel="LocationName" [showClear]="true"
                            optionValue="LocationId" [autoDisplayFirst]="false" [(ngModel)]="ruledata.LocationId"
                            id="location"></p-dropdown>
                        <label for="location">Location</label>
                    </span>
                </div>
                <div class="field col-12 md:col-1">
                    <span class="p-float-label">
                        <input type="number" id="priority" [(ngModel)]="ruledata.Priority" pInputText>
                        <label for="priority">Priority</label>
                    </span>
                </div>

                <div class="field col-12 md:col-2">
                    <span class="p-float-label">
                        <p-calendar [showIcon]="true" id="validfrom" [(ngModel)]="ruledata.ValidFrom"></p-calendar>
                        <label for="validfrom">Valid From</label>
                    </span>
                </div>
                <div class="field col-12 md:col-2">
                    <span class="p-float-label">
                        <p-calendar [showIcon]="true" id="validto" [(ngModel)]="ruledata.ValidTo"></p-calendar>
                        <label for="validto">Valid To</label>
                    </span>
                </div>
                <div class="field col-12 md:col-2">

                    <p-checkbox name="group1" [binary]="true" inputId="isActive"
                        [(ngModel)]="ruledata.IsActive"></p-checkbox>
                    <label styleClass="mr-4" for="isActive">IsActive</label>



                </div>

            </div>
        </div>
        <div class="p-toolbar-group-right">
            <span>
                <p-button icon="pi pi-times" [routerLink]="['/home/routingguide']"
                    class="mr-2 p-inputtext-sm"></p-button>
            </span>

        </div>
    </p-toolbar>

    <!-- ToolBar End -->


    <!-- Data Table for filedNames and Conditions -->

    <div> <p-table [value]="tabledata" styleClass="p-datatable-sm p-datatable-gridlines p-datatable-striped "
            responsiveLayout="scroll" [scrollable]="true" scrollHeight="350px">
            <ng-template pTemplate="header">
                <tr>
                    <th>FieldName</th>
                    <th>Condition</th>
                    <th>From Range</th>
                    <th>To Range</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-record>
                <tr>
                    <td>{{record['fieldName']}}</td>
                    <td><span>
                            <p-dropdown [options]="condition" [autoDisplayFirst]="false"     class="custom-dd" [showClear]="true"
                                [(ngModel)]='record.Condition' appendTo="body"></p-dropdown>
                        </span>


                    </td>
                    <td><input type="text" pInputText [(ngModel)]="record.fromRange" [style]="{width:'100%'}"></td>
                    <td><input type="text" pInputText [(ngModel)]="record.toRange" [style]="{width:'100%'}"  *ngIf="record.Condition == 'In Between'"></td>
                </tr>
            </ng-template>



        </p-table>
    </div>

    <!-- Data table End -->

</div>

<!-- Display tab Panels -->
<div class="card" [style]="{ padding: '0px',height:'40%' }">
    <div [style]="{ float:'left', width:'95%'}">

        <p-tabView>

            <!-- Tab Panel Shipping Process -->
            <p-tabPanel header="Shipping Process">

                <div class="card">
                    <button type="button" pButton label="Add Carrier" (click)="addNewCarrier()"
                        class="mr-2 p-inputtext-sm"></button>

                    <p-table [value]="carrierDetails"
                        styleClass="p-datatable-sm p-datatable-gridlines p-datatable-striped " [style]="{ width:'100%'}"
                        responsiveLayout="scroll" [scrollable]="true">
                        <ng-template pTemplate="header">
                            <tr>
                                <th>Carrier Name</th>
                                <th>Carrier Service</th>
                                <!-- <th >Carrier Code</th> -->
                                <th>Shipping Account</th>
                                <th>Payment Type</th>
                                <th>Billing Account</th>
                                <th>Country Code</th>
                                <th>Zip Code</th>
                                <th>Action</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-rowData>
                            <tr>
                                <td> <span class="p-float-label">
                                        <input type="text" pInputText [(ngModel)]="rowData['CarrierName']"
                                            [style]="{width:'85%'}">
                                        <!-- <button pButton  pRipple icon="pi pi-search"
                        (click)="carriername.toggle($event)" ></button> -->

                                        <button pButton pRipple icon="pi pi-search"
                                            (click)="loadCarriers( rowData,  $event , carriername)"></button>



                                    </span></td>
                                <td>
                                    <span class="p-float-label">
                                        <input type="text" pInputText [(ngModel)]="rowData['CarrierService']"
                                            [style]="{width:'85%'}">
                                        <button pButton pRipple icon="pi pi-search"
                                            (click)="loadCarrierService(rowData,$event,carrierservice)"></button>





                                    </span>
                                </td>



                                <!-- <td>
                                    <span class="p-float-label">
                                        <input type="text" pInputText [(ngModel)]="rowData['ServiceCode']"
                                            disabled="true" [style]="{width:'85%'}">

                                    </span>
                                </td> -->



                                <td>
                                    <span class="p-float-label">
                                        <input type="text" pInputText [(ngModel)]="rowData['ShippingAccount']"
                                            [style]="{width:'85%'}">

                                            <button pButton pRipple icon="pi pi-search"
                                            (click)="loadCarrierAccounts(rowData,$event,ShippingAccount)"></button>
                                    </span>

                                </td>



                                <td>
                                    <span class="p-float-label">
                                        <p-dropdown [options]="paymentTypes" [(ngModel)]="rowData['PaymentType']" placeholder="Payment Tyepe" optionLabel="name" optionValue="name" appendTo="body" [style]="{width:'100%'}"></p-dropdown>
                                    </span>

                                </td>

                                <td>
                                    <span class="p-float-label" >
                                        <input type="text" pInputText [(ngModel)]="rowData['billingAccount']"
                                            [style]="{width:'100%'}" >
                                    </span>
                                </td>

                                <td>
                                    <span class="p-float-label">
                                        <input type="text" pInputText [(ngModel)]="rowData['CountryCode']"
                                            [style]="{width:'100%'}">
                                    </span>
                                </td>


                                <td>
                                    <span class="p-float-label">
                                        <input type="text" pInputText [(ngModel)]="rowData['ZipCode']"
                                            [style]="{width:'100%'}">
                                    </span>
                                </td>
                                <td> <button type="button" icon="pi pi-trash" pButton pRipple
                                        (click)="deleteCarrier(rowData)"></button></td>
                            </tr>
                        </ng-template>

                    </p-table>

                </div>
            </p-tabPanel>

            <!-- Shipping Process Tab Panel End -->
            <p-tabPanel header="Document Process"></p-tabPanel>
            <p-tabPanel header="Business Partners"></p-tabPanel>
            <p-tabPanel header="Default Values">

                <div class="grid p-fluid mt-3">
                    <ng-template ngFor let-field [ngForOf]="this.defaultFields" let-i="index">
                        <ng-template [ngIf]="field.fieldType == 'Textbox'">
                            <div class="field col-12  {{ ' md:' + field.width }}  " [hidden]="!field.visible">
                                <span class="p-float-label">
    
                                    <input [type]="field.datatype" pInputText
                                        [(ngModel)]="defaultObjects[i].value" />
                                    <!-- <input [type]="field.datatype" pInputText /> -->
                                    <label>{{ field.title }}</label>
                                </span>
                            </div>
                        </ng-template>
                        <!-- To display Dropdown -->
                        <ng-template [ngIf]="field.fieldType == 'Dropdown'">
                            <div class="field col-12 {{'md:' + field.width }}" [hidden]="!field.visible">
                                <span class="p-float-label">
    
                                    <p-dropdown [options]="field.defaultValue" [autoDisplayFirst]="false"
                                        [showClear]="true" [optionLabel]="field.optionLabel" [filter]="true"
                                        [optionValue]="field.optionName"
                                        [(ngModel)]="defaultObjects[i].value"
                                        [style]="{ width: '100%' }">
                                    </p-dropdown>
                                    <label [for]="field.fieldName">{{ field.title }}</label>
                                </span>
                            </div>
                        </ng-template>
    
                    </ng-template>
                </div>
                
            </p-tabPanel>

            <ng-container *ngFor="let tab of tabNames; let j = index">
                <ng-template [ngIf]="tab['visible'] === true">
                    <p-tabPanel header="{{tab['tabName']}}" [style]="{ padding: '0px' }">
                    </p-tabPanel>
                </ng-template>


            </ng-container>


        </p-tabView>
    </div>

    <!-- End of Tab View -->

    <!-- Tab Name Properties  -->
    <div [style]="{float:'right'}">
        <i class="pi pi-cog  right-wrapper" style="font-size: 20px" (click)="op.toggle($event)"></i>
    </div>

    <!-- <p-tabPanel header="Shipping Process" [style]="{ padding: '0px' }">
        </p-tabPanel>
        <p-tabPanel header="Document Process" [style]="{ padding: '0px' }">
        </p-tabPanel>
        <p-tabPanel header="Business Partners" [style]="{ padding: '0px' }">
        </p-tabPanel>
        <p-tabPanel header="ERP Process" [style]="{ padding: '0px' }">
        </p-tabPanel>
        <p-tabPanel header="Logistics Service Providers" [style]="{ padding: '0px' }">
        </p-tabPanel> -->


</div>

<!-- OverLay Panel For displaying Tab Names-->
<p-overlayPanel #op>

    <div class="card"> <p-table [value]="tabNames"
            styleClass="p-datatable-sm p-datatable-gridlines p-datatable-striped " responsiveLayout="scroll"
            [scrollable]="true" scrollHeight="350px">
            <ng-template pTemplate="header">
                <tr>
                    <th>Tab Name</th>
                    <th>Visible</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-tab>
                <tr>
                    <td>{{tab['tabName']}}</td>
                    <td> <p-checkbox name="group1" [binary]="true" inputId="visible"
                            [(ngModel)]="tab['visible']"></p-checkbox></td>
                </tr>

            </ng-template>

        </p-table>
        <span>
            <p-button label="New Tab" (click)="newTabDialog_flag=true" class="mr-2 p-inputtext-sm"></p-button>
        </span>
        <span>
            <p-button label="Update" (click)="updateTabNames()" class="mr-2 p-inputtext-sm"></p-button>
        </span>


    </div>


</p-overlayPanel>

<!-- End of OverLay Panel -->

<!-- new Tab Name Dialog starts here  -->
<p-dialog [(visible)]="newTabDialog_flag">
    <div class="card">
        <div class="grid p-fluid mt-2">
            <div class="field col-12 md:col-6">
                <span class="p-float-label">
                    <input type="text" pInputText id="tabName" [(ngModel)]="  newtab['tabName'] ">
                    <label for="tabName"> Tab Name</label>
                </span>
            </div>
            <div class="field col-12 md:col-6">
                <span>
                    <p-checkbox name="group1" [binary]="true" inputId="visible"
                        [(ngModel)]="newtab['visible']"></p-checkbox>
                    <label for="visible">Visible</label>

                </span>
            </div>
        </div>
        <p-button label="Add" (click)="addTab()" class="mr-2 p-inputtext-sm"></p-button>

    </div>

</p-dialog>

<!-- New tab Name dialog end -->
<!-- Foote for Save and Cancel Buttons -->
<div class="col-12 md:col-12  footer">
    <div class="grid p-fluid mt-2">
        <div class="field col-12 md:col-1">
            <span class="p-float-label mr-2"><button pButton label="Save" (click)="saveRule()"></button></span>
        </div>
        <div class="field col-12 md:col-1">
            <span class="p-float-label mr-2">
                <button pButton label="Cancel" [routerLink]="['/home/routingguide']"></button></span>
        </div>

    </div>
</div>

<!-- End of footer -->

<!-- Overlay Panel for carriernames -->
<p-overlayPanel #carriername>

    <div class="card">
        <p-table [value]="carriers" styleClass="p-datatable-sm p-datatable-gridlines p-datatable-striped "
            responsiveLayout="scroll" [scrollable]="true" scrollHeight="350px">
            <ng-template pTemplate="header">
                <tr>
                    <th></th>
                    <th>Carrier</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-record>
                <tr>
                    <td alignFrozen="left" pFrozenColumn [frozen]="true">
                        <button pButton pRipple icon="pi pi-check" class="p-button-rounded p-button-primary p-mr-2"
                            (click)="assigncarrier(record , $event , carriername)"></button>
                    </td>
                    <td>{{record}}</td>

                </tr>

            </ng-template>

        </p-table>


    </div>


</p-overlayPanel>

<!-- End of overlay Panel -->

<!-- Overlay Panel for Carrier Services -->
<p-overlayPanel #carrierservice>

    <div class="card">
        <p-table [value]="carrierServices" styleClass="p-datatable-sm p-datatable-gridlines p-datatable-striped "
            responsiveLayout="scroll" [scrollable]="true" scrollHeight="350px">
            <ng-template pTemplate="header">
                <tr>
                    <th></th>
                    <th>Carrier Service</th>
                    <th>Service Code</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-service>
                <tr>
                    <td alignFrozen="left" pFrozenColumn [frozen]="true">
                        <button pButton pRipple icon="pi pi-check" class="p-button-rounded p-button-primary p-mr-2"
                            (click)="assigncarrierservice(service , $event , carrierservice)"></button>
                    </td>
                    <td>{{service.ServiceName}}</td>
                    <td>{{service.ServiceCode}}</td>

                </tr>

            </ng-template>

        </p-table>


    </div>


</p-overlayPanel>

<!-- End of overlay Panel -->


<p-overlayPanel #ShippingAccount>

    <div class="card">
        <p-table [value]="accounts" styleClass="p-datatable-sm p-datatable-gridlines p-datatable-striped "
            responsiveLayout="scroll" [scrollable]="true" scrollHeight="350px">
            <ng-template pTemplate="header">
                <tr>
                    <th></th>
                    <th>Account</th>
                    
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-account>
                <tr>
                    <td alignFrozen="left" pFrozenColumn [frozen]="true">
                        <button pButton pRipple icon="pi pi-check" class="p-button-rounded p-button-primary p-mr-2"
                            (click)="assigncarrierAccount(account , $event , ShippingAccount)"></button>
                    </td>
                    <td>{{account.name}}</td>
                 

                </tr>

            </ng-template>

        </p-table>


    </div>


</p-overlayPanel>