
<!-- 
<p-toast 
  position="bottom-center" 
  key="confirm" 
  [baseZIndex]="5000">
  <ng-template let-message pTemplate="message">
    <div class="flex flex-column align-items-start" style="flex: 1">
      <div class="flex align-items-center gap-2">
        
        <i class="fas fa-exclamation-triangle text-warning" style="font-size: 1.5rem;"></i>
        <span class="font-bold text-900">
          Warning
        </span>
      </div>
      <div class="font-medium text-lg my-3 text-900">
        {{ message.summary }}
      </div>
      <div class="font-medium text-lg my-3 text-900">
        {{ message.detail }}
      </div>
      <p-button size="small" label="ok" (click)="clearWarnings()" />
    </div>
  </ng-template>
</p-toast> -->


  <p-dialog
    header="Validation Error"
    [(visible)]="isPopupVisible"
    modal="modal"
    [baseZIndex]="5000"
    (onHide)="clearWarnings()"
    class="custom-dialog"
  >
    <div class="flex flex-column align-items-start">
      <div class="flex align-items-center gap-2">
        <!-- Content goes here -->
      </div>
      <div class="font-medium text-lg my-3 text-900">
        {{ message }}
      </div>
      <p-button size="small" label="ok"  class="custom-button" (click)="clearWarnings()" />
    </div>
  </p-dialog>




