
<div class="login-body">
    <div class="login-panel"></div>

    <div class="login-content">

        <img src="assets/images/companylogo.png" alt="Product Logo"/>


        <h1><span>SIGN IN</span> PRODUCTNAME</h1>
        <form (ngSubmit)="onSubmit()" [formGroup]="loginForm">

            <div class="login-input-wrapper">
                <input type="text"  placeholder="Username"  formControlName="login"  pInputText> <!--autocomplete="off"-->
                <i class="pi pi-user"></i>
            </div>
    
            <div class="login-input-wrapper">
                <input type="password" placeholder="Password" formControlName="password" pInputText>
                <i class="pi pi-lock"></i>
            </div>
            <button   pButton type="submit" label="Sign In"  [disabled]="loginForm.invalid" class="mr-2"></button>
            <button   pButton  type='Button' label="Register"  (click)="openRegister()"></button>

           
        </form>

       


        

        
    </div>
</div>

<p-dialog [(visible)]="openRegisterFormDialog" [style]="{ width: '70%' }" header="Register" [modal]="true"
        styleClass="p-fluid">
       
            <p-header>Register</p-header>
            <div class="card">
                <div class="grid p-fluid mt-3">
                    <div class="field col-12 md:col-6">
                        <span class="p-float-label">
                            <input type="text" id="company" pInputText [(ngModel)]="newcompany['company']" >
                            <label for="company">Company Name</label>

                        </span>
                    </div>
                    
                    <div class="field col-12 md:col-6">
                        <span class="p-float-label">
                            <input type="text" id="contact" pInputText [(ngModel)]="newcompany['contact']">
                            <label for="contact">Contact Name</label>

                        </span>
                    </div>
                    <div class="field col-12 md:col-6">
                        <span class="p-float-label">
                            <input type="text" id="Addrln1" pInputText [(ngModel)]="newcompany['addrln1']">
                            <label for="Addrln1">Address Line1</label>

                        </span>
                    </div>
                    <div class="field col-12 md:col-6">
                        <span class="p-float-label">
                            <input type="text" id="addrln2" pInputText [(ngModel)]="newcompany['addrln2']">
                            <label for="addrln2">Address Line2</label>

                        </span>
                    </div>
                    <div class="field col-12 md:col-6">
                        <span class="p-float-label">
                            <input type="text" id="city" pInputText [(ngModel)]="newcompany['city']">
                            <label for="city">City</label>

                        </span>
                    </div>
                    <div class="field col-12 md:col-6">
                        <span class="p-float-label">
                            <input type="text" id="state" pInputText [(ngModel)]="newcompany['state']">
                            <label for="state">State/Province Code</label>

                        </span>
                    </div>
                    <div class="field col-12 md:col-6">
                        <span class="p-float-label">
                            <input type="text" id="country" pInputText [(ngModel)]="newcompany['country']">
                            <label for="country">Country</label>

                        </span>
                    </div>
                    <div class="field col-12 md:col-6">
                        <span class="p-float-label">
                            <input type="text" id="zipcode" pInputText [(ngModel)]="newcompany['zipcode']">
                            <label for="zipcode">ZipCode</label>

                        </span>
                    </div>
                    <div class="field col-12 md:col-6">
                        <span class="p-float-label">
                            <input type="text" id="e-mail" pInputText [(ngModel)]="newcompany['e-mail']">
                            <label for="e-mail">E-mail</label>

                        </span>
                    </div>
                    <div class="field col-12 md:col-6">
                        <span class="p-float-label">
                            <input type="text" id="phoneno" pInputText [(ngModel)]="newcompany['phoneno']">
                            <label for="phoneno">Phone Number</label>

                        </span>
                    </div>
                    <div class="field col-12 md:col-6">
                        <span class="p-float-label">
                            <input type="text" id="fax" pInputText [(ngModel)]="newcompany['fax']">
                            <label for="fax">Fax Number</label>

                        </span>
                    </div>
                </div>
                <button   pButton  label="Register"  (click)="saveCompany()"></button>
            </div>
            
        </p-dialog> 


