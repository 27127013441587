import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ShipNowService {
  private fedExUrl = 'https://shipapi.cfapps.eu10-004.hana.ondemand.com/FedEx';

  private baseUrl: string = environment.apiUrl;
  private deleteurl : string = environment.deleteAPI;

  constructor(private http: HttpClient) { }

  getShippingData(): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/LocationMaster`);
  }

  createShipment(data: any): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/your-endpoint`, data);
  }

  getProducts(): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/MasterData/products`);
  }
  packItem(payload: any): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/hu`, payload);
  }

  deleteItem(payload: any): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/clouddeletehu`, payload);
  }
  
  packAllItems(payload: any): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/hu`, payload);
  }

  deleteItems(payload: any): Observable<any> {
    return this.http.post<any>(`${this.deleteurl}`, payload);
  }

  getTimelineData(): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/formOperations/shipments`);
  }

  sendFedExRequest(payload:any): Observable<any> {
    return this.http.post<any>(`${this.fedExUrl}`,payload);
  }
  sendShipRequest(payload:any): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/formOperations/shipments`,payload);
  }

  sendShipRequestCarrier(payload:any): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/shipments`,payload);
  }
}
