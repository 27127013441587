
import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, NavigationEnd } from '@angular/router';
import {
  FormGroup,
  FormControl,
  ValidatorFn,
  Validators,
} from '@angular/forms';

import { ERPSHIPService } from '../erpship.service';
@Component({
  selector: 'app-dynamicforms',
  templateUrl: './dynamicforms.component.html',
  styleUrls: ['./dynamicforms.component.scss']
})
export class DynamicformsComponent implements OnInit{


  allForms:any[]=[];

  // ---------------------FOR FROM FILED INFORMATION IN POPUP
  fieldForm :any;
  fields: any[] = [];
  fieldOperationType = 'Add';

  newXformobject: any = {};

  /*newXformobject = {
    id: '',
    formName: '',
    formTitle:'',
    isActive: true,
    numberOfFieldsPerRow: 1,
    fields: [],
  };*/

  //-------------------------------------------------------------
  xformName: string = 'xform';
  formDialog: boolean = false;
  submitted: boolean = false;
  xformDialog: boolean = true;
  currentSelectedField: any = '';
  currentSelectedFormid: any = '';
  //---------------------------------------------
  selectedState: string = '';
  dropdownItems: any = [];
  //-------------------------------------
  xformslist: any[] = [];

  xformsobj: any;
  selectedxforms: any[];

  constructor(
    private http: HttpClient,
    private xfservice: ERPSHIPService, private router: Router
  ) {


    this.xfservice.processAuth().subscribe((data) => { console.log("Token found and no wrror") }, error => {
      console.log("No token found")
      this.router.navigate(['']);
    });

    this.xfservice.getxForms().subscribe((data) => {
      this.xformslist = data;
    });

    this.xfservice.getxAllForms().subscribe((data) => {
      this.allForms = data;
    });

    this.fieldForm = new FormGroup({
      fieldName: new FormControl(),
      fieldHeader: new FormControl(),
      fieldType: new FormControl(),
     // Values: new FormControl(),
      dataType: new FormControl(),
      isrequired: new FormControl(),
     // errorMessage: new FormControl(),
    });
    this.selectedxforms = [];
  }

  ngOnInit(): void { }

  editxField(event: any): void {
    this.fieldOperationType = 'Update';
    console.log(event);
    this.fieldForm.patchValue(event);

    this.currentSelectedField = event;
  }
  editXform(event: any): void {
    this.newXformobject = event;

    this.fieldForm.patchValue({
      fieldName: '',
      fieldType: '',
      dataType: '',
      isrequired: '',
      //errorMessage: '',
    });
    //this.currentSelectedField = event.fields;
    this.formDialog = true;
  }

  openNew(): void {
    //this.fieldOperationType = 'Add';
    this.newXformobject = {
      id: '',
      formName: '',
      isActive: true,
      numberOfFieldsPerRow: 1,
      masterdata: '',
      formgroupname:'',
      fields: [],
    };
    this.currentSelectedFormid = '';
    this.formDialog = true;
  }
  hideDialog(): void {
    this.formDialog = false;
    //this.currentSelectedField = '';
  }
  saveForm(): void {

   
    if (this.currentSelectedFormid == '') {
     // console.log('This is new form : ' + this.newXformobject.formName);
     // console.log('Fields Are ------> : ' + this.newXformobject.fields);
      let isidexist = false;

      // --------------- checking for ID field ------------------
      this.newXformobject.fields.forEach((xf:any) => {
        if (xf['fieldName'] === 'id') {
          isidexist = true;
        }
      });

      let idfield = {
        fieldName: 'id',
        fieldType: 'Textbox',
        dataType: 'Int',
        isrequired: 'No',
        errorMessage: '',
      };

      if (isidexist) {
      } else {
        this.newXformobject.fields.push(idfield as never);
      }

      this.xfservice
        .addForm(this.xformName, this.newXformobject)
        .subscribe((data) => {
          this.formDialog = false;
          //window.location.reload();

          this.getFormsList();
        //  console.log(data);
        });
    }
  }

  getFormsList(): void {
    this.xfservice.getxForms().subscribe((data) => {
      this.xformslist = data;
    });
  }
  saveField(): void { }

  onFieldSubmit(): void {
    let index = this.newXformobject.fields.indexOf(
      this.currentSelectedField as never
    );

    //console.log('inde is: ' + index);
    if (index == -1) {
      this.newXformobject.fields.push(this.fieldForm.value as never);
    } else {
      this.newXformobject.fields[index] = this.fieldForm.value as never;
    }
    this.currentSelectedField = '';
    this.fieldForm.reset();

    this.fieldOperationType = 'Add';
  }


}
