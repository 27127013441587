import { NgModule, isDevMode } from '@angular/core';
import { FormsModule } from '@angular/forms';
import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
//applicationsercice
import { MatTabsModule } from '@angular/material/tabs';
import { MatButtonModule } from '@angular/material/button';
import { AppMenuServiceService } from './app.menu.service.service';

// PrimeNG Components for demos
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { AccordionModule } from 'primeng/accordion';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { AvatarModule } from 'primeng/avatar';
import { AvatarGroupModule } from 'primeng/avatargroup';
import { BadgeModule } from 'primeng/badge';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { CarouselModule } from 'primeng/carousel';
import { CascadeSelectModule } from 'primeng/cascadeselect';
import { ChartModule } from 'primeng/chart';
import { CheckboxModule } from 'primeng/checkbox';
import { ChipModule } from 'primeng/chip';
import { ChipsModule } from 'primeng/chips';
// import { CodeHighlighterModule } from 'primeng/codehighlighter';SidebarModule 
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { ColorPickerModule } from 'primeng/colorpicker';
import { ContextMenuModule } from 'primeng/contextmenu';
import { DataViewModule } from 'primeng/dataview';
import { DialogModule } from 'primeng/dialog';
import { DividerModule } from 'primeng/divider';
import { DockModule } from 'primeng/dock';
import { DropdownModule } from 'primeng/dropdown';
import { FieldsetModule } from 'primeng/fieldset';
import { FileUploadModule } from 'primeng/fileupload';
import { GalleriaModule } from 'primeng/galleria';
import { ImageModule } from 'primeng/image';
import { InplaceModule } from 'primeng/inplace';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputMaskModule } from 'primeng/inputmask';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { KnobModule } from 'primeng/knob';
// import { LightboxModule } from 'primeng/lightbox';
import { ListboxModule } from 'primeng/listbox';
import { MegaMenuModule } from 'primeng/megamenu';
import { MenuItemContent, MenuModule } from 'primeng/menu';
import { MenubarModule } from 'primeng/menubar';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { MultiSelectModule } from 'primeng/multiselect';
import { OrderListModule } from 'primeng/orderlist';
import { OrganizationChartModule } from 'primeng/organizationchart';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PaginatorModule } from 'primeng/paginator';
import { PanelModule } from 'primeng/panel';
import { PanelMenuModule } from 'primeng/panelmenu';
import { PasswordModule } from 'primeng/password';
import { PickListModule } from 'primeng/picklist';
import { ProgressBarModule } from 'primeng/progressbar';
import { RadioButtonModule } from 'primeng/radiobutton';
import { RatingModule } from 'primeng/rating';
import { RippleModule } from 'primeng/ripple';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { ScrollTopModule } from 'primeng/scrolltop';
import { SelectButtonModule } from 'primeng/selectbutton';
import { SidebarModule } from 'primeng/sidebar';
import { SkeletonModule } from 'primeng/skeleton';
import { SlideMenuModule } from 'primeng/slidemenu';
import { SliderModule } from 'primeng/slider';
import { SplitButtonModule } from 'primeng/splitbutton';
import { SplitterModule } from 'primeng/splitter';
import { StepsModule } from 'primeng/steps';
import { TabMenuModule } from 'primeng/tabmenu';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { TagModule } from 'primeng/tag';
import { TerminalModule } from 'primeng/terminal';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { TimelineModule } from 'primeng/timeline';
import { ToastModule } from 'primeng/toast';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { ToolbarModule } from 'primeng/toolbar';
import { TooltipModule } from 'primeng/tooltip';
import { TreeModule } from 'primeng/tree';
import { TreeTableModule } from 'primeng/treetable';
import { VirtualScrollerModule } from 'primeng/virtualscroller';
import { ShipRequestModalComponent } from './ship-request-modal/ship-request-modal.component';

//Interspector

import { TokenInterceptor } from './token.interceptor';

import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { AppMainComponent } from './app-main/app-main.component';
import { AppTopBarComponent } from './app-top-bar/app-top-bar.component';
import { AppProfileComponent } from './app-profile/app-profile.component';
import { AppMenuComponent } from './app-menu/app-menu.component';
import { AppMenuitemComponent } from './app-menuitem/app-menuitem.component';
import { DashBoardComponent } from './dash-board/dash-board.component';
import { DynamicformsComponent } from './dynamicforms/dynamicforms.component';
import { FormOperationsComponent } from './form-operations/form-operations.component';
import { XShipComponent } from './xship/xship.component';

//MONACO EDITOR
import { MonacoEditorModule, MONACO_PATH } from '@materia-ui/ngx-monaco-editor';
import { AppConfigComponent } from './app-config/app-config.component';
import { CarrierSpecialServiceConfigComponent } from './carrier-special-service-config/carrier-special-service-config.component';
import { shipComponent } from './ship/ship.component';
import { ManifestComponent } from './manifest/manifest.component';
import { ConsolidationComponent } from './consolidation/consolidation.component';
import { ConsolidationTemplateComponent } from './consolidation-template/consolidation-template.component';
import { RoutingguideComponent } from './routingguide/routingguide.component';
import { PlanningComponent } from './planning/planning.component';
import { CenteraltowerComponent } from './centeraltower/centeraltower.component';
import { UserprofileComponent } from './userprofile/userprofile.component';
import { SloginComponent } from './slogin/slogin.component';
import { RoutingGuideParametersComponent } from './routing-guide-parameters/routing-guide-parameters.component';
import { RGFieldPriorityComponent } from './rgfield-priority/rgfield-priority.component';
import { NewroutingruleComponent } from './newroutingrule/newroutingrule.component';
import { PackshipComponent } from './packship/packship.component';
import { PackShipV2Component } from './pack-ship-v2/pack-ship-v2.component';
import { RoutingGuideDefaultsComponent } from './routing-guide-defaults/routing-guide-defaults.component';
import { ChatbotComponent } from './chatbot/chatbot.component';
import { EmailprocessComponent } from './emailprocess/emailprocess.component';
import { DashBoardsComponent } from './dash-boards/dash-boards.component';
import { RoutingBillingDetailsComponent } from './routing-billing-details/routing-billing-details.component';
import { AdditionalInfoComponent } from './additional-info/additional-info.component';
import { DashboardHeaderComponent } from './pages/dashboard/dashboard-header/dashboard-header.component';
import { RoutingBillingDetailComponent } from './pages/dashboard/routing-billing-detail/routing-billing-detail.component';
import { InternationalDetailsComponent } from './pages/dashboard/international-details/international-details.component';
import { DashboardTableComponent } from './pages/dashboard/dashboard-table/dashboard-table.component';
import { ShipToFromComponent } from './pages/dashboard/ship-to-from/ship-to-from.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { TrackShipmentComponent } from "./track-shipment/track-shipment.component";

import { ProgressStatusComponent } from './progress-status/progress-status.component';
import { DragDropModule } from "@angular/cdk/drag-drop";
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { ShipRequestComponent } from './ship-request/Ship-Request.component';
import { InputGroupModule } from 'primeng/inputgroup';
import { InputGroupAddonModule } from 'primeng/inputgroupaddon';
import {AddressBookPopupComponent} from 'src/app/popup/address-book-popup/address-book-popup.component'
import { WarningPopupComponent } from './popup/warning-popup/warning-popup.component';
import { DocumentsComponent } from './pages/dashboard/documents/documents.component';
import { ChargesComponent } from './pages/dashboard/charges/charges.component';
import { SafePipe } from './common/pipes/safe.pipe';
import { ShipNowPackComponent } from './pages/dashboard/ship-now-pack/ship-now-pack.component';
import { ShipNowComponent } from './ship-now/ship-now.component';

@NgModule({ declarations: [
        AppComponent,
        LoginComponent,
        AppMainComponent,
        AppTopBarComponent,
        AppProfileComponent,
        AppMenuComponent,
        AppMenuitemComponent,
        DashBoardComponent,
        DynamicformsComponent,
        FormOperationsComponent,
        XShipComponent,
        AppConfigComponent,
        // ShipRequestlistComponent,
        CarrierSpecialServiceConfigComponent,
        shipComponent,
        ManifestComponent,
        ConsolidationComponent,
        ConsolidationTemplateComponent,
        RoutingguideComponent,
        PlanningComponent,
        CenteraltowerComponent,
        UserprofileComponent,
        SloginComponent,
        RoutingGuideParametersComponent,
        RGFieldPriorityComponent,
        NewroutingruleComponent,
        PackshipComponent,
        PackShipV2Component,
        RoutingGuideDefaultsComponent,
        ChatbotComponent,
        EmailprocessComponent,
        DashBoardsComponent,
        ShipNowComponent,
        RoutingBillingDetailsComponent,
        ShipNowPackComponent,
        ShipRequestComponent,
        ShipRequestModalComponent,
        ProgressStatusComponent,
        AdditionalInfoComponent,      
        TrackShipmentComponent,

        DashboardHeaderComponent,
        RoutingBillingDetailComponent,
        InternationalDetailsComponent,
        DashboardTableComponent,
        ShipToFromComponent,
        DocumentsComponent,
        ChargesComponent,
        SafePipe, 
        AddressBookPopupComponent,
        WarningPopupComponent,
    ],
    bootstrap: [AppComponent], imports: [BrowserModule,
        FormsModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        AccordionModule,
        AutoCompleteModule,
        AvatarModule,
        AvatarGroupModule,
        BadgeModule,
        BreadcrumbModule,
        ButtonModule,
        CalendarModule,
        CardModule,
        CarouselModule,
        CascadeSelectModule,
        ChartModule,
        CheckboxModule,
        ChipModule,
        ChipsModule,
        // CodeHighlighterModule,
        CommonModule,
        ConfirmDialogModule,
        ConfirmPopupModule,
        ColorPickerModule,
        ContextMenuModule,
        DataViewModule,
        DialogModule,
        DividerModule,
        DockModule,
        DropdownModule,
        FieldsetModule,
        FileUploadModule,
        GalleriaModule,
        ImageModule,
        InplaceModule,
        InputNumberModule,
        InputMaskModule,
        InputSwitchModule,
        InputTextModule,
        InputTextareaModule,
        KnobModule,
        // LightboxModule,
        ListboxModule,
        MegaMenuModule,
        MenuModule,
        MenubarModule,
        MessageModule,
        MessagesModule,
        MultiSelectModule,
        OrderListModule,
        OrganizationChartModule,
        OverlayPanelModule,
        PaginatorModule,
        PanelModule,
        PanelMenuModule,
        PasswordModule,
        PickListModule,
        ProgressBarModule,
        ProgressSpinnerModule,
        RadioButtonModule,
        RatingModule,
        RippleModule, ReactiveFormsModule,
        ScrollPanelModule,
        ScrollTopModule,
        SelectButtonModule,
        SidebarModule,
        SkeletonModule,
        SlideMenuModule,
        SliderModule,
        SplitButtonModule,
        SplitterModule,
        StepsModule,
        TableModule,
        TabMenuModule,
        TabViewModule,
        TagModule,
        TerminalModule,
        TimelineModule,
        TieredMenuModule,
        ToastModule,
        ToggleButtonModule,
        ToolbarModule,
        TooltipModule,
        TreeModule,
        TreeTableModule,
        DragDropModule,
        TabViewModule,
        MatTabsModule,
        MatButtonModule,
        InputGroupModule,
        InputGroupAddonModule,
        VirtualScrollerModule, MonacoEditorModule, ServiceWorkerModule.register('ngsw-worker.js', {
  enabled: !isDevMode(),
  // Register the ServiceWorker as soon as the application is stable
  // or after 30 seconds (whichever comes first).
  registrationStrategy: 'registerWhenStable:30000'
})], providers: [
  MessageService,
        { provide: ChatbotComponent },
        // { provide: LocationStrategy, useClass: HashLocationStrategy, },
        { provide: MONACO_PATH, useValue: 'https://unpkg.com/monaco-editor@0.31.1/min/vs', },
        { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
        AppMenuServiceService, ConfirmationService,
        provideHttpClient(withInterceptorsFromDi()),
        provideAnimationsAsync()
    ] })
export class AppModule { }
