import { Component, OnInit, AfterViewInit, ViewChild, ɵsetAllowDuplicateNgModuleIdsForTest, ElementRef,SimpleChanges ,Input, ChangeDetectorRef } from '@angular/core';
import { Table } from 'primeng/table'
import { HttpClient, withJsonpSupport } from '@angular/common/http';

import { ERPSHIPService } from 'src/app/erpship.service';
import { MessageService } from 'primeng/api';
import { Observable, forkJoin } from 'rxjs';
import { PrimeNGConfig } from 'primeng/api';
import { CodeModel } from 'src/app/code-model';
import { ActivatedRoute, Router } from '@angular/router';
import { JsonPipe, Location } from '@angular/common'
import {
  FormGroup,
  FormControl,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { ThisReceiver } from '@angular/compiler';
import { StorageService } from 'src/app/services/storage.service';
import { AddressBookPopupComponent } from 'src/app/popup/address-book-popup/address-book-popup.component';
import { HistoryService } from 'src/app/services/ship-now/history.service';
import { GetAllDataService } from 'src/app/services/ship-now/get-all-data.service';


@Component({
  selector: 'app-ship-to-from',
  templateUrl: './ship-to-from.component.html',
  styleUrl: './ship-to-from.component.scss', providers: [MessageService]
})
export class ShipToFromComponent {
  @ViewChild('dt2') addrdt: any;
  @Input() shipment!: any;
  @ViewChild(AddressBookPopupComponent) popup: AddressBookPopupComponent;
  
  @Input() refreshFlag: boolean = false;

  states: Array<any> = [
    { label: 'Select', value: null },
    { label: 'State 1', value: 'state1' },
    { label: 'State 2', value: 'state2' },
  ];
  addressTypes: Array<any> = [
    { label: 'Residential', value: 'residential' },
    { label: 'Commercial', value: 'commercial' },
  ];
  // countries: Array<any> = [
  //   { label: 'Select', value: null },
  //   { label: 'Country 1', value: 'country1' },
  //   { label: 'Country 2', value: 'country2' },
  // ];
  countries :any;
  isDisabled: boolean = false;

  selectedValues: string[] = [];

  // shipment: any = {};  //super master data
  currentAddressBlock: any;
  addressbookcols: any[] = [];
  addressbookfilters: any[] = [];
  addressbookgrid: any[] = [];
  AddressBook_dialog: boolean = false;
  currentSelectedLocationid: any = {};
  Original_LocationMasterData: any;
  LocationMasterData: any;
  package: any = {};
  //master data
  Master_countries: any[] = [];
  Master_states: any[] = [];
  Master_cities: any[] = [];
  Master_addressBook: any[] = [];

  // All available columns
  allColumns: any[] = [];

  dataSourceList: any[] = [];
  //  for Location 
  xformMetaData: any;
  xfields: any[] = [];
  xcolumns: any[] = [];
  allForms: any[] = [];

  shipTo : any;

  //
  selectedProduct: any;
  selectedProductIndex: number | null = null;
  constructor(
    private router: Router,
    private cdr : ChangeDetectorRef,
    private dataService : GetAllDataService,
    private historyService: HistoryService,
    private http: HttpClient,
    private xfservice: ERPSHIPService, public messageService: MessageService,
    private primengConfig: PrimeNGConfig, private route: ActivatedRoute, private location: Location,private storageService: StorageService
  ) {

        this.xfservice.getLocationMasterData().subscribe((data) => {   
          this.LocationMasterData = data;
          this.countries = this.LocationMasterData.countrylist.map((item: any) => ({
            label: item.name,
            value: item.code,
            currency: item.currency
          }));
        }
      )
  }

  ngOnInit() {
    this.historyService.selectedHistory$.subscribe((shipment) => {
      if (shipment) {
        this.shipment = shipment;
        console.log('Received shipment data in ShipToFromComponent:', this.shipment);
        this.mapShipmentData(this.shipment);
      } else {
        console.warn('No shipment data received');
      }
    });
    this.shipment = {
      HeaderInfo: {
        FeederSystem: '',
        DocumentType: '',
        ShipDate: '',
        Location: '1',
        ShipmentType: ''
      },

      ShipFrom: {
        COMPANY: '',
        CONTACT: '',
        ADDRESS_LINE1: '',
        ADDRESS_LINE2: '',
        ADDRESS_LINE3: '',
        CITY: '',
        STATE: '',
        ZIPCODE: '',
        COUNTRY: '',
        PHONE: '',
        EMAIL: '',
      },
      ShipTo: {
        COMPANY: '',
        CONTACT: '',
        ADDRESS_LINE1: '',
        ADDRESS_LINE2: '',
        ADDRESS_LINE3: '',
        CITY: '',
        STATE: '',
        ZIPCODE: '',
        COUNTRY: '',
        PHONE: '',
        EMAIL: '',
      },
    };
    
    this.xfservice.getLocationMasterData().subscribe((data) => {
    this.storageService.setItem('data', data);
    this.countries = data.countrylist;
    const getLocationData = data.location;
    // Extract the location data
    const shipFromLocation = getLocationData[0];
    // const shipToLocation = getLocationData[1];

    // Map the location data to your form fields
    if (shipFromLocation) {
      this.shipment = {
        ...this.shipment,
        ShipFrom: {
          LOCATIONID : shipFromLocation.LocationId,
          LOCATIONNAME : shipFromLocation.LocationName,
          COMPANY: shipFromLocation.LocationName,
          CONTACT: shipFromLocation.Contact,
          ADDRESS_LINE1: shipFromLocation.AddressLine1,
          ADDRESS_LINE2: shipFromLocation.AddressLine2 || '',
          CITY: shipFromLocation.City,
          STATE: shipFromLocation.State,
          ZIPCODE: shipFromLocation.ZipCode,
          COUNTRY: shipFromLocation.Country,
          PHONE: shipFromLocation.Phone,
          EMAIL: shipFromLocation.Email
        }
      };
     
    }
      this.Original_LocationMasterData = JSON.parse(JSON.stringify(data));
      let rowdata = this.Original_LocationMasterData.location.filter((item: any) => 
        item.LocationId == data['xLocationId'])

    })

  }

  ngOnChanges(changes: SimpleChanges) {
    this.dataService.setShipToFromData(this.shipment);
    if (changes['refreshFlag'] && changes['refreshFlag'].currentValue) {
      this.ngOnInit();
    }
  }

  mapShipmentData(shipment: any) {
    console.log('Mapping shipment data...');

    const details = shipment.details;

    if (details) {
      const shipFromLocation = details.ShipFrom;
      const shipToLocation = details.ShipTo;

      if (shipFromLocation) {
        this.shipment.ShipFrom = {
          CITY: shipFromLocation.CITY,
          EMAIL: shipFromLocation.EMAIL,
          PHONE: shipFromLocation.PHONE,
          STATE: shipFromLocation.STATE,
          COMPANY: shipFromLocation.COMPANY,
          CONTACT: shipFromLocation.CONTACT,
          COUNTRY: shipFromLocation.COUNTRY,
          ZIPCODE: shipFromLocation.ZIPCODE,
          ADDRESS_LINE1: shipFromLocation.ADDRESS_LINE1,
          ADDRESS_LINE2: shipFromLocation.address_line2 || '',
          ADDRESS_LINE3: shipFromLocation.address_line3 || ''
        };
        console.log('Ship From location mapped:', this.shipment.ShipFrom);
      } else {
        console.warn('No Ship From location data found');
      }

      if (shipToLocation) {
        this.shipment.ShipTo = {
          CITY: shipToLocation.CITY,
          EMAIL: shipToLocation.EMAIL,
          PHONE: shipToLocation.PHONE,
          STATE: shipToLocation.STATE,
          COMPANY: shipToLocation.COMPANY,
          CONTACT: shipToLocation.CONTACT,
          COUNTRY: shipToLocation.COUNTRY,
          ZIPCODE: shipToLocation.ZIPCODE,
          ADDRESS_LINE1: shipToLocation.ADDRESS_LINE1,
          ADDRESS_LINE2: shipToLocation.address_line2 || '',
          ADDRESS_LINE3: shipToLocation.address_line3 || ''
        };
        console.log('Ship To location mapped:', this.shipment.ShipTo);
      } else {
        console.warn('No Ship To location data found');
      }
      // Disable the fields after mapping
      this.isDisabled = true;

    } else {
      console.warn('No details object found in shipment data');
    }

    console.log('Completed mapping shipment data.');
  }
  //COUNTRY CHNAGE START
  changeCountry(event: any): void {    
    console.log(event.value);
    //to get all countries
 // this.xfservice
    //   .getMasterCountries("country_code='" + event.value + "'")
    //   .subscribe((data) => {
    //     this.Master_states = data;
    //     console.log(this.Master_countries)
    //   });
  }




  //Address Book Popup START
  openAddressBook(fromblock: any): void {
    this.currentAddressBlock = fromblock;
    this.addressbookcols = [];
    this.addressbookfilters = [];
    this.xfservice.getAddressBookTemplate().subscribe((data) => {
      this.addressbookgrid = data[0].fields;
      this.addressbookgrid.forEach((colfield) => {
        // console.log(colfield);
        let col = {
          field: '',
          header: '',
          visible: true,
          editable: false,
          fieldType: '',
          datatype: '',
        };
        col.field = colfield.fieldName;
        col.header = colfield.fieldName;
        col.editable = colfield.editable;
        col.fieldType = colfield.fieldType;
        col.datatype = colfield.datatype;
        this.addressbookfilters.push(colfield.fieldName)
        if (col.field == 'id' || col.field == 'IsActive') {
          col.visible = false;
        }
        if (col.visible) {
          this.addressbookcols.push(col);
        }
      });
      //console.log('addressbook fields are: ' + JSON.stringify(this.addressbookcols));

      this.xfservice.getMasterDataByName('addressBook').subscribe((data) => {
        this.Master_addressBook = data;
        // console.log('Address:' + data)
        this.AddressBook_dialog = true;
      });
    });
  }

  showPopup( ) {
    this.popup.display = true;
  };

  handleAddressSelect(address: any) {
    this.shipment = {
      ...this.shipment,
      ShipFrom: address
    };
    
  };

  getFormValues() {
    return this.shipment
  }
  
}
