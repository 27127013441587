import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { finalize, catchError } from 'rxjs/operators';
import { LoadingService } from './services/loading-service.service';
import { MessageService } from 'primeng/api';
import { Router } from '@angular/router';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  private notificationShown = false; // Flag to track if notification has been shown

  constructor(
    private loadingService: LoadingService,
    private messageService: MessageService,
    private router: Router 
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    // Show loader
    this.loadingService.show();

    const token: any = window.sessionStorage.getItem('X-Token');
    let req = request;

    if (token) {
      req = request.clone({
        headers: request.headers.set('X-Token', token),
      });
    }

    return next.handle(req).pipe(
      // Hide loader after request completes
      finalize(() => this.loadingService.hide()),
      // Catch errors
      catchError((error: HttpErrorResponse) => {
        if ((error.status === 401 || error.status === 403) && !this.notificationShown) {
          this.notificationShown = true; // Set flag to true to prevent duplicate notifications
          this.messageService.add({
            severity: 'error',
            summary: 'Failure',
            detail: 'Session expired. Please log in again.',
            life: 3000
          });

          // Reset the flag after a timeout to allow for future notifications
          setTimeout(() => this.notificationShown = false, 3000);
          this.router.navigate(['/']); // Redirect to the base URL
          window.sessionStorage.removeItem('X-Token'); // Clear expired token
        }
        return throwError(error); // Return the error so that it can be handled by the component
      })
    );
  }
}
