<div class="">
    <p-toast></p-toast>
    <p-toast position="top-left" key="tl"></p-toast>
    <p-toast position="top-center" key="tc"></p-toast>
    <p-toast position="bottom-center" key="bc"></p-toast>
    
    <div class="main-container pt-7 pt-5">
        <div class="titleContainer flex text-dark justify-content-left p-3">
            <div class="pageTitle" title="TrackShipment"><b>Track Shipments</b></div>
        </div>
    
    

        <div class=" main-container1 p-grid p-align-center p-justify-between xcarr-filters__wrapper">

            <!-- Date Selection Section -->
            <!-- <div class="flex justify-content-between date-select pr-5 ps-4">
                <label id="dateselect" class="p-m-0 p-text-nowrap date-label" style="cursor: pointer;">
                    <span (click)="searchTrackData('LabelClickSearch')">
                        Total Shipments: <b style="color:#3C94D2">{{ shipRequests.length }}</b> in Last <b style="color:#3C94D2">{{(shipDateTo.getDate() - shipDateFrom.getDate())}} Days</b>
                    </span>
                </label>
                <label id="daterange" class="p-m-0" style="font-size: 14px;">
                    <span>{{formatDate(shipDateFrom)}} - {{formatDate(shipDateTo)}}</span>
                </label>
            </div> -->
    
            <!-- Dashboard Filters Section -->
            <div class="xcarr-dashbrd-filters p-d-flex p-jc-start p-ai-center ms-3" id="thumbdiv" style="overflow-x: auto; margin-bottom: 5px !important;">
                <div *ngFor="let dummy of ' '.repeat(3).split(''), let x = index">

                    <div *ngIf="x==0" class="flex justify-content-between grid col-12 ps-2" style="padding-bottom: 0px !important;">
                        <ng-container *ngFor="let status of statusOptions">
                            <div class="col ps-0 border-round-sm">
                                <div class="w-6rem">
                                    <span class="text-left font-bold text-base">{{status.id}}</span>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                    <!-- <div *ngIf="x==1" class="flex justify-content-between grid col-12 ps-2 pt-0">
                        <ng-container *ngFor="let status of statusOptions">
                            <div class="col pt-0 ps-0 border-round-sm">
                                <div class="w-6rem">
                                    <span class="text-left font-bold">{{status.description}}</span>
                                </div>
                            </div>
                        </ng-container>
                    </div> -->
                    <div *ngIf="x==2" class="flex justify-content-between grid col-12 ps-2" style="padding-bottom: 10px !important;">
                        <ng-container *ngFor="let status of statusOptions">
                                <!-- <span>{{status.description}}</span> -->
                            <div class="col pr-2 mr-1 border-round-sm" (click)="changeStatus(status.id)" [ngStyle]="{'background-color': status.colorpicker, 'cursor': 'pointer'}">
                                <div class="w-4rem p-1">
                                    <!-- <span (click)="changeStatus(status.id)" [ngStyle]="{'background-color': status.colorpicker}">c</span> -->
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
    <p-toast position="bottom-center" key="c" [baseZIndex]="5000">
        <ng-template let-message pTemplate="message">
            <div class="p-flex p-flex-column" style="flex: 1">
                <div class="p-text-center">
                    <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
                    <h4>{{ message.summary }}</h4>
                    <p>{{ message.detail }}</p>
                </div>
                <div class="p-grid p-fluid">
                    <div class="p-col-6">
                        <button type="button" pButton label="Yes" class="p-button-success"></button>
                    </div>
                    <div class="p-col-6">
                        <button type="button" pButton label="No" class="p-button-secondary"></button>
                    </div>
                </div>
            </div>
        </ng-template>
    </p-toast>
    

    <div class="trackcls" *ngIf="isDataLoaded">
        <!-- Track Shipment TABLE-->
        <p-table #dt [value]="shipRequests" [(selection)]="selectedShipRequest" id="track-grid" [paginator]="true" [rows]="10"
            [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,15,20,25,50]"  [resizableColumns]="true"  [rowHover]="true"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" dataKey="id" [scrollable]="true"
            responsiveLayout="stack"  scrollHeight="34rem"
            styleClass="p-datatable-sm p-datatable-gridlines p-datatable-striped mr-3 ml-3"
            [globalFilterFields]="xcolumns" [tableStyle]="{ 'min-width': '50rem'}">
            
            <ng-template pTemplate="caption">
                <div class="flex align-items-center justify-content-between">
                    <i class="pi pi-filter p-bg-blue-500" style="font-size: 18px" (click)="op.toggle($event)"></i>
                    <div class="flex align-items-center justify-content-left">
                        <i class="pi pi-file-excel mr-3" style="font-size: 18px"></i>

                        <i class="fa fa-lg fa-cog" style="font-size: 18px; padding-top: 2px;" (click)="openTableProperties()"></i>

                    </div>
                </div>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th style="width: 3rem">
                        <p-tableHeaderCheckbox />
                    </th>
                    <th hidden>Id</th>
                    <ng-container *ngFor="let xf of xfields; let i = index">
                        <ng-template [ngIf]="xf.visible==true">
                            <ng-container *ngFor="let field of xf.fields; let j = index">
                                <ng-template [ngIf]="field['TableVisible'] === true">
                                    <th pResizableColumn>
                                        {{ xf['IncludeHeader'] + ' ' + field.title }}
                                    </th>
                                </ng-template>
                            </ng-container>
                        </ng-template>
                    </ng-container>
                    <th>Actions</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-shipRequest let-expanded="expanded" let-columns="xfields">
                <tr>
                    <td>
                        <p-tableCheckbox [value]="shipRequest"/>
                    </td>
                    <td hidden>{{ shipRequest.id }}</td>
                    <ng-template [ngIf]="xfields[0].visible==true">
                        <ng-container *ngFor="let field of xfields[0].fields; let j = index">
                            <ng-template [ngIf]="field['TableVisible'] === true">
                                <td> {{ shipRequest.HeaderInfo[field.fieldName] }}</td>
                            </ng-template>
                        </ng-container>
                    </ng-template>
                    <ng-template [ngIf]="xfields[1].visible==true">
                        <ng-container *ngFor="let field of xfields[1].fields; let j = index">
                            <ng-template [ngIf]="field['TableVisible'] === true">
                                <td> {{ shipRequest.Shipper[field.fieldName] }}</td>
                            </ng-template>
                        </ng-container>
    
                    </ng-template>
    
                    <ng-template [ngIf]="xfields[2].visible==true">
    
                        <ng-container *ngFor="let field of xfields[2].fields; let j = index">
                            <ng-template [ngIf]="field['TableVisible'] === true">
                                <td> {{ shipRequest.ShipTo[field.fieldName] }}</td>
                            </ng-template>
                        </ng-container>
                    </ng-template>
                    <ng-template [ngIf]="xfields[3].visible==true">
    
                        <ng-container *ngFor="let field of xfields[3].fields; let j = index">
                            <ng-template [ngIf]="field['TableVisible'] === true">
                                <td> {{ shipRequest.ShipFrom[field.fieldName] }}</td>
                            </ng-template>
                        </ng-container>
                    </ng-template>
                    <ng-template [ngIf]="xfields[4].visible==true">
                        <ng-container *ngFor="let field of xfields[4].fields; let j = index">
                            <ng-template [ngIf]="field['TableVisible'] === true">
                                <td> {{ shipRequest.SoldTo[field.fieldName] }}</td>
                            </ng-template>
                        </ng-container>
                    </ng-template>
                    <ng-template [ngIf]="xfields[5].visible==true">
                        <ng-container *ngFor="let field of xfields[5].fields; let j = index">
                            <ng-template [ngIf]="field['TableVisible'] === true">
                                <td> {{ shipRequest.CarrierDetails[field.fieldName] }}</td>
                            </ng-template>
                        </ng-container>
                    </ng-template>
                    <td>
                        <p-splitButton class="bg-none"
                            label="Edit" 
                            (onClick)="save('info')" 
                            styleClass="p-button-text" appendTo="body"
                            [model]="items" text></p-splitButton>
                    </td>
                </tr>
            </ng-template>
            <!-- <ng-template pTemplate="summary">
                <div class="p-d-flex p-ai-center p-jc-between">
    
                </div>
            </ng-template> -->
        </p-table>
    </div>
    <!-- TABLE PROPERTIES  DIALOG START-->
    <p-dialog [(visible)]="tableProperties_dialog" [style]="{ width: '70%' }" [modal]="true" styleClass="p-fluid">
        <p-header>{{ " Properties"}}</p-header>
        <div class="card" [style]="{padding: '0px',height: '70vh',width: '99%',float: 'left',overflow: 'scroll'}">
        <p-table [value]="xfields" styleClass="p-datatable-sm p-datatable-gridlines p-datatable-striped "
            dataKey="fieldHeader" responsiveLayout="stack">
            <ng-template pTemplate="header">
                <tr>
                    <th style="width: 5rem"></th>
                    <th>Visible</th>
                    <th>Coulm Header</th>
                    <th>Include Header Name</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-fielddata let-expanded="expanded">
                <tr>
                    <td>
                        <button type="button" pButton pRipple [pRowToggler]="fielddata"
                            class="p-button-text p-button-rounded p-button-plain"
                            [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
                    </td>
                    <td>
                        <p-checkbox [(ngModel)]="fielddata.visible" [binary]="true"
                            [disabled]="false"></p-checkbox>{{
                        fielddata.visible }}
                    </td>
                    <td> {{ fielddata.fieldHeader }}</td>
                    <td> 
                        <span class="p-float-label">
                            <input pInputText [(ngModel)]="fielddata['IncludeHeader']" />
                        </span>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="rowexpansion" let-fielddata>
                <tr>
                    <td colspan="3">
                        <div class="p-3">
                            <p-table [value]="fielddata.fields" dataKey="title">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th>Visible</th>
                                        <th>Field</th>
                                        <th>Filter</th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-field>
                                    <tr>
                                        <td>
                                            <p-checkbox [(ngModel)]="field['TableVisible']" [binary]="true"
                                                [disabled]="false">
                                            </p-checkbox>{{fielddata['TableVisible'] }}
                                        </td>
                                        <td>{{field.title}}</td>
                                        <td>
                                            <p-checkbox [(ngModel)]="field['filterField']" [binary]="true"
                                                [disabled]="false">{{field['filterField']}}
                                            </p-checkbox>
                                        </td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
        <button type="button" pButton pRipple label="Save Changes" icon="pi pi-check" class="p-button-sm"
            (click)="SaveTemplate('ManifestTable')">
        </button>
    </p-dialog>
    <p-overlayPanel #op [showCloseIcon]="true" [dismissable]="true" [style]="{width: '50%'}">

        <div class="grid p-fluid">
            <div class="col-12 md:col-3">
                <div class="p-field">
                    <label for="location">Location Name <span class="p-error">*</span></label>
                    <p-dropdown id="ddlLocation" [options]="locationOptions" 
                    [(ngModel)]="selectedLocation" [filter]="true" [showClear]="true" 
                    placeholder="Select Location" optionLabel="LocationName" optionValue="LocationId">
                </p-dropdown>
                </div>
            </div>
            <div class="col-12 md:col-3">
                <div class="p-field">
                    <label for="carrier">Carrier</label>
                    <p-dropdown id="ddlCarrier" [options]="carrierOptions" 
                        [(ngModel)]="selectedCarrier" [filter]="true" [showClear]="true" 
                        placeholder="Select Carrier" optionLabel="CarrierName" optionValue="id">
                    </p-dropdown>
                </div>
            </div>
            <div class="col-12 md:col-3">
                <div class="p-field">
                    <label for="paymentType">Payment Type</label>
                    <p-dropdown id="ddlPaymentType" [options]="paymentTypeOptions" [(ngModel)]="Track" 
                    optionLabel="label" optionValue="value" placeholder="Select Payment Type"></p-dropdown>
                </div>
            </div>
            <div class="col-12 md:col-3">
                <label for="poNumber">PO Number</label>
                <div class="p-inputgroup p-input-icon-right">
                    <i class="serch-div pi pi-search"></i>
                    <input id="poNumber" type="text" pInputText [(ngModel)]="Track" maxlength="30" />
                </div>
            </div>
        </div>
        <div class="grid p-fluid">
            <div class="col-12 md:col-3">
                <div class="p-inputgroup p-input-icon-right">
                    <label for="fromDate">Ship From Date</label>
                    <p-calendar inputId="from" [(ngModel)]="filters['shipDateFrom']" dateFormat="yy-mm-dd" placeholder="Select Date"></p-calendar>
                </div>
            </div>
            <div class="col-12 md:col-3">
                <div class="p-inputgroup p-input-icon-right">
                    <label for="fromDate">Ship From Date</label>
                    <p-calendar inputId="to" [(ngModel)]="filters['shipDateTo']" dateFormat="yy-mm-dd" placeholder="Select Date"></p-calendar>
                </div>
            </div>
            <div class="col-12 md:col-3">
                <div class="p-inputgroup p-input-icon-right">
                    <label for="shipperName">Shipper Name</label>
                    <div class="p-inputgroup p-input-icon-right">
                        <i class="serch-div pi pi-search"></i>
                        <input id="shipperName" [(ngModel)]="Track" type="text" pInputText placeholder="Shipper Name" />
                    </div>         
                </div>
            </div>
            <div class="col-12 md:col-3">
                <div class="p-inputgroup p-input-icon-right">
                    <label for="shipToCompany">Ship To Company</label>
                    <div class="p-inputgroup p-input-icon-right">
                        <i class="serch-div pi pi-search"></i>
                        <input id="shipToCompany" type="email" pInputText [(ngModel)]="Track" maxlength="30" />
                    </div>
                </div>
            </div>
        </div>
        <div class="grid p-fluid">
            <div class="col-12 md:col-3">
                <div class="p-inputgroup p-input-icon-right">
                    <label for="costCenter">Cost Center</label>
                    <div class="p-inputgroup p-input-icon-right">
                        <i class="serch-div pi pi-search"></i>
                        <input id="costCenter" type="text" pInputText [(ngModel)]="Track" maxlength="30" />               
                    </div>
                </div>
            </div>
            <div class="col-12 md:col-3">
                <div class="p-field">
                    <label for="feederSystem">Feeder System</label>
                    <p-dropdown id="ddlFeederSystem" [options]="feederSystemOptions" [(ngModel)]="Track" optionLabel="FEEDERSYSTEM_NAME" placeholder="Select Feeder System"></p-dropdown>
                </div>
            </div>
            <div class="col-12 md:col-3">
                <div class="p-inputgroup p-input-icon-right">
                    <label for="deliveryNumber">Delivery Number</label>
                    <input id="deliveryNumber" type="text" pInputText [(ngModel)]="Track" maxlength="30" />
                </div>
            </div>
            <div class="col-12 md:col-3">
                <div class="p-field">
                    <label for="trackingNumber">Tracking/Pro/BOL#</label>
                    <input id="trackingNumber" type="text" pInputText [(ngModel)]="Track" maxlength="30" />
                </div>
            </div>
        </div>
        <div class="grid p-fluid">
            <div class="col-12 md:col-3">
                <div class="p-field">
                    <label for="country">Country</label>
                    <p-dropdown id="ddlCountry" [options]="countryOptions" [(ngModel)]="selectedCountry"
                        optionLabel="name" placeholder="Select Country" optionValue="id"
                        [filter]="true" [showClear]="true"
                    >
                    </p-dropdown>
                </div>
            </div>
            <div class="col-12 md:col-3">
                <div class="p-field">
                    <label for="status">Status</label>
                    <p-dropdown id="ddlStatus" [options]="statusOptions" 
                        [(ngModel)]="selectedStatus" optionLabel="description"
                        optionValue="id"
                        placeholder="Select Status">
                    </p-dropdown>
                </div>
            </div>
            <div class="col-12 md:col-3">
                <div class="p-inputgroup p-input-icon-right">
                    <label for="user">User</label>
                    <div class="p-inputgroup p-input-icon-right">
                        <i class="serch-div pi pi-search"></i>
                        <input id="user" type="text" pInputText [(ngModel)]="Track" maxlength="30" />                   
                    </div>
                </div>
            </div>
        </div>

        <div class="grid p-d-flex">
            <div class="col-12 md:col-4">               
                <p-button class="p-d-flex justify-content-start footer-cancel" label="Cancel"></p-button>          
        </div>
        <div class="col-12 md:col-4">              
    </div>
            <div class="col-12 md:col-4 apply-div">        
                    <p-button styleClass="bg-primary-600 text-white" label="ApplyFilter" (click)="applyFilters()"></p-button>
            </div>
        </div>
    </p-overlayPanel>
</div>