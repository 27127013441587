import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {  HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class ERPSHIPService {
     // sourceurl: string = 'http://localhost:3000';
     //shipapiurl :string ='http://localhost:5001'
   // erpurl :string ='http://localhost:6001';
     //erpurl :string ='https://sapewmservices-unexpected-manatee-iy.cfapps.eu10-004.hana.ondemand.com';
erpurl:string ='https://sapewmservices-responsible-antelope-aq.cfapps.eu10-004.hana.ondemand.com'
//  erpurl:string ='http://localhost:6001'
     sourceurl: string = 'https://xcservices.cfapps.eu10-004.hana.ondemand.com';
      shipapiurl: string ='https://shipapi.cfapps.eu10-004.hana.ondemand.com'

    //sourceurl: string = 'https://pcship2023.plutocloud.com/ESPServices';
    //  shipapiurl: string ='https://pcship2023.plutocloud.com/ShipApi'

    chatbotServiceUrl:string = "https://prest-mediating-crane-es.cfapps.eu10-004.hana.ondemand.com/query"
                                        

 
  constructor(private http: HttpClient) { }

//Chatbot communication

processChatbot(request:any):Observable<any>{
  const headers = {
    'content-type': 'application/json',
    'X-CSRF-Token': 'Fetch',
  };
   return this.http.post(this.chatbotServiceUrl, request, {
    headers,
  });
}



//packship defaults post
savePackShipDefaults(request:any):Observable<any>{
  const headers = {
   'content-type': 'application/json',
   'X-CSRF-Token': 'Fetch',
 };
  return this.http.post(this.sourceurl + '/packship', request, {
   headers,
 });
}

//packshipdefaults get

getPackShipDefaults():Observable<any>{
  return this.http.get(this.sourceurl + '/packship')
}


  //Consolidation POST
  processAddressValidation(request:any):Observable<any>{
    const headers = {
     'content-type': 'application/json',
     'X-CSRF-Token': 'Fetch',
   };
    return this.http.post(this.shipapiurl + '/AddressValidation', request, {
     headers,
   });
 }


 processRateShopAllCarriers(request:any,carrierRateEndPoint:any):any{
  const headers = {
    'content-type': 'application/json',
    'X-CSRF-Token': 'Fetch',
  };

  return new Promise((resolve, reject) =>{ resolve(this.http.post(this.shipapiurl + '/' + carrierRateEndPoint, request, {    headers,  }))})

 }



  processRateShop(request:any, carrierRateEndPoint:any):Observable<any>{
    console.log(this.shipapiurl + '/' + carrierRateEndPoint)
     const headers = {
      'content-type': 'application/json',
      'X-CSRF-Token': 'Fetch',
    };
     return this.http.post(this.shipapiurl + '/' + carrierRateEndPoint, request, {
      headers,
    });
  }

  //TO SAVE TEMPLATE BY TEMPLATE NAME
saveConsolidation(requestdata:any): Observable<any> {
  const headers = {
    'content-type': 'application/json',
    'X-CSRF-Token': 'Fetch',
  };

  return this.http.post(this.sourceurl + '/consolidation', requestdata, {
    headers,
  });
}

//tO GET DATA FROM cONSOLIDATION TABLE
getconsolidationdata():Observable<any>{
  return this.http.get(this.sourceurl + '/consolidation')
}
//To GET ShipRequest byb Document Number
getShipRequest(DocNo:any):Observable<any>{
 
  return this.http.get(this.sourceurl + '/shipRequest/' + DocNo)

}


// add item to HU PROCESSPACK
processPack(request:any):Observable<any>{
  const headers = {
   'content-type': 'application/json',
   'X-CSRF-Token': 'Fetch',
 };


 return this.http.post(this.erpurl + '/transactions/cloudpack' , request,{headers,});
}


//Process Repeat Pack

processRepeatPack(request:any):Observable<any>{
  const headers = {
   'content-type': 'application/json',
   'X-CSRF-Token': 'Fetch',
 };


 return this.http.post(this.erpurl + '/transactions/cloudRepeatPack' , request,{headers,});
}


//To Get  HU ITEMS 
getHUItems(request:any):Observable<any>{
  const headers = {
   'content-type': 'application/json',
   'X-CSRF-Token': 'Fetch',
 };
//  return this.http.post(this.erpurl + '/transactions/huitems' , request,{headers,});
return this.http.post(this.erpurl + '/transactions/cloudhuitems' , request,{headers,});
}
// to Get Consolidate Data

getERPConsolidatedDocumentsRequest(request:any):Observable<any>{
  const headers = {
   'content-type': 'application/json',
   'X-CSRF-Token': 'Fetch',
 };
 return this.http.post(this.erpurl + '/consolidation' , request,{headers,});
}
//To Get SAP ERP documents
getERPDocumentsRequest(request:any):Observable<any>{
   const headers = {
    'content-type': 'application/json',
    'X-CSRF-Token': 'Fetch',
  };
  return this.http.post(this.erpurl + '/clouddelivery' , request,{headers,});
}

//get Packaging Material

getPackagingMaterial(request:any):Observable<any>{
  const headers = {
   'content-type': 'application/json',
   'X-CSRF-Token': 'Fetch',
 };
 return this.http.post(this.erpurl + '/MasterData/PackagingMaterial' , request,{headers,});
}

//Creating HU

createHUSAP(request:any):Observable<any>{
  const headers = {
    'content-type': 'application/json',
    'X-CSRF-Token': 'Fetch',
  };
  // return this.http.post(this.erpurl + '/transactions/createHUList',request,{headers,})
  return this.http.post(this.erpurl + '/transactions/cloudcreateHUList',request,{headers,})

}


//deleteTotalItemFromHU
deleteTotalItemFromHU(request:any):Observable<any>{
  const headers = {
    'content-type': 'application/json',
    'X-CSRF-Token': 'Fetch',
  };
  return this.http.post(this.erpurl + '/transactions/cloudunpackItem',request,{headers,})

}



//deletePartial Quantity of ItemFromHU
deletePartialItemQtyFromHU(request:any):Observable<any>{
  const headers = {
    'content-type': 'application/json',
    'X-CSRF-Token': 'Fetch',
  };
  return this.http.post(this.erpurl + '/transactions/unpackItemByQty',request,{headers,})

}


//deleteHULIST

deleteHUList(request:any):Observable<any>{
  const headers = {
    'content-type': 'application/json',
    'X-CSRF-Token': 'Fetch',
  };


  return this.http.post(this.erpurl + '/transactions/clouddeleteAllHU',request,{headers,})

}


//Deleting HU
deleteHUSAP(request:any):Observable<any>{
  const headers = {
    'content-type': 'application/json',
    'X-CSRF-Token': 'Fetch',
  };
  // return this.http.post(this.erpurl + '/transactions/deletehu',request,{headers,})
  return this.http.post(this.erpurl + '/transactions/clouddeletehu',request,{headers,})

}




  //Getting Special Services
  getSpecialServiceTemplate():Observable<any>{
    return this.http.get<any>(this.sourceurl + '/carrierdetails/getspecialservices' )
  }
  getSpecialServicesByCarrier(carrier:any):Observable<any>{
    return this.http.get<any>(this.sourceurl + '/carrierdetails/getspecialservices/' + carrier )
  }

//Save Profile 
saveProfile(record:any):Observable<any>{
  const headers = {
    'content-type': 'application/json',
    'X-CSRF-Token': 'Fetch',
  };
  return this.http.post(
    this.sourceurl + '/profile',
    record,
    {
      headers,
    }
  );
}

//GetProfile
getUserProfile(userid:any):Observable<any>{
  return this.http.get<any>(this.sourceurl + '/profile/' + userid )
}


//ToSave SpecialService
saveSpecialServiceTemplate(carrier:any, record:any):Observable<any>{
  const headers = {
    'content-type': 'application/json',
    'X-CSRF-Token': 'Fetch',
  };
  
  return this.http.post(
    this.sourceurl + '/carrierDetails/' + carrier,
    record,
    {
      headers,
    }
  );

}

  //to get all global carriers
getAllCarriers():Observable<any>{
  return this.http.get<any>(this.sourceurl + '/carrierDetails/allCarriers');
}

 //toGet All form names and titles
 getxAllForms(): Observable<any> {
  return this.http.get<any>(this.sourceurl + '/xforms/getAllForms');
}

//SHIP REQUEST SERVICE START

saveShipRequest( record: any): Observable<any> {
const headers = {
  'content-type': 'application/json',
  'X-CSRF-Token': 'Fetch',
};

return this.http.post(
  this.sourceurl + '/shipRequest',
  record,
  {
    headers,
  }
);
}


//SHIP REQUEST SERVICE END 


//getShip for x-csrf

getShip(): Observable<any> {
  return this.http.get<any>('http://3.235.141.50/createshipment');
}

//SHIP Booking SERVICE START
BookShipment( record: any, carriername:any): Observable<any> {
  const headers = {
    'content-type': 'application/json',
    'X-CSRF-Token': 'Fetch',
  };
console.log('Ship API Url is: ' + this.shipapiurl + '/' + carriername)
 // this.getShip().subscribe((data) =>{console.log('Get Respons is : ' + data)});
 //this.sourceurl + '/Ship'
  return this.http.post(
    this.shipapiurl + '/' + carriername,
    record,
    {
      headers,
    }
  );
  }
  
  //SHIP Booking SERVICE END 


//SHIP Booking SERVICE START
SaveShipment( record: any): Observable<any> {
  const headers = {
    'content-type': 'application/json',
    'X-CSRF-Token': 'Fetch',
  };

 // this.getShip().subscribe((data) =>{console.log('Get Respons is : ' + data)});
 //this.sourceurl + '/Ship'
  return this.http.post(this.sourceurl + '/shipments/',
    record,
    {
      headers,
    }
  );
  }
  
  //SHIP Booking SERVICE END 




getxForms(): Observable<any> {
  return this.http.get<any>(this.sourceurl + '/xforms');
}


//to get dashboard data
getDashBoardData(request:any): Observable<any> {
  const headers = {
    'content-type': 'application/json',
    'X-CSRF-Token': 'Fetch',
  };
  return this.http.post(this.sourceurl + '/Dashboard' , request,{headers});
}

performPostData(name: string, record: any): Observable<any> {
  const headers = {
    'content-type': 'application/json',
    'X-CSRF-Token': 'Fetch',
  };

  return this.http.post(this.sourceurl + '/operations/' + name, record, {
    headers,
  });
}

//TO DELETE FIELD FROM TEMPLATEBLOCK
deleteFieldFromBlock(fieldName:any, blockName:any,description:any, screenname:any): Observable<any> {
  const headers = {
    'content-type': 'application/json',
    'X-CSRF-Token': 'Fetch',
  };

  return this.http.post(this.sourceurl + '/Template/Delete/' + fieldName + '/' + blockName + '/' + description + '/' + screenname, {}, {
    headers,
  });
}

//TO SAVE TEMPLATE BY TEMPLATE NAME
saveScreenTemplateByName(screenName: any, templatedata:any): Observable<any> {
  const headers = {
    'content-type': 'application/json',
    'X-CSRF-Token': 'Fetch',
  };

  return this.http.post(this.sourceurl + '/Template/' + screenName, templatedata, {
    headers,
  });
}

//TO SAVE TEMPLATE BY TEMPLATE NAME and CHANGE DESCRIPTION
saveScreenTemplateByNameAndDescription(screenName: any, templatedata:any, description:any): Observable<any> {
  const headers = {
    'content-type': 'application/json',
    'X-CSRF-Token': 'Fetch',
  };

  return this.http.post(this.sourceurl + '/Template/' + screenName + '/' + description, templatedata, {
    headers,
  });
}

getAddressBookTemplate(): Observable<any> {
  return this.http.get<any>(this.sourceurl + '/General/GetAddressBooktemplate');
}

getLocationMasterData(): Observable<any> {
  return this.http.get<any>(this.sourceurl + '/LocationMaster');
}

getScreenTemplateByName(screenName: any): Observable<any> {
  return this.http.get<any>(this.sourceurl + '/Template/' + screenName);
}

getxFormByName(Name: any): Observable<any> {
  return this.http.get<any>(this.sourceurl + '/xforms/GetFormByName/' + Name);
}

getFilterDataByFromName( name : any , condition: any):Observable<any>{
  return this.http.get<any>(this.sourceurl + '/xforms/GetFilterDataByFormName/' + name + '/' + condition);
}

getxFormById(id: any): Observable<any> {
  return this.http.get<any>(this.sourceurl + '/xforms/' + id);
}
getMasterCountries(data:any): Observable<any> {
  return this.http.get<any>(this.sourceurl + '/citystatecountry/' + data );
}
getMasterDataByName(formName:any): Observable<any> {
  return this.http.get<any>(this.sourceurl + '/MasterData/' + formName);
}
getxFormRecordsByFromName(formName: any): Observable<any> {
  return this.http.get<any>(this.sourceurl + '/formOperations/' + formName);
}




addForm(name: string, xformdata: any): Observable<any> {
  const headers = {
 
    'content-type': 'application/json',
    'X-CSRF-Token': 'Fetch',
  };
 
  return this.http.post(this.sourceurl + '/xforms/-1/' + name, xformdata, {
    headers,
  });
}

saveRecordData(formName: string, record: any): Observable<any> {
  const headers = {
    //'content-type': 'application/x-www-form-urlencoded',
    'content-type': 'application/json',
    'X-CSRF-Token': 'Fetch',
  };

//lert(formName)

  if (formName == 'users' || formName == 'xUSers')
  {
//    alert ('Going thou user')
    return this.http.post(
      this.sourceurl + '/User/' + formName.toLowerCase(),
      record,
      {
        headers,
      }
    );
  }else
  {
  //  alert ('Going thou formoperations')
    return this.http.post(
      this.sourceurl + '/formOperations/' + formName,
      record,
      {
        headers,
      }
    );
  }
  
}


processRoutingGuide(record:any): Observable<any> {
  const headers = {
    'content-type': 'application/json',
    'X-CSRF-Token': 'Fetch',
  };

  return this.http.post(this.sourceurl + '/processRoutingRules/', record, {
    headers,
  });
}

createHU(record: any): Observable<any> {
  const headers = {
    'content-type': 'application/json',
    'X-CSRF-Token': 'Fetch',
  };

  return this.http.post(this.sourceurl + '/hu/', record, {
    headers,
  });
}

getDropDownList(fieldName: any, tableName: any,condition:string): Observable<any> {
  console.log(
    'calling drop down data with : ' + fieldName + '    ' + tableName
  );
  return this.http.get<any>(
    this.sourceurl + '/DynamicList/' + fieldName + '/' + tableName
  );
}

addNewForm(newForm: any): Observable<any> {
  const headers = {
    'content-type': 'application/x-www-form-urlencoded',
    'X-CSRF-Token': 'Fetch',
  };

  return this.http.post(
    this.sourceurl + '/xforms/-1/' + newForm.formName,
    newForm,
    {
      headers,
    }
  );
}



processLogin(logindata: any): Observable<any> {


  var headers_object = new HttpHeaders();
  headers_object.append('Content-Type', 'application/json');
  headers_object.append('observe', 'response')
  headers_object.append('withCredentials', 'true')
  


   return this.http.post(
    this.sourceurl + '/User',
    logindata,
       {observe: 'response'  } 
  )
  


}


processAuth(): Observable<any> {

  const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    observe: 'response' as 'response'
  };

 
  return this.http.get<any>(
    this.sourceurl + '/Auth', {observe: 'response'  } 
  );

 


}


//toget formMasterData By ID
getFormMasterData(formid:any): Observable<any> {
  return this.http.get<any>(this.sourceurl + '/FormMasterData/' + formid);
}



//FILTERS RELEATED METHODS
getShipRequestByFilters(condition:any): Observable<any>{
  //alert("COndition is: " + condition)
  const headers = {
    'content-type': 'application/json',
    'X-CSRF-Token': 'Fetch',
  };
  console.log(condition)
  return this.http.post<any>(this.sourceurl + '/conditionalShipRequest',condition,{headers,});

}


//get Shipments  with filters getShipments
getShipmentstByFilters(condition:any): Observable<any>{
  //alert("COndition is: " + condition)
  const headers = {
    'content-type': 'application/json',
    'X-CSRF-Token': 'Fetch',
  };
  //console.log(condition)
  return this.http.post<any>(this.sourceurl + '/shipments/getShipments',condition,{headers,});

}

//To Save Consolidation Template

saveConsolidationTemplate(record:any):Observable<any>{
 
  const headers = {
    'content-type': 'application/json',
    'X-CSRF-Token': 'Fetch',
  };
  return this.http.post<any>(this.sourceurl + '/consolidationtemplate',record,{headers,});

}

//To get Consolidation Template Data
getConsolidationTemplate():Observable<any>{
  return this.http.get<any>(this.sourceurl + '/consolidationtemplate');
}

//to Save Routing Guide Priority Fields

saveRGPriority(data:any):Observable<any>{
  const headers = {
    'content-type': 'application/json',
    'X-CSRF-Token': 'Fetch',
  };
  return this.http.post(this.sourceurl +"/rgFieldPriority",data,{headers,})
}

//To Get Routing Guide Priorities
getRGPriority():Observable<any>{
  return this.http.get<any>(this.sourceurl +"/rgFieldPriority");
}


//To save Routing Guide Rules
saveRGRules(data:any):Observable<any>{
  const headers = {
    'content-type': 'application/json',
    'X-CSRF-Token': 'Fetch',
  };
  return this.http.post(this.sourceurl + '/rgRules',data,{headers,})

}

//To get Routing Guide Rules
getRGRules():Observable<any>{
  return this.http.get(this.sourceurl + '/rgRules');
}

//To get Routing Guide Rules by Id
getRgRules(id:any):Observable<any>{
  return this.http.get(this.sourceurl + '/rgRules/' + id)
}

//to get carrier list


}
