
<div class="p-3">
  <form>
    <div class="row align-items-center g-2 mb-4">
      <!-- Scan Product -->
      <div class="col-auto d-flex align-items-center">
        <label style="min-width:100px !important;">Scan Product</label>
        <input pInputText id="scanProduct" type="text" class="form-control" />
      </div>
      <div class="col-auto d-flex align-items-center">
        <label style="min-width: 70px !important;">Quantity</label>
        <input pInputText id="scanProduct" type="text" class="form-control"  />
      </div>
      <div class="col-auto">
        <p-dropdown [options]="packingOptions"></p-dropdown>
      </div>
  
      <!-- Dimensions Dropdown -->
      <div class="col-auto">
        <p-dropdown optionLabel="Name" [options]="Dimensions"></p-dropdown>
      </div>
  
      <div class="col-auto">
        <input pInputText id="length" style="width: 50px !important;" type="text" class="form-control" />
      </div>
      <div class="col-auto">
        <input pInputText id="width" style="width: 50px !important;" type="text" class="form-control"  />
      </div>
      <div class="col-auto">
        <input pInputText id="height" style="width: 50px !important;" type="text" class="form-control" />
      </div>
      <div class="col-auto">
  <span>
    Pkgs/Weight   
  </span> 
  </div>
      <div class="col-auto">
        <input pInputText id="width" placeholder="1" style="width: 150px !important;" type="text" class="form-control" />
      </div>
      <div class="col-auto">
        <input pInputText id="height" placeholder="1" style="width: 150px !important;" type="text" class="form-control"  />
      </div> 
      <div class="col-auto">
        <button pButton label="Auto Pack"  class="p-button-primary validate-button"></button>
      </div>
    </div>
  </form>

  <div>
    <p-table [value]="products" class="p-datatable-sm mt-3">
      <ng-template pTemplate="header">
        <tr>
          <th style="width: 5%; !important">#</th>
          <th>Product</th>
          <th>Description</th>
          <th>Total Quantity</th>
          <th>Balance Quantity</th>
          <th>Partial Quantity</th>
          <th>S</th>
          <th>IS DG</th>
          <th>Actions</th>
          <th>
      <button pButton style="background-color: #ffffff !important;color: #DA3B3B !important;" class="p-button-danger" >
        <i class="pi pi-trash mr-2" (click)="deleteAll()" style="cursor: pointer; color: #DA3B3B !important;"></i>Delete All
      </button>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-product let-i="rowIndex">
        <tr>
          <td>{{ i + 1 }}</td>
          <td><input pInputText [(ngModel)]="product.product" (input)="manageRows(i)" /></td>
          <td><input pInputText [(ngModel)]="product.description" (input)="manageRows(i)" /></td>
          <td><input pInputText [(ngModel)]="product.totalQuantity" (input)="updateBalanceQuantity(product)" /></td>
          <td><input pInputText [(ngModel)]="product.balanceQuantity" readonly/></td>
          <td><input pInputText [(ngModel)]="product.partialQuantity" (input)="updateBalanceQuantity(product)" /></td>
          <td><input type="checkbox" [(ngModel)]="product.s" /></td>
          <td><input type="checkbox" [(ngModel)]="product.isDG" /></td>
          <td>
            <img (click)="deleteProduct(i)" class="btn-img ml-3" src="../../assets/icon/material-symbols_delete-outline.png">
            <!-- <i class="pi pi-trash ml-3" (click)="deleteProduct(i)" style="cursor: pointer; color: red;font-size: 1.3rem"></i> -->
          </td>
          <td>
            <img (click)="searchProducts()" class="btn-img ml-5" src="../../assets/icon/tabler_search.png">
          </td>
        </tr>
      </ng-template>
    </p-table>
    <div class="row p-3 mr-5" style="background-color:#fff;">   
      <div class="text-center mt-1">
        <button pButton (click)="packItem()" class="p-button-primary validate-button mr-3">
          <span class="d-flex align-items-center">
              <img src="../../assets/icon/pajamas_package.png" class="icon-img"> Pack
          </span>
      </button>
      <button pButton (click)="packAllItems()" class="p-button-primary validate-button mr-3">
        <span class="d-flex align-items-center">
            <img src="../../assets/icon/pajamas_package.png" class="icon-img"> Pack All
        </span>
    </button>   
     <span class="total-bal mr-5">
      TOTAL BAL QTY: {{ getTotalBalanceQuantity() }}
     </span>     
      </div>
      </div>
    <div class="mt-1">
   <p-table [value]="packedItems" class="p-datatable-sm mt-3">
  <!-- Table Header -->
<!-- Table Header -->
<ng-template pTemplate="header">
  <tr>
    <th style="width: 5%; !important">#</th>
    <th>HU</th>
    <th>Weight</th>
    <th>Weight Units</th>
    <th>Dimension</th>
    <th>Dimension Units</th>
    <th>Class</th>
    <th>Tracking#</th>
    <th>Action</th>
    <th>
      <button pButton style="background-color: #ffffff !important;color: #DA3B3B !important;" class="p-button-danger">
        <i class="pi pi-trash mr-2" (click)="deleteAll()" style="cursor: pointer; color: #DA3B3B !important;"></i>Delete All
      </button>
    </th>
  </tr>
</ng-template>

<!-- Table Body with Data -->
<ng-template pTemplate="body" let-item let-i="rowIndex">
  <tr *ngIf="packedItems.length > 0">
    <td>{{ i + 1 }}</td>
    <td>{{ item.hu }}</td>
    <td>
      <input pInputText type="text" [(ngModel)]="item.weight" (ngModelChange)="onInputChange()">
    </td>
    <td>
      <input pInputText type="text" [(ngModel)]="item.weightUnits" (ngModelChange)="onInputChange()">
    </td>
    <td>
      <p-dropdown optionLabel="Name" [options]="Dimensions" [(ngModel)]="item.dimension" (onChange)="onInputChange()"></p-dropdown>
    </td>
    <td>
      <input pInputText type="text" [(ngModel)]="item.dimensionUnits" (ngModelChange)="onInputChange()">
    </td>
    <td>
      <input pInputText type="text" [(ngModel)]="item.class" (ngModelChange)="onInputChange()">
    </td>
    <td>
      <input pInputText type="text" [(ngModel)]="item.tracking" (ngModelChange)="onInputChange()">
    </td>
    <td>
      <img (click)="deleteProduct(i)" class="btn-img ml-3" src="../../assets/icon/material-symbols_delete-outline.png">
    </td>
    <td>
      <img (click)="getProducts(item)" class="btn-img ml-5" src="../../assets/icon/tabler_search.png">
    </td>
  </tr>
</ng-template>

  
</p-table>
<div *ngIf="packedItems.length === 0" class="row text-center mt-5 mr-5" >
    <span class="text-center mr-5" style="color: #999;
    font-size: 18px;
    font-weight: 450;
    ">
      No data available.
    </span>
</div>
<!-- No Data Template -->
  
    </div>
     
    <p-dialog header="Product Details" [(visible)]="displayDialog" [modal]="true" [style]="{width: '110vw'}" (onHide)="closeDialog()">
      <ng-container *ngIf="productDetails">
        <!-- Table with pagination and template reference variable #dt -->
        <p-table #dt [value]="productDetails" [scrollable]="true" class="p-datatable-sm mt-3" 
        [paginator]="true" [rows]="10" [showCurrentPageReport]="true" 
        [rowsPerPageOptions]="[10, 20, 30]">
 <ng-template pTemplate="caption">
   <div style="text-align: right">
     <input type="text" pInputText size="50" placeholder="Global Filter"
            (input)="applyFilterGlobal($event)" style="width:auto">
   </div>
 </ng-template>
 <ng-template pTemplate="header">
   <tr>
     <th style="white-space: nowrap; width: 50px;">S.No</th>
     <th pSortableColumn="ProductNo" style="white-space: nowrap; width: 120px;">Product# <p-sortIcon field="ProductNo"></p-sortIcon></th>
     <th pSortableColumn="Description" style="white-space: nowrap; width: 200px;">Description <p-sortIcon field="Description"></p-sortIcon></th>
     <th pSortableColumn="Quantity" style="white-space: nowrap; width: 100px;">Quantity <p-sortIcon field="Quantity"></p-sortIcon></th>
     <!-- <th pSortableColumn="UnitWeight" style="white-space: nowrap; width: 100px;">Unit Weight <p-sortIcon field="UnitWeight"></p-sortIcon></th> -->
     <!-- <th pSortableColumn="Dimension" style="white-space: nowrap; width: 150px;">Dimension <p-sortIcon field="Dimension"></p-sortIcon></th> -->
   </tr>
 </ng-template>
 <ng-template pTemplate="body" let-item let-i="rowIndex">
   <tr style="cursor: pointer;" (click)="selectProduct(item)">
     <td>{{ i + 1 }}</td>
     <td>{{ item.ProductNo }}</td>
     <td>{{ item.Description }}</td>
     <td>{{ item.Quantity }}</td> <!-- Adjust based on your data -->
     <!-- <td>{{ item.UnitWeight }}</td> -->
     <!-- <td>{{ item.Dimension }}</td> -->
   </tr>
 </ng-template>
</p-table>

      </ng-container>
    </p-dialog>
    
    <p-dialog header="Product Details" [(visible)]="displayProductDetails" [modal]="true" [style]="{width: '50vw'}">
      <p-table [value]="selectedProduct.ItemsInfo" class="p-datatable-sm">
        <ng-template pTemplate="header">
          <tr>
            <th>Product</th>
            <th>Description</th>
            <!-- <th>Total Quantity</th>
            <th>Balance Quantity</th> -->
            <th>Quantity</th>
            <!-- <th>Is Serial</th>
            <th>Is DG</th> -->
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-item>
          <tr>
            <td>{{ item.product }}</td>
            <td>{{ item.description }}</td>
            <!-- <td>{{ item.totalQuantity }}</td>
            <td>{{ item.balanceQuantity }}</td> -->
            <td>{{ item.partialQuantity }}</td>
            <!-- <td>{{ item.isSerial }}</td>
            <td>{{ item.isDG }}</td> -->
          </tr>
        </ng-template>
      </p-table>
    </p-dialog>
    
    
    
    <!-- Delete All Button -->
  
    <!-- Table ends  -->
    
  </div>
</div>
