<div class="container mt-2 pt-2">
    <div class="align-middle">
        <div class="row justify-content-center">
            <div class="col-12">
                <div class="row justify-content-center">
                    <div class="col-4">
                        &nbsp;
                    </div>
                    <div class="col-4">
                        &nbsp;
                    </div>
                    <div class="col-4 ps-7">
                        <button class="btn_Edit">
                            <img
                                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAYAAAByUDbMAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABqSURBVHgB7dPLCcAwDANQjaL9l8oobQ+Guu4vsnqswRDi5GHyAfTgliOSMIKBLJFtkAkaZUzBOUG8mZOgurGCUkdv3U1DRPPc+LAw1yxopv5D4uP8DAL2K7ahjNnQFcYulLGaMgQcv4YFra82cG/2g0R9AAAAAElFTkSuQmCC">
                        </button>
                    </div>
                </div>
                <br />
                <div class="row justify-content-center">
                    <div class="col-2 bg_col text-center pe-0">
                        <div id="styleborder">
                            <div class="dev_img_sec">
                                <img src="/assets/images/quote.png" class="img-fluid" alt="Quote Now" /><br /><br />
                            </div>
                            <a routerLink='/home/quotenow' class="btn btn_common_Css btn-quote_Now">Quote Now</a>
                        </div>
                    </div>
                    <div class="col-2 bg_col text-center pe-0">
                        <div id="styleborder">
                            <div class="dev_img_sec">
                                <img src="/assets/images/ship.png" class="img-fluid" alt="Ship Now" />
                            </div>
                            <a routerLink='/home/ShipNow' class="btn btn_common_Css btn-ship_Now">Ship Now</a>
                        </div>
                    </div>
                    <div class="col-2 bg_col text-center pe-0">
                        <div id="styleborder">
                            <div class="dev_img_sec">
                                <img src="/assets/images/track.png" class="img-fluid" alt="Track Now" />
                           </div>
                            <a routerLink="/home/TrackShipment" class="btn btn_common_Css btn-track_Now">Track Now</a>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    </div>
<div class="footer-label">
    <div class="col-12 text-center">
        <span><i class="fa fa-copyright"></i> eshipjet2024. All rights reserved</span>
    </div>
</div>