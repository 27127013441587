import { Component } from '@angular/core';

@Component({
  selector: 'app-dash-boards',
  templateUrl: './dash-boards.component.html',
  styleUrls: ['./dash-boards.component.scss']
})
export class DashBoardsComponent {

}
