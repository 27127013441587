<div class="layout-wrapper" (click)="onLayoutClick()" [ngClass]="{
        'layout-static': isStatic(),
        'layout-overlay': isOverlay(),
        'layout-overlay-active': overlayMenuActive,
        'layout-horizontal': isHorizontal(),
        'layout-slim': isSlim(),
        'layout-static-inactive': staticMenuDesktopInactive,
        'layout-mobile-active': staticMenuMobileActive,
        'layout-menu-dark': app.darkMenu,
        'layout-menu-light': !app.darkMenu,
        'p-input-filled': app.inputStyle === 'filled',
        'p-ripple-disabled': !app.ripple
    }"
>
   <app-topbar *ngIf="showNavbar" ></app-topbar> 
    <!-- class="layout-menu-logo" -->
    <div *ngIf="showNavbar"  class="layout-menu-container" (click)="onMenuClick($event)">
        <!-- <div style="width: 100px;height: 50px;"  >
            <a href="#">
                <img
                    id="layout-menu-logo"
                    src="assets\images\companylogo1.jpg" width="100%" 
                    library="babylon-layout"
                />
            </a>
        </div> -->
        <div *ngIf="showNavbar" class="layout-menu-wrapper">
            <div class="menu-scroll-content">
                <!-- <app-inline-profile                 
                ></app-inline-profile>               -->
                <app-menu></app-menu>
            </div>
        </div>
    </div>

    <!-- <div class="layout-main">
        <div class="layout-content mt-0" [style]="{padding: '0em'}"> -->
            <router-outlet></router-outlet>
        <!-- </div>
    </div> -->

    <app-config></app-config>

    <!-- <div class="layout-mask"></div> -->
<!-- </div> -->


