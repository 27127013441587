<p-table *ngIf="shippingCharges.length > 0" [value]="shippingCharges" class="p-datatable-sm mt-3">
    <ng-template pTemplate="header">
      <tr>
        <th style="width: 25%; !important">#</th>
        <th style="width: 25%; !important">Description</th>
        <th style="width: 25%; !important">amount</th>
        <th style="width: 25%; !important">currency</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-product let-i="rowIndex">
      <tr>
        <td>{{ i + 1 }}</td>
        <td>{{ product.description }}</td>
        <td>{{ product.amount }}$</td>
        <td>{{ product.currency }}</td>
      </tr>
    </ng-template>
  </p-table>

  <p *ngIf="shippingCharges.length === 0">No shipping charges available.</p>
