
  <div class="transform">
  <app-dashboard-header (triggerRefresh)="triggerRefresh()" (dataEmitter)="receiveData($event)"></app-dashboard-header>
  <app-warning-popup></app-warning-popup>

<div style="height: 105vh;"  class="p-2" [@.disabled]="true">
  <mat-tab-group
  cdkDropList
  cdkDropListOrientation="horizontal"
  (cdkDropListDropped)="drop($event)"
  class="example-drag-tabs"
  [(selectedIndex)]="selectedTabIndex"
  (selectedIndexChange)="onTabChange($event)">
  
  <mat-tab *ngFor="let tab of tabs">
    <ng-template mat-tab-label>
      <span
        cdkDrag
        cdkDragPreviewClass="background"
        class="mat-tab-label">{{ tab.label }}</span>
    </ng-template>

    <!-- Wrap the content with a div to apply the animation -->
    <div class="fade-in" style="overflow: hidden !important;">
     <ng-container [ngSwitch]="tab.content">
        <app-ship-to-from *ngSwitchCase="'ship-to-from'" [refreshFlag]="refreshFlag" [shipment]="selectedOrder"></app-ship-to-from>
        <app-routing-billing-detail *ngSwitchCase="'routing-billing-detail'" [refreshFlag]="refreshFlag" [shipment]="selectedOrder"></app-routing-billing-detail>
        <p *ngSwitchCase="'additional-info'">
          This tab contains Additional Info. This is some dummy text for the Additional Info tab.
        </p>
        <app-ship-now-pack *ngSwitchCase="'ship-now-pack'" [refreshFlag]="refreshFlag" [shipment]="selectedOrder"></app-ship-now-pack>
        <app-international-details *ngSwitchCase="'international-details'"></app-international-details>
        <app-documents [shipment]="selectedOrder" *ngSwitchCase="'documents'"></app-documents>
        <app-charges [shipment]="selectedOrder" *ngSwitchCase="'charges'"></app-charges>
        <p *ngSwitchCase="'dg'">
          This tab includes Dangerous Goods (DG) details. This is some dummy text for the DG tab.
        </p>
      </ng-container>
    </div>
  </mat-tab>
</mat-tab-group>

</div>


<!-- tab-view.component.html -->


<footer class="footer">
  <div class="row p-3" style="background-color:#E8EAF1;">
    <ng-container *ngIf="selectedTabIndex === 4 || selectedTabIndex === 3">
      <div class="button-wrapper">
        <button class="validate-button">Save Profile</button>
        <button class="validate-button">Shipment Confirmation</button>
        <button class="validate-button">Hold</button>
        <button (click)="onSubmit()" class="btn-shp">Ship Now</button>
      </div>
    </ng-container>
    <ng-container *ngIf="selectedTabIndex != 4 && selectedTabIndex != 3">
      <div class="button-wrapper">
        <button (click)="onSubmit()" class="btn-shp">Ship Now</button>
      </div>
    </ng-container>
  </div>
</footer>
</div>

<!-- Tabs End  -->
  
