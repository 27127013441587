
<form [formGroup]="form">
  <div class="header-container">
    <div class="header-content">
      <!-- <i style="font-size: 1rem;color: #030E4C" class="pi pi-link"></i> -->
      <span class="header-title mr-2">Connected To
      </span>

      <p-dropdown formControlName="selectedConnection" class="custom-dropdown mr-2" [options]="connections"
        placeholder="Select" optionLabel="Name">
      </p-dropdown>


      <div class="custom-dropdown-container mr-2">
        <label (click)="toggleList()" class="custom-dropdown-label">
          {{ form.controls['selectedFilter'].value?.Name || 'Select an option' }}
        </label>

        <ul *ngIf="listVisible" class="custom-dropdown-list">
          <li *ngFor="let filter of filters" (click)="selectFilter(filter)">
            {{ filter.Name }}
          </li>
        </ul>
      </div>

      <!-- <div class="search-container mr-2">
        <span class="p-input-icon-right">
          <i class="pi pi-search"></i>
          <input 
            type="text" 
            formControlName="searchTerm" 
            placeholder="Search..."
            class="p-inputtext p-component"
          />
        </span>
      </div> -->
      <div class="search-container">
        <!-- Search Input Field -->
        <input #cursorFocus type="text" formControlName="searchTerm" [(ngModel)]="searchTerm" placeholder="Search..." class="p-inputtext p-component"
          (keyup.enter)="searchFilter()" />

        <!-- Search Icon to Trigger Popup -->
        <i class="pi pi-search search-icon" (click)="openHistoryDialog()">
        </i>

        <!-- test -->

        <!-- HISTORY FILTER PANEL-->


        <p-overlayPanel #historyop [showCloseIcon]="true" [dismissable]="true" [style]="{width: '450px'}">

          <div class="grid p-fluid">

            <div class="field col-12 md:col-6">
              <span class="p-float-label">
                <p-calendar inputId="from" [(ngModel)]="history_filters['shipDateFrom']"></p-calendar>
                <label for="from">From Date</label>

              </span>
            </div>
            <div class="field col-12 md:col-6">
              <span class="p-float-label">
                <p-calendar inputId="to" [(ngModel)]="history_filters['shipDateTo']"></p-calendar>
                <label for="to">To Date</label>
              </span>
            </div>
          </div>
          <div class="grid p-fluid mt-1">
            <ng-template ngFor let-xf [ngForOf]="history_filter_xfields" let-i="index">
              <ng-template ngFor let-field [ngForOf]="xf.fields" let-j="index">
                <ng-template [ngIf]="field['filterField']==true && field['fieldName']!='ShipDate'">

                  <div class="field col-12 md:col-6">
                    <span class="p-float-label">
                      <input type="text" id="inputtext" pInputText
                        [(ngModel)]="history_filters[xf.fieldHeader + '.' + field.fieldName]">
                      <label for="inputtext">{{ field.title }}</label>
                    </span>


                  </div>
                </ng-template>
              </ng-template>
            </ng-template>
          </div>

          <!-- <div class="flex align-items-bottom-right"> -->
          <div class="grid p-fluid mt-1">
            <p-button label="Get Shipments" (click)="applyHistoryFilters('shipments',$event , historyop)"
              class="mr-1"></p-button>
            <p-button label="Get Open Orders" (click)="applyHistoryFilters('openorders', $event , historyop)"
              class="mr-1"></p-button>

          </div>


        </p-overlayPanel>



        <!-- HISTORY FILTER PANEL END-->

        <!-- HISTORY OPEN & MANIFEST LIST DIALOG  openorderloading-->

        <p-dialog [(visible)]="history_Dialog" [style]="{ width: '90%' }" header="History/Open orders" [modal]="true"
          styleClass="p-fluid">

          <div class="flex">
            <button pButton pRipple type="button" label="Shipments" class="mr-1" [loading]="shiphistoryloading"
              (click)="getShipments('shipments')"></button>
            <button pButton pRipple type="button" label="Open Orders" class="mr-1" [loading]="openorderloading"
              (click)="getShipments('shiprequest')"></button>

          </div>

          <!-- MANIEFST TABLE-->
          <p-table #manifestdt [value]="historyOrders" [tableStyle]="{'min-width': '75rem'}"
            [(selection)]="selectedHistoryOrder" selectionMode="single" [paginator]="true" [rows]="10"
            [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,25,50]"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" dataKey="id"
            [scrollable]="true" responsiveLayout="stack"
            styleClass="p-datatable-sm p-datatable-gridlines p-datatable-striped "
            [globalFilterFields]="global_history_xcolumns">
            <ng-template pTemplate="caption">
              <div class="flex align-items-center justify-content-between">

                <p-button icon="pi pi-filter" (click)="historyop.toggle($event)"></p-button>
                <div class="ml-auto mr-2">
                  <i class="pi pi-search" style="margin:4px 4px 0 0"></i>
                  <input type="text" pInputText size="50" placeholder="Global Filter"
                    (input)="applyManifestFilterGlobal($event, 'contains')" style="width:auto">
                </div>
                <p-button icon="pi pi-cog" (click)="openHistoryTableProperties()"></p-button>
              </div>
            </ng-template>

            <ng-template pTemplate="header">
              <tr>
                <!-- <th style="width: 3rem">
                Select
            </th> -->
                <th style="width: 5rem"></th>
                <th pSortableColumn="id">Id<p-sortIcon field="id"></p-sortIcon></th>

                <ng-container *ngFor="let xf of history_xfields; let i = index">
                  <ng-template [ngIf]="xf.visible==true">
                    <ng-container *ngFor="let field of xf.fields; let j = index">
                      <ng-template [ngIf]="field['TableVisible'] === true">
                        <th pSortableColumn="{{field.title }}">
                          {{ xf['IncludeHeader'] + field.title }}
                          <p-sortIcon field="{{ field.title }}"></p-sortIcon>
                        </th>
                      </ng-template>
                    </ng-container>
                  </ng-template>
                </ng-container>
                <th></th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-shipRequest let-expanded="expanded" let-columns="history_xfields">


              <tr (click)="selectOrderorShipment(shipRequest)">

                <td>
                  <button type="button" pButton pRipple [pRowToggler]="shipRequest"
                    class="p-button-text p-button-rounded p-button-plain"
                    [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
                </td>
                <td>{{ shipRequest.id }} </td>
                <ng-template [ngIf]="history_xfields[0].visible==true">
                  <ng-container *ngFor="let field of history_xfields[0].fields; let j = index">
                    <ng-template [ngIf]="field['TableVisible'] === true">
                      <td> {{ shipRequest?.details?.HeaderInfo?.[field.fieldName] }} </td>
                    </ng-template>
                  </ng-container>
                </ng-template>
                <ng-template [ngIf]="history_xfields[1].visible==true">
                  <ng-container *ngFor="let field of history_xfields[1].fields; let j = index">
                    <ng-template [ngIf]="field['TableVisible'] === true">
                      <td> {{ shipRequest?.details?.Shipper?.[field.fieldName] }}</td>
                    </ng-template>
                  </ng-container>

                </ng-template>

                <ng-template [ngIf]="history_xfields[2].visible==true">

                  <ng-container *ngFor="let field of history_xfields[2].fields; let j = index">
                    <ng-template [ngIf]="field['TableVisible'] === true">
                      <td> {{ shipRequest?.details?.ShipTo?.[field.fieldName] }}</td>
                    </ng-template>
                  </ng-container>
                </ng-template>
                <ng-template [ngIf]="history_xfields[3].visible==true">

                  <ng-container *ngFor="let field of history_xfields[3].fields; let j = index">
                    <ng-template [ngIf]="field['TableVisible'] === true">
                      <td> {{ shipRequest?.details?.ShipFrom?.[field.fieldName] }}</td>
                    </ng-template>
                  </ng-container>
                </ng-template>
                <ng-template [ngIf]="history_xfields[4].visible==true">
                  <ng-container *ngFor="let field of history_xfields[4].fields; let j = index">
                    <ng-template [ngIf]="field['TableVisible'] === true">
                      <td> {{ shipRequest?.details?.SoldTo?.[field.fieldName] }}</td>
                    </ng-template>
                  </ng-container>
                </ng-template>
                <ng-template [ngIf]="history_xfields[5].visible==true">
                  <ng-container *ngFor="let field of history_xfields[5].fields; let j = index">
                    <ng-template [ngIf]="field['TableVisible'] === true">
                      <td> {{ shipRequest?.details?.CarrierDetails?.[field.fieldName] }}</td>
                    </ng-template>
                  </ng-container>
                </ng-template>

              </tr>
            </ng-template>

            <ng-template pTemplate="rowexpansion" let-shipRequest>
              <tr>
                <td colspan="18">
                  <div class="p-3">


                    <p-table [value]="shipRequest.details.Packages" dataKey="Sno" [scrollable]="true"
                      responsiveLayout="stack">
                      <ng-template pTemplate="caption">
                        <legend> {{'Total Packages: ' + shipRequest.details.Packages.length }}</legend>
                      </ng-template>
                      <ng-template pTemplate="header">
              <tr>
                <th style="width: 5rem"></th>
                <th>Sno</th>
                <th>HU</th>
                <th>Weight</th>
                <th>Dimension</th>


              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-package let-expandedPackage="expanded">

              <tr>
                <td>
                  <button type="button" pButton pRipple [pRowToggler]="package"
                    class="p-button-text p-button-rounded p-button-plain"
                    [icon]="expandedPackage ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>

                </td>
                <td>{{ package['Sno'] }}</td>
                <td>{{ package['HU'] }}</td>
                <td>{{ package['Weight'] }}</td>
                <td>{{ package['Dimension'] }}</td>


              </tr>
            </ng-template>
            <ng-template pTemplate="rowexpansion" let-package>
              <tr>
                <td colspan="10">
                  <div class="p-3">


                    <p-table [value]="package['ItemsInfo']" dataKey="ItemNo">
                      <ng-template pTemplate="caption">
                        <legend> {{'Total Items: ' + package['ItemsInfo'].length }}</legend>
                      </ng-template>
                      <ng-template pTemplate="header">
              <tr>

                <th>ItemNo</th>
                <th>ProductNo</th>
                <th>Description</th>
                <th>UnitCost</th>
                <th>UnitWeight</th>
                <th>Dimension</th>
                <th>Quantity</th>
                <th>Partial</th>
                <th>Balance</th>


              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-item>

              <tr>

                <td>{{ item['ItemNo'] }}</td>
                <td>{{ item['ProductNo'] }}</td>
                <td>{{ item['Description'] }}</td>
                <td>{{ item['UnitCost'] }}</td>
                <td>{{ item['UnitWeight'] }}</td>
                <td>{{ item['Dimension'] }}</td>
                <td>{{ item['Quantity'] }}</td>
                <td>{{ item['Partial'] }}</td>
                <td>{{ item['Balance'] }}</td>



              </tr>
            </ng-template>

            <ng-template pTemplate="emptymessage">
              <tr>
                <td colspan="6">There are no items for this Package yet.</td>
              </tr>
            </ng-template>
          </p-table>
      </div>
      </td>
      </tr>
      </ng-template>

      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="6">There are no packages for this shipment yet.</td>
        </tr>
      </ng-template>
      </p-table>
    </div>
    </td>
    </tr>
    </ng-template>




    <ng-template pTemplate="summary">
      <div class="p-d-flex p-ai-center p-jc-between">
        In total there are {{ historyOrders.length }} Shipments(s)

      </div>
    </ng-template>



    </p-table>

    <!-- <button pButton pRipple type="button" label="Select " class="mr-1" (click)="selectOrderorShipment({})"></button> -->

    </p-dialog>


    <!-- HISTORY OPEN & MANIFEST LIST DIALOG END HERE-->
    <!-- testend -->

    <!-- Dialog Popup -->
    <p-dialog header="Search" [(visible)]="searchDialogVisible" [modal]="true" [style]="{width: '50vw'}"
      [draggable]="false" [resizable]="false" (onHide)="onDialogHide()">
      <!-- Your search form or content goes here -->
      <input type="text" formControlName="searchTermPopup" placeholder="Search in Popup..."
        class="p-inputtext p-component" />
      <button pButton label="Search" (click)="performSearch()"></button>
    </p-dialog>
  </div>


  <button pButton type="button" label="Get"  (click)="searchFilter()" class="p-button p-button-primary mr-2"></button>
  <button pButton type="button" label="Consolidation" class="p-button p-button-primary"></button>

  <div class="right-content">
    <span class="delivery-title">Pickup Date</span>
    <p-calendar formControlName="pickupDate" [iconDisplay]="'input'" [showIcon]="true" 
      [dateFormat]="'dd/mm/yy'" inputId="icondisplay" />

    <span class="delivery-title">Ship Date</span>
    <p-calendar formControlName="shipDate" [iconDisplay]="'input'" [showIcon]="true" [minDate]="today"
      [dateFormat]="'dd/mm/yy'" inputId="icondisplay" />

    <button pButton type="button" label="New" class="p-button p-button-primary"></button>

    <i class="pi pi-clock timeline-icon" (click)="openTimelineSidebar()" style="cursor: pointer;"></i>
    <!-- <img style="cursor: pointer;" class="icon" src="../../../assets/icon/maki_cross.png" alt="timeline"
      (click)="navigateToDashboard()"> -->
      <i 
      class="pi pi-times animated-icon" 
      (click)="navigateToDashboard()"
      style="font-size: 30px; cursor: pointer;color: #030E4C;">
    </i>
    
    
  </div>
  </div>
  <!-- PrimeNG Sidebar -->

  <!-- Hamburger Icon -->
  <div class="hamburger-menu" (click)="toggleMenu()">
    <i class="pi pi-bars"></i>
  </div>

  <!-- Mobile Menu -->
  <div class="mobile-menu" *ngIf="menuVisible">
    <div class="mobile-menu-item">
      <span class="header-title">Connect To</span>
      <p-dropdown formControlName="selectedConnection" class="custom-dropdown" [options]="connections"
        optionLabel="name"> </p-dropdown>
    </div>
    <div class="mobile-menu-item">
      <label (click)="toggleList()" class="custom-dropdown-label">
        {{ form.controls['selectedFilter'].value?.name || 'Select an option' }}
      </label>
      <ul *ngIf="listVisible" class="custom-dropdown-list">
        <li *ngFor="let filter of filters" (click)="selectFilter(filter)">
          {{ filter.name }}
        </li>
      </ul>
    </div>
    <div class="mobile-menu-item">
      <span class="p-input-icon-right">
        <i class="pi pi-search"></i>
        <p-dropdown formControlName="selectedDelivery" [options]="deliveries" optionLabel="name" placeholder="Delivery"
          filter="true" filterPlaceholder="Search..." inputId="dropdown">
        </p-dropdown>
      </span>
    </div>
    <div class="mobile-menu-item">
      <button pButton type="submit" label="Get" class="p-button p-button-primary"></button>
    </div>
    <div class="mobile-menu-item">
      <button pButton type="button" label="Consolidation" class="p-button p-button-primary"></button>
    </div>
    <div class="mobile-menu-item">
      <span class="delivery-title">Pickup Date</span>
      <p-calendar formControlName="pickupDate" [iconDisplay]="'input'" [showIcon]="true" [minDate]="today"
        [dateFormat]="'dd/mm/yy'" inputId="icondisplay" />
    </div>
    <div class="mobile-menu-item">
      <span class="delivery-title">Ship Date</span>
      <p-calendar formControlName="shipDate" [iconDisplay]="'input'" [showIcon]="true" [minDate]="today"
        [dateFormat]="'dd/mm/yy'" inputId="icondisplay" />
    </div>
    <div class="mobile-menu-item">
      <button pButton type="button" label="New" class="p-button p-button-primary"></button>
    </div>
  </div>
  </div>
</form>


<p-sidebar [(visible)]="showSidebar" position="right" [modal]="false" [showCloseIcon]="false"
  [style]="{'width': '350px', 'height': '150vh'}">
  <div class="sidebar-header">
    <h3>Shipment Types</h3>
    <button type="button" pButton icon="pi pi-times" (click)="showSidebar = false"
      class="ui-button-secondary ui-button-sm close-button"></button>
  </div>

  <div class="shipment-type-dropdown p-1">
    <p-dropdown  [options]="shipmentTypes"  [(ngModel)]="selectedShipmentType" placeholder="Select a shipment type"
       (ngModelChange)="onShipmentTypeChange($event)">
    </p-dropdown>

  </div>
  <!-- Show shipments if available -->
  <div *ngIf="filteredShipments.length; else noData" class="shipment-card-container">
    <div class="shipment-card" *ngFor="let shipment of filteredShipments">
      <h4 style="cursor: pointer;" (click)="selectHistory(shipment)">Delivery # {{shipment.details.HeaderInfo.DocumentNumber}}</h4>
      <p>Carrier: {{shipment.details.CarrierDetails.Carrier}}</p>
      <p>Status: {{shipment.details.TrackingStatus}}</p>
      <p>Date & Time: {{shipment.details.HeaderInfo.ShipDate | date:'MM/dd/yyyy hh:mm:ss a'}}</p>
    </div>
  </div>

  <!-- Template to display when there is no data -->
  <ng-template #noData>
    <div class="no-data-message">
      <p>No data available</p>
    </div>
  </ng-template>

</p-sidebar>
<app-warning-popup></app-warning-popup>
