<form class="p-3">
    <!-- Row for dropdowns and input fields -->
    <div class="row mb-3">
      <!-- Ship Method Dropdown -->
      <div class="col-md-2">
        <div class="form-group">
          <label for="shipMethod">INT Duties/Taxes</label>
          <p-dropdown id="shipMethod" [options]="shipMethods"></p-dropdown>
        </div>
      </div>
  
      <!-- Service Type Dropdown -->
      <div class="col-md-2">
        <div class="form-group">
          <label for="serviceType">Payor Zip Code</label>
          <input id="serviceType" type="text" pInputText class="form-control" />
        </div>
      </div>
  
      <!-- Carrier ID Input -->
      <div class="col-md-2">
        <div class="form-group">
          <label for="carrierId">ITN/XTN (SED/EEI)</label>
          <input id="carrierId" type="text" pInputText class="form-control" />
        </div>
      </div>
  
      <div class="col-md-2">
        <div class="form-group">
          <label for="carrierId">ATLAS MRN #</label>
          <input id="carrierId" type="text" pInputText class="form-control" />
        </div>
      </div>

      <!-- Account Number Input -->
      <div class="col-md-2">
        <div class="form-group">
          <label for="accountNumber">Terms of Shipment</label>
          <p-dropdown id="shipMethod" [options]="shipMethods"></p-dropdown>
        </div>
      </div>

      <div class="col-md-2">
        <div class="form-group">
          <label for="carrierId">Documents Content Type</label>
          <p-dropdown id="shipMethod" [options]="shipMethods"></p-dropdown>
        </div>
      </div>
    </div>
  
    <!-- Button Row -->
  
  
    <!-- Row for remaining dropdowns and inputs -->
    <div class="row mb-3">
        <!-- Ship Method Dropdown -->
        <div class="col-md-2">
          <div class="form-group">
            <label for="shipMethod">Duties Account #</label>
            <input id="serviceType" type="text" pInputText class="form-control" />
        </div>
        </div>
    
        <!-- Service Type Dropdown -->
        <div class="col-md-2">
          <div class="form-group">
            <label for="serviceType">Ship From Tax #</label>
            <input id="serviceType" type="text" pInputText class="form-control" />
        </div>
        </div>
    
        <!-- Carrier ID Input -->
        <div class="col-md-2">
          <div class="form-group">
            <label for="carrierId">Booking Confirm #</label>
            <input id="carrierId" type="text" pInputText class="form-control" />
          </div>
        </div>
    
        <div class="col-md-2">
          <div class="form-group">
            <label for="carrierId">Ship To Tax #</label>
            <input id="carrierId" type="text" pInputText class="form-control" />
          </div>
        </div>
  
        <!-- Account Number Input -->
        <div class="col-md-2">
          <div class="form-group">
            <label for="accountNumber">Reason for Export</label>
            <p-dropdown id="shipMethod" [options]="shipMethods" ></p-dropdown>
          </div>
        </div>
  
        <div class="col-md-2">
          <div class="form-group">
            <label for="carrierId">Freight Forwarder Tax #</label>
            <input id="carrierId" type="text" pInputText class="form-control" />
          </div>
        </div>
      </div>
  
    <!-- Notes Textarea -->
    <div class="row mb-3">
        <!-- Ship Method Dropdown -->
        <div class="col-md-2">
          <div class="form-group">
            <label for="shipMethod">Payor Country Code</label>
            <input id="carrierId" type="text" pInputText class="form-control" />
          </div>
        </div>
    
        <!-- Service Type Dropdown -->
        <div class="col-md-2">
          <div class="form-group">
            <label for="serviceType">Permit #</label>
            <input id="carrierId" type="text" pInputText class="form-control" />
          </div>
        </div>
    
        <!-- Carrier ID Input -->
        <div class="col-md-2">
          <div class="form-group">
            <label for="carrierId">Booking Confirm #</label>
            <p-dropdown id="shipMethod" [options]="shipMethods"></p-dropdown>
          </div>
        </div>
    
        <div class="col-md-2">
          <div class="form-group">
            <label for="carrierId">CustomDecVal</label>
            <input id="carrierId" type="text" pInputText class="form-control" />
          </div>
        </div>
  
        <!-- Account Number Input -->
        <div class="col-md-2">
          <div class="form-group">
            <label for="accountNumber">Compli. Statement</label>
            <input id="accountNumber" type="text" pInputText class="form-control" />
          </div>
        </div>
  
      </div>
    <!-- Additional options -->
    <div class="titleCard mt-3">
      <div class="name">
      <div class="row">
        <div class="col-md-1">
         <span>H U</span>
        </div>
        <div class="col-md-1">
          <span>product no</span>     
        </div>
        <div class="col-md-1">
            <span>License NO </span>  
        </div>
        <div class="col-md-1">
            <span>E C C</span>
        </div>
        <div class="col-md-1">
            <span>Description</span>
        </div>
        <div class="col-md-1">
            <span>U O M</span>
        </div>
        <div class="col-md-1">
            <span>CURRENCY</span>
        </div>
        <div class="col-md-1">
            <span>Country of MFR</span>
        </div>
        <div class="col-md-1">
            <span>Harmonized</span>
        </div>
        <div class="col-md-1">
            <span>Weight</span>
        </div>
        <div class="col-md-1">
            <span>Quantity</span>
        </div>
        <div class="col-md-1">
            <span>Unit Value</span>
        </div>
      </div>
        <!-- Add any additional form elements here -->
      </div>
    </div>
  </form>
  