import { Component ,OnInit} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ERPSHIPService } from '../erpship.service';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-routing-guide-parameters',
  templateUrl: './routing-guide-parameters.component.html',
  styleUrls: ['./routing-guide-parameters.component.scss'],providers: [MessageService],
})
export class RoutingGuideParametersComponent  implements OnInit{

  tableProperties_dialog=false;
  xfields: any = {}
  isDataLoaded=false;
  constructor(private router: Router, public messageService: MessageService, private xfservice: ERPSHIPService) {
    this.xfservice.processAuth().subscribe((data) => { }, error => {
      console.log("No token found")
      this.router.navigate(['']);
    });

  

  }
  ngOnInit(): void {
    
    
    this.xfservice.getScreenTemplateByName('RoutingGuideFields').subscribe((data:any)=>{

      this.xfields=data[0].details
      this.isDataLoaded=true;
     // console.log(this.xfields);
    })
  }
  SaveTemplate(templatename: any): void {
    this.xfservice
    .saveScreenTemplateByName(templatename, this.xfields)
    .subscribe((data) => {

      //this.tableProperties_dialog = false
      this.messageService.add({
        key: 'tc',
        severity: 'success',
        summary: 'Template',
        detail: templatename + ' Properties are successfully saved.',
      });


    });

  }

}
