<div class="chat-container" #scrollMe [scrollTop]="scrollMe.scrollHeight">
  <div *ngFor="let message of messages" class="message">
    <div *ngIf="message['source']=='User'">
      <div class="grid p-fluid">
        <div class="field col-1">
          <p-avatar image="assets/images/avatar.png" shape="circle">
          </p-avatar>
        </div>

        
        <div class="field col-6">
          <div class="msg">
            {{ message.data.question }}
          </div>

          <div class="field col-5"></div>
        </div>
      </div>

    </div>

    <div  *ngIf="message['source']=='Chatbot'">
      <div class="grid p-fluid ">
        <div class="field col-11">
          
          <div class="chatmsg" *ngIf="message.data !== '....'">
            {{ message.data.output }}    
          </div>
        </div>
     


        <div class="field col-1">
          <p-avatar image="assets/images/chatbot.png" size="large" shape="circle">
          </p-avatar>
          <p-progressSpinner  styleClass="w-2rem h-2rem" strokeWidth="8" *ngIf="message.data == '....'"></p-progressSpinner>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="grid p-fluid mt-1">
  <div class="field col-11">
    <input class="p-inputtext-lg" pInputText [(ngModel)]="userMessage" placeholder="Type your message..."
      (keydown.enter)="sendMessage()" />
  </div>
  <div class="field col-1">
    <img id="layout-topbar-logo" src="assets/images/mike.png" alt="babylon-layout" (click)="processmike()" />
  </div>
</div>