<div class="layout-topbar" style="background-color: white !important;" *ngIf="isDataLoaded">
    <!-- <div class="layout-topbar"  *ngIf="isDataLoaded"> -->
    <a routerLink="/home/DashBoards" class="layout-topbar-logo">
        <img id="layout-topbar-logo" src="assets/images/companylogo.png" alt="babylon-layout" />
    </a>

    <p-splitButton label="Shipping" [model]="menuitems" styleClass="p-button-text mr-2 mb-2 ps-5"></p-splitButton>

    <!-- <a class="layout-menu-button"  href="#" (click)="appMain.onMenuButtonClick($event)"> -->
    <!-- <i class="pi pi-bars">   </i>  -->
    <!-- </a> -->

    <!-- <a id="topbar-menu-button"   href="#" (click)="appMain.onTopbarMenuButtonClick($event)"> -->
    <!-- <i class="pi pi-ellipsis-v"></i> -->
    <!-- </a> -->
    <!-- <label>1.6</label> -->
    <ul class="topbar-menu fadeInDown" [ngClass]="{'topbar-menu-visible': appMain.topbarMenuActive}">


        <li #profile class="user-profile" *ngIf="app.profileMode==='popup'||appMain.isHorizontal()"
            [ngClass]="{'active-topmenuitem':appMain.activeTopbarItem === profile}">

            <a href="#" (click)="appMain.onTopbarItemClick($event,profile)">

                <p-avatar [image]="profileImage" size="large" shape="circle">
                </p-avatar>


            </a>

            <ul class="fadeInDown">
                <li role="menuitem">
                    <a href="#" (click)="appMain.onTopbarSubItemClick($event)" (click)="openProfile()">
                        <i class="pi pi-user"></i>
                        <span>Profile</span>
                    </a>
                </li>

                <li role="menuitem">
                    <a href="#" (click)="appMain.onTopbarSubItemClick($event)" (click)="processLogout()">
                        <i class="pi pi-sign-out"></i>
                        <span>Sign out</span>
                    </a>
                </li>
            </ul>
        </li>
        <li #masterConfig [ngClass]="{'active-topmenuitem':appMain.activeTopbarItem === masterConfig}">
            <a href="#" (click)="appMain.onTopbarItemClick($event,masterConfig)" (click)="processchat()">
                <i class="topbar-icon pi pi-comment"></i>
                <span class="topbar-item-name">Settings</span>
            </a>


            <!-- <ul class="fadeInDown">
        
                <ng-template ngFor let-formItem [ngForOf]="masterforms">
                    <li role="menuitem">
                        <a [routerLink]='formItem.rl' (click)="appMain.onTopbarSubItemClick($event)">
                            <i [class]="formItem.icon"></i>
                            <span>{{ formItem.label }}</span>
                        </a>
                    </li>
                </ng-template>
            </ul> -->
        </li>
        <li #settings [ngClass]="{'active-topmenuitem':appMain.activeTopbarItem === settings}">
            <a href="#" (click)="appMain.onTopbarItemClick($event,settings)">
                <i class="topbar-icon pi pi-cog" styleClass="p-button-rounded p-button-text"></i>
                <span class="topbar-item-name">Settings</span>

            </a>
            <ul class="fadeInDown">
                <li role="menuitem">
                    <a href="#" style="display: none;" (click)="appMain.onTopbarSubItemClick($event)"
                        (click)="processTheam()">
                        <i class="pi pi-pencil"></i>
                        <span>Change Theme</span>

                    </a>
                </li>
                <li role="menuitem">
                    <a routerLink='/home/SpecialService' (click)="appMain.onTopbarSubItemClick($event)">
                        <i class=" pi pi-cog"></i>
                        <span>Special Services</span>
                    </a>
                </li>
                <li role="menuitem">
                    <a routerLink='/home/consolidationTemplate' (click)="appMain.onTopbarSubItemClick($event)">
                        <i class=" pi pi-cog"></i>
                        <span>Consolidation Template</span>
                    </a>
                </li>

                <li role="menuitem">
                    <a routerLink='/home/DynamicForms' (click)="appMain.onTopbarSubItemClick($event)">
                        <i class=" pi pi-file"></i>
                        <span>Dynamic Forms</span>
                    </a>
                </li>

                <li role="menuitem">
                    <a routerLink='/home/RoutingGuideParameters' (click)="appMain.onTopbarSubItemClick($event)">
                        <i class=" pi pi-file"></i>
                        <span>Routing Guide Settings</span>
                    </a>
                </li>

                <li role="menuitem">
                    <a routerLink='/home/RoutingGuideDefaults' (click)="appMain.onTopbarSubItemClick($event)">
                        <i class=" pi pi-file"></i>
                        <span>Routing Guide Defaults</span>
                    </a>
                </li>

                <li role="menuitem">
                    <a routerLink='/home/RGFieldPriority' (click)="appMain.onTopbarSubItemClick($event)">
                        <i class=" pi pi-file"></i>
                        <span>Routing Filed Priority</span>
                    </a>
                </li>

            </ul>
        </li>


        <li><span><i class="fa fa-bell" style="font-size: 18px;margin-top: 10px;"></i></span></li>

        <li #masterConfig2 [ngClass]="{'active-topmenuitem':appMain.activeTopbarItem === masterConfig2}">
            <!-- <a href="#" (click)="appMain.onTopbarItemClick($event,masterConfig2)" (click)="locop.toggle($event)" (click)="getForms()">
                <i class="topbar-icon pi pi-plus"></i>
                <span class="topbar-item-name">Settings</span>
            </a> -->

            <!-- {{'V 1.x'}} -->
            <a href="#" (click)="appMain.onTopbarItemClick($event,masterConfig2)" (click)="locop.show($event)"
                (click)="getForms()">
                <i class="topbar-icon pi pi-list"></i>
                <span class="topbar-item-name">Settings</span>
            </a>


        </li>









        <!-- Orifinal menu Start-->

        <!-- Orifinal menu End-->



        <!-- duplicate menu End-->

    </ul>
</div>


<p-overlayPanel [showTransitionOptions]="'0ms'" [hideTransitionOptions]="'0ms'" #locop>
    <p-panelMenu [model]="items" [style]="{'width':'300px'}"></p-panelMenu>
</p-overlayPanel>

<p-dialog [(visible)]="chatflag" [style]="{ width: '70%' }" [header]="'Profile : ' + udetails['user_id']  "
    [modal]="true" styleClass="p-fluid">
    <app-chatbot></app-chatbot>
</p-dialog>



<p-dialog [(visible)]="profileflag" [style]="{ width: '70%' }" [header]="'Profile : ' + udetails['user_id']  "
    [modal]="true" styleClass="p-fluid">

    <div class="grid p-fluid">
        <div class="col-12 md:col-12 mt-3" hidden="true">
            <span class="p-float-label">
                <input type="text" pInputText [(ngModel)]="user['user_id']" />
                <label>First Name</label>
            </span>
        </div>
        <div class="col-12 md:col-6 mt-3">
            <span class="p-float-label">
                <input type="text" pInputText [(ngModel)]="user['FirstName']" />
                <label>First Name</label>
            </span>
        </div>
        <div class="col-12 md:col-6 mt-3">
            <span class="p-float-label">
                <input type="text" pInputText [(ngModel)]="user['LastName']" />
                <label>Last Name</label>
            </span>
        </div>
        <div class="col-12 md:col-6 mt-3">
            <span class="p-float-label">
                <input type="text" pInputText [(ngModel)]="user['Address1']" />
                <label>Address1</label>
            </span>
        </div>

        <div class="col-12 md:col-6 mt-3">
            <span class="p-float-label">
                <input type="text" pInputText [(ngModel)]="user['Address2']" />
                <label>Address2</label>
            </span>
        </div>
        <div class="col-12 md:col-6 mt-3">
            <span class="p-float-label">
                <input type="text" pInputText [(ngModel)]="user['City']" />
                <label>City</label>
            </span>
        </div>
        <div class="col-12 md:col-6 mt-3">
            <span class="p-float-label">
                <input type="text" pInputText [(ngModel)]="user['State']" />
                <label>State</label>
            </span>
        </div>
        <div class="col-12 md:col-6 mt-3">
            <span class="p-float-label">
                <input type="text" pInputText [(ngModel)]="user['ZipCode']" />
                <label>Zip Code</label>
            </span>
        </div>
        <div class="col-12 md:col-6 mt-3">
            <span class="p-float-label">
                <input type="text" pInputText [(ngModel)]="user['Country']" />
                <label>Country</label>
            </span>
        </div>
        <div class="col-12 md:col-6 mt-3">
            <span class="p-float-label">
                <input type="text" pInputText [(ngModel)]="user['Phone']" />
                <label>Phone</label>
            </span>
        </div>
        <div class="col-12 md:col-6 mt-3">
            <span class="p-float-label">
                <input type="text" pInputText [(ngModel)]="user['E-mail']" />
                <label>E-mail</label>
            </span>
        </div>


        <p-image [src]="'data:image/png;base64,' + user['Image']" alt="Image" width="150" [preview]="true"></p-image>
        <p-fileUpload name="myfile" [maxFileSize]="50000" accept="{{filetype}}" mode="basic" chooseLabel="Select Image"
            (onSelect)="fileToBase64($event.files[0])"></p-fileUpload>



    </div>
    <button pButton pRipple label="Save" icon="pi pi-check" class="p-button-sm" (click)="Save()"></button>
</p-dialog>