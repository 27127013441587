<div class="pagedetails-header">
  <div class="titleContainer">
    <div class="pageTitle">{{ pageTitle }}</div>
    <button class="buttonTitle btntext" (click)="onTitleBtnClick()">{{ buttonTitle }}</button>
  </div>
  <div class="pageStatus">
    <div class="totalRec-container" (click)="statusProgressBarClick()">
      <div class="count">{{ total || 0 }}</div>
      <div class="thumbtitle">Total Records</div>
      <div class="totalProgressbar-cls"></div>
    </div>
    <div class="open-container" (click)="statusProgressBarClick()">
      <div class="count">{{ total || 0 }}</div>
      <div class="thumbtitle">Open</div>
      <div class="openProgressbar-cls"></div>
    </div>
    <div class="shipped-container" (click)="statusProgressBarClick()">
      <div class="count">{{ active || 0 }}</div>
      <div class="thumbtitle">Shipped</div>
      <div class="shippedProgressbar-cls"></div>
    </div>
    <div class="cancelled-container" (click)="statusProgressBarClick()">
      <div class="count">{{ inActive || 0 }}</div>
      <div class="thumbtitle">Cancelled</div>
      <div class="cancelledprogressbar-cls"></div>
    </div>
  </div>
</div>