
import {Component, OnInit} from '@angular/core';
import { AppMenuServiceService } from '../app.menu.service.service';
import {PrimeNGConfig} from 'primeng/api';
import { AppComponent } from '../app.component';
import { Router, NavigationEnd } from '@angular/router';
import { ERPSHIPService } from '../erpship.service';

@Component({
  selector: 'app-app-main',
  templateUrl: './app-main.component.html',
  styleUrls: ['./app-main.component.scss']
})
export class AppMainComponent {

    showNavbar: boolean = true;

  ngOnInit(): void {
    // Call checkRoute with the current URL on component initialization
    this.checkRoute(this.router.url);

    // Subscribe to route changes during navigation
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.checkRoute(event.urlAfterRedirects);
      }
    });
  }

  checkRoute(url: string): void {
   // Hide navbar for shipnow route, show it for other routes
    if (url === '/home/shipnow') {
      this.showNavbar = false;
    } else {
        this.showNavbar = false;
      } 
  }

  rotateMenuButton: boolean = false;

    topbarMenuActive: boolean= true;

    overlayMenuActive: boolean= false;

    staticMenuDesktopInactive: boolean= false;

    staticMenuMobileActive: boolean= false;

    menuClick: boolean= false;

    topbarItemClick: boolean= false;

    configClick: boolean= false;

    activeTopbarItem: any;

    menuHoverActive: boolean= false;

    configActive: boolean= false;

    inlineMenuActive: boolean= false;

    inlineMenuClick: boolean= false;

    constructor(private menuService: AppMenuServiceService, private primengConfig: PrimeNGConfig, public app: AppComponent,private router: Router, private xfservice: ERPSHIPService) {

        this.xfservice.processAuth().subscribe((data:any) => { }, (error:any) => {
            console.log("No token found")
            this.router.navigate(['']);
          });

        //   console.log(this.app)

    }

    onLayoutClick() {
        if (!this.topbarItemClick) {
            this.activeTopbarItem = null;
            this.topbarMenuActive = false;
        }

        if (!this.menuClick || (this.inlineMenuClick && this.isSlim())) {
            if (this.isHorizontal() || this.isSlim()) {
                this.menuService.reset();
            }

            if (this.overlayMenuActive || this.staticMenuMobileActive) {
                this.hideOverlayMenu();
            }

            this.menuHoverActive = false;
        }

        if (this.configActive && !this.configClick) {
            this.configActive = false;
        }

        if (this.inlineMenuActive && !this.inlineMenuClick && !this.isStatic()) {
            this.inlineMenuActive = false;
        }

        this.inlineMenuClick = false;
        this.configClick = false;
        this.topbarItemClick = false;
        this.menuClick = false;
    }

    onMenuButtonClick(event:any) {
        this.menuClick = true;
        this.rotateMenuButton = !this.rotateMenuButton;
        this.topbarMenuActive = false;

        if (this.app.layoutMode === 'overlay' && (!this.isMobile() && !this.isTablet())) {
            this.overlayMenuActive = !this.overlayMenuActive;
        } else {
            if (this.isDesktop()) {
                this.staticMenuDesktopInactive = !this.staticMenuDesktopInactive;
            } else {
                this.staticMenuMobileActive = !this.staticMenuMobileActive;
            }
        }

        event.preventDefault();
    }

    onMenuClick($event:any) {
        this.menuClick = true;

        if (this.inlineMenuActive && !this.inlineMenuClick && !this.isStatic()) {
            this.inlineMenuActive = false;
        }
    }

    onInlineMenuClick(event:any) {
        this.inlineMenuActive = !this.inlineMenuActive;
        this.inlineMenuClick = true;
    }

    onTopbarMenuButtonClick(event:any) {
        this.topbarItemClick = true;
        this.topbarMenuActive = !this.topbarMenuActive;

        this.hideOverlayMenu();

        event.preventDefault();
    }

    onTopbarItemClick(event:any, item:any) {
       
        this.topbarItemClick = true;

        if (this.activeTopbarItem === item) {
            this.activeTopbarItem = null;
        } else {
            this.activeTopbarItem = item;
        }
        
        event.preventDefault();
    }

    onTopbarSubItemClick(event:any) {

        
       // alert("hahah : " + JSON.stringify(event))
       // console.log('Logout event : ' + JSON.stringify(event));
        event.preventDefault();
    }

    onConfigClick(event:any) {
        this.configClick = true;
    }

    onRippleChange(event:any) {
        this.app.ripple = event.checked;
        this.primengConfig = event.checked;
    }

    hideOverlayMenu() {
        this.rotateMenuButton = false;
        this.overlayMenuActive = false;
        this.staticMenuMobileActive = false;
    }

    isTablet() {
        const width = window.innerWidth;
        return width <= 1024 && width > 640;
    }

    isDesktop() {
        return window.innerWidth > 1024;
    }

    isMobile() {
        return window.innerWidth <= 640;
    }

    isOverlay() {
        return this.app.layoutMode === 'overlay';
    }

    isHorizontal() {
        return this.app.layoutMode === 'horizontal';
    }

    isSlim() {
        return this.app.layoutMode === 'slim';
    }

    isStatic() {
        return this.app.layoutMode === 'static';
    }


}
